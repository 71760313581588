import { useUserStore } from "@entities/user";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { FORGOT_PASSWORD_STEPS } from "@widgets/interfaces";
import { Input } from "antd";
import { OTPProps } from "antd/es/input/OTP";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { validationSchema } from "./validation";
import "./checkCode.scss";

const baseTimerNum = 59;

interface IProps {
  onClickContinue: (obj: any) => void;
}

const CheckCode = (props: IProps) => {
  const [searchParams] = useSearchParams();
  const { resendCodeSignUp, checkVerifyCodeSignUp } = useUserStore(
    (state) => state
  );
  const { openNotification, contextHolder } = NotificationCustom();
  const [isShowTimer, setShowTimer] = useState(
    !!localStorage.getItem("resendTimer")
  );
  const [seconds, setSeconds] = useState(0);
  const { onClickContinue } = props;
  const [responseErrorSignUpText, setResponseErrorSignUpText] = useState("");
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const data = await checkVerifyCodeSignUp(
          values.code,
          searchParams.get("email") || ""
        );
        data.id &&
          onClickContinue({
            step: FORGOT_PASSWORD_STEPS.SET_PASSWORD,
            token: data.verificationCode,
          });
      } catch (error: any) {
        setResponseErrorSignUpText(error.response.data.message);
        openNotification({
          message: error.response.data.message,
          type: "default",
          duration: 5,
        });
      }
    },
  });

  const resendCode = () => {
    try {
      resendCodeSignUp(searchParams.get("email") || "");
      localStorage.setItem("resendTimer", Date.now().toString());
      setShowTimer(true);
      setSeconds(0);
      openNotification({
        message: "Another code was sent to your email",
        type: "default",
        duration: 5,
      });
    } catch (error) {}
  };

  const getElapsedSeconds = () => {
    const time = localStorage.getItem("resendTimer");
    if (!time) return 0;
    const timestamp = Number(time);
    const now = Date.now();
    return Math.floor((now - timestamp) / 1000);
  };

  useEffect(() => {
    if (isShowTimer) {
      startTimer();
    }
  }, [isShowTimer]);

  const startTimer = () => {
    setSeconds(getElapsedSeconds());

    const timer = setInterval(() => {
      setSeconds((prev) => {
        const newSeconds = prev + 1;
        if (newSeconds > baseTimerNum - 1) {
          clearInterval(timer);
          setShowTimer(false);
          localStorage.removeItem("resendTimer");
        }
        return newSeconds;
      });
    }, 1000);
  };

  const viewSeconds =
    baseTimerNum - seconds >= 10
      ? baseTimerNum - seconds
      : "0" + (baseTimerNum - seconds);

  const onInput = (value: any) => {
    setFieldValue("code", value.join(""));
  };

  const sharedProps: OTPProps = {
    onInput,
  };

  const { setFieldValue, errors, values } = formik;

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit();
        e.preventDefault();
      }}
    >
      {contextHolder}
      <div className="check-code">
        <div className="check-code-block">
          <div className="title">Check your email</div>
          <div className="text ">
            We sent a verification code to
            <div className="email">{searchParams.get("email") || ""}</div>
          </div>
        </div>
        <div className="poz-r h-76">
          <span className="label">6-digit verification code</span>
          <div
            className={
              errors.code || responseErrorSignUpText
                ? "verify-code__container-error"
                : ""
            }
          >
            <Input.OTP
              formatter={(str) => str.toUpperCase()}
              {...sharedProps}
            />
          </div>
          {(errors.code || responseErrorSignUpText) && (
            <span className="error-validation-message">
              {errors.code || responseErrorSignUpText}
            </span>
          )}
        </div>
        <div className="btn-step">
          <ButtonStyled
            text="Send a code"
            className="setpass__btn"
            type="primary"
            fill="gray-primary-900"
            htmlType="submit"
          />
        </div>

        <div className="verify-form__resend-title">Didn’t receive a code?</div>
        <div
          className={`verify-form__resend-text  poz-r ${
            isShowTimer ? "mt-16 mb-46" : ""
          }`}
        >
          <div
            onClick={resendCode}
            className={`${
              isShowTimer ? "disabled-no-effect clicked" : ""
            } cursor-p ta-center mt-6`}
          >
            Click to resend
          </div>

          {isShowTimer && (
            <div className="verify-form__timer clicked-timer">
              &nbsp;in 0:{viewSeconds}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default CheckCode;
