import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";

import {
  IInviteUser,
  ISettingsStore,
  ISettingsUser,
  UserRolesEnum,
} from "./settings.types";
import settingsService from "../api";
import { IDataTransfer } from "@shared/interfaces";
import { loadStateWrapper } from "@shared/utils";

export const useSettingsStore = create<ISettingsStore>()(
  devtools(
    immer((set, get) => ({
      usersTable: [],
      inviteUserError: "",
      recordsCountUsers: 0,
      filtersUsersTable: {},
      isLoadingUsersTableInitial: true,
      isLoadingUsersTable: false,
      isLoadingInviteUser: false,
      getUsersTable: async (params: IDataTransfer) => {
        try {
          const { data } = await loadStateWrapper(
            set,
            settingsService.getUsers(params),
            "isLoadingUsersTable"
          );

          set({
            usersTable: data.users,
            recordsCountUsers: data.count,
            // isLoadingUsersTableInitial: false,
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      setUsersTable: (users: ISettingsUser[]) => set({ usersTable: users }),
      saveInviteUser: async (inviteUser: IInviteUser) => {
        try {
          const { data } = await loadStateWrapper(
            set,
            settingsService.inviteUsers(inviteUser),
            "isLoadingInviteUser"
          );
          return data;
        } catch (error: any) {
          if (error.response && error.response.status === 400) {
            set({ inviteUserError: error.response.data.message });
            return error.response.data.message;
          } else {
            console.log(error);
          }
        }
      },
      deleteUserById: async (id: string) => {
        const { data } = await settingsService.deleteUserById(id);
      },
      editUserById: async (id: string, role: UserRolesEnum) => {
        const { data } = await settingsService.editUserById(id, role);
      },
      getFiltersForUsers: async () => {
        const { data } = await settingsService.getFiltersForUsers();
        set({ filtersUsersTable: data, isLoadingUsersTableInitial: false });
      },
      resendInvitation: async (email: string) => {
        await settingsService.resendInvitation(email);
      },
      setInviteUserErrorText: (text: string) => set({ inviteUserError: text }),
    }))
  )
);
