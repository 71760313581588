interface IProps {
  color?: string;
}

const Export = ({ color= '#212529' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
  <path d="M1.93398 4.66673H2.99398V8.00006C2.99398 8.36673 3.29398 8.66673 3.66065 8.66673H6.32732C6.69398 8.66673 6.99398 8.36673 6.99398 8.00006V4.66673H8.05398C8.64732 4.66673 8.94732 3.94673 8.52732 3.52673L5.46732 0.466729C5.20732 0.206729 4.78732 0.206729 4.52732 0.466729L1.46732 3.52673C1.04732 3.94673 1.34065 4.66673 1.93398 4.66673ZM0.333984 10.6667C0.333984 11.0334 0.633984 11.3334 1.00065 11.3334H9.00065C9.36732 11.3334 9.66732 11.0334 9.66732 10.6667C9.66732 10.3001 9.36732 10.0001 9.00065 10.0001H1.00065C0.633984 10.0001 0.333984 10.3001 0.333984 10.6667Z" fill={color}/>
  </svg>
);

export default Export;