import { ArrowBorder, Close, Delete, Plus } from "@shared/ui/assets";
import {
  ButtonStyled,
  InputCustom,
  LoaderDefault,
  NotificationCustom,
} from "@shared/ui/components";
import { Divider, Modal, Select } from "antd";
import { FC, useRef, useState } from "react";
import "./inviteUsersPopup.scss";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import {
  capitalizeFirstLetter,
  generateId,
  getParamsAsObject,
} from "@shared/utils";
import {
  IInviteUser,
  UserRolesEnum,
  useSettingsStore,
} from "@entities/settings";
import { validationSchema } from "./validation";
import { useSearchParams } from "react-router-dom";
import { useUserStore } from "@entities/user";

interface IProps {
  flag: boolean;
  onClose: () => void;
}

const optionsSelectRoles = [
  {
    label: capitalizeFirstLetter(UserRolesEnum.ADMIN),
    value: capitalizeFirstLetter(UserRolesEnum.ADMIN),
    description:
      "Viewers can access and view groups but cannot make changes in group configuration or access workspace settings.",
  },
  {
    label: capitalizeFirstLetter(UserRolesEnum.MANAGER),
    value: capitalizeFirstLetter(UserRolesEnum.MANAGER),
    description:
      "Managers have full control within groups, including creating and configuring groups, repricing product items, editing viewer roles.",
  },
  {
    label: capitalizeFirstLetter(UserRolesEnum.VIEWER),
    value: capitalizeFirstLetter(UserRolesEnum.VIEWER),
    description:
      "Admins have full control within workspace and groups, including inviting members, managing billing, accessing settings, and editing user roles.",
  },
];

const initialValue = {
  email: "",
  role: capitalizeFirstLetter(UserRolesEnum.VIEWER),
};

const InviteUsersPopup: FC<IProps> = (props) => {
  const { flag, onClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    saveInviteUser,
    inviteUserError,
    getUsersTable,
    getFiltersForUsers,
    setInviteUserErrorText,
    isLoadingInviteUser,
  } = useSettingsStore((state) => state);
  const [open, setOpen] = useState(false);
  const user = useUserStore((state) => state.user);

  const handleSelect = (value: string) => {
    handleChangeSelect(value);
    setOpen(false);
  };

  const { contextHolder, openNotification } = NotificationCustom();

  const initialValues: IInviteUser = initialValue;
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const chooseEnumRole =
          UserRolesEnum[
            values.role?.toUpperCase() as keyof typeof UserRolesEnum
          ];

        const res = await saveInviteUser({
          email: values.email,
          role: chooseEnumRole,
        });

        if (!res.id) {
          return;
        }
        openNotification({
          placement: "bottom",
          message: `Invite sent to ${values.email}`,
          duration: 5,
          type: "success",
          className: "notification__wrapper",
        });
        getUsersTable(getParamsAsObject(searchParams));
        getFiltersForUsers();
        onClose();
        resetForm();
        setInviteUserErrorText("");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const onCloseLocal = () => {
    onClose();
    resetForm();
    setInviteUserErrorText("");
  };

  const { setFieldValue, values, errors, resetForm } = formik;

  const handleChangeEmail = (e: any) => {
    const value = e.target.value;
    setFieldValue("email", value);
  };

  const handleChangeSelect = (value: any) => {
    setFieldValue("role", value);
  };

  const chooseOptions = () => {
    if (user.role === UserRolesEnum.ADMIN) {
      return optionsSelectRoles.filter((el) => {
        return el.value !== capitalizeFirstLetter(UserRolesEnum.ADMIN);
      });
    }
    if (user.role === UserRolesEnum.MANAGER) {
      return optionsSelectRoles.filter((el) => {
        return (
          el.value !== capitalizeFirstLetter(UserRolesEnum.ADMIN) &&
          el.value !== capitalizeFirstLetter(UserRolesEnum.MANAGER)
        );
      });
    }
    if (user.role === UserRolesEnum.VIEWER) {
      return optionsSelectRoles.filter((el) => {
        return (
          el.value !== capitalizeFirstLetter(UserRolesEnum.ADMIN) &&
          el.value !== capitalizeFirstLetter(UserRolesEnum.MANAGER)
        );
      });
    }
    return optionsSelectRoles;
  };

  return (
    <Modal
      open={flag}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onCloseLocal()}
      className="users-modal-invite"
    >
      <>
        {contextHolder}
        <div className="users-modal-invite__wrapper">
          <div onClick={onCloseLocal} className="modal-close__icon">
            <Close />
          </div>
          <h3>Invite Team Member</h3>
        </div>
        <Divider />
      </>
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
      >
        <div className="modal__invite-user-items">
          <div className="modal__invite-user-content">
            <div className="modal__invite-user-form-block">
              <div className="modal__invite-user-email-block">
                <div className="user-label">Email</div>
                <div className="user-input">
                  <InputCustom
                    value={values.email}
                    onChangeInput={(e) => handleChangeEmail(e)}
                    type="text"
                    name="name-invite-user"
                    className={
                      errors.email || inviteUserError
                        ? "error-validation-input"
                        : ""
                    }
                    fill="gray-primary-900"
                  />
                  {(errors.email || inviteUserError) && (
                    <span className="validation-error">
                      {errors.email || inviteUserError}
                    </span>
                  )}
                </div>
              </div>
              <div className="modal__invite-user-role-block ">
                <div className="role-label">Role</div>
                <div className="role-select">
                  <Select
                    open={open}
                    onDropdownVisibleChange={setOpen}
                    value={values.role}
                    className="custom-arrow"
                    suffixIcon={<ArrowBorder color="#212527" />}
                    dropdownStyle={{ width: "530px" }}
                    dropdownRender={() => (
                      <>
                        {chooseOptions().map((el, ind) => {
                          return (
                            <div
                              key={ind}
                              className={
                                values.role === el.value
                                  ? "role-select-item-active"
                                  : "role-select-item"
                              }
                              onClick={() => {
                                handleSelect(el.value);
                              }}
                            >
                              <div className="role-select-item-title">
                                {el.label}
                              </div>
                              <div className="role-select-item-description">
                                {el.description}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className="modal__monitoring-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onCloseLocal}
            />
            {/* //Send invite */}

            <ButtonStyled
              type="primary"
              text="Send invite"
              htmlType="submit"
              fill="gray-primary-900"
              className="w-120"
              icon={isLoadingInviteUser ? <LoaderDefault /> : <></>}
              disabled={isLoadingInviteUser}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default InviteUsersPopup;
