import { IProduct } from "@entities/products";
import { EGroupStatus, EGroupType, IGroupModify } from "./groups.types";

export class GroupDTO {
  private readonly status: string = EGroupStatus.COMING_SOON;
  private readonly totalItems: number = 0;
  private readonly repricedItems: number = 0;
  private readonly name: string = "";
  private readonly type: EGroupType;
  private readonly products: IProduct[] = [];
  private readonly selectedProductsIds: string[] = [];
  private readonly groupId: string = "";
  private readonly selectedCategories: string[] = [];

  constructor(groupData: IGroupModify) {
    this.name = groupData?.name || "";
    this.type = groupData?.type || EGroupType.DRAFT;
    this.products = groupData?.products || [];
    this.selectedProductsIds = groupData?.selectedProductsIds || [];
    this.selectedCategories = groupData?.selectedCategories || [];
  }

  create() {
    return this;
  }
}
