import { FC } from "react";

import { IFormUserInfoState, ROUTES } from "@shared/interfaces";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { Input, Select } from "antd";
import { ArrowBorder } from "@shared/ui/assets";
import "./setInfoForm.scss";
import { useFormik } from "formik";
import { validationSchema } from "./validations/setInfoFormValidation";
import { useSearchParams } from "react-router-dom";
import { EDepartment, useUserStore } from "@entities/user";

const user: IFormUserInfoState = {
  fullName: "",
  department: "",
  otherDepartmentName: "",
};

interface IProps {
  onContinueClick: ({ type }: { type: SIGNUP_STEPS }) => void;
}

const DEPARTMENTS = [
  {
    value: EDepartment.MARKETING,
    label: "Marketing",
  },
  {
    value: EDepartment.FINANCE_ACCOUNTING,
    label: "Finance & Accounting",
  },
  {
    value: EDepartment.MERCHANDISING,
    label: "Merchandising",
  },
  {
    value: EDepartment.OPERATIONS,
    label: "Operations",
  },
  {
    value: EDepartment.IT,
    label: "IT",
  },
  {
    value: EDepartment.OWNER_PARTNER,
    label: "Owner / Partner",
  },
  {
    value: EDepartment.OTHER,
    label: "Other",
  },
];

const SetInfoForm: FC<IProps> = ({ onContinueClick }) => {
  const [searchParams] = useSearchParams();
  const { onCompleteSignup } = useUserStore((state) => state);
  const { contextHolder, openNotification } = NotificationCustom();

  const formik = useFormik<IFormUserInfoState>({
    initialValues: user,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const [firstName, lastName] = values.fullName.split(" ");
        const res = await onCompleteSignup({
          firstName: firstName,
          lastName: lastName,
          department: values.otherDepartmentName || values.department,
          email: searchParams.get("email") || "",
          token: searchParams.get("token") || "",
        });

        if (res.accessToken) {
          localStorage.setItem("_accessToken", res.accessToken);
        }

        window.location.href = ROUTES.ADMIN_GROUPS;
      } catch (error: any) {
        openNotification({
          message: error.response.data.message,
          type: "error",
          icon: null,
        });
      }
    },
  });

  const { setFieldValue, values, errors } = formik;

  const onChange = (value: string) => {
    setFieldValue("department", value);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("fullName", e.target.value);
  };

  return (
    <div>
      {contextHolder}
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
        className="signup-form_wrapper"
      >
        <div className="form__item custom-height">
          <label className="" htmlFor={"full_name"}>
            Full name
          </label>

          <Input
            id="full_name"
            className="gray-primary-900 form-item__mb24"
            name="fullName"
            onChange={onChangeInput}
            type="text"
            value={values.fullName}
            status={errors.fullName && "error"}
          />
        </div>
        {errors.fullName && (
          <span className="error-validation-message">{errors.fullName}</span>
        )}

        <div className="form__item custom-height">
          <label className="" htmlFor={"department"}>
            Department
          </label>

          <Select
            optionFilterProp="label"
            suffixIcon={<ArrowBorder />}
            onChange={onChange}
            className={`gray-primary-900 form-item__mb24  custom-arrow ${
              errors.department && "error-validation-select"
            }`}
            options={DEPARTMENTS}
          />
        </div>
        {errors.department && (
          <span className="error-validation-message">{errors.department}</span>
        )}

        {values.department === EDepartment.OTHER && (
          <>
            <div className="form__item">
              <label className="" htmlFor={"other_dep_name"}>
                Your department
              </label>

              <Input
                id="other_dep_name"
                className="gray-primary-900 form-item__mb24"
                name="other_dep_name"
                onChange={({ target }) =>
                  setFieldValue("otherDepartmentName", target.value)
                }
                type="text"
                value={values.otherDepartmentName}
                status={errors.otherDepartmentName && "error"}
              />
            </div>
            {errors.otherDepartmentName && (
              <span className="error-validation-message-role">
                {errors.otherDepartmentName}
              </span>
            )}
          </>
        )}

        <ButtonStyled
          text="Done"
          className="btn__submit"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />
      </form>
    </div>
  );
};

export default SetInfoForm;
