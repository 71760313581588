import "./signupForm.scss";
import { FC, useState } from "react";
import { IFormEmailState } from "@shared/interfaces";
import {
  AlertCustom,
  ButtonStyled,
  NotificationCustom,
} from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { Input } from "antd";
import { useFormik } from "formik";
import { useUserStore } from "@entities/user";
import { validationSchema } from "./validations/signUpFormValidation";
import { NavLink, useSearchParams } from "react-router-dom";

const user: IFormEmailState = {
  email: "",
};

interface IProps {
  onContinueClick: (obj: any) => void;
}

const SignupForm: FC<IProps> = ({ onContinueClick }) => {
  const { checkInvite } = useUserStore((state) => state);
  const [responseErrorSignUpText, setResponseErrorSignUpText] = useState("");
  const { openNotification, contextHolder } = NotificationCustom();

  const formik = useFormik<IFormEmailState>({
    initialValues: user,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        await checkInvite(values.email);
        onContinueClick({ step: SIGNUP_STEPS.CHECK_CODE, email: values.email });
      } catch (error: any) {
        setResponseErrorSignUpText(error.response.data.message);
        console.log(error);
        openNotification({
          message: "Internal Server Error. Try one more time.",
          type: "default",
          duration: 5,
        });
      }
    },
  });

  const { setFieldValue, values, errors } = formik;

  const alertBody = (
    <div className="alert__body">
      <p>If your email is correct:</p>
      <ul>
        <li>Contact your company admin</li>
        <li>
          Contact us via <NavLink to={""}>support@therelu.com</NavLink>
        </li>
      </ul>
    </div>
  );

  return (
    <div>
      {contextHolder}
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
        className="signup-form_wrapper"
      >
        <div className="form__item custom-height ">
          <label className="signup-form__label" htmlFor={"email"}>
            Email
          </label>

          <Input
            id="email"
            className="gray-primary-900 form-item__mb24"
            name="email"
            onChange={({ target }) => setFieldValue("email", target.value)}
            type="text"
            value={values.email}
            status={(errors.email || responseErrorSignUpText) && "error"}
          />
          {(errors.email || responseErrorSignUpText) && (
            <span className="validation-email">
              {responseErrorSignUpText || "Incorrect email. Please try again"}
            </span>
          )}
        </div>
        {(errors.email || responseErrorSignUpText) && (
          <div className="mt-24">
            <AlertCustom type="default" message={alertBody} showIcon={false} />
          </div>
        )}

        <ButtonStyled
          text="Continue"
          className="signup-form-btn"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />
      </form>
    </div>
  );
};

export default SignupForm;
