import { EDepartment } from "@entities/user";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(
      /^(?=.*\s)[A-Za-zА-Яа-яЁё]{2,}(\s[A-Za-zА-Яа-яЁё]{2,})+$/,
      "Full name must contain at least two words, each at least 2 letters long."
    )
    .required("Please specify your full name"),
  department: Yup.string().required("Please specify your department"),
  otherDepartmentName: Yup.string().when("department", {
    is: EDepartment.OTHER,
    then: (schema) =>
      schema
        .required("Please specify your role")
        .max(50, "Department name must be at most 50 characters long."),
    otherwise: (schema) => schema.notRequired(),
  }),
});
