import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IItemInfoState, Period } from "./itemInfo.types";
import itemInfoService from "../api";
import { loadStateWrapper } from "@shared/utils";
import { IDataTransfer } from "@shared/interfaces";
import { ICompetitor } from "@entities/groups";

export const useItemInfoStore = create<IItemInfoState>()(
  devtools(
    immer((set, get) => ({
      itemCompetitors: [],
      datesOfGraphCompetitors: [],
      isLoading: false,
      datesOfGraphElasticity: [],

      getItemCompetitors: async (productId: string) => {
        try {
          const { data } = await itemInfoService.getItemCompetitors(productId);

          set({
            itemCompetitors: data,
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      changeMonitoringStatus: async (id: number, monitoringStatus: boolean) => {
        try {
          await itemInfoService.changeMonitoringStatus(id, monitoringStatus);

          const newData = get().itemCompetitors.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                monitoringStatus,
              };
            }
            return item;
          });

          set({
            itemCompetitors: newData,
          });
        } catch (e) {
          console.log(e);
        }
      },
      changeLocalMonitoringStatus: (id: number, monitoringStatus: boolean) => {
        const newData = get().itemCompetitors.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              monitoringStatus,
            };
          }
          return item;
        });
        set({
          itemCompetitors: newData,
        });
      },
      getDatesOfGraphCompetitors: async (
        key: string,
        productId: string,
        competitorsIds: any[]
      ) => {
        const { data } = await loadStateWrapper(
          set,
          itemInfoService.getDatesOfGraphCompetitors(
            key,
            productId,
            competitorsIds
          )
        );
        set({
          datesOfGraphCompetitors: data,
        });
      },
      clearDatesOfGraphCompetitors: () => {
        set({
          datesOfGraphCompetitors: [],
        });
      },
      setDatesOfGraphElasticity: (data: any[]) => {
        set({
          datesOfGraphElasticity: data,
        });
      },
      getElasticityDataGraph: async (productId: string) => {
        const { data } = await itemInfoService.getElasticityDataGraph(
          productId
        );

        const newData = data.map((el: any) => ({
          price: +el.price,
          sales: +el.sales,
          revenue: +el.revenue,
          profit: +el.profit,
          cost: +el.cost,
        }));

        set({
          datesOfGraphElasticity: newData,
        });
      },
      filterByStateCompetitorsProduct: async (id: string, state: boolean) => {
        const { data } = await itemInfoService.filterByStateCompetitorsProduct(
          id,
          state
        );
      },
      removeCompetitorMonitoringFromGroup: async (id: string) => {
        const { data } =
          await itemInfoService.removeCompetitorMonitoringFromGroup(id);
      },
    }))
  )
);
