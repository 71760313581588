import { FC } from "react";
import "./groupDeletePopup.scss";
import { Divider, Modal } from "antd";
import { Typography } from "antd";
import { useGroupsStore } from "@entities/groups";
import { ButtonStyled } from "@shared/ui/components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useGroupStore } from "@entities/group";

const { Title } = Typography;

interface IProps {
  flag: boolean;
  onClose: (flag: boolean) => void;
}

const GroupDeletePopup: FC<IProps> = ({ flag, onClose }: IProps) => {
  const navigate = useNavigate();
  const currentGroup = useGroupStore((state) => state.currentGroup);

  const { removeGroup } = useGroupsStore((state) => state);

  const { clearGroup } = useGroupStore((state) => state);

  const onDeleteGroup = async () => {
    try {
      await removeGroup(currentGroup.id);
      onClose(false);
      clearGroup();
      navigate(ROUTES.ADMIN_GROUPS);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={flag}
      onOk={() => {}}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose(false)}
    >
      <div className="groups__form-create">
        <Title level={2} className="groups__form-title">
          Delete Group
        </Title>
        <Divider />
        <Title level={5} className="groups__form-suptitle">
          This will delete {currentGroup.name}. This action will ungroup all
          items inside the group and unplug their strategies. You cannot undo
          this action.
        </Title>
        <Divider />
        <div className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onClose}
            />

            <ButtonStyled
              text="Delete"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={onDeleteGroup}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupDeletePopup;
