import React, { FC, useEffect, useState } from "react";
import "./ItemOverview.scss";
import {
  AutoRepricingEnum,
  Metric,
  MetricToGrow,
  MetricToMaintain,
  PriceFormulaType,
  PriceRoundingEnum,
  Rate,
  SelectLimitPricing,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import moment from "moment";
import {
  ArrowBorder,
  Close,
  Delete,
  Edit,
  Info,
  Plus,
  Warning,
} from "@shared/ui/assets";
import { RepricingStatus } from "@entities/products";
import { Divider, Modal, Select } from "antd";
import {
  BadgeCustom,
  ButtonStyled,
  CustomTooltip,
  InputWithIcons,
  LoaderDefault,
} from "@shared/ui/components";
import { useFormik } from "formik";

import { validationSchema } from "./validationOverview";
import Unlock from "@shared/ui/assets/Unlock";
import Lock from "@shared/ui/assets/Lock";
import {
  checkIsPositiveValue,
  findRangePercentage,
  getItemWord,
  isValidLimitPriceValue,
  isValidLimitValue,
  toFixedCustom,
} from "@shared/utils";
import { useParams } from "react-router-dom";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { VIEWER_DISABLE_ITEMS_TEXT } from "@shared/constants/utilsConstants";
import { useGroupStore } from "@entities/group";

interface IProps {
  productId: string;
}

const optionsSelect = [
  {
    label: "Current price ($)",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
];

const limitOptions = [
  {
    text: (keyWord: string) => `Min ${keyWord} (optional)`,
    key: "min",
    placeholder: "Min",
  },
  {
    text: (keyWord: string) => `Max ${keyWord} (optional)`,
    key: "max",
    placeholder: "Max",
  },
];

const ItemOverview: FC<IProps> = (props) => {
  const { id } = useParams();
  const { productId } = props;
  const { isViewer } = useCheckRole();
  const productsInGroup = useGroupStore((state) => state.productsInGroup);

  const currentProduct = productsInGroup.find(
    (product) => product.id === productId
  );

  const productException = currentProduct?.exceptionLimits;

  const getCountNewPriceReady = useGroupStore(
    (state) => state.getCountNewPriceReady
  );

  const {
    fetchChangeLockProductMode,
    modifyException,
    removeExceptionItemGroupById,
    removeLocalExceptionById,
    getGroup,
    changeCountNewPriceReady,
    setStatusProductById,
    modifyLocalException,
  } = useGroupStore((state) => state);

  const { currentGroup, recordsCountInGroup, strategy } = useGroupStore(
    (state) => state
  );
  const { getExceptions } = useGroupsStore((state) => state);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  const initialValues = productException || {
    pricing: "",
    min: {
      value: "",
    },
    max: {
      value: "",
    },
  };

  useEffect(() => {
    if (productException) {
      resetForm({ values: productException });
    } else {
      resetForm({
        values: { pricing: "", min: { value: "" }, max: { value: "" } },
      });
    }
  }, [productId, productException, isOpenModal]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (currentProduct?.id) {
        try {
          onCloseModalException();
          setStatusProductById(productId, RepricingStatus.PENDING);
          modifyLocalException(currentProduct?.id, values);
          await modifyException(currentProduct?.id, values);
          await getCountNewPriceReady(id || "");
          await getGroup(id || "");
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const handleClickOnLock = (productId: string, isLocked: boolean) => {
    fetchChangeLockProductMode(productId, isLocked);
    changeCountNewPriceReady(isLocked, productId);
  };

  const {
    setFieldValue,
    values,
    setValues,
    resetForm,
    errors,
    setErrors,
    setFieldError,
  } = formik;

  useEffect(() => {
    if (currentGroup.id && id) {
      getExceptions(id);
    }
  }, []);

  if (!currentProduct) {
    return null;
  }

  const renderPriceType = () => {
    if (strategy.strategyType === StrategyTypeEnum.ELASTICITY) {
      return "Price Elasticity";
    }

    if (strategy.strategyType === StrategyTypeEnum.COMPETITION) {
      return "Competition";
    }
  };

  const formatElasticityMetric = (metric: string) => {
    if (!metric) {
      return "";
    }

    switch (metric) {
      case MetricToGrow.PROFIT: {
        return "Profit";
      }
      case MetricToGrow.SALES: {
        return "Sales";
      }
      case MetricToGrow.REVENUE: {
        return "Revenue";
      }
    }
  };

  const renderStrategyRule = () => {
    const { strategyRule } = strategy;
    const { pricesFormula, competitors } = strategyRule;

    if (strategy.strategyType === StrategyTypeEnum.ELASTICITY) {
      let text = "";

      if (pricesFormula?.metricToGrow) {
        text += `Grow: ${formatElasticityMetric(pricesFormula?.metricToGrow)}`;
      }

      if (
        pricesFormula?.metricToMaintain &&
        pricesFormula?.metricToMaintain !== MetricToMaintain.NONE
      ) {
        text += `${
          pricesFormula?.metricToGrow ? ", maintain" : "Maintain"
        }: ${formatElasticityMetric(pricesFormula?.metricToMaintain)}`;

        if (pricesFormula?.metricToMaintain === MetricToMaintain.SALES) {
          text += `(units))`;
        }
      }

      return text;
    }

    if (!pricesFormula.type) {
      return "N/A";
    }

    let rate = "";
    let priceFormulaType = "";

    switch (pricesFormula.type) {
      case PriceFormulaType.LOWEST: {
        priceFormulaType = "Lower than";
        break;
      }

      case PriceFormulaType.AVERAGE: {
        priceFormulaType = "Average to";
        break;
      }

      case PriceFormulaType.HIGHEST: {
        priceFormulaType = "Highest than";
        break;
      }
    }

    switch (pricesFormula.rate) {
      case Rate.DOLLAR: {
        rate = "$";
        break;
      }

      case Rate.PERCENTAGE: {
        rate = "%";
        break;
      }
    }

    let text = `${priceFormulaType} ${competitors.length} ${
      competitors.length > 1 ? "Competitors" : "Competitor"
    } `;

    if (pricesFormula.type !== PriceFormulaType.AVERAGE) {
      text += `${pricesFormula.metric === Metric.PLUS ? "+" : "-"} ${
        pricesFormula?.value || "0.00"
      }${rate} `;
    }

    return text;
  };

  const formatPricing = (pricing: any) => {
    if (!pricing) {
      return "";
    }

    switch (pricing) {
      case SelectLimitPricing.CURRENT_PRICE: {
        return "Current price";
      }

      case SelectLimitPricing.MARGIN: {
        return "Margin";
      }
    }
  };

  const renderPriceLimits = () => {
    const { priceLimits } = strategy;

    const isMinActive = priceLimits.pricing && priceLimits.min?.value;

    const isMaxActive = priceLimits?.pricing && priceLimits.max?.value;

    let text = "";

    if (isMinActive) {
      text += `Min
            ${formatPricing(priceLimits.pricing)}
          
            ${
              priceLimits.pricing === SelectLimitPricing.CURRENT_PRICE
                ? "-"
                : ""
            }
            ${priceLimits.min.value}%`;
    }

    if (isMaxActive) {
      text += `${isMinActive ? ", max" : "Max"}
            ${formatPricing(priceLimits.pricing)}
           
                 ${
                   priceLimits.pricing === SelectLimitPricing.CURRENT_PRICE
                     ? "+"
                     : ""
                 }
            ${priceLimits.max.value}%`;
    }

    if (text === "") {
      return "N/A";
    }

    return text;
  };
  const renderPriceRounding = () => {
    if (!Object.keys(strategy.priceRounding).length) {
      return "N/A";
    }

    const decimal = strategy.priceRounding.decimal;
    const integer = strategy.priceRounding.integer;
    const metric = strategy.priceRounding.metric;

    let text = "";

    if (decimal?.active) {
      text += `Per decimals`;

      if (decimal?.value && decimal.value !== "") {
        text += ` (${decimal.value}), `;
      } else {
        text += " N/A, ";
      }
    }

    if (integer?.active) {
      text += `Per integer`;

      if (integer?.value && integer.value !== "") {
        text += ` (${integer.value}),  `;
      } else {
        text += " N/A, ";
      }
    }

    if (metric) {
      if (metric === PriceRoundingEnum.ROUND_UP) {
        text += " round up";
      }

      if (metric === PriceRoundingEnum.ROUND_DOWN) {
        text += " round down";
      }

      text += "";
    }

    return text;
  };

  const renderUpdatePolicy = () => {
    let text = "Auto repricing";

    if (
      !strategy.updatePolicy?.value ||
      strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_OFF
    ) {
      text += ` off`;
    }

    if (strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_ON) {
      text += ` on`;
    }

    return text;
  };

  const { currentPrice, state, newPrice } = currentProduct;

  const onCloseModalException = () => {
    setIsOpenModal(false);

    setValues({
      pricing: "",
      min: {
        value: "",
      },
      max: {
        value: "",
      },
    });
  };

  const onCloseModalRemoveException = () => {
    setIsOpenRemoveModal(false);
  };

  const chooseRenderMode = () => {
    const res = (
      <>
        {productException ? null : (
          <div className="add-exception-btn-block">
            <CustomTooltip title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}>
              <div className={isViewer ? "disabled" : ""}>
                <ButtonStyled
                  icon={<Plus color="#212529" />}
                  text="Add custom limit"
                  htmlType="button"
                  onClick={() => !isViewer && setIsOpenModal(true)}
                />
              </div>
            </CustomTooltip>
          </div>
        )}
      </>
    );

    return res;
  };

  const onRemoveException = async () => {
    setIsOpenRemoveModal(false);
    setStatusProductById(productId, RepricingStatus.PENDING);
    removeLocalExceptionById(productId);

    await removeExceptionItemGroupById(productId);

    await getCountNewPriceReady(id || "");
    await getGroup(id || "");
  };

  const compareTextException = () => {
    const minValue = productException?.min?.value;
    const maxValue = productException?.max?.value;
    const isPriceMode =
      productException?.pricing === SelectLimitPricing.CURRENT_PRICE;
    const isMarginMode =
      productException?.pricing === SelectLimitPricing.MARGIN;
    const chooseSing = isPriceMode ? "$" : "";
    const chooseSingMargin = isMarginMode ? "%" : "";

    const choosePricingText = isPriceMode ? "price" : "margin";

    const isMinActiveText = minValue
      ? `Min ${choosePricingText} ${chooseSing}${minValue}${chooseSingMargin}`
      : "";
    const isMaxActiveText = maxValue
      ? `Max  ${choosePricingText} ${chooseSing}${maxValue}${chooseSingMargin}`
      : "";
    const isComma = minValue && maxValue ? ", " : " - ";

    if (productException?.pricing && !minValue && !maxValue) {
      return "Min - Max";
    }

    return `${isMinActiveText || "Min"}${isComma}${isMaxActiveText || "Max"}`;
  };

  const handleBlurCustom = async (key: string) => {
    let value = values[key].value;

    if (value) {
      if (!value.includes(".")) {
        value = `${value}.00`;
        setFieldValue(`[${key}].value`, value);
      } else if (value.split(".")[1].length === 0) {
        value = `${value}00`;
        setFieldValue(`[${key}].value`, value);
      } else if (value.split(".")[1].length === 1) {
        value = `${value}0`;
        setFieldValue(`[${key}].value`, value);
      }
    }
  };

  const onChangeValue = (value: string, key: string) => {
    const isValid = isValidLimitValue(value);
    const isValidPrice = isValidLimitPriceValue(value);
    if (value.includes(",")) {
      value = value.replace(",", ".");
    }
    if (values.pricing === SelectLimitPricing.MARGIN) {
      isValid && setFieldValue(`[${key}].value`, value);
    }
    if (values.pricing === SelectLimitPricing.CURRENT_PRICE) {
      isValidPrice && setFieldValue(`[${key}].value`, value);
    }
    const diffKey = key === "min" ? "max" : "min";
    if (errors[diffKey] && errors[key]) {
      setFieldError(key, "");
      setFieldError(diffKey, "");
    } else {
      setFieldError(key, "");
    }
  };

  const renderPopupAddException = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          open={isOpenModal}
          centered={true}
          onOk={() => {}}
          closeIcon={false}
          maskClosable={false}
          footer={[]}
          width={900}
        >
          <form
            onSubmit={(e) => {
              formik.handleSubmit();
              e.preventDefault();
            }}
            className="modal__exception"
          >
            <div className="modal__header-exception">
              <div
                onClick={onCloseModalException}
                className="modal-close__icon"
              >
                <Close />
              </div>
              <h2 className="modal__title">Add Сustom Limit</h2>
              <Divider />
            </div>
            <div className="modal__exception-content">
              <div className="df mb-54">
                <div className="df ai-center image-title">
                  <div className="df ai-center mr-12">
                    {currentProduct.imageUrl && (
                      <img
                        alt="img-url"
                        className="product__img"
                        src={currentProduct.imageUrl}
                      />
                    )}
                  </div>
                  <div>
                    <div className="product-id">{currentProduct.article}</div>
                    <div className="product-name">{currentProduct.name}</div>
                  </div>
                </div>
                <div className="costs-title">
                  <div>
                    <div className="title">Cost</div>
                    <div className="title">${currentProduct.cost}</div>
                  </div>
                  <div>
                    <div className="title">Current Price</div>
                    <div className="title">${currentProduct.currentPrice}</div>
                  </div>
                  <div>
                    <div className="title">Current Margin</div>
                    <div className="title">${currentProduct.currentMargin}</div>
                  </div>
                </div>
              </div>
              <div className="df ">
                <div className="label">Limit by</div>
                <Select
                  onChange={(value) => {
                    setValues({
                      pricing: value,
                      max: { value: "" },
                      min: { value: "" },
                    });
                    setErrors({});
                  }}
                  suffixIcon={<ArrowBorder />}
                  value={values.pricing || null}
                  optionFilterProp="label"
                  options={optionsSelect}
                  placeholder={"Select limit"}
                  className={`gray-primary-900  custom-arrow ${
                    errors.pricing ? "error-validation-select" : ""
                  } ${values.pricing ? "w-520 mr-32" : ""}`}
                />
                {errors.pricing && (
                  <div className="error-validation-message select-error">
                    {errors?.pricing as string}
                  </div>
                )}
                {values.pricing && (
                  <>
                    {limitOptions.map(({ text, key, placeholder }, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <div className="modal__exception-input mr-16 poz-r">
                            <InputWithIcons
                              wrapperClassName={`input-label__wrapper ${
                                errors[key] ? "error-validation-input" : ""
                              }`}
                              rightIcon={
                                values.pricing ===
                                SelectLimitPricing.CURRENT_PRICE ? (
                                  <>$</>
                                ) : (
                                  <>%</>
                                )
                              }
                              value={values[key]?.value || ""}
                              onChange={(value) => {
                                onChangeValue(value, key);
                              }}
                              onBlur={() => {
                                handleBlurCustom(key);
                              }}
                              placeholder={placeholder}
                            />
                            {errors[key] && (
                              <div className="error-validation-message input-error">
                                {errors[key] as string}
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </div>
            </div>

            <Divider />
            <div key={1} className="groups__form-content">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={onCloseModalException}
                />
                <ButtonStyled
                  type="primary"
                  text={productException ? "Save" : "Add"}
                  htmlType="submit"
                  fill="gray-primary-900"
                  className="w-84"
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  };

  const renderPopupRemoveException = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          open={isOpenRemoveModal}
          centered={true}
          onOk={() => {}}
          closeIcon={false}
          maskClosable={false}
          footer={[]}
          width={500}
        >
          <div className="modal__exception">
            <div className="modal__header-exception">
              <div
                onClick={onCloseModalRemoveException}
                className="modal-close__icon"
              >
                <Close />
              </div>
              <h2 className="modal__title">Remove Custom Limit</h2>
              <Divider />
            </div>
            <div className="modal__exception-content">
              <div>
                {" "}
                Are you sure you want to remove the custom price limit?
              </div>
              <div>
                This item will follow the main price limit rule after removal.
              </div>
            </div>
            <Divider />
            <div key={1} className="groups__form-content">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={onCloseModalRemoveException}
                />
                <ButtonStyled
                  type="primary"
                  text="Remove"
                  htmlType="submit"
                  fill="red-600"
                  className="w-84"
                  onClick={onRemoveException}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const renderNewPrice = () => {
    if (currentProduct.status === RepricingStatus.PENDING) {
      return (
        <h2>
          <span className="overview-current-price-update">
            <LoaderDefault /> Updating. May take a few minutes
          </span>
        </h2>
      );
    } else if (currentProduct.state) {
      return <h2 className="overview-current-price-na">-</h2>;
    } else if (newPrice && +toFixedCustom(newPrice) > 0) {
      const badgeValue = findRangePercentage(
        +currentProduct.currentPrice,
        +newPrice
      );
      return (
        <h2 className="overview-new-reprice">
          ${toFixedCustom(currentProduct.newPrice || 0)}{" "}
          <BadgeCustom
            text={badgeValue}
            className={
              checkIsPositiveValue(badgeValue)
                ? "badge-new-price-positive"
                : "badge-new-price-negative"
            }
          />
        </h2>
      );
    } else if (newPrice && +toFixedCustom(newPrice) < 0) {
      return (
        <h2 className="overview-current-price-na">
          <CustomTooltip
            styles={{ width: "440px" }}
            title={
              "Negative new price detected. Please review your group configuration to resolve this issue. The product will remain unpriced until the issue is fixed"
            }
          >
            <Warning />
          </CustomTooltip>
          &nbsp;N/A
        </h2>
      );
    } else {
      return <h2 className="overview-current-price-na">N/A</h2>;
    }
  };

  const newPriceTooltip = (
    <CustomTooltip title={"New price after repricing"} placement="top">
      <Info />
    </CustomTooltip>
  );

  return (
    <>
      {renderPopupAddException()}
      {renderPopupRemoveException()}
      <div className="item-overview">
        <section className="item-overview-repricing">
          <div className="df jc-sb mb-16">
            <h3 className="overview-title">Repricing</h3>
            <div className="overview-last-reprice-block">
              <span className="overview-last-reprice">Last reprice </span>
              {currentProduct.last_reprice ? (
                <span className="overview-last-reprice-date">
                  {moment(currentProduct.last_reprice).format(
                    "MMM D, YYYY,HH:mm"
                  )}
                </span>
              ) : (
                <span className="overview-last-reprice-no-date">N/A</span>
              )}
            </div>
          </div>
          <div className="df overview-current-price-block">
            <div className="overview-current-price-item">
              <div className="overview-last-reprice">Current price </div>
              <div className="df">
                <h2 className="overview-current-price">
                  ${toFixedCustom(currentPrice)}
                </h2>

                <CustomTooltip
                  title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
                >
                  <span
                    className={`${
                      isViewer ? "disabled" : ""
                    } overview-current-price-lock`}
                    onClick={() =>
                      !isViewer && handleClickOnLock(productId, !state)
                    }
                  >
                    {state ? <Lock /> : <Unlock />}
                  </span>
                </CustomTooltip>
              </div>
            </div>
            <div className="overview-current-price-item">
              <div className="overview-last-reprice">
                New price {newPriceTooltip}
              </div>
              {renderNewPrice()}
            </div>
          </div>
        </section>
        <section className="item-overview-configuration">
          <h3 className="overview-title">Group Configuration</h3>
          <div className="summary__overview">
            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Product Items</h5>
              <h5 className="summary__overview-value available-items">
                {recordsCountInGroup} {getItemWord(recordsCountInGroup)}
                {/* from{" "} */}
                {/* {currentGroup.selectedCategories || "N/A"} category */}
              </h5>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Strategy Type</h5>
              <div
                className={`summary__overview-value ${strategy.strategyType}`}
              >
                <span className="capitalize">{renderPriceType()}</span>
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Strategy Rule</h5>
              <div className="summary__overview-value">
                {renderStrategyRule()}
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Price Limits</h5>
              <div className="summary__overview-value">
                {renderPriceLimits()}
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Price Roundings </h5>
              <div className="summary__overview-value">
                {renderPriceRounding()}
              </div>
            </div>
            {/* TODO */}
            {/* <div className="summary__overview-item">
              <h5 className="summary__overview-key">Update Policy</h5>
              <div className="summary__overview-value">
                {renderUpdatePolicy()}
              </div>
            </div> */}
          </div>
          <Divider />
          <div className="summary__overview-item mt-12">
            <h5 className="summary__overview-key">Custom Price Limit</h5>
            <div className="summary__overview-value df jc-sb ai-c exception-item ">
              {productException ? (
                <span className="mr-16">{compareTextException()}</span>
              ) : null}
              {productException ? (
                <div className="custom-limit-actions">
                  <CustomTooltip
                    styles={{ width: "250px" }}
                    title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
                  >
                    <div
                      onClick={() => !isViewer && setIsOpenModal(true)}
                      className={`${isViewer ? "disabled" : ""} icon-action`}
                    >
                      <Edit color="#6C757D" />
                    </div>
                  </CustomTooltip>
                  <CustomTooltip
                    styles={{ width: "250px" }}
                    title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
                  >
                    <div
                      onClick={() => !isViewer && setIsOpenRemoveModal(true)}
                      className={`${isViewer ? "disabled" : ""} icon-action`}
                    >
                      <Delete color="#6C757D" />
                    </div>
                  </CustomTooltip>
                </div>
              ) : (
                <>N/A</>
              )}
            </div>
          </div>
          {chooseRenderMode()}
        </section>
      </div>
    </>
  );
};

export default ItemOverview;
