import { FC, useEffect, useState } from "react";
import { Checkbox, Divider, Modal } from "antd";
import { ButtonStyled } from "@shared/ui/components";
import { useParams } from "react-router-dom";
import { Close } from "@shared/ui/assets";
import { generateTextColumn, Pagination } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { IProduct, useProductsStore } from "@entities/products";
import { useShallow } from "zustand/react/shallow";
import { AlertTarget, CurrentStep, useGroupsStore } from "@entities/groups";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import {
  BRAND_IDS_PARAM,
  CATEGORY_IDS_PARAM,
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  GROUP_IDS_PARAM,
  LIMIT_PARAM,
  LS_IS_WARNING_LIMITS,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";
import {
  filteredCompareQueryObj,
  getItemWord,
  toFixedCustom,
} from "@shared/utils";
import { useDebounce } from "use-debounce";
import useStrategy from "@shared/model/hooks/useStrategy";
import EditItemsPopupBulkAction from "./EditItemsPopupBulkAction";

interface IProps {
  flag: boolean;
  setItemsPopupShow: (bool: boolean) => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  BRAND = "brand",
  GROUPS = "groupId",
  STORE = "store",
  CATEGORY = "category",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
}

export const ungrouped = "Ungrouped";

const ItemsPopup: FC<IProps> = ({ flag, setItemsPopupShow }: IProps) => {
  const {
    setProducts,
    setProductsSelectedIds,
    selectAllProducts,
    selectProduct,
    selectProductsFromGroup,
    selectProductFromGroup,
    removeSelectedProductsWithGroup,
  } = useProductsStore(useShallow((state) => state));
  const { saveEmptyGroupWithProducts } = useGroupsStore(
    useShallow((state) => state)
  );
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const {
    strategy: { productItems },
  } = useGroupsStore((state) => state);
  const { currentGroup } = useGroupsStore((state) => state);
  const { id } = useParams();

  const products = useProductsStore((state) => state.products);
  const {
    currentGroup: { selectedCategories },
  } = useGroupsStore((state) => state);
  const productsSelectedIds = useProductsStore(
    useShallow((state) => state.productsSelectedIds)
  );
  const recordsCount = useProductsStore(
    useShallow((state) => state.recordsCount)
  );
  const selectedProductsWithGroup = useProductsStore(
    useShallow((state) => state.selectedProductsWithGroup)
  );
  const isAllSelected = products.every((product) =>
    productsSelectedIds.includes(product.id)
  );

  const { checkIsMoreStepThen } = useStrategy();
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);
  const { getProducts } = useProductsStore((state) => state);
  const {
    clearAlertToStore,
    updateStrategy,
    getStrategy,
    getBrandsByCategoryIds,
  } = useGroupsStore((state) => state);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | undefined;
  }>({
    key: "",
    direction: undefined,
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const [currentCategories, setCurrentCategories] = useState<string[]>([]);
  const [currentBrands, setCurrentBrands] = useState<string[]>([]);
  const [currentGroupsList, setCurrentGroupsList] = useState<string[]>([
    ungrouped,
  ]);
  const [isModalInfoDeleteFromGroupOpen, setIsModalInfoDeleteFromGroupOpen] =
    useState<boolean>(false);

  const [debouncedValue] = useDebounce(searchValue, 500);
  const defineCurrentOffset = () => {
    const res = products.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  useEffect(() => {
    if (!flag) {
      setCurrentCategories([]);
      setSearchValue("");
      setCurrentBrands([]);
      setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
      setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    }
    return () => {
      removeSelectedProductsWithGroup();
    };
  }, [flag]);

  useEffect(() => {
    setCurrentCategories(currentGroup?.selectedCategories || []);
  }, [currentGroup?.selectedCategories]);

  useEffect(() => {
    const groupListModify = currentGroupsList.map((el) => {
      if (el === ungrouped) {
        return ungrouped.toLowerCase();
      }
      return el;
    });
    const data = {
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [LIMIT_PARAM]: currentLimit,
      [OFFSET_PARAM]: currentOffset,
      [CATEGORY_IDS_PARAM]: currentCategories.join(","),
      [GROUP_ID_PARAM]: id,
      [BRAND_IDS_PARAM]: currentBrands.join(","),
      [SEARCH_PARAM]: debouncedValue,
      [GROUP_IDS_PARAM]: groupListModify.join(","),
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;

    if (flag) {
      getProducts(filteredData);
    }
  }, [
    sortConfig.key,
    sortConfig.direction,
    currentLimit,
    currentOffset,
    currentBrands,
    debouncedValue,
    flag,
    currentCategories,
  ]);

  useEffect(() => {
    if (flag) getBrandsByCategoryIds(currentCategories);
  }, [flag]);

  useEffect(() => {
    return () => {
      setProducts([], 0);
      setProductsSelectedIds([]);
      setSortConfig({ key: "", direction: undefined });
    };
  }, [flag]);

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!products.length}
          onChange={(event) => {
            const arr = products.reduce((acc: string[], el) => {
              if (el.group) {
                acc.push(el.id);
              }
              return acc;
            }, []);

            selectProductsFromGroup(arr, event?.target.checked);
            selectAllProducts(event.target.checked);
          }}
        ></Checkbox>
      ),
      className: "table-w-48",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-302",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-39",
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-144",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    // {
    //   content: generateTextColumn({
    //     text: "Groups",
    //     isSorted: true,
    //     sortDirection: sortConfig.direction || "",
    //     isActiveSort: THEAD_SORT_KEYS.GROUPS === sortConfig.key,
    //   }),
    //   className: "table-w-140",
    //   sortKey: THEAD_SORT_KEYS.GROUPS,
    // },
    {
      content: generateTextColumn({
        text: "Category",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CATEGORY === sortConfig.key,
      }),
      className: "table-w-140",
      sortKey: THEAD_SORT_KEYS.CATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id, group }: IProduct) => (
        <Checkbox
          checked={productsSelectedIds.some((el) => el === id)}
          onChange={(event) => {
            selectProduct(id, event?.target.checked);
            group && selectProductFromGroup(id, event?.target.checked);
          }}
        ></Checkbox>
      ),
    },
    {
      content: ({ article, name }: IProduct) => (
        <>
          <h5 className="product__subtitle">{article}</h5>
          <h5 className="product__title">{name}</h5>
        </>
      ),
      className: "table-w-302",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div className="df ai-center">
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${toFixedCustom(currentPrice)}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => (
        <h5>{toFixedCustom(currentMargin)}%</h5>
      ),
      className: "ta-right",
    },
    {
      content: ({ cost }: IProduct) => <h5>${toFixedCustom(cost)}</h5>,
    },
    {
      content: ({ brand }: IProduct) => <h5 className="ta-left">{brand}</h5>,
    },
    {
      content: ({ category }: IProduct) => (
        <h5 className="ta-left">{category} </h5>
      ),
    },
    {
      content: ({ store }: IProduct) => <h5 className="ta-left">{store} </h5>,
    },
  ];

  const dataTableBody = products.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productsSelectedIds.some((id) => id === item.id),
      className: el.className,
      item: item,
    }));
  });

  const onClose = () => {
    setItemsPopupShow(false);
    setProducts([], 0);
  };

  const addProductsToGroup = async () => {
    const res = await saveEmptyGroupWithProducts(
      productsSelectedIds,
      id || null,
      selectedCategories || []
    );
    setItemsPopupShow(false);
    setProducts([], 0);
    return res;
  };

  const handleOk = async () => {
    const res = await addProductsToGroup();
    setIsModalInfoDeleteFromGroupOpen(false);
    res && setIsModalInfoOpen(false);
    res && setItemsPopupShow(false);
    localStorage.setItem(LS_IS_WARNING_LIMITS, "true");

    getStrategy();
  };

  const handleCancel = () => {
    setIsModalInfoOpen(false);
  };

  const renderModalDeleteItemsFromGroup = () => {
    return (
      <Modal
        title={<div className="modal__title">Confirm Adding Items</div>}
        open={isModalInfoDeleteFromGroupOpen}
        onOk={() => {}}
        onCancel={() => setIsModalInfoDeleteFromGroupOpen(false)}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={() => setIsModalInfoDeleteFromGroupOpen(false)}
              />

              <ButtonStyled
                type="primary"
                text="Confirm"
                htmlType="button"
                fill="gray-primary-900"
                className={`groups-empty__btn w-84`}
                onClick={handleOk}
                iconPosition="end"
              />
            </div>
          </div>,
        ]}
      >
        <div className="modal__container">
          <div className="confirm-modal-body-info">
            You are about to move
            <b>
              {` ${selectedProductsWithGroup.length}`}{" "}
              {getItemWord(selectedProductsWithGroup.length)}
            </b>{" "}
            from other groups to the new group. This will remove them from their
            current group, and if you selected all items, that group will be
            deleted.
            {selectedProductsWithGroup.length &&
            checkIsMoreStepThen(CurrentStep.STRATEGY_TYPE) ? (
              <>
                {" "}
                Adding new items may suggest competitors in "Strategy Rule" and
                custom limits in "Price Limits".
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  };

  const handleChange = async () => {
    if (selectedProductsWithGroup.length) {
      setIsModalInfoDeleteFromGroupOpen(true);
    } else {
      setIsModalInfoOpen(true);
    }
  };

  const modalInfoRender = () => (
    <Modal
      title={<div className="modal__title">Confirm Adding Items to Group</div>}
      open={isModalInfoOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={handleCancel}
            />

            <ButtonStyled
              type="primary"
              text="Confirm"
              htmlType="button"
              fill="gray-primary-900"
              className={`groups-empty__btn w-84`}
              onClick={handleOk}
              iconPosition="end"
            />
          </div>
        </div>,
      ]}
    >
      <div className="modal__container">
        <div className="confirm-modal-body-info">
          Are you sure you want to add{" "}
          <b>
            {` ${productsSelectedIds.length}`} selected{" "}
            {getItemWord(productsSelectedIds.length)}
          </b>{" "}
          ? This action will add new items to the current group and may suggest
          additional competitors in the "Strategy Rule" and items with custom
          limits in the "Price Limits" tab.
        </div>
      </div>
    </Modal>
  );

  const isShowProducts = products.length;

  const chooseText = () => {
    return "No items found. Try to change filters";
  };

  return (
    <div className="main-block">
      {modalInfoRender()}
      {renderModalDeleteItemsFromGroup()}

      <Modal
        open={flag}
        className="modal-products"
        footer={null}
        centered={true}
        onOk={() => {}}
        closeIcon={false}
        maskClosable={false}
      >
        <div className="modal__container">
          <div onClick={() => onClose()} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal__title">Add Items</h2>
          <Divider />
          <div className="modal__content">
            <div className="modal__body">
              <EditItemsPopupBulkAction
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                currentBrands={currentBrands}
                setCurrentBrands={setCurrentBrands}
                setCurrentCategories={setCurrentCategories}
                selectedCategories={currentCategories}
                setCurrentGroupsList={setCurrentGroupsList}
                currentGroupsList={currentGroupsList}
                pagination={{
                  currentLimit,
                  currentOffset,
                }}
                setCurrentLimit={setCurrentLimit}
                setCurrentOffset={setCurrentOffset}
              />
              {isShowProducts ? (
                <div className="products__table products__table-block">
                  <TableCustom
                    bodyData={dataTableBody}
                    headData={headDataTable}
                    handleSort={handleSort}
                  />
                </div>
              ) : null}
            </div>
            {isShowProducts ? (
              <>
                <div className="pagination__container">
                  <div className="selected__block mr-24">
                    <span className="selected__count">
                      {productsSelectedIds.length}
                    </span>
                    <span className="selected__text">
                      {getItemWord(productsSelectedIds.length)} selected
                    </span>
                  </div>

                  <Pagination
                    prevPage={definePrefPage}
                    nextPage={defineNextPage}
                    onChange={onChangeItemsCount}
                    recordsCount={definePageCount()}
                    currentLimit={defineCurrentLimit()}
                    currentOffset={defineCurrentOffset()}
                    currentSelectLimit={currentLimit.toString()}
                  />
                </div>
                <Divider />
              </>
            ) : (
              <div className="products__table noData__products">
                {chooseText()}
              </div>
            )}
            <div className="modal__footer">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={() => onClose()}
                />
                <ButtonStyled
                  type="primary"
                  text="Add"
                  htmlType="button"
                  fill="gray-primary-900"
                  className={`items__btn-next ${
                    productsSelectedIds.length ? "" : "disabled"
                  }`}
                  onClick={handleChange}
                  iconPosition="end"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ItemsPopup;
