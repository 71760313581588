import { LayoutWithAside, TeamManagement } from "@widgets/admin";
import { Tabs } from "antd";
import "./settings.scss";
import {
  CreditCardIcon,
  GroupsIcon,
  IntegrationIcon,
  SettingsSystemIcon,
} from "@shared/ui/assets";
import { ROUTES } from "@shared/interfaces";
import { useNavigate } from "react-router-dom";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { useUserStore } from "@entities/user";
import { UserRolesEnum } from "@entities/settings";

export const SettingsPage = () => {
  const navigate = useNavigate();
  const { isManager, isAdmin, isSuAdmin, isOwner } = useCheckRole();
  const role = useUserStore((state) => state.user.role);

  const tabOptions = [
    {
      label: "Team Management",
      key: "teamManagement",
      disabled: false,
      description: "Manage and invite team members",
      icon: <GroupsIcon />,
      linkTo: ROUTES.ADMIN_SETTINGS_USERS,
      permissions: [
        UserRolesEnum.ADMIN,
        UserRolesEnum.SU_ADMIN,
        UserRolesEnum.OWNER,
        UserRolesEnum.MANAGER,
      ],
    },
    {
      label: "Billing",
      key: "billing",
      disabled: true,
      description: "Manage your subscription and payment details",
      icon: <CreditCardIcon />,
      permissions: [
        UserRolesEnum.ADMIN,
        UserRolesEnum.SU_ADMIN,
        UserRolesEnum.OWNER,
      ],
    },
    {
      label: "Integrations",
      key: "integrations",
      disabled: true,
      description: "Manage plug & play integrations",
      icon: <IntegrationIcon />,
      permissions: [
        UserRolesEnum.ADMIN,
        UserRolesEnum.SU_ADMIN,
        UserRolesEnum.OWNER,
      ],
    },
    {
      label: "System",
      key: "system",
      disabled: true,
      description: "Configure pricing model",
      icon: <SettingsSystemIcon />,
      permissions: [
        UserRolesEnum.ADMIN,
        UserRolesEnum.SU_ADMIN,
        UserRolesEnum.OWNER,
      ],
    },
  ];
  return (
    <LayoutWithAside>
      <>
        <h1 className="content__title pl-24 pt-30 pb-30">Settings</h1>
        <div className="settings-block">
          {tabOptions.map(
            (
              { label, key, description, disabled, icon, linkTo, permissions },
              ind
            ) => {
              return (
                permissions.includes(role) && (
                  <div
                    className={`settings-page-item ${
                      !disabled ? "active" : ""
                    }`}
                    onClick={() => linkTo && navigate(linkTo)}
                  >
                    <div className="mr-16 ">{icon}</div>
                    <div>
                      <h4 className="label"> {label}</h4>

                      <p className=" description">{description}</p>
                    </div>
                  </div>
                )
              );
            }
          )}
        </div>
      </>
    </LayoutWithAside>
  );
};

export default SettingsPage;
