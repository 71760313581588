import { FC, useEffect, useRef, useState } from "react";
import "./itemInfo.scss";
import { Close } from "@shared/ui/assets";
import { Tabs } from "antd";
import ItemOverview from "./ItemOverview/ItemOverview";
import { StrategyTypeEnum } from "@entities/groups";
import ItemMarket from "./ItemMarket/ItemMarket";
import ItemPriceElasticity from "./ItemPriceElasticity/ItemPriceElasticity";
import { useGroupStore } from "@entities/group";

interface IProps {
  onClose: (flag: boolean) => void;
  productId: string;
}

const ItemInfo: FC<IProps> = (props) => {
  const { onClose, productId } = props;
  const { productsInGroup, strategy } = useGroupStore((state) => state);
  const currentProduct = productsInGroup.find(
    (product) => product.id === productId
  );
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setActiveKey("1");
  }, [productId]);

  const drawerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node)
    ) {
      onClose(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (!currentProduct) {
    return null;
  }
  const { name, article, brand, store, externalLink } = currentProduct;

  const chooseActualTab =
    strategy.strategyType === StrategyTypeEnum.COMPETITION
      ? {
          label: <h4 className="tab-title default-tab">Competition</h4>,
          key: "2",
          disabled: false,
          children: (
            <div className="item-tab-scroll">
              <ItemMarket productId={productId} />
            </div>
          ),
        }
      : {
          label: <h4 className="tab-title default-tab">Price Elasticity</h4>,
          key: "3",
          disabled: false,
          children: (
            <div className="item-tab-scroll">
              <ItemPriceElasticity
                currentPrice={currentProduct.currentPrice}
                productId={productId}
                newPrice={currentProduct.newPrice}
                activeTab={activeKey}
              />
            </div>
          ),
        };

  const tabOptions: { [key: string]: any }[] = [
    {
      label: <h4 className="tab-title default-tab">Overview</h4>,
      key: "1",
      disabled: false,
      children: <ItemOverview productId={productId} />,
    },
    { ...chooseActualTab },
  ];

  const headerInfo = [
    {
      value: article,
      key: "SKU",
    },
    {
      value: brand,
      key: "Brand",
    },
    {
      value: store,
      key: "Store",
    },
  ];

  return (
    <div className="item-info__container" ref={drawerRef}>
      <div className="item-info__header-container">
        <div className="item-info__header">
          <span className="item-info__title">
            <a href={externalLink || "/*"} target="_blank">
              {name}
            </a>
          </span>
          <div onClick={() => onClose(false)} className="item-info-close__icon">
            <Close />
          </div>
        </div>
        <div>
          {headerInfo.map(({ value, key }) => {
            return (
              <div className="item-info__header-info" key={key}>
                <span className="item-info__header-info-key">{key}</span>
                <span className="item-info__header-info-value">{value}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <Tabs
          activeKey={activeKey}
          defaultActiveKey="1"
          onChange={(key) => setActiveKey(key)}
          centered={true}
          items={tabOptions.map(({ label, key, children, disabled }, ind) => {
            return {
              disabled: disabled,
              label: label,
              key: key,
              children: children,
            };
          })}
        />
      </div>
    </div>
  );
};

export default ItemInfo;
