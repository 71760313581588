import { FORGOT_PASSWORD_STEPS } from "@widgets/interfaces";
import "./checkEmail.scss";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { Input } from "antd";
import { useFormik } from "formik";
import { useUserStore } from "@entities/user";
import { validationSchema } from "./validation";
import { useState } from "react";

interface IProps {
  onClickContinue: (obj: any) => void;
}

const CheckEmail = (props: IProps) => {
  const { onClickContinue } = props;
  const { checkEmail } = useUserStore((state) => state);
  const { openNotification, contextHolder } = NotificationCustom();
  const [responseErrorSignUpText, setResponseErrorSignUpText] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const data = await checkEmail(values.email);

        onClickContinue({
          step: FORGOT_PASSWORD_STEPS.CHECK_CODE,
          email: data.email,
        });
      } catch (error: any) {
        setResponseErrorSignUpText(error.response.data.message);
        openNotification({
          message: "Internal Server Error. Try one more time.",
          type: "default",
          duration: 5,
        });
      }
    },
  });

  const { setFieldValue, errors, values } = formik;

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit();
        e.preventDefault();
      }}
    >
      {contextHolder}
      <div className="check-email">
        <div className="check-text-block">
          <div className="title">Forgot password?</div>
          <div className="text ">
            Enter your email and we will send you a reset code
          </div>
        </div>
        <div className="poz-r">
          <span className="check-email__label">Email</span>
          <Input
            id="email"
            className="gray-primary-900 form-item__mb24"
            name="email"
            onChange={({ target }) => setFieldValue("email", target.value)}
            type="text"
            value={values.email}
            status={(errors.email || responseErrorSignUpText) && "error"}
          />
          {(errors.email || responseErrorSignUpText) && (
            <span className="error-validation-message error-validation-message-email">
              {errors.email || responseErrorSignUpText}
            </span>
          )}
        </div>
        <div className="btn-step">
          <ButtonStyled
            text="Send a code"
            className="setpass__btn"
            type="primary"
            fill="gray-primary-900"
            htmlType="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default CheckEmail;
