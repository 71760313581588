import {
  IProduct,
  ISelectProductWithGroup,
  RepricingStatus,
} from "@entities/products";
import { IDataTransfer, IQuery } from "@shared/interfaces";
import React from "react";

export enum EGroupStatus {
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  COMING_SOON = "COMING_SOON",
  IN_PROGRESS = "IN_PROGRESS",
  REPRICE_READY = "REPRICE_READY",
}

export const NEW_REPRICE_READY = "newPrice";

export enum EGroupType {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE",
}

export interface IGroup {
  id: string;
  name: string;
  type: EGroupType;
  updated_at?: string;
  totalItems: number;
  isSelected: boolean;
  status: EGroupStatus;
  repriceItems: number;
  products?: IProduct[];
  selectedProductsIds?: string[];
  selectedCategories?: any[];
  currentStep: CurrentStep;
  last_reprice?: string;
  alerts?: IAlert[];
}

export interface ICategory {
  id: number;
  name: string;
  subcategories: ISubCategory[];
  brands: IBrand[];
}

export interface ISubCategory {
  id: number;
  name: string;
}
export interface IBrand {
  id: number;
  name: string;
}

export enum AdditionalCustomStep {
  STRATEGY_CHECK_NO_COMPETITORS_ERROR = "strategyCheckNoCompetitorsError",
}

export enum CurrentStep {
  PRODUCT_ITEM = "productItems",
  STRATEGY_TYPE = "strategyType",
  STRATEGY_RULE = "strategyRule",
  PRICE_LIMITS = "priceLimits",
  PRICE_ROUNDING = "priceRounding",
  UPDATE_POLICY = "updatePolicy",
  SUMMARY = "summary",
}

export interface IProductOfCompetitor {
  id: string;
  name: string;
  store: string;
  brand: string;
  subcategory: string;
  currentPrice: number;
  category: string;
  externalLink: string;
  productTraffic: number;
  created_at: string;
  checked?: boolean;
}

export interface ICompetitor {
  [key: string]: any;
  id: string;
  name: string;
  externalLink: string;
  created_at: string;
  products: IProductOfCompetitor[];
  categoryTraffic?: number;
  score?: number;
}

export enum StrategyTypeEnum {
  COMPETITION = "competition",
  ELASTICITY = "elasticity",
}

export enum MetricToGrow {
  SALES = "sales",
  PROFIT = "profit",
  REVENUE = "revenue",
}
export enum MetricToMaintain {
  NONE = "none",
  SALES = "sales",
  PROFIT = "profit",
  REVENUE = "revenue",
}

export enum Metric {
  PLUS = "plus",
  MINUS = "minus",
}
export enum LimitMSRP {
  PLUS = "plus",
  MINUS = "minus",
}

export enum Rate {
  DOLLAR = "dollar",
  PERCENTAGE = "percentage",
}

export enum PriceFormulaType {
  HIGHEST = "highest",
  AVERAGE = "average",
  LOWEST = "lowest",
}

export enum SelectLimitPricing {
  MARGIN = "margin",
  CURRENT_PRICE = "currentPrice",
  MSRP = "msrp",
}

export interface IProductExceptionItem {
  [key: string]: any;
  pricing: SelectLimitPricing | string;
  min: {
    value: string;
  };
  max: {
    value: string;
  };
}

export interface IProductException extends IProduct {
  exceptionLimits: IProductExceptionItem;
}

export enum AlertType {
  WARNING = "warning",
  ERROR = "error",
}

export interface IAlert {
  type: AlertType | "";
  priority: Priority | null;
  message: string;
  target: AlertTarget | "";
  isShow?: boolean;
}

export enum Priority {
  HIGHT = 0,
  MEDIUM = 1,
  LOW = 2,
  DEFAULT = Infinity,
}

export enum PriceRoundingEnum {
  ROUND_UP = "roundUp",
  ROUND_DOWN = "roundDown",
}

export const STEP_KEYS = [
  CurrentStep.PRODUCT_ITEM,
  CurrentStep.STRATEGY_TYPE,
  CurrentStep.STRATEGY_RULE,
  CurrentStep.PRICE_LIMITS,
  CurrentStep.PRICE_ROUNDING,
  // CurrentStep.UPDATE_POLICY,
  CurrentStep.SUMMARY,
];

export enum AutoRepricingEnum {
  AUTO_REPRICING_ON = "autoRepricingOn",
  AUTO_REPRICING_OFF = "autoRepricingOff",
  AUTO_REPRICING_CUSTOM = "autoRepricingCustom",
}

export type PriceLimit = {
  value: number;
};

export interface IStrategyModel {
  [key: string]: any;
  id: string;
  productItems: {
    alerts: IAlert[];
  };

  strategyType: StrategyTypeEnum | null;
  strategyRule: {
    alerts: IAlert[];
    competitors: string[];
    pricesFormula: {
      type: PriceFormulaType | null;
      value: string;
      metric: Metric;
      rate: Rate;
      metricToGrow: MetricToGrow | "";
      metricToMaintain: MetricToMaintain | "";
    };
  };
  priceLimits: {
    pricing: SelectLimitPricing | null;

    [key: string]: any;
    alerts: IAlert[];
    min: PriceLimit;
    max: PriceLimit;
  };
  exceptionProducts: IProductException[];
  priceRounding: {
    alerts: IAlert[];
    [key: string]: any;
    metric: PriceRoundingEnum;
    decimal: {
      active: boolean;
      value: string;
    };
    integer: {
      active: boolean;
      value: string;
    };
  };
  updatePolicy: {
    alerts: IAlert[];
    value: AutoRepricingEnum;
  };
  summary?: {
    alerts: IAlert[];
    name: "";
  };
}

// export enum AlertTarget {
//   EMPTY = "",
//   PRODUCT_ITEMS__REMOVE_ALL_ITEMS = "productItemsRemoveAllItems",
//   PRICE_LIMITS__NEW_EXCEPTION = "priceLimitsNewExceptionExist",
//   STRATEGY_RULE__CHANGE_STRATEGY = "strategyRuleChangeStrategy",
//   STRATEGY_RULE__ADD_NEW_PRODUCT = "strategyRuleAddNewProduct",
//   STRATEGY_RULE__NO_COMPETITORS = "strategyRuleNoCompetitors",
//   STRATEGY_RULE__ITEMS_NO_COMPETITORS = "strategyRuleItemsNoCompetitors",
//   STRATEGY_RULE__DELETE_ALL_COMPETITORS = "strategyRuleDeleteAllCompetitors",
//   GROUP_NEGATIVE__NEW_PRICES = "groupNegativeNewPrices",
//   VALIDATION_ERROR = "validationError",
// }

export enum AlertTarget {
  EMPTY = "",
  PRODUCT_ITEMS__REMOVE_ALL_ITEMS = "productItemsRemoveAllItems",
  PRICE_LIMITS__NEW_EXCEPTION = "priceLimitsNewExceptionExist",
  PRICE_LIMITS__REMOVE_ALL_ITEMS = "priceLimitsRemoveAllItems",
  STRATEGY_RULE__CHANGE_STRATEGY = "strategyRuleChangeStrategy",
  STRATEGY_RULE__ADD_NEW_PRODUCT = "strategyRuleAddNewProduct",
  STRATEGY_RULE__NO_COMPETITORS = "strategyRuleNoCompetitors",
  STRATEGY_RULE__ITEMS_NO_COMPETITORS = "strategyRuleItemsNoCompetitors",
  STRATEGY_RULE__DELETE_ALL_COMPETITORS = "strategyRuleDeleteAllCompetitors",
  STRATEGY_RULE__REMOVE_ALL_ITEMS = "strategyRuleRemoveAllItems",
  STRATEGY_RULE__REMOVE_ITEMS_AND_NO_COMPETITORS = "strategyRuleRemoveItemsAndNoCompetitors",
  GROUP_NEGATIVE__NEW_PRICES = "groupNegativeNewPrices",
}

export type StrategyKeys = keyof IStrategyModel;

export interface IValidationError {
  [key: string]: any;
  priority: Priority | null;
  type: AlertType | "";
}

export interface IValidationKey {
  [key: string]: IValidationError | { [key: string]: any } | null;
}

export interface ValidationErrorsT {
  [key: keyof IStrategyModel]: IValidationKey | null;
}

export interface IGroupFormCreate extends Pick<IGroup, "name"> {}
export interface IGroupFormEdit extends Pick<IGroup, "name" | "id"> {}
export interface IGroupModify extends Partial<IGroup> {}

export interface IGroupList {
  id: string;
  name: string;
  type: EGroupType;
}

export interface IGroupsState {
  filters: any;
  groups: IGroup[];
  recordsCount: number;
  currentGroup: IGroup;
  categories: ICategory[];
  subcategories: ISubCategory[];
  brands: IBrand[];
  strategy: IStrategyModel;
  keyOfActiveStep: CurrentStep;
  configId: number | undefined;
  newPricesAvailable: any[];
  isUpdatePriceInProcess: boolean;
  lastPricesUpdate: Date | undefined;
  competitorsAll: ICompetitor[];
  competitorsInGroup: ICompetitor[];
  competitorsAllSelectedIds: string[];
  competitorsInGroupSelectedIds: string[];
  productsInGroup: IProduct[];
  productsInGroupSelectedIds: string[];
  recordsCountInGroup: number;
  productInGroupIdRadioSelected: string;
  exceptionProducts: IProductException[];
  allExceptions: IProductException[];
  validationErrors: ValidationErrorsT;
  isLoading: boolean;
  countExceptionsExist: number;
  isLoadingModifyException: boolean;
  isInitialLoaderGroups: boolean;
  productsNewRepriceReady: number;
  productsForReprice: IProduct[];
  recordsCountForReprice: number;
  isInitialLoadingGroupItems: boolean;
  repricedIds: string[];
  isLoadingStrategyRule: boolean;
  groupsList: IGroupList[];
  getConfig: () => void;
  updatePrices: () => void;
  setGroupName: (value: string) => void;
  getCategories: () => void;
  setBrands: (brands: IBrand[]) => void;
  setSubcategories: (subcategories: ISubCategory[]) => void;
  getGroup: (id: string) => Promise<any>;
  setCurrentGroup: (group: any) => void;
  getGroups: (queryParams: any) => void;
  setGroups: (groups: IGroup[]) => void;
  selectAllGroup: (flag: boolean) => void;
  getGroupsFilters: (queryParams: any) => void;
  showUpdatePriceAlert: (newArr: []) => void;
  updateGroup: (formData: IGroupFormEdit) => void;
  updateGroupName: (value: string) => Promise<void>;
  createGroup: () => Promise<IGroup | undefined>;
  selectGroup: (groupId: string, flag: boolean) => void;
  deleteGroup: (group: IGroup, queryParams: IQuery) => void;
  removeGroup: (groupId: string) => Promise<void>;
  saveEmptyGroupWithProducts: (
    selectedProductsIds: string[],
    groupId: string | null,
    selectedCategories: string[]
  ) => Promise<any>;
  getProductsInGroup: (params: IDataTransfer) => Promise<void>;
  deleteProductsInGroup: (groupId: number) => void;
  selectProductInGroup: (productId: string, flag: boolean) => void;
  selectAllProductsInGroup: (flag: boolean) => void;
  removeSelectProductsIdsInGroup: () => void;
  setCompetitorsInGroup: (arr: ICompetitor[]) => void;
  // Strategy
  getAllCompetitors: (groupId: string, queryParams: IQuery) => void;
  getCompetitorsInGroup: (groupId: string, queryParams: IDataTransfer) => void;
  selectCompetitorsAll: (flag: boolean) => void;
  selectCompetitorsInGroup: (flag: boolean) => void;
  selectCompetitor: (competitorId: string) => void;
  selectCompetitorInGroup: (competitorId: string) => void;
  getMonthlyCategoryTraffic: (groupId: string) => any;
  updateStrategy: (
    keyOfStrategy: CurrentStep | AdditionalCustomStep
  ) => Promise<any>;
  getStrategy: () => Promise<void>;
  setStrategyType: (type: StrategyTypeEnum) => void;
  addCompetitorsToGroup: () => void;
  setCompetitorsAllSelectedIds: (arr: string[]) => void;
  setProductsInGroup: (value: IProduct[]) => void;
  clearStrategyKey: (key: StrategyKeys) => void;
  addAlertToStore: (key: StrategyKeys, alert: IAlert) => void;
  setProductInGroupIdRadioSelected: (value: string) => void;
  addExceptionToGroup: () => void;
  getExceptions: (id?: string) => void;
  removeExceptionById: (id: string) => void;
  removeExceptionItemGroupById: (id: string) => void;
  removeLocalExceptionById: (id: string) => void;
  removeCompetitors: () => Promise<void>;
  removeCompetitorsInGroup: () => void;
  clearCompetitorsAll: () => void;
  getAllExceptions: (params: IDataTransfer) => any;
  setValidationError: (error: {
    [key: keyof IStrategyModel]: IValidationKey | null;
  }) => void;
  editException: (
    productId: string,
    obj: any,
    mode?: string
  ) => IProductException[];
  saveExceptionChange: (productId: string) => Promise<void>;
  clearAlertToStore: (key: StrategyKeys, updatedAlerts: IAlert[]) => void;
  setExceptionProducts: (value: IProductException[]) => void;
  setAllExceptions: (arr: IProductException[]) => void;
  clearStrategyInStore: () => void;
  removeProductsInGroup: () => void;
  setKeyOfActiveStep: (key: CurrentStep) => void;
  filterAllExceptions: () => void;
  fetchCompleteCreateGroup: () => Promise<any>;
  fetchSaveCurrentStep: (keyOfStrategy: CurrentStep) => void;
  clearGroup: () => void;
  removeSelectedCompetitorsIds: () => void;
  modifyException: (productId: string, exceptionLimits: any) => Promise<void>;
  getBrandsFromGroup: () => Promise<void>;
  handleSaveNewPrice: (productId: string, newPrice: number) => Promise<void>;
  // getCountNewPriceReady: (groupId?: string) => Promise<void>;
  getProductsForReprice: (params: IDataTransfer) => Promise<void>;
  applyNewPrices: (groupId: string) => Promise<void>;
  changeCountNewPriceReady: (isLocked: boolean, productId: string) => void;
  getProductById: (productId: string) => Promise<void>;
  setNewStatusGroup: (status: EGroupStatus) => void;
  changeProduct: (product: IProduct) => void;
  setStatusProductById: (id: string, status: RepricingStatus) => void;
  seTisInitialLoadingGroupItems: (value: boolean) => void;
  setProductsForReprice: (products: IProduct[]) => void;
  setNewStatusToGroups: (group: IGroup) => void;
  setRepricedIds: (arr: string[]) => void;
  setCountNewPriceReady: (count: number) => void;
  modifyLocalException: (
    productId: string,
    exception: IProductExceptionItem
  ) => void;
  clearIsRepricedStatuses: () => void;
  getBrandsByCategoryIds: (categoriesIds: string[]) => Promise<void>;
  getGroupsList: (ids: string[]) => Promise<void>;
  startEditGroup: (id: string) => Promise<void>;
  rollbackEditGroup: (id: string) => Promise<void>;
  commitEditGroup: (id: string) => Promise<void>;
  getProductsWithoutCompetitors: (data: IDataTransfer) => Promise<void>;
  deleteAlertStrategyById: (id: string, data: any) => Promise<void>;
  selectProductOfCompetitor: (productId: string) => void;
  selectProductOfCompetitorInGroup: (productId: string) => void;
}
