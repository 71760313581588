import { IProduct, RepricingStatus } from "@entities/products";
import { generateTextColumn, Pagination, TableCustom } from "@shared/common";
import { ISortParams, SortParams } from "@shared/interfaces";
import Lock from "@shared/ui/assets/Lock";
import Unlock from "@shared/ui/assets/Unlock";
import {
  checkIsPositiveValue,
  findMarginRange,
  findRangePercentage,
  getLSKeyRepricedIds,
  getTimeAgo,
  toFixedCustom,
} from "@shared/utils";
import { Divider } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import "./tableItems.scss";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { CustomTooltip, LoaderDefault } from "@shared/ui/components";
import { VIEWER_DISABLE_ITEMS_TEXT } from "@shared/constants/utilsConstants";
import { useParams } from "react-router-dom";
import { useGroupStore } from "@entities/group";
import { Warning } from "@shared/ui/assets";
import { AlertTarget } from "@entities/groups";

interface IProps {
  setSortConfig: any;
  tableWidth: number;
  setShowInfoDrawer: any;
  setActiveProductId: any;
  sortConfig: any;
  activeProductId: string;
  currentBrand: string[];
  setCurrentLimit: any;
  setCurrentOffset: any;
  currentOffset: number;
  currentLimit: number;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  BRAND = "brand",
  STORE = "store",
  STATE = "state",
  SUBCATEGORY = "subcategory",
  LAST_REPRICE = "last_reprice",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
  NEW_PRICE = "newPrice",
  NEW_MARGIN = "newMargin",
  CATEGORY = "category",
}

const TableItems: FC<IProps> = (props) => {
  const { id } = useParams();
  const { currentGroup } = useGroupStore((state) => state);
  const {
    setSortConfig,
    tableWidth,
    setShowInfoDrawer,
    setActiveProductId,
    sortConfig,
    activeProductId,
    setCurrentLimit,
    setCurrentOffset,
    currentOffset,
    currentLimit,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const [widthOfScroll, setWidthOfScroll] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const recordsCount = useGroupStore((state) => state.recordsCountInGroup);
  const { fetchChangeLockProductMode, isLoading, changeCountNewPriceReady } =
    useGroupStore((state) => state);

  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, scrollLeft: 0 });

  const productsInGroup = useGroupStore((state) => state.productsInGroup);

  const { isViewer } = useCheckRole();

  useEffect(() => {
    checkIfScrollable();
  }, [containerRef?.current, containerRef, tableWidth]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleClickOnLock = (productId: string, isLocked: boolean) => {
    fetchChangeLockProductMode(productId, isLocked);
    changeCountNewPriceReady(isLocked, productId);
  };

  const showItemInfoDrawer = (productId: string) => {
    setShowInfoDrawer(true);
    setActiveProductId(productId);
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault();

    setIsDragging(true);
    setOffset({
      x: e.clientX,
      scrollLeft: containerRef?.current?.scrollLeft || 0,
    });
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (!isDragging || !containerRef.current) return;

    const container = containerRef.current;
    const deltaX = e.clientX - offset.x;

    const scrollDistance =
      (deltaX / container.offsetWidth) * container.scrollWidth;

    container.scrollLeft = offset.scrollLeft + scrollDistance;

    setOffset({ x: e.clientX, scrollLeft: container.scrollLeft });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const isShowAlert =
    currentGroup?.alerts &&
    currentGroup?.alerts?.length > 0 &&
    currentGroup.alerts.some(
      (el) => el.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES
    );

  const headDataTable = [
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380 sticky-table__th--left-name",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71 sticky-table__th--left-image",
    },
    {
      content: generateTextColumn({
        text: "Lock",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STATE === sortConfig.key,
        tooltipText: "Lock current price from repricing",
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.STATE,
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-130",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-141",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: isShowAlert ? (
          <div className="df ai-center">
            <CustomTooltip
              styles={{ width: 500 }}
              title={
                currentGroup.alerts?.find(
                  (el) => el.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES
                )?.message || ""
              }
            >
              <Warning />
            </CustomTooltip>
            &nbsp;New price
          </div>
        ) : (
          "New price"
        ),
        tooltipText: "New price after repricing",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NEW_PRICE === sortConfig.key,
      }),
      className: "table-w-163",
      sortKey: THEAD_SORT_KEYS.NEW_PRICE,
    },
    {
      content: generateTextColumn({
        text: "New margin",
        tooltipText: "Expected margin after repricing",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NEW_MARGIN === sortConfig.key,
      }),
      className: "table-w-144",
      sortKey: THEAD_SORT_KEYS.NEW_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120 brand-w-style",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Category",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CATEGORY === sortConfig.key,
      }),
      className: "table-w-135",
      sortKey: THEAD_SORT_KEYS.CATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-109 store-w-style",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
    {
      content: generateTextColumn({
        text: "Last reprice",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.LAST_REPRICE === sortConfig.key,
      }),
      className: "table-w-135",

      sortKey: THEAD_SORT_KEYS.LAST_REPRICE,
    },
  ];

  const repricedIds = id
    ? localStorage.getItem(getLSKeyRepricedIds(id))?.split(",")
    : [];

  const dataTableBodyCompare = [
    {
      content: ({ id, article, name }: IProduct) => {
        return (
          <div
            className="default-padding-blocks-table custom-height-group-items-name"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <h5 className="product__subtitle ">{article}</h5>
            <h5
              className="group_product__title"
              style={{ width: tableWidth * 0.3 }}
            >
              {name}
            </h5>
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-380 sticky-table__body--left-name  ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ imageUrl, id }: IProduct) => {
        return (
          <div
            className="image-block-table custom-height-group-items"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <img alt="img-url" className="product__img" src={imageUrl} />
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-71 sticky-table__body--left-image ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ state, id }: IProduct) => (
        <div
          className={` default-padding-blocks-table`}
          onClick={(e) => {
            !isViewer && handleClickOnLock(id, !state);
            e.stopPropagation();
          }}
        >
          <CustomTooltip
            className="custom-tooltip-items"
            isUseClasses={false}
            title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
          >
            <div
              className={`${isViewer ? "disabled custom-tooltip-items" : ""}`}
            >
              {state ? <Lock /> : <Unlock />}
            </div>
          </CustomTooltip>
        </div>
      ),
      className: () => "table-w-109 ",
    },
    {
      content: ({ currentPrice, id }: IProduct) => (
        <h5
          className="default-padding-blocks-table ta-right custom-height-group-items"
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
        >
          ${toFixedCustom(currentPrice)}
        </h5>
      ),
      className: ({ id }: IProduct) => {
        return `table-w-109 ${
          repricedIds?.includes(id.toString()) ? "complete-current-price" : ""
        }`;
      },
    },
    {
      content: ({ currentMargin = "0", id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right default-padding-blocks-table custom-height-group-items"
        >
          {toFixedCustom(currentMargin)}%
        </h5>
      ),
      className: () => "ta-right table-w-109",
    },
    {
      content: ({
        state,
        id,
        newPrice,
        currentPrice,

        status,
      }: IProduct) => {
        const isPending = status === RepricingStatus.PENDING;
        const newPriceChooser = newPrice ? +newPrice : null;
        const range = findRangePercentage(+currentPrice, newPriceChooser);
        return (
          <div
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
            className="ta-right default-padding-blocks-table custom-height-group-items"
          >
            <div>
              <div className="product__current-value">
                {isPending ? (
                  <LoaderDefault />
                ) : (
                  <>
                    {!state && newPrice ? (
                      +toFixedCustom(newPrice) > 0 ? (
                        `$${toFixedCustom(newPrice || 0)}`
                      ) : (
                        <CustomTooltip
                          styles={{ width: 500 }}
                          title="Negative new price detected. 
                      To fix this please review your strategy rule or add custom limits. 
                      These products will remain unpriced until the issue is resolved."
                        >
                          <div className="df ai-center na-current-price">
                            <Warning />
                            &nbsp;{"N/A"}
                          </div>
                        </CustomTooltip>
                      )
                    ) : (
                      "-"
                    )}
                  </>
                )}
              </div>
              {!isPending ? (
                <div>
                  {!state && newPrice && +toFixedCustom(newPrice) > 0 ? (
                    <div
                      className={
                        checkIsPositiveValue(range)
                          ? "product__new-value--positive"
                          : "product__new-value--negative"
                      }
                    >
                      {range}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        );
      },
      className: () => "table-w-135",
    },
    {
      content: ({ currentMargin, state, id, newMargin, status }: IProduct) => {
        const isPending = status === RepricingStatus.PENDING;
        const newPriceChooser = newMargin ? +newMargin : null;
        const range = findMarginRange(+currentMargin, newPriceChooser);

        return (
          <>
            <h5
              onClick={(e) => {
                onOpenDrover(id, e);
              }}
              className="ta-right default-padding-blocks-table custom-height-group-items"
            >
              <div>
                <div className="product__current-value">
                  {isPending ? (
                    <>
                      <LoaderDefault />
                    </>
                  ) : !state && newMargin ? (
                    `${toFixedCustom(toFixedCustom(newMargin || 0))}%`
                  ) : (
                    "-"
                  )}
                </div>
                {!isPending ? (
                  <div>
                    {!state ? (
                      <div
                        className={
                          checkIsPositiveValue(range)
                            ? "product__new-value--positive"
                            : "product__new-value--negative"
                        }
                      >
                        {range}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </h5>
          </>
        );
      },
      className: () => "table-w-90",
    },
    {
      content: ({ cost, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right custom-height-group-items default-padding-blocks-table"
        >
          ${toFixedCustom(cost)}
        </h5>
      ),
      className: () => "table-w-90",
    },

    {
      content: ({ brand, id }: IProduct) => (
        <div
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          <h5 className="resize-block-brand">{brand}</h5>
        </div>
      ),
      className: () => "table-w-120 ",
    },
    {
      content: ({ category, id }: IProduct) => (
        <div
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          <h5 className="resize-block-brand">{category}</h5>
        </div>
      ),
      className: () => "table-w-135",
    },
    {
      content: ({ store, id }: IProduct) => (
        <div
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          <h5 className="resize-block-store">{store}</h5>
        </div>
      ),
      className: () => "table-w-109",
    },
    {
      content: ({ last_reprice, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items default-padding-blocks-table "
        >
          {getTimeAgo(last_reprice || "")}
        </h5>
      ),
      className: () => "table-w-135",
    },
  ];

  const dataTableBody = productsInGroup.map((item: any) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: item.id === activeProductId,
      className: el?.className(item) || "",
      item: item,
    }));
  });

  const handleScroll = (event: any) => {
    const { scrollLeft } = event.target;

    const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;

    setWidthOfScroll(tableWidth - 460 - maxScrollLeft);

    setScrollLeft(scrollLeft);
  };

  const onOpenDrover = (id: string, e: any) => {
    showItemInfoDrawer(id);
    e.stopPropagation();
  };

  const checkIfScrollable = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;

      setIsScrollable(scrollWidth > clientWidth);
      const maxScrollLeft = scrollWidth - clientWidth;

      setWidthOfScroll(tableWidth - 450 - maxScrollLeft);
    }
  };

  const [heightOfTableItems, setHeightOfTableItems] = useState(0);

  useEffect(() => {
    const heightOfTableItemsLocal = currentGroup?.alerts?.some(
      (el) => el.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES && el.isShow
    )
      ? 400
      : 316;

    setHeightOfTableItems(heightOfTableItemsLocal);
  }, [currentGroup?.alerts]);

  return (
    <>
      <div>
        <div
          onScroll={handleScroll}
          ref={containerRef}
          className={` ${
            isLoading ? "clear-table-overflow" : "group-items__table"
          }`}
          style={{
            width: tableWidth,
            maxHeight: `calc(100vh - ${heightOfTableItems}px)`,
          }}
        >
          <TableCustom
            headData={headDataTable}
            bodyData={dataTableBody}
            handleSort={handleSort}
          />
          {/* <div>qwe</div> */}
          {!productsInGroup.length && !isLoading ? (
            <>
              <div className="group-items__no-results">No results found.</div>
              <Divider />
            </>
          ) : null}
          <div
            onMouseDown={handleMouseDown}
            className="custom-scrollbar-block"
            style={{ width: tableWidth - 460 }}
          >
            {isScrollable && !isLoading && !!productsInGroup.length && (
              <div
                className="custom-scrollbar"
                style={{
                  left: `${scrollLeft}px`,
                  width: `${widthOfScroll}px`,
                }}
              ></div>
            )}
          </div>
          <div
            onMouseDown={handleMouseDown}
            className="custom-scrollbar-vertical-block"
          >
            <div
              // className="custom-scrollbar"
              style={
                {
                  // left: `${scrollLeft}px`,
                  // width: `${widthOfScroll}px`,
                }
              }
            ></div>
          </div>
        </div>
      </div>
      {productsInGroup.length ? (
        <>
          <div className="pagination__container group-items__pagination-container">
            <div></div>
            <Pagination
              prevPage={definePrefPage}
              nextPage={defineNextPage}
              onChange={onChangeItemsCount}
              recordsCount={definePageCount()}
              currentLimit={defineCurrentLimit()}
              currentSelectLimit={currentLimit.toString()}
              currentOffset={defineCurrentOffset()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default TableItems;
