import React, { useEffect, useRef, useState } from "react";
import "./competition.scss";
import {
  AlertCustom,
  ButtonStyled,
  CustomTooltip,
  InputCustom,
  LoaderFullScreen,
  RadioCustom,
  ScoreRange,
  SwitcherCustom,
} from "@shared/ui/components";
import {
  ArrowBorder,
  Delete,
  Error,
  Info,
  Plus,
  Warning,
} from "@shared/ui/assets";
import { StrategyCompetitionPopup } from "@widgets/admin/groups";
import { Checkbox, Drawer } from "antd";
import { generateTextColumn, TableCustom } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import {
  Rate,
  Metric,
  AlertType,
  CurrentStep,
  ICompetitor,
  PriceFormulaType,
  useGroupsStore,
  AlertTarget,
} from "@entities/groups";
import {
  extractAndReplace,
  filteredCompareQueryObj,
  getCompetitorWord,
  getItemWord,
  htmlToString,
} from "@shared/utils";
import { useParams } from "react-router-dom";
import "./competition.scss";
import { DIRECTION_PARAM, ORDER_BY_PARAM } from "@shared/constants";
import useStrategy from "@shared/model/hooks/useStrategy";
import PopupUnmatchedProducts from "../PopupUnmatchedProducts/PopupUnmatchedProducts";
import LinkToNew from "@shared/ui/assets/LinkToNew";

enum THEAD_SORT_KEYS {
  NAME = "name",
  TRAFFIC = "score",
}

const dataSign = [
  {
    key: Metric.PLUS,
    content: <>+</>,
  },
  {
    key: Metric.MINUS,
    content: <>-</>,
  },
];

const dataRate = [
  {
    key: Rate.DOLLAR,
    content: <>$</>,
  },
  {
    key: Rate.PERCENTAGE,
    content: <>%</>,
  },
];

interface IProps {
  setActiveTab: (highlighted: CurrentStep) => void;
}

const Competition = ({ setActiveTab }: IProps) => {
  const { id } = useParams();
  const [isShowModal, setIsShowModal] = useState(false);
  const {
    competitorsInGroup,
    competitorsInGroupSelectedIds,
    getCompetitorsInGroup,
    selectCompetitorInGroup,
    selectCompetitorsInGroup,
    strategy,
    removeCompetitors,
    removeCompetitorsInGroup,
    updateStrategy,
    getStrategy,
    removeSelectedCompetitorsIds,
    isLoadingStrategyRule,
    deleteAlertStrategyById,
    selectProductOfCompetitorInGroup,
  } = useGroupsStore((state) => state);
  const { clearAlertToStore } = useGroupsStore((state) => state);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const isAllSelected = competitorsInGroup.every((competitor) =>
    competitorsInGroupSelectedIds.includes(competitor.id)
  );
  const [openDelProductsModal, setOpenDelProductsModal] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: "",
    direction: "",
  });
  const type = strategy.strategyRule.pricesFormula.type;
  const valueInput = strategy.strategyRule.pricesFormula.value;
  const metric = strategy.strategyRule.pricesFormula.metric;
  const rate = strategy.strategyRule.pricesFormula.rate;
  const alerts = strategy.strategyRule?.alerts;
  const [value, setValue] = useState(valueInput);

  const metricActiveIndex =
    dataSign.findIndex((el) => el.key === metric) >= 0
      ? dataSign.findIndex((el) => el.key === metric)
      : 0;

  const rateActiveIndex =
    dataRate.findIndex((el) => el.key === rate) >= 0
      ? dataRate.findIndex((el) => el.key === rate)
      : 0;

  const optionsToChooseCollapseMetric = {
    [PriceFormulaType.HIGHEST]: "The highest price among competitors",
    [PriceFormulaType.AVERAGE]:
      "The average price is calculated as the arithmetic mean",
    [PriceFormulaType.LOWEST]: "The lowest price among competitors",
  };

  const options = [
    {
      element: optionsToChooseCollapseMetric[PriceFormulaType.HIGHEST],
      checked: false,
      key: PriceFormulaType.HIGHEST,
    },
    {
      element: (
        <div className="df ">
          <span className="mr-8">The average price among competitors</span>
          <CustomTooltip
            title={optionsToChooseCollapseMetric[PriceFormulaType.AVERAGE]}
            placement="right"
            arrow={true}
          >
            <Info />
          </CustomTooltip>
        </div>
      ),
      checked: false,
      key: PriceFormulaType.AVERAGE,
    },
    {
      element: optionsToChooseCollapseMetric[PriceFormulaType.LOWEST],
      checked: false,
      key: PriceFormulaType.LOWEST,
    },
  ];

  const combineSaveToStore = (key: string, value: string) => {
    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        strategyRule: {
          ...state.strategy.strategyRule,
          alerts: [],
          pricesFormula: {
            ...state.strategy.strategyRule.pricesFormula,
            [key]: value,
          },
        },
      },
    }));
  };

  const handleClick = (option: any) => {
    combineSaveToStore("type", option.key);
    setValue("");

    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        strategyRule: {
          ...state.strategy.strategyRule,
          pricesFormula: {
            ...state.strategy.strategyRule.pricesFormula,
            value: "",
            rate: "dollar",
            metric: "plus",
          },
        },
      },
    }));
    updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const onSearchChange = (target: any) => {
    setValue(target.value);
  };

  const handleBlur = () => {
    let valueLocal = value;

    if (value) {
      if (value.includes(".")) {
        const [integerPart, decimalPart] = value.split(".");
        const truncatedDecimal = decimalPart.substring(0, 2);
        valueLocal = `${integerPart}.${truncatedDecimal.padEnd(2, "0")}`;
      } else {
        valueLocal = `${value}.00`;
      }
    }
    setValue(valueLocal);
    combineSaveToStore("value", valueLocal);
    updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key) && isNaN(Number(e.key)) && e.key !== ".") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const data = {
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
    };

    const queryObject = filteredCompareQueryObj(data) as IDataTransfer;
    if (!isShowModal && id) {
      getCompetitorsInGroup(id, queryObject);
    }
    return () => {
      removeSelectedCompetitorsIds();
      removeCompetitorsInGroup();
      setExpandedRowKeys([]);
    };
  }, [isShowModal, sortConfig]);

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const checkMainCheckboxValue = () => {
    const isShowMinus = competitorsInGroup.reduce((acc, item) => {
      if (
        !acc &&
        item.products.some(
          (product) =>
            product.checked &&
            !competitorsInGroup.every((competitor) =>
              competitorsInGroupSelectedIds.includes(competitor.id)
            )
        )
      ) {
        return true;
      }
      return acc;
    }, false);
    return isShowMinus;
  };

  const checkCompetitorsCheckboxValue = (id: string) => {
    const isAllInCompetitorsChecked = competitorsInGroup.reduce(
      (acc, competitor) => {
        if (
          !acc &&
          competitor.id === id &&
          competitor.products.every((product) => product.checked)
        ) {
          acc = true;
        }

        return acc;
      },
      false
    );

    const isShowMinus = competitorsInGroup
      .find((item) => item.id === id)
      ?.products.some(
        (product) =>
          product.checked &&
          !competitorsInGroup.every((competitor) =>
            competitorsInGroupSelectedIds.includes(competitor.id)
          )
      );
    return isShowMinus && !isAllInCompetitorsChecked;
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          indeterminate={checkMainCheckboxValue()}
          checked={isAllSelected && !!competitorsInGroup.length}
          onChange={(event) => selectCompetitorsInGroup(event.target.checked)}
        />
      ),
      className: "table-w-48 ",
    },
    {
      content: <div></div>,
      className: "table-w-52",
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Competitor name",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
          })}
        </>
      ),
      className: "table-w-492 ",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Score",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.TRAFFIC === sortConfig.key,
            tooltipText: "Traffic per month",
          })}
        </>
      ),
      className: "table-w-117",
      sortKey: THEAD_SORT_KEYS.TRAFFIC,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: ICompetitor) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              indeterminate={checkCompetitorsCheckboxValue(id)}
              checked={competitorsInGroupSelectedIds.some((el) => el === id)}
              onChange={() => {
                selectCompetitorInGroup(id);
              }}
            ></Checkbox>
          </div>
        );
      },
      className: "df jc-c p-16",
    },
    {
      content: ({ id }: any) => {
        return (
          <>
            {expandedRowKeys.includes(id) ? (
              <div className="arrow__border-top">
                <ArrowBorder />
              </div>
            ) : (
              <div className="arrow__border-bottom">
                <ArrowBorder />
              </div>
            )}
          </>
        );
      },
      className: "",
    },
    {
      content: ({ name, externalLink, products }: ICompetitor) => {
        return (
          <span>
            <a href={externalLink} target="_blank">
              {name}
            </a>
            <span className="expanded__list-count">
              ({products.length} {getItemWord(products.length)})
            </span>
          </span>
        );
      },
      className: "table-item-title",
    },
    {
      content: ({ score }: ICompetitor) => {
        return (
          <div className="table-item-traffic">
            <div> {score}</div>

            <div>{score ? <ScoreRange count={score} /> : "-"}</div>
          </div>
        );
      },
      className: "table-item-title table-w-117",
    },
  ];

  const handleExpanded = (item: string) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(item)) {
        return prevKeys.filter((key) => key !== item);
      } else {
        return [...prevKeys, item];
      }
    });
  };

  const dataTableBody = competitorsInGroup.map((item: ICompetitor) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      className: el.className,
      isSelected: competitorsInGroupSelectedIds.some((id) => id === item.id),
      isExpanded: expandedRowKeys.includes(item.id),
      item: item.id,
    }));
  });

  const isShowContentWithItems =
    strategy.strategyRule.competitors.length || competitorsInGroup.length;

  const onCloseWarning = () => {
    const { alerts } = strategy.strategyRule;
    const filteredAlerts = alerts?.filter(
      (el) => el.target !== AlertTarget.STRATEGY_RULE__ADD_NEW_PRODUCT
    );
    id &&
      deleteAlertStrategyById(id, {
        strategyKey: CurrentStep.STRATEGY_RULE,
        alertTarget: AlertTarget.STRATEGY_RULE__ADD_NEW_PRODUCT,
      });
    clearAlertToStore(CurrentStep.STRATEGY_RULE, filteredAlerts);
    // updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const handleAddCompetitors = () => {
    setIsShowModal(true);
    onCloseWarning();
  };

  const handleRemoveCompetitors = async () => {
    await removeCompetitors();
    const data = {
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
    };

    const queryObject = filteredCompareQueryObj(data) as IDataTransfer;
    if (!isShowModal && id) {
      await getCompetitorsInGroup(id, queryObject);
    }
    if (competitorsInGroupSelectedIds.length === competitorsInGroup.length) {
      useGroupsStore.setState((state) => ({
        strategy: {
          ...state.strategy,
          strategyRule: {
            alerts: [],
            competitors: [],
            pricesFormula: {
              type: null,
              value: "",
              metric: Metric.PLUS,
              rate: Rate.DOLLAR,
              metricToGrow: "",
              metricToMaintain: "",
            },
          },
        },
      }));
    }
    await getStrategy();
    setExpandedRowKeys([]);
  };

  const alertError = alerts.find((alert) => alert.type === AlertType.ERROR);
  const alertWarning = alerts.find((alert) => alert.type === AlertType.WARNING);

  const getDynamicHeight = () => {
    const height = window.innerHeight;
    const alertHeight = 58;
    const defaultMinusValue = 560;
    const paddings = 14;

    const isErrorAlert = alerts.some((el) => {
      return el.type === AlertType.ERROR;
    });

    const isWarningAlert = alerts.some((el) => {
      return el.type === AlertType.WARNING;
    });

    const checkIsError = isErrorAlert ? alertHeight : 0;
    const checkIsWarning = isWarningAlert ? alertHeight : 0;

    const isAlert = isErrorAlert || isWarningAlert;
    const minusValue = isAlert ? 0 : 20;
    const correctionHeight = 42;

    return (
      height -
      defaultMinusValue -
      checkIsError -
      checkIsWarning -
      paddings +
      minusValue +
      correctionHeight -
      100
    );
  };

  const blockRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsCollapsed(!entry.isIntersecting);
      },
      {
        threshold: [1],
      }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    let lastScrollY = blockRef?.current?.getBoundingClientRect().y || 0;

    const handleScroll = () => {
      const currentScrollY = blockRef?.current?.getBoundingClientRect().y || 0;

      setIsOpenCollapse(false);
      lastScrollY = currentScrollY;
    };

    wrapperRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
      wrapperRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [competitorsInGroup]);

  const handleOpenCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse);
  };

  const chooserClassNames = () => {
    let resClassNames = "";
    if (alertError && alertWarning && isOpenCollapse) {
      return (resClassNames += "");
    }
    if (alertError && alertWarning) {
      return (resClassNames += " all-alerts-are");
    }
    if (alertError) {
      resClassNames += " groups-strategy-rule__table-error";
    }
    if (alertWarning) {
      resClassNames += " groups-strategy-rule__table-warning";
    }
    return resClassNames;
  };

  const switcherMetric = (
    <SwitcherCustom
      activeIndex={metricActiveIndex}
      data={dataSign}
      onClick={(metric) => {
        combineSaveToStore("metric", metric);
        updateStrategy(CurrentStep.STRATEGY_RULE);
      }}
    />
  );

  const switcherRate = (
    <SwitcherCustom
      activeIndex={rateActiveIndex}
      data={dataRate}
      onClick={(rate) => {
        combineSaveToStore("rate", rate);
        updateStrategy(CurrentStep.STRATEGY_RULE);
      }}
    />
  );

  const handleOpenDelProductsModal = () => {
    setOpenDelProductsModal(true);
  };

  const resParsText = () => {
    const { extracted, cleanStr } = extractAndReplace(
      (alertError && alertError.message) || "",
      "a",
      "span"
    );
    return (
      <div>
        {cleanStr.split("{TEXT1}").map((part, index) =>
          index === 0 ? (
            part
          ) : (
            <React.Fragment key={index}>
              <span className="custom-support-competition">
                {extracted.text1}
              </span>
              {part.split("{TEXT2}").map((subPart, subIndex) =>
                subIndex === 0 ? (
                  subPart
                ) : (
                  <React.Fragment key={subIndex}>
                    <span
                      className="remove-unmatched-products"
                      onClick={handleOpenDelProductsModal}
                    >
                      {extracted.text2}
                    </span>
                    {subPart}
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  };

  const resParsTextNoCompetitors = () => {
    const { extracted, cleanStr } = extractAndReplace(
      (alertError && alertError.message) || "",
      "a",
      "span"
    );
    return (
      <div>
        {cleanStr.split("{TEXT1}").map((part, index) =>
          index === 0 ? (
            part
          ) : (
            <>
              <span
                className="custom-support-competition"
                onClick={() => setActiveTab(CurrentStep.STRATEGY_TYPE)}
              >
                {extracted.text1}
              </span>
              {part.split("{TEXT2}").map((subPart, subIndex) =>
                subIndex === 0 ? (
                  subPart
                ) : (
                  <div key={subIndex}>
                    <span className="remove-unmatched-products">
                      {extracted.text2}
                    </span>
                    {subPart}
                  </div>
                )
              )}
            </>
          )
        )}
      </div>
    );
  };

  const isSelectedProduct = () => {
    return (
      competitorsInGroup.filter((item) =>
        item.products.some((product) => product.checked)
      ).length || null
    );
  };

  const handleCheckboxClick = (productId: string) => {
    selectProductOfCompetitorInGroup(productId);
  };

  const render = () => {
    return isShowContentWithItems ? (
      <div className="groups-strategy-rule__wrapper" ref={wrapperRef}>
        <>
          <>
            <h3 className="groups-strategy-rule__title">Strategy Rule</h3>
            <h5 className="groups-strategy-rule__subtitle">
              Configure the key strategy rule
            </h5>
          </>

          {!isLoadingStrategyRule && (
            <>
              <div>
                {alertError &&
                alertError.target ==
                  AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                  <AlertCustom
                    type="error"
                    message={resParsTextNoCompetitors()}
                    icon={<Error />}
                    className="groups-strategy-rule__alert-error"
                    closable={false}
                  />
                ) : null}

                {alertError &&
                alertError.target !==
                  AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                  <AlertCustom
                    type="error"
                    message={
                      alertError.target ===
                      AlertTarget.STRATEGY_RULE__ITEMS_NO_COMPETITORS
                        ? resParsText()
                        : alertError?.message
                    }
                    icon={<Error />}
                    className="groups-strategy-rule__alert-error"
                    closable={false}
                  />
                ) : null}
              </div>

              <h4 className="groups-price-limits__text">
                Choose how to set your item prices
              </h4>
            </>
          )}
        </>

        {isLoadingStrategyRule ? (
          <LoaderFullScreen />
        ) : (
          <div className="groups-strategy-rule__content">
            <>
              {options.map((option, index) => (
                <div key={index} className="groups-strategy-rule__radio">
                  <div className="groups-strategy-rule__radio-icon">
                    <RadioCustom
                      checked={type === option.key}
                      onChange={() => handleClick(option)}
                    />
                  </div>
                  <div
                    className="groups-strategy-rule__radio-text"
                    onClick={() => handleClick(option)}
                  >
                    {option.element}
                  </div>
                  {type === option.key &&
                  option.key !== PriceFormulaType.AVERAGE ? (
                    <div className="groups-strategy-rules__wrapper">
                      {switcherMetric}
                      <InputCustom
                        onBlur={() => handleBlur()}
                        className="groups-strategy-rule__input"
                        type="string"
                        value={value}
                        name="search"
                        placeholder="0.00"
                        onChangeInput={({ target }) => {
                          onSearchChange(target);
                        }}
                        fill="gray-primary-900"
                        onKeyDown={handleKeyDown}
                      />
                      {switcherRate}
                    </div>
                  ) : null}
                </div>
              ))}

              {alertWarning ? (
                <div className="groups-strategy-rule__alert-warning">
                  <AlertCustom
                    type="warning"
                    onClose={onCloseWarning}
                    message={alertWarning?.message}
                    icon={<Warning />}
                    closable={true}
                  />
                </div>
              ) : null}
            </>

            {isCollapsed ? (
              <>
                {isOpenCollapse ? (
                  <div className="collapsed-block-competitors-open">
                    <>
                      <div>
                        {alertError &&
                        alertError.target ==
                          AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                          <AlertCustom
                            type="error"
                            message={resParsTextNoCompetitors()}
                            icon={<Error />}
                            className="groups-strategy-rule__alert-error"
                            closable={false}
                          />
                        ) : null}

                        {alertError &&
                        alertError.target !==
                          AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                          <AlertCustom
                            type="error"
                            message={
                              alertError.target ===
                              AlertTarget.STRATEGY_RULE__ITEMS_NO_COMPETITORS
                                ? resParsText()
                                : alertError?.message
                            }
                            icon={<Error />}
                            className="groups-strategy-rule__alert-error"
                            closable={false}
                          />
                        ) : null}
                      </div>

                      <h4 className="groups-price-limits__text">
                        Choose how to set your item prices
                      </h4>
                    </>

                    <>
                      {options.map((option, index) => (
                        <div
                          key={index}
                          className="groups-strategy-rule__radio"
                        >
                          <div className="groups-strategy-rule__radio-icon">
                            <RadioCustom
                              checked={type === option.key}
                              onChange={() => handleClick(option)}
                            />
                          </div>
                          <div
                            className="groups-strategy-rule__radio-text"
                            onClick={() => handleClick(option)}
                          >
                            {option.element}
                          </div>

                          {type === option.key &&
                          option.key !== PriceFormulaType.AVERAGE ? (
                            <div className="groups-strategy-rules__wrapper">
                              {switcherMetric}
                              <InputCustom
                                onBlur={() => handleBlur()}
                                className="groups-strategy-rule__input"
                                type="string"
                                value={value}
                                name="search"
                                placeholder="0.00"
                                onChangeInput={({ target }) => {
                                  onSearchChange(target);
                                }}
                                fill="gray-primary-900"
                                onKeyDown={handleKeyDown}
                              />
                              {switcherRate}
                            </div>
                          ) : null}
                        </div>
                      ))}
                      {alertWarning ? (
                        <div className="groups-strategy-rule__alert-warning">
                          <AlertCustom
                            type="warning"
                            onClose={onCloseWarning}
                            message={alertWarning?.message}
                            icon={<Warning />}
                            closable={true}
                          />
                        </div>
                      ) : null}

                      <Drawer />
                      <div>
                        <div className="groups-strategy-rule__info-table-collapsed">
                          <h4 className="groups-strategy-rule__text dc">
                            {competitorsInGroup.length}{" "}
                            {getCompetitorWord(competitorsInGroup.length)}
                          </h4>

                          <ButtonStyled
                            text="Add competitors"
                            className="groups-strategy-rule__btn"
                            icon={<Plus color="#000" />}
                            onClick={handleAddCompetitors}
                          />
                        </div>
                      </div>
                    </>
                    <div>
                      <TableCustom headData={headDataTable} bodyData={[]} />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={`collapsed-block-competitors`}>
                      <div>
                        {alertError &&
                        alertError.target ==
                          AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                          <AlertCustom
                            type="error"
                            message={resParsTextNoCompetitors()}
                            icon={<Error />}
                            className="groups-strategy-rule__alert-error"
                            closable={false}
                          />
                        ) : null}

                        {alertError &&
                        alertError.target !==
                          AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                          <AlertCustom
                            type="error"
                            message={
                              alertError.target ===
                              AlertTarget.STRATEGY_RULE__ITEMS_NO_COMPETITORS
                                ? resParsText()
                                : alertError?.message
                            }
                            icon={<Error />}
                            className="groups-strategy-rule__alert-error"
                            closable={false}
                          />
                        ) : null}
                      </div>

                      <div className="df jc-sb ai-center collapsed-block-competitors__wrapper">
                        <div className="">
                          <h4 className="groups-price-limits__text">
                            Choose how to set your item prices
                          </h4>
                        </div>
                        <div>
                          {type ? optionsToChooseCollapseMetric[type] : null}
                          {valueInput ? (
                            <span>
                              &nbsp;
                              <span>
                                {dataSign.find((item) => item.key === metric)
                                  ?.content || null}
                              </span>
                              <span>{valueInput}</span>
                              <span>
                                {dataRate.find((item) => item.key === rate)
                                  ?.content || null}
                              </span>
                            </span>
                          ) : null}
                        </div>
                        {/* <div
                          onClick={handleOpenCollapse}
                          className="collapsed-block-icon"
                        >
                          <Expand />
                        </div> */}
                        <div>
                          <ButtonStyled
                            text="Add competitors"
                            className="groups-strategy-rule__btn"
                            icon={<Plus color="#000" />}
                            onClick={handleAddCompetitors}
                          />
                        </div>
                      </div>
                      {alertWarning ? (
                        <div className="groups-strategy-rule__alert-warning">
                          <AlertCustom
                            type="warning"
                            onClose={onCloseWarning}
                            message={alertWarning?.message}
                            icon={<Warning />}
                            closable={true}
                          />
                        </div>
                      ) : null}
                      <div className="table-header-custom-block">
                        <TableCustom
                          headData={headDataTable}
                          bodyData={[]}
                          handleSort={handleSort}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : null}
            <div className="anchor-block" ref={blockRef}></div>

            <div>
              <div className="groups-strategy-rule__info-table">
                <h4 className="groups-strategy-rule__text dc">
                  {competitorsInGroup.length}{" "}
                  {getCompetitorWord(competitorsInGroup.length)}
                </h4>

                <ButtonStyled
                  text="Add competitors"
                  className="groups-strategy-rule__btn"
                  icon={<Plus color="#000" />}
                  onClick={handleAddCompetitors}
                />
              </div>
            </div>

            <div
              className={`groups-strategy-rule__table ${chooserClassNames()} ${
                isOpenCollapse
                  ? "groups-strategy-rule__table-open-collapsed "
                  : ""
              }`}
              style={{ height: getDynamicHeight() }}
            >
              <TableCustom
                headData={headDataTable}
                bodyData={dataTableBody}
                onRowEvent={(item) => {
                  handleExpanded(item);
                }}
                handleSort={handleSort}
                expandedRowRender={(rowItem: string) => {
                  const expandedRow = competitorsInGroup.find(
                    (el) => el.id === rowItem
                  );
                  if (expandedRow) {
                    return expandedRowKeys.includes(rowItem) ? (
                      <>
                        {expandedRow.products.map((product, index) => {
                          return (
                            <tr
                              key={product.id + index}
                              className={`disable-default-styles ${
                                product.checked ? "tr-selected" : ""
                              }`}
                            >
                              <td></td>
                              <td className="disable-padding">
                                <div className=" check-item-product">
                                  <Checkbox
                                    checked={product.checked}
                                    onClick={() =>
                                      handleCheckboxClick(product.id)
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                colSpan={2}
                                className="expanded__list-container"
                              >
                                <ul>
                                  <li
                                    key={product.id}
                                    className="expanded__list"
                                  >
                                    <div className="df">
                                      <div
                                        className="expanded__list-item"
                                        onClick={() =>
                                          handleCheckboxClick(product.id)
                                        }
                                      >
                                        {product.name}
                                      </div>
                                      <a
                                        className="icon"
                                        target="_blank"
                                        href={product.externalLink}
                                      >
                                        <LinkToNew />
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : null;
                  }
                  return null;
                }}
              />
              {isSelectedProduct() && (
                <div className="groups-strategy-rule__footer">
                  <div className="selected__container">
                    <div className="selected__block mr-24">
                      {competitorsInGroupSelectedIds.length > 0 ? (
                        <>
                          <span className="selected__count">
                            {competitorsInGroupSelectedIds.length}&nbsp;
                          </span>

                          <span className="selected__text">
                            {getCompetitorWord(
                              competitorsInGroupSelectedIds.length
                            )}{" "}
                            selected
                          </span>
                        </>
                      ) : null}
                      <div className="groups-block-delete__btn">
                        <ButtonStyled
                          type="default"
                          icon={<Delete />}
                          text="Remove"
                          htmlType="button"
                          fill="danger_300"
                          className="groups-delete__btn"
                          onClick={handleRemoveCompetitors}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ) : (
      <>
        <div className="groups-strategy-rule__wrapper">
          <h3 className="groups-strategy-rule__title">Strategy Rule</h3>
          <h5 className="groups-strategy-rule__subtitle">
            Configure the key pricing strategy rule
          </h5>

          {isLoadingStrategyRule ? (
            <LoaderFullScreen />
          ) : (
            <div className="groups-strategy-rule__content">
              {alertError &&
              alertError.target == AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                <AlertCustom
                  type="error"
                  message={resParsTextNoCompetitors()}
                  icon={<Error />}
                  className="groups-strategy-rule__alert-error"
                  closable={false}
                />
              ) : null}

              {alertError &&
              alertError.target !==
                AlertTarget.STRATEGY_RULE__NO_COMPETITORS ? (
                <AlertCustom
                  type="error"
                  message={
                    alertError.target ===
                    AlertTarget.STRATEGY_RULE__ITEMS_NO_COMPETITORS
                      ? resParsText()
                      : alertError?.message
                  }
                  icon={<Error />}
                  className="groups-strategy-rule__alert-error"
                  closable={false}
                />
              ) : null}

              <div className="groups-strategy-rule__text">
                Add competitors first to start configuring the strategy rule
              </div>

              <div>
                <ButtonStyled
                  type="primary"
                  icon={<Plus />}
                  text="Add competitors"
                  htmlType="button"
                  fill="gray-primary-900"
                  className="groups-strategy-rule__btn"
                  onClick={() => {
                    setIsShowModal(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <StrategyCompetitionPopup
        flag={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
      <PopupUnmatchedProducts
        flag={openDelProductsModal}
        setItemsPopupShow={setOpenDelProductsModal}
      />
      {render()}
    </>
  );
};

export default Competition;
