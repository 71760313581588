import { Checkbox, Select } from "antd";
import React, { FC } from "react";
import "./multipleSelect.scss";
import { ArrowBorder, Close } from "@shared/ui/assets";

interface IProps {
  value?: string[];
  onChange: (value: string[]) => void;
  options: { label: React.ReactElement; value: string }[];
  selectedItems: string[];
  placeholder?: string;
  className?: string;
  selectedText?: React.ReactNode;
  noItems?: React.ReactElement;
  handleClear?: () => void;
  open?: boolean;
  mainOption?: { label: React.ReactElement; value: string };
}

const allDEfaultValue = "all";

const MultipleSelect: FC<IProps> = (props) => {
  const {
    onChange,
    options,
    selectedItems,
    placeholder = "Select options",
    className = "",
    noItems,
    selectedText,
    handleClear,
    open,
    mainOption,
  } = props;

  const optionsCompare = [
    { label: <>Select all</>, value: allDEfaultValue },
  ].concat(options);

  const allOptionValues = options
    .filter((option) => option.value !== allDEfaultValue)
    .map((option) => option.value);

  const handleChange = (value: string[]) => {
    const filteredSelects = value.filter((item) => item !== allDEfaultValue);

    if (
      value.includes(allDEfaultValue) &&
      filteredSelects.length !== allOptionValues.length
    ) {
      onChange(allOptionValues);
    } else {
      onChange(filteredSelects);
    }
  };

  return (
    <div className="multiple-select poz-r">
      {selectedItems.length ? (
        <div
          onClick={(e) => {
            handleClear && handleClear();
            e.stopPropagation();
          }}
          className="multiple-select__close"
        >
          <Close />
        </div>
      ) : null}
      <Select
        open={open}
        mode="multiple"
        value={selectedItems}
        suffixIcon={<ArrowBorder color="#212527" />}
        className={`custom-arrow ${className}`}
        onChange={handleChange}
        showSearch={false}
        optionRender={(option) => (
          <div style={{ color: "red" }}>{option.label}</div>
        )}
        placeholder={
          // selectedItems.length === 0 ? (
          //   <span style={{ color: "black" }}>None</span>
          // ) : (
          placeholder
          // )
        }
        maxTagCount={0}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        maxTagPlaceholder={(omittedValues) => {
          // if (selectedItems.length === 0) {
          //   return "No data";
          // }
          // return isAllSelected ? (
          //   "All selected"
          // ) : (
          return <div className="multiple-select__count">{selectedText}</div>;
          // );
        }}
        dropdownRender={(menu) => (
          <div>
            {mainOption ? (
              <div className="added-select-item">
                <Checkbox
                  key={"option.value"}
                  checked={selectedItems.includes(mainOption.value)}
                  onChange={(e) => {
                    const newValue = e.target.checked
                      ? [...selectedItems, mainOption.value]
                      : selectedItems.filter(
                          (item) => item !== mainOption.value
                        );
                    handleChange(newValue);
                  }}
                >
                  {mainOption.value}
                </Checkbox>
              </div>
            ) : null}
            <div className="multiple-select__content">
              {optionsCompare
                .filter((option) => option.value !== allDEfaultValue)
                .map((option) => (
                  <Checkbox
                    key={option.value}
                    checked={selectedItems.includes(option.value)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...selectedItems, option.value]
                        : selectedItems.filter((item) => item !== option.value);
                      handleChange(newValue);
                    }}
                  >
                    {option.label}
                  </Checkbox>
                ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default MultipleSelect;
