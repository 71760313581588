import { useEffect, useState } from "react";
import "./mainForgotPassword.scss";
import CheckEmail from "../checkEmail/CheckEmail";
import { NavLink, useSearchParams } from "react-router-dom";
import { FORGOT_PASSWORD_STEPS } from "@widgets/interfaces";
import { FORGOT_PASSWORD_STEP_KEY } from "@shared/constants";
import { Arrow } from "@shared/ui/assets";
import CheckCode from "../checkCode/CheckCode";
import EnterNewPassword from "../enterNewPassword/EnterNewPassword";
import Complete from "../complete/Complete";
import { ROUTES } from "@shared/interfaces";

export const MainForgotPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get(FORGOT_PASSWORD_STEP_KEY)) {
      setSearchParams((prev) => {
        prev.set(FORGOT_PASSWORD_STEP_KEY, FORGOT_PASSWORD_STEPS.CHECK_EMAIL);
        return new URLSearchParams(prev);
      });
    }
  }, [searchParams, setSearchParams]);

  const onContinueClick = (values: { [key: string]: string }) => {
    const updatedParams = new URLSearchParams(searchParams);

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, value);
      } else {
        updatedParams.delete(key);
      }
    });
    setSearchParams(updatedParams);
  };

  const renderContent = () => {
    switch (searchParams.get(FORGOT_PASSWORD_STEP_KEY)) {
      case FORGOT_PASSWORD_STEPS.CHECK_EMAIL:
        return <CheckEmail onClickContinue={onContinueClick} />;
      case FORGOT_PASSWORD_STEPS.CHECK_CODE:
        return <CheckCode onClickContinue={onContinueClick} />;
      case FORGOT_PASSWORD_STEPS.SET_PASSWORD:
        return <EnterNewPassword onClickContinue={onContinueClick} />;
      case FORGOT_PASSWORD_STEPS.COMPLETE:
        return <Complete />;
      default:
        return null;
    }
  };
  return (
    <div className="main-forgot-password">
      <div className="main-forgot-password__content">
        <div className="main-forgot-password__logo">The Relu</div>
        {renderContent()}
        {searchParams.get(FORGOT_PASSWORD_STEP_KEY) !==
          FORGOT_PASSWORD_STEPS.COMPLETE && (
          <div className="main-forgot-password__back">
            <Arrow color="#212529" />{" "}
            <span className="main-forgot-password__back-text">
              <NavLink to={ROUTES.LOGIN}>Back to Log in</NavLink>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
