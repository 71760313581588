import "./layoutWithAside.scss";
import { useEffect, useState } from "react";
import { ROUTES } from "@shared/interfaces";
import { Layout, Popover, Divider } from "antd";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Dots,
  Profile,
  GroupIcon,
  ArrowBorder,
  SettingsIcon,
  KnowledgeBase,
  Notification,
} from "@shared/ui/assets";
import { useUserStore } from "@entities/user";
import { useShallow } from "zustand/react/shallow";
import NotificationsAside from "./NotificationsAside";
import ProfilePopoverContent from "./ProfilePopoverContent";
import { Badge as CustomBadge, CustomTooltip } from "@shared/ui/components";
import { useUserSettingsStore } from "@entities/userSettings";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { GroupsOnCancelNotificationPopup } from "@widgets/admin/groups";
import { useGroupsStore } from "@entities/groups";
import { FILTER_BY_PARAM, LIMIT_PARAM, OFFSET_PARAM } from "@shared/constants";
import DiscardPopup from "@widgets/admin/groups/ui/EditGroup/DiscardPopup/DiscardPopup";

const { Content } = Layout;

interface IProps {
  children: React.ReactElement;
}

const LayoutWithAside = ({ children }: IProps) => {
  const { id } = useParams();
  const { logOut } = useUserStore((state) => state);
  const { isViewer, isManager } = useCheckRole();
  const { setAsideCollapsed, asideCollapsed } = useUserSettingsStore(
    (state) => state
  );
  const user = useUserStore(useShallow((state) => state.user));
  const notifications = useUserStore(
    useShallow((state) => state.notifications)
  );

  const navigate = useNavigate();
  const [isOpenConfirmationShow, setCancelConfirmationShow] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [isDiscardPopupShow, setIsDiscardPopupShow] = useState(false);
  const {
    clearGroup,
    removeGroup,
    currentGroup,
    clearStrategyInStore,
    commitEditGroup,
    rollbackEditGroup,
  } = useGroupsStore((state) => state);

  const location = useLocation();

  const regex = new RegExp(`^${ROUTES.ADMIN_GROUPS_CREATE}/\\d+$`);
  const regexEdit = new RegExp(`^${ROUTES.ADMIN_GROUPS_EDIT}/\\d+$`);
  const isCreateGroupMode = regex.test(location.pathname);
  const isEditGroupMode = regexEdit.test(location.pathname);

  // useEffect(() => {
  //   async function fetchAPI() {
  //     await getNotifications();
  //   }

  //   fetchAPI();
  // }, []);

  const ProfilePopover = () => (
    <CustomTooltip title="Profile" placement="right">
      <Popover
        content={
          <ProfilePopoverContent
            user={user}
            logOut={() => {
              logOut();
              window.history.replaceState(null, "", window.location.pathname);
            }}
          />
        }
        placement="right"
        trigger="click"
        arrow={false}
        overlayClassName="aside-popover__profile"
      >
        <div className="aside__item w-47">
          <>
            <div className="df ai-center">
              <div className="aside__item-ico">
                <Profile />
              </div>
              {asideCollapsed && <h5 className="aside__link">Profile</h5>}
            </div>
            {asideCollapsed && (
              <div className="aside__actions">
                <Dots />
              </div>
            )}
          </>
        </div>
      </Popover>
    </CustomTooltip>
  );

  const NotificationPopover = () => (
    <Popover
      content={<NotificationsAside />}
      placement="right"
      trigger="click"
      arrow={false}
      overlayClassName="aside-popover__notifications"
    >
      <div className="aside__item aside__margin-corection">
        <div className="aside__item-ico ">
          <Notification isShow={!!notifications.length} />
        </div>
        {asideCollapsed && <h5 className="aside__link">Notifications</h5>}
        {asideCollapsed && (
          <CustomBadge
            count={notifications.length}
            className="aside__actions badge-default"
            fill="gray-600"
          />
        )}
      </div>
    </Popover>
  );

  const onCollapse = () => {
    const collapseValue = !asideCollapsed ? 1 : 0;
    setAsideCollapsed(!!collapseValue);
  };

  const groupsRedirect = () => {
    const combineUrl = `${ROUTES.ADMIN_GROUPS}?${LIMIT_PARAM}=10&${OFFSET_PARAM}=0&${FILTER_BY_PARAM}=ALL`;
    if (isCreateGroupMode) {
      setCancelConfirmationShow(true);
      setRedirectUrl(combineUrl);
    } else if (isEditGroupMode) {
      setIsDiscardPopupShow(true);
      setRedirectUrl(combineUrl);
    } else {
      navigate(combineUrl);
    }
  };

  const settingsRedirect = () => {
    if (isCreateGroupMode) {
      setCancelConfirmationShow(true);
      setRedirectUrl(ROUTES.ADMIN_SETTINGS);
    } else if (isEditGroupMode) {
      setIsDiscardPopupShow(true);
      setRedirectUrl(ROUTES.ADMIN_SETTINGS);
    } else {
      navigate(ROUTES.ADMIN_SETTINGS);
    }
  };

  const renderSidebar = () => {
    return (
      <div
        className={`${
          asideCollapsed ? "aside__container" : "aside__container-active"
        }`}
      >
        <div className="demo-logo-vertical">
          <h4>ReLU</h4>
        </div>

        <div className="aside__menu">
          <div className="aside__menu-top">
            <div onClick={groupsRedirect}>
              <CustomTooltip title="Groups" placement="right">
                <div
                  className={
                    location.pathname === ROUTES.ADMIN_GROUPS ||
                    location.pathname === ROUTES.BASE
                      ? "aside__link aside__link--active"
                      : "aside__link"
                  }
                >
                  <div className="aside__item">
                    <div className="aside__item-ico">
                      <GroupIcon />
                    </div>
                    {asideCollapsed && <div>Groups</div>}
                  </div>
                </div>
              </CustomTooltip>
            </div>
          </div>

          <div className="aside__menu-bottom">
            <div className="aside__item disabled">
              <NavLink
                to={ROUTES.ADMIN_KNOWLEDGE_BASE}
                // className={chooseActiveClass}
              >
                <div className="aside__item-ico">
                  <KnowledgeBase />
                </div>
                {asideCollapsed && " Knowledge Base"}
              </NavLink>
            </div>
            <div>
              <CustomTooltip title="Settings" placement="right">
                <>
                  {!isViewer && (
                    <div
                      onClick={settingsRedirect}
                      // to={ROUTES.ADMIN_SETTINGS}
                      className={
                        location.pathname.includes(ROUTES.ADMIN_SETTINGS)
                          ? "aside__link aside__link--active"
                          : "aside__link"
                      }
                    >
                      <div className="aside__item w-47">
                        <div className="aside__item-ico">
                          <SettingsIcon />
                        </div>
                        {asideCollapsed && <div>Settings</div>}
                      </div>
                    </div>
                  )}
                </>
              </CustomTooltip>
            </div>

            {/* <NotificationPopover /> */}
            <ProfilePopover />
            {/* </CustomTooltip> */}

            {/* <Divider className="mb-8 mt-8" /> */}

            {/* <div
              className="aside__item aside__arrow"
              onClick={() => onCollapse()}
            >
              <div className="aside__item-ico aside__arrow">
                <ArrowBorder />
              </div>
              {asideCollapsed && (
                <h5 className="aside__link">Collapse menu </h5>
              )}
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const onClickSaveAsDraft = () => {
    navigate(redirectUrl);
    clearGroup();
  };

  const cancelApprove = async () => {
    if (currentGroup.id) {
      await removeGroup(currentGroup.id);
      setCancelConfirmationShow(false);
      navigate(redirectUrl);
    }
  };

  return (
    <div className="layout__container">
      <GroupsOnCancelNotificationPopup
        flag={isOpenConfirmationShow}
        onClose={() => setCancelConfirmationShow(false)}
        onOk={cancelApprove}
        onClickSaveAsDraft={onClickSaveAsDraft}
      />
      <DiscardPopup
        flag={isDiscardPopupShow}
        onClose={() => setIsDiscardPopupShow(false)}
        onOk={async () => {
          id && (await rollbackEditGroup(id));
          setIsDiscardPopupShow(false);
          navigate(redirectUrl);
        }}
        onClickSaveChanges={async () => {
          id &&
            (await commitEditGroup(id).then(() => {
              navigate(`${ROUTES.ADMIN_GROUPS_ID.replace(/\/:id$/, "")}/${id}`);
            }));

          clearStrategyInStore();
          setIsDiscardPopupShow(false);
        }}
      />
      {renderSidebar()}
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </div>
  );
};

export default LayoutWithAside;
