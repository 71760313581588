import "./SignupVerifyWidget.scss";
import { VerifyForm } from "@widgets/auth/index";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  onContinueClick: ({ type }: { type: SIGNUP_STEPS }) => void;
}

const SignupVerifyWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  const [searchParams] = useSearchParams();
  return (
    <div className="signup-verify__container">
      <h1 className="signup-verify__title">The Relu</h1>
      <h1 className="signup-verify__subtitle">Check your email</h1>
      <h4 className="signup-verify__text">We sent a verification code to</h4>
      <h4 className="signup-verify__subtext verify__subtext-margin">
        {searchParams.get("email") || null}
      </h4>
      <VerifyForm onContinueClick={onContinueClick} />
    </div>
  );
};

export default SignupVerifyWidget;
