import {
  AlertType,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";

export interface IValidationExceptionItem {
  [key: string]: any;
  pricing?: boolean;
  rangeValidation?: boolean;
}

const useValidationStepper = () => {
  const strategy = useGroupsStore((state) => state.strategy);
  const { exceptionProducts } = useGroupsStore((state) => state);
  const {
    currentGroup: { name },
  } = useGroupsStore((state) => state);
  const {
    priceLimits: { max, min, pricing },
    priceRounding: { decimal, integer },
  } = strategy;

  const checkValidPriceLimitInputs = () => {
    const minValue = min?.value;
    const maxValue = max?.value;

    if (pricing === SelectLimitPricing.MARGIN && minValue && maxValue) {
      return +minValue > +maxValue;
    }
    return false;
  };

  const checkValidPriceLimitsExceptions = (price?: SelectLimitPricing) => {
    return (exceptionProducts || [])?.reduce(
      (arrValidationExceptions: IValidationExceptionItem, el) => {
        const { pricing } = el.exceptionLimits || {};

        const dataError = {
          pricing: price ? !price : !pricing,
          rangeValidation:
            el.exceptionLimits?.min?.value &&
            el.exceptionLimits?.max?.value &&
            +el.exceptionLimits?.min?.value > +el.exceptionLimits?.max?.value,

          id: el.id,
          isZeroMin:
            pricing === SelectLimitPricing.CURRENT_PRICE &&
            (el.exceptionLimits?.min?.value === "0.00" ||
              el.exceptionLimits?.min?.value === "0"),
          isZeroMax:
            pricing === SelectLimitPricing.CURRENT_PRICE &&
            (el.exceptionLimits?.max?.value === "0.00" ||
              el.exceptionLimits?.max?.value === "0"),
        };

        if (
          dataError.pricing ||
          dataError.rangeValidation ||
          dataError.isZeroMin ||
          dataError.isZeroMax
        ) {
          arrValidationExceptions.push(dataError);
        }

        return arrValidationExceptions;
      },
      []
    );
  };

  const checkValidPriceRoundingDecimalInput = () => {
    return decimal?.active && !decimal?.value;
  };
  const checkValidPriceRoundingIntegerInput = () => {
    return integer?.active && !integer?.value;
  };
  const checkValidNameSummaryInput = () => {
    return !name;
  };

  return {
    //       ===================  limits  ====================
    //input

    isErrorValidPriceLimitsInputs: checkValidPriceLimitInputs(),
    //exceptions
    arrValidationExceptions: (price?: SelectLimitPricing) =>
      checkValidPriceLimitsExceptions(price),
    //       ===================  Rounding  ====================
    isErrorValidDecimalInput: checkValidPriceRoundingDecimalInput(),
    isErrorValidIntegerInput: checkValidPriceRoundingIntegerInput(),
    //       ===================  Summary  ====================
    isErrorValidNameSummaryInput: checkValidNameSummaryInput(),
  };
};

export default useValidationStepper;
