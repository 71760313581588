import { Divider, Select } from "antd";
import {
  AlertCustom,
  ButtonStyled,
  CheckboxStyled,
} from "@shared/ui/components";
import { ArrowBorder, Expand, Info, Plus, Warning } from "@shared/ui/assets";
import { AddExceptionPopup } from "../../../AddExceptionPopup";
import { useEffect, useRef, useState } from "react";
import {
  AlertTarget,
  CurrentStep,
  IProductException,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import InputWithIcons from "@shared/ui/components/inputWithIcons/InputWithIcons";
import ItemException from "./ItemException/ItemException";
import "./priceLimits.scss";
import { LS_IS_WARNING_LIMITS } from "@shared/constants";
import parse from "html-react-parser";
import { isValidLimitPriceValue, isValidLimitValue } from "@shared/utils";

const noLimit = "No limit";

const optionsSelect = [
  {
    label: noLimit,
    value: noLimit,
  },
  {
    label: "Current price (%)",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
];

interface IProps {
  handleBlurValidation: (price?: SelectLimitPricing) => void;
}

const EditPriceLimits = (props: IProps) => {
  const { handleBlurValidation } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { priceLimits } = useGroupsStore((state) => state.strategy);
  const {
    getExceptions,
    exceptionProducts,
    setValidationError,
    updateStrategy,
    clearAlertToStore,
    strategy,
  } = useGroupsStore((state) => state);
  const { validationErrors } = useGroupsStore((state) => state);
  const alert = (priceLimits?.alerts && priceLimits?.alerts[0]) || {};

  const [exceptionTableHeight, setExceptionTableHeight] = useState<number>(0);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

  const isShowWarningAlert = () => {
    return localStorage.getItem(LS_IS_WARNING_LIMITS) === "true";
  };

  useEffect(() => {
    if (!isOpenModal) {
      getExceptions();
    }
  }, [isOpenModal]);

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const alertHeight = 55;

    if (
      isShowWarningAlert() &&
      alert.target === AlertTarget.PRICE_LIMITS__NEW_EXCEPTION
    ) {
      setExceptionTableHeight(innerHeight - 514 - alertHeight);
    } else if (exceptionProducts.length > 0) {
      setExceptionTableHeight(innerHeight - 514 + 22);
    }
  }, [isShowWarningAlert(), exceptionProducts, alert]);

  const handleClickAddException = () => {
    setIsOpenModal(true);
  };

  const handleChooseSelect = async (value: string) => {
    useGroupsStore.setState((state) => {
      return {
        strategy: {
          ...state.strategy,
          priceLimits: {
            ...state.strategy.priceLimits,
            pricing: value,
            min: null,
            max: null,
          },
        },
        countExceptionsExist: 0,
      };
    });

    const priceLimitsErrors = {
      ...(useGroupsStore.getState().validationErrors as Record<string, any>)[
        CurrentStep.PRICE_LIMITS
      ],
    };
    priceLimitsErrors.pricing = null;
    priceLimitsErrors.minInput = null;
    priceLimitsErrors.maxInput = null;

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: priceLimitsErrors,
    });

    updateStrategy(CurrentStep.PRICE_LIMITS);
  };

  const handleChangeInput = async (key: string, value: string) => {
    value = value.replace(",", ".");
    const pricing = useGroupsStore.getState().strategy.priceLimits?.pricing;
    const isVAlid = isValidLimitValue(value);
    const isVAlidPrice = isValidLimitPriceValue(value);
    const priceLimitsErrors = {
      ...(useGroupsStore.getState().validationErrors as Record<string, any>)[
        CurrentStep.PRICE_LIMITS
      ],
    };

    if (isVAlid && pricing === SelectLimitPricing.MARGIN) {
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                value,
              },
            },
          },
        };
      });

      if (priceLimitsErrors[`${key}Input`]?.value) {
        priceLimitsErrors[`${key}Input`] = null;

        setValidationError({
          [CurrentStep.PRICE_LIMITS]: priceLimitsErrors,
        });
      }
    }
    if (isVAlidPrice && pricing === SelectLimitPricing.CURRENT_PRICE) {
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                value,
              },
            },
          },
        };
      });
      if (priceLimitsErrors[`${key}Input`]?.value) {
        priceLimitsErrors[`${key}Input`] = null;

        setValidationError({
          [CurrentStep.PRICE_LIMITS]: priceLimitsErrors,
        });
      }
    }

    if (
      priceLimitsErrors[`minInput`]?.range &&
      priceLimitsErrors[`maxInput`]?.range
    ) {
      priceLimitsErrors[`minInput`] = null;
      priceLimitsErrors[`maxInput`] = null;

      setValidationError({
        [CurrentStep.PRICE_LIMITS]: priceLimitsErrors,
      });
    }
  };

  const minInputError = validationErrors[CurrentStep.PRICE_LIMITS]?.minInput;
  const maxInputError = validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput;

  const isErrorClassInput = (key: string) => {
    const pricing = useGroupsStore.getState().strategy.priceLimits?.pricing;
    if (pricing === SelectLimitPricing.MARGIN) {
      if (key === "min" && minInputError) {
        return "error-validation-input";
      }
      if (key === "max" && maxInputError) {
        return "error-validation-input";
      }
    }

    return "";
  };

  const isErrorClassSelect = () => {
    if (validationErrors[CurrentStep.PRICE_LIMITS]?.pricing) {
      return "error-validation-select";
    }

    return "";
  };

  const isErrorInput = (key: string) => {
    if (key === "min" && minInputError) {
      return true;
    }
    if (key === "max" && maxInputError) {
      return true;
    }
    return "";
  };

  const isErrorSelect = () => {
    if (validationErrors[CurrentStep.PRICE_LIMITS]?.pricing) {
      return "Specify the min/max margins below, or select 'No limit' option";
    }
    return "";
  };

  const getTextError = () => {
    const isMaxInputErrorRange =
      validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput?.range;

    const isMinInputErrorRange =
      validationErrors[CurrentStep.PRICE_LIMITS]?.minInput?.range;

    const isMaxInputErrorValue =
      validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput?.value;

    const isMinInputErrorValue =
      validationErrors[CurrentStep.PRICE_LIMITS]?.minInput?.value;

    if (isMaxInputErrorRange && isMinInputErrorRange) {
      return `Logical mismatch.`;
    }
    if (isMinInputErrorValue || isMaxInputErrorValue) {
      return `Please enter a value.`;
    }
    return "";
  };

  const onCloseWarningAlert = async () => {
    const { priceLimits } = strategy;
    clearAlertToStore(
      CurrentStep.PRICE_LIMITS,
      priceLimits?.alerts.filter(
        (el) => el.target !== AlertTarget.PRICE_LIMITS__NEW_EXCEPTION
      )
    );
    updateStrategy(CurrentStep.PRICE_LIMITS);
    localStorage.setItem(LS_IS_WARNING_LIMITS, "false");
  };
  const validationExceptions =
    validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
  const isShowErrorValidationExceptions =
    validationExceptions &&
    Object.values(validationExceptions).some((value) => value);

  const blockRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsCollapsed(!entry.isIntersecting);
      },
      {
        threshold: [1],
      }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    let lastScrollY = blockRef?.current?.getBoundingClientRect().y || 0;

    const handleScroll = () => {
      const currentScrollY = blockRef?.current?.getBoundingClientRect().y || 0;
      // if (currentScrollY < lastScrollY) {
      setIsOpenCollapse(false);
      // }
      lastScrollY = currentScrollY;
    };

    wrapperRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
      wrapperRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const compareTextException = () => {
    const minValue = strategy.priceLimits?.min?.value;
    const maxValue = strategy.priceLimits?.max?.value;
    const chooseSing =
      strategy?.priceLimits?.pricing === SelectLimitPricing.CURRENT_PRICE
        ? "$"
        : "";
    const chooseSingMargin =
      strategy?.priceLimits?.pricing === SelectLimitPricing.MARGIN ? "%" : "";

    const chooseWord =
      strategy?.priceLimits?.pricing === SelectLimitPricing.CURRENT_PRICE
        ? "price"
        : "margin";

    const isMinActiveText = minValue
      ? `Min ${chooseWord} ${chooseSing}${minValue}${chooseSingMargin}`
      : "";

    const isMaxActiveText = maxValue
      ? `Max ${chooseWord} ${chooseSing}${maxValue}${chooseSingMargin}`
      : "";

    const isComma = minValue && maxValue ? ", " : "";
    return `${isMinActiveText}${isComma}${isMaxActiveText}`;
  };

  const handleOpenCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse);
  };

  const handleBlur = async (key: string) => {
    localStorage.setItem(LS_IS_WARNING_LIMITS, "true");
    let value =
      useGroupsStore.getState()?.strategy[CurrentStep.PRICE_LIMITS][key]?.value;
    if (value === "") return updateStrategy(CurrentStep.PRICE_LIMITS);

    if (value && !value.includes(".")) {
      value = `${value}.00`;
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                value,
              },
            },
          },
        };
      });
      handleBlurValidation();

      return updateStrategy(CurrentStep.PRICE_LIMITS);
    } else if (value && value.split(".")[1].length === 0) {
      value = `${value}00`;
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                value,
              },
            },
          },
        };
      });
      handleBlurValidation();

      return updateStrategy(CurrentStep.PRICE_LIMITS);
    } else if (value && value.split(".")[1].length === 1) {
      value = `${value}0`;
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                value,
              },
            },
          },
        };
      });

      handleBlurValidation();

      return updateStrategy(CurrentStep.PRICE_LIMITS);
    }

    useGroupsStore.setState((state) => {
      return {
        strategy: {
          ...state.strategy,
          priceLimits: {
            ...state.strategy.priceLimits,
            [key]: {
              ...state.strategy.priceLimits[key],
              value,
            },
          },
        },
      };
    });
    await updateStrategy(CurrentStep.PRICE_LIMITS);

    handleBlurValidation();
  };

  const inputsArr = [
    {
      valuePrice: "Set in price",
      valueMargin: "Ser min margin",
      key: "min",
      className: "mb-32 df ai-center",
      currentPriceIcon: "-",
      placeholder: "Min",
    },
    {
      valuePrice: "Set max price",
      valueMargin: "Set max margin",
      key: "max",
      className: "mb-8 df ai-center",
      currentPriceIcon: "+",
      placeholder: "Max",
    },
  ];

  const isCurrentPriceSelect =
    priceLimits?.pricing === SelectLimitPricing.CURRENT_PRICE;

  const chooseRenderCollapse = isOpenCollapse ? (
    <div className="groups-price-limits__collapsed-open-block">
      <div className="groups-price-limits__content">
        <>
          <h4 className="groups-price-limits__text">
            Set price limits for all items
          </h4>
          <div className="groups-price-limits__check-block df">
            <div className="poz-r">
              <Select
                options={optionsSelect}
                placeholder="Select limit"
                suffixIcon={<ArrowBorder color="#212527" />}
                className={`w-256 mt-12  custom-arrow ${isErrorClassSelect()}`}
                value={priceLimits?.pricing || optionsSelect[0]?.value}
                onChange={(value) => handleChooseSelect(value)}
              />
              {isErrorSelect() && (
                <div className="error-validation-message select-error">
                  {isErrorSelect()}
                </div>
              )}
            </div>
            <div className="groups-price-limits__checkboxes-block ">
              {inputsArr.map((el) => {
                const key = el.key;
                return (
                  <div className="df chooser-checkbox-limit poz-r" key={key}>
                    {priceLimits.pricing ? (
                      isCurrentPriceSelect ? (
                        <span className={`${el.className} df ai-center`}>
                          <div>
                            <InputWithIcons
                              wrapperClassName={`${isErrorClassInput(key)}`}
                              classNameInput={`inputClassNameCenter groups-price-limits__input-current-price`}
                              leftIcon={el.currentPriceIcon}
                              rightIcon="%"
                              onChange={(value: string) =>
                                handleChangeInput(key, value)
                              }
                              value={priceLimits[key]?.value?.toString() || ""}
                              onBlur={() => {
                                handleBlur(key);
                              }}
                              placeholder={el.placeholder}
                            />
                          </div>
                        </span>
                      ) : (
                        <span className={`${el.className} df ai-center`}>
                          <>
                            <InputWithIcons
                              wrapperClassName={`${isErrorClassInput(key)}`}
                              classNameInput={`inputClassNameCenter groups-price-limits__input  `}
                              rightIcon="%"
                              onChange={(value: string) =>
                                handleChangeInput(key, value)
                              }
                              value={priceLimits[key]?.value?.toString() || ""}
                              onBlur={() => {
                                handleBlur(key);
                              }}
                              placeholder={el.placeholder}
                            />
                          </>
                          {isErrorInput(key) && (
                            <div className="error-validation-message">
                              {getTextError()}
                            </div>
                          )}
                        </span>
                      )
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          {isShowWarningAlert() &&
          alert.target === AlertTarget.PRICE_LIMITS__NEW_EXCEPTION ? (
            <div className="groups-price-limits__warning">
              <AlertCustom
                type="warning"
                message={parse(alert?.message || "")}
                icon={<Warning />}
                closable={true}
                onClose={() => {
                  onCloseWarningAlert();
                }}
              />
            </div>
          ) : null}
        </>
        <Divider className="divider" />
        <div
          className={`df jc-sb ai-center ${
            isOpenCollapse
              ? "groups-price-limits__exception-title-table-fixed"
              : "groups-price-limits__exception-title-table"
          }`}
        >
          <div>
            <h4 className="groups-price-limits__text">
              Set a custom price limit for specific items
            </h4>
            <div className="groups-price-limits__subtitle mt-4">
              Custom price limits override main price limits.
            </div>
          </div>

          <div>
            <ButtonStyled
              text="Add custom limit"
              icon={<Plus color="#212529" />}
              onClick={handleClickAddException}
              iconPosition="start"
            />
          </div>

          {isShowErrorValidationExceptions ? (
            <div className="info-error-block">
              <Info color="#dc3545" />{" "}
              <span>Fix errors in the items list below</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="collapsed-block-main">
      <div
        className={`${
          isShowWarningAlert() &&
          alert.target === AlertTarget.PRICE_LIMITS__NEW_EXCEPTION
            ? "groups-price-limits__collapsed-block-warning"
            : "groups-price-limits__collapsed-block"
        }`}
      >
        <div className="collapsed-block">
          <h4 className="groups-price-limits__text">
            Set price limits for all items
          </h4>
          <div>{compareTextException()}</div>
          {/* <div onClick={handleOpenCollapse} className="collapsed-block-icon">
            <Expand />
          </div> */}
        </div>
        <div>
          <div>
            <ButtonStyled
              text="Add custom limit"
              icon={<Plus color="#212529" />}
              onClick={handleClickAddException}
              iconPosition="start"
            />
          </div>
        </div>
      </div>
      <div>
        {isShowWarningAlert() &&
        alert.target === AlertTarget.PRICE_LIMITS__NEW_EXCEPTION ? (
          <div>
            <AlertCustom
              type="warning"
              message={parse(alert?.message || "")}
              icon={<Warning />}
              closable={true}
              onClose={() => {
                onCloseWarningAlert();
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );

  const replaceFirstMatch = (exceptionProducts: IProductException[]) => {
    let isModified = false;
    return exceptionProducts.reduce(
      (acc: any[], exception: IProductException) => {
        if (!exception?.exceptionLimits?.pricing && !isModified) {
          acc.push({
            ...exception,
            exceptionLimits: {
              isOpenSelect: true,
            },
          });
          isModified = true;
        } else {
          acc.push(exception);
        }
        return acc;
      },
      []
    );
  };
  const initialExceptionProducts = replaceFirstMatch(exceptionProducts);

  useEffect(() => {
    setNewExceptionProducts(initialExceptionProducts);
  }, [exceptionProducts]);

  const [newExceptionProducts, setNewExceptionProducts] = useState<any[]>(
    initialExceptionProducts
  );

  const deleteIsOpenSelectParam = () => {
    setNewExceptionProducts(
      newExceptionProducts.map((product) => ({
        ...product,
        exceptionLimits: product.exceptionLimits
          ? (({ isOpenSelect, ...rest }) => rest)(product.exceptionLimits)
          : product.exceptionLimits,
      }))
    );
  };

  return (
    <>
      <AddExceptionPopup
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />

      <div
        className={`groups-price-limits__wrapper content__container `}
        ref={wrapperRef}
      >
        {isCollapsed ? chooseRenderCollapse : null}
        <h3 className="groups-price-limits__title">Price Limits</h3>
        <h5 className="groups-price-limits__subtitle">
          Adjust strategy with price limits
        </h5>
        <div className="groups-price-limits__content">
          <>
            <h4 className="groups-price-limits__text">
              Set base price limit by
            </h4>
            <div className="groups-price-limits__check-block df">
              <div className="poz-r">
                <Select
                  options={optionsSelect}
                  placeholder="Select limit"
                  suffixIcon={<ArrowBorder color="#212527" />}
                  className={`w-256 mt-12  custom-arrow ${isErrorClassSelect()}`}
                  value={priceLimits?.pricing || optionsSelect[0]?.value}
                  onChange={(value) => handleChooseSelect(value)}
                />
                {isErrorSelect() && (
                  <div className="error-validation-message select-error">
                    {isErrorSelect()}
                  </div>
                )}
              </div>
              <div className="groups-price-limits__checkboxes-block ">
                {priceLimits?.pricing !== optionsSelect[0]?.value &&
                  inputsArr.map((el) => {
                    const key = el.key;
                    return (
                      <div
                        className="df chooser-checkbox-limit poz-r"
                        key={key}
                      >
                        {priceLimits.pricing ? (
                          isCurrentPriceSelect ? (
                            <span className={`${el.className} df ai-center`}>
                              <div>
                                <InputWithIcons
                                  wrapperClassName={`${isErrorClassInput(key)}`}
                                  classNameInput={`inputClassNameCenter groups-price-limits__input-current-price`}
                                  leftIcon={el.currentPriceIcon}
                                  rightIcon="%"
                                  onChange={(value: string) =>
                                    handleChangeInput(key, value)
                                  }
                                  value={
                                    priceLimits[key]?.value?.toString() || ""
                                  }
                                  onBlur={() => {
                                    handleBlur(key);
                                  }}
                                  placeholder={el.placeholder}
                                />
                              </div>
                            </span>
                          ) : (
                            <span className={`${el.className} df ai-center`}>
                              <>
                                <InputWithIcons
                                  wrapperClassName={`${isErrorClassInput(key)}`}
                                  classNameInput={`inputClassNameCenter groups-price-limits__input  `}
                                  rightIcon="%"
                                  onChange={(value: string) =>
                                    handleChangeInput(key, value)
                                  }
                                  value={
                                    priceLimits[key]?.value?.toString() || ""
                                  }
                                  onBlur={() => {
                                    handleBlur(key);
                                  }}
                                  placeholder={el.placeholder}
                                />
                                {isErrorInput(key) && (
                                  <div className="error-validation-message">
                                    {getTextError()}
                                  </div>
                                )}
                              </>
                            </span>
                          )
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
            {isShowWarningAlert() &&
            alert.target === AlertTarget.PRICE_LIMITS__NEW_EXCEPTION ? (
              <div className="groups-price-limits__warning">
                <AlertCustom
                  type="warning"
                  message={parse(alert?.message || "")}
                  icon={<Warning />}
                  closable={true}
                  onClose={() => {
                    onCloseWarningAlert();
                  }}
                />
              </div>
            ) : null}
          </>
          <div ref={blockRef}></div>

          <Divider className="divider" />
          <div className="df jc-sb  groups-price-limits__exception-title-table">
            <div>
              <h4 className="groups-price-limits__text">
                Set a custom price limit for specific items
              </h4>
              <div className="groups-price-limits__subtitle mt-4">
                Custom price limits override main price limits.
              </div>
            </div>

            <div>
              <ButtonStyled
                text="Add custom limit"
                icon={<Plus color="#212529" />}
                onClick={handleClickAddException}
                iconPosition="start"
              />
            </div>

            {isShowErrorValidationExceptions ? (
              <div className="info-error-block">
                <Info color="#dc3545" />{" "}
                <span>Fix errors in the items list below</span>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="groups-price-limits__exception-wrapper"
          style={{ height: exceptionTableHeight }}
        >
          <div className="groups-price-limits__exception-table">
            {(newExceptionProducts || [])?.map((product: IProductException) => (
              <ItemException
                product={product}
                key={product.id}
                handleBlurValidation={handleBlurValidation}
                deleteIsOpenSelectParam={deleteIsOpenSelectParam}
              />
            ))}
          </div>
          {!exceptionProducts.length ? (
            <div className="groups-price-limits__no-exception">No items</div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EditPriceLimits;
