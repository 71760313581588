import "./SignupPersonalInfoWidget.scss";
import { SetInfoForm } from "@widgets/auth/index";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { Typography } from "antd";
import { FC } from "react";

const { Title } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: SIGNUP_STEPS }) => void;
}

const SignupPersonalInfoWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup-setinfo__container">
      <h1 className="signup-setinfo__title">The Relu</h1>
      <h1 className="signup-setinfo__subtitle">Almost done!</h1>
      <h4 className="signup-setinfo__text">Please fill your personal data</h4>
      <h4 className="signup-setinfo__text">
        to provide you a better experience
      </h4>
      <div className="signup-setinfo__form">
        <SetInfoForm onContinueClick={onContinueClick} />
      </div>
    </div>
  );
};

export default SignupPersonalInfoWidget;
