import React from "react";

interface IProps {
  color?: string;
}

const SettingsIcon = ({ color }: IProps = { color: "#CED4DA" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_14889_69460)">
        <path
          d="M19.6503 9.54L14.8103 9.12L12.9203 4.67C12.5803 3.86 11.4203 3.86 11.0803 4.67L9.19032 9.13L4.36032 9.54C3.48032 9.61 3.12032 10.71 3.79032 11.29L7.46032 14.47L6.36032 19.19C6.16032 20.05 7.09032 20.73 7.85032 20.27L12.0003 17.77L16.1503 20.28C16.9103 20.74 17.8403 20.06 17.6403 19.2L16.5403 14.47L20.2103 11.29C20.8803 10.71 20.5303 9.61 19.6503 9.54ZM12.0003 15.9L8.24032 18.17L9.24032 13.89L5.92032 11.01L10.3003 10.63L12.0003 6.6L13.7103 10.64L18.0903 11.02L14.7703 13.9L15.7703 18.18L12.0003 15.9Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_14889_69460">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SettingsIcon;
