import { ROUTES } from "@shared/interfaces";
import "./profilePopover.scss";
import { IUser } from "@entities/user";
import { FC, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GroupsOnCancelNotificationPopup } from "@widgets/admin/groups";
import { useGroupsStore } from "@entities/groups";
import { UserRolesEnum } from "@entities/settings";
import DiscardPopup from "@widgets/admin/groups/ui/EditGroup/DiscardPopup/DiscardPopup";

interface IProps {
  user: Partial<IUser>;
  logOut: () => void;
}
const ProfilePopoverContent: FC<IProps> = ({ user, logOut }: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpenConfirmationShow, setCancelConfirmationShow] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const { clearGroup } = useGroupsStore((state) => state);
  const {
    removeGroup,
    currentGroup,
    commitEditGroup,
    clearStrategyInStore,
    rollbackEditGroup,
  } = useGroupsStore((state) => state);
  const [isDiscardPopupShow, setIsDiscardPopupShow] = useState(false);

  const location = useLocation();

  const regex = new RegExp(`^${ROUTES.ADMIN_GROUPS_CREATE}/\\d+$`);
  const regexEdit = new RegExp(`^${ROUTES.ADMIN_GROUPS_EDIT}/\\d+$`);
  const isCreateGroupMode = regex.test(location.pathname);
  const isEditGroupMode = regexEdit.test(location.pathname);

  const cancelApprove = async () => {
    if (currentGroup.id) {
      await removeGroup(currentGroup.id);
      setCancelConfirmationShow(false);
      navigate(redirectUrl);
    }
  };

  const onClickSaveAsDraft = () => {
    navigate(redirectUrl);
    clearGroup();
  };

  const profileRedirect = () => {
    if (isCreateGroupMode) {
      setRedirectUrl(ROUTES.ADMIN_PROFILE);
      setCancelConfirmationShow(true);
    } else if (isEditGroupMode) {
      setIsDiscardPopupShow(true);
      setRedirectUrl(ROUTES.ADMIN_PROFILE);
    } else {
      navigate(ROUTES.ADMIN_PROFILE);
    }
  };
  const passAndSecurityRedirect = () => {
    if (isCreateGroupMode) {
      setRedirectUrl(ROUTES.ADMIN_PROFILE_EDIT_PASSWORD);
      setCancelConfirmationShow(true);
    } else if (isEditGroupMode) {
      setIsDiscardPopupShow(true);
      setRedirectUrl(ROUTES.ADMIN_PROFILE_EDIT_PASSWORD);
    } else {
      navigate(ROUTES.ADMIN_PROFILE_EDIT_PASSWORD);
    }
  };

  const chooseBadge = (role: UserRolesEnum) => {
    switch (role) {
      case UserRolesEnum.OWNER:
        return "owner__badge";
      case UserRolesEnum.ADMIN:
        return "admin__badge";
      case UserRolesEnum.MANAGER:
        return "manager__badge";
      case UserRolesEnum.VIEWER:
        return "viewer__badge";
      default:
        return "default__badge";
    }
  };

  return (
    <div className="popover-profile__content">
      <GroupsOnCancelNotificationPopup
        flag={isOpenConfirmationShow}
        onClose={() => setCancelConfirmationShow(false)}
        onOk={cancelApprove}
        onClickSaveAsDraft={onClickSaveAsDraft}
      />
      <DiscardPopup
        flag={isDiscardPopupShow}
        onClose={() => setIsDiscardPopupShow(false)}
        onOk={async () => {
          id && (await rollbackEditGroup(id));
          setIsDiscardPopupShow(false);
          navigate(redirectUrl);
        }}
        onClickSaveChanges={async () => {
          id &&
            (await commitEditGroup(id).then(() => {
              navigate(`${ROUTES.ADMIN_GROUPS_ID.replace(/\/:id$/, "")}/${id}`);
            }));

          clearStrategyInStore();
          setIsDiscardPopupShow(false);
        }}
      />
      <div className="df ai-center popover-profile__info">
        <h4 className="popover-profile__name">{`${user.firstName} ${user.lastName}`}</h4>
        <h5
          className={`popover-profile__role ${chooseBadge(
            user.role as UserRolesEnum
          )}`}
        >
          {user.role}
        </h5>
      </div>
      <h5 className="popover-profile__email">{user.email}</h5>
      <h4 className="popover-content__item " onClick={profileRedirect}>
        Personal Info
      </h4>
      <h4 className="popover-content__item " onClick={passAndSecurityRedirect}>
        Password & Security
      </h4>
      <h4
        className="popover-content__item aside-popover__logout"
        onClick={() => logOut()}
      >
        Logout
      </h4>
    </div>
  );
};

export default ProfilePopoverContent;
