import { UserRolesEnum } from "@entities/settings";
import { IFormLoginState } from "@shared/interfaces";
import { IDataLoginResponse } from "@shared/interfaces/response";

export enum EDepartment {
  MARKETING = "MARKETING",
  FINANCE_ACCOUNTING = "FINANCE_ACCOUNTING",
  MERCHANDISING = "MERCHANDISING",
  OPERATIONS = "OPERATIONS",
  IT = "IT",
  OWNER_PARTNER = "OWNER_PARTNER",
  OTHER = "OTHER",
}

export interface IUser {
  id?: string | null;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  role: UserRolesEnum;
  userName: string;
  productCategories: ICategory[];
}

export interface INotification {
  id?: number | null;
  userId: number | null;
  isReaded: boolean | null;
  content: string | null;
  created_at: Date;
}

export interface ISetNewPassword {
  password: string;
  email: string;
  token: string;
}

export interface IEditPassword {
  currentPassword: string;
  newPassword: string;
}
export interface IChangePersonalInfo {
  firstName: string;
  lastName: string;
}

export interface ICompleteDataTransfer {
  firstName: string;
  lastName: string;
  department: EDepartment | string;
  email: string;
  token: string;
}

export interface ISccessToken {
  accessToken: string;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface IInviteDataTransfer {
  email: string;
  token: string;
}

export enum EUserNotification {
  SHOW_APPLYING_CONFIRMATION = "SHOW_APPLYING_CONFIRMATION",
}

export interface IUserNotification {
  type: EUserNotification;
  payload: {
    showApplyingConfirmation: boolean;
    groupId: number;
  };
}

export interface IUserState {
  user: IUser;
  notifications: INotification[];
  validationErrorMessage: string;
  qwe: string;
  checkAuth: () => Promise<any>;
  refreshToken: () => Promise<any>;
  setUser: (userData: IUser) => void;
  logIn: (formData: IFormLoginState) => Promise<IDataLoginResponse>;
  logOut: () => Promise<any>;
  getNotifications: () => Promise<any>;
  checkActualInviteUser: (dataObj: IInviteDataTransfer) => Promise<void>;
  checkVerifyCodeSignUp: (code: string, email: string) => Promise<any>;
  checkInvite: (email: string) => Promise<any>;
  checkEmail: (email: string) => Promise<any>;
  resendCodeSignUp: (email: string) => Promise<void>;
  onCreatePasswordSignUp: (data: ISetNewPassword) => Promise<void>;
  onEditPassword: (data: ISetNewPassword) => Promise<void>;
  onCompleteSignup: (data: ICompleteDataTransfer) => Promise<{
    accessToken: string;
    user: IUser;
  }>;
  changePassword: (dataObj: IEditPassword) => Promise<any>;
  setValidationErrorMessage: (message: string) => void;
  changePersonalInfo: (dataObj: IChangePersonalInfo) => Promise<void>;
  deleteAccountByDefault: () => Promise<void>;
  setShowNotificationType: (obj: IUserNotification) => void;
}
