import { IFormLoginState } from "@shared/interfaces";
import { ApiService } from "@shared/utils";
import {
  IChangePersonalInfo,
  ICompleteDataTransfer,
  IEditPassword,
  IInviteDataTransfer,
  ISetNewPassword,
  IUserNotification,
} from "../model/user.types";

class UserService extends ApiService {
  passwordURL: string;
  authURL: string;
  notificationsURL: string;

  constructor() {
    super();

    this.baseURL = "/api/v1/users";
    this.authURL = "/api/v1/auth";
    this.notificationsURL = "/api/v1/notifications";
    this.passwordURL = `${this.baseURL}/password`;
  }

  async getToketCsrf() {
    return await this.baseServiceInstance.get("/token");
  }

  async checkAuth() {
    return await this.baseServiceInstance.get(`${this.authURL}/check`);
  }

  async refreshToken() {
    return await this.baseServiceInstance.get(`${this.authURL}/refresh`);
  }

  async login(formData: IFormLoginState) {
    return await this.baseServiceInstance.post(
      `${this.authURL}/login`,
      formData
    );
  }

  async logout(id: string) {
    return await this.baseServiceInstance.delete(
      `${this.authURL}/logout/${id}`
    );
  }

  async recoveryPassword(formData: any) {
    return await this.baseServiceInstance.post(
      `${this.passwordURL}/recovery`,
      formData
    );
  }

  async resetPassword(formData: any) {
    return await this.baseServiceInstance.post(
      `${this.passwordURL}/reset`,
      formData
    );
  }

  async updatePassword(formData: any) {
    //return await this.baseServiceInstance.post(`${this.passwordUrl}/update`, formData);
  }

  async getNotifications(userId: string) {
    return await this.baseServiceInstance.get(`${this.notificationsURL}`);
  }
  async checkActualInviteUser(data: IInviteDataTransfer) {
    const queryString = new URLSearchParams(data as any).toString();
    return await this.baseServiceInstance.get(
      `${this.authURL}/invite?${queryString}`
    );
  }
  async checkInvite(email: string) {
    const chooseQueryString = new URLSearchParams({ email }).toString();
    return await this.baseServiceInstance.get(
      `${this.authURL}/check-invite?${chooseQueryString}`
    );
  }
  async checkEmail(email: string) {
    const chooseQueryString = new URLSearchParams({ email }).toString();
    return await this.baseServiceInstance.get(
      `${this.authURL}/check-email?${chooseQueryString}`
    );
  }
  async checkVerifyCodeSignUp(code: string, email: string) {
    return await this.baseServiceInstance.get(
      `${this.authURL}/check-verification?email=${email}&verificationCode=${code}`
    );
  }
  async resendCodeSignUp(email: string) {
    return await this.baseServiceInstance.post(
      `${this.authURL}/resend-verification`,
      {
        email,
      }
    );
  }

  async onCreatePasswordSignUp(params: ISetNewPassword) {
    const { password, ...obj } = params;
    const chooseQueryString = new URLSearchParams(obj).toString();
    return await this.baseServiceInstance.patch(
      `${this.authURL}/change-password?${chooseQueryString}`,
      {
        password: params.password,
      }
    );
  }
  async onCompleteSignup(params: ICompleteDataTransfer) {
    const { firstName, lastName, department, ...obj } = params;
    const chooseQueryString = new URLSearchParams(obj).toString();
    return await this.baseServiceInstance.patch(
      `${this.authURL}/complete-signup?${chooseQueryString}`,
      { firstName, lastName, department }
    );
  }
  async changePassword(dataObj: IEditPassword) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/change-password`,
      dataObj
    );
  }
  async changePersonalInfo(dataObj: IChangePersonalInfo) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/change-personal-info`,
      dataObj
    );
  }
  async deleteAccountByDefault() {
    return await this.baseServiceInstance.delete(
      `${this.baseURL}/delete-account`
    );
  }

  async setShowNotificationType(data: IUserNotification) {
    return await this.baseServiceInstance.post(`${this.baseURL}/events`, data);
  }
  async onEditPassword(params: any) {
    const { password, ...obj } = params;
    const chooseQueryString = new URLSearchParams(obj).toString();
    return await this.baseServiceInstance.patch(
      `${this.authURL}/set-password?${chooseQueryString}`,
      {
        password,
      }
    );
  }
}

const userService = new UserService();

export default userService;
