import {
  generateTextColumn,
  getMenuPropsItems,
  Pagination,
  TableCustom,
} from "@shared/common";
import { ISortParams, SortParams } from "@shared/interfaces";
import {
  BadgeCustom,
  LoaderItemsTable,
  NotificationCustom,
} from "@shared/ui/components";
import { MenuProps } from "antd";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserRolesEnum, useSettingsStore } from "@entities/settings";
import { AsideDropdown } from "@widgets/admin/asideDropdown";
import { Done, Dots } from "@shared/ui/assets";
import RemoveUserPopup from "./RemoveUserPopup/RemoveUserPopup";
import EditUserPopup from "./EditUserPopup/EditUserPopup";
import {
  DIRECTION_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import moment from "moment";
import "./tableUsers.scss";
import { capitalizeFirstLetter, getParamsAsObject } from "@shared/utils";
import { useSearchParams } from "react-router-dom";
import { useUserSettingsStore } from "@entities/userSettings";
import { IUser, useUserStore } from "@entities/user";
import useCheckRole from "@shared/model/hooks/useCheckRole";

export enum MODE_POPUP {
  REVOKE = "revoke",
  REMOVE = "remove",
}

interface IProps {
  setSortConfig: (config: {
    key: string;
    direction: ISortParams | null;
  }) => void;
  sortConfig: { key: string; direction: ISortParams | null };
  setCurrentLimit: (limit: number) => void;
  setCurrentOffset: (offset: number) => void;
  currentOffset: number;
  currentLimit: number;
  updateParams: (
    params: Record<string, string | number | null | undefined>
  ) => void;
}

const THEAD_SORT_KEYS = {
  NAME: "fullname",
  EMAIL: "email",
  ROLE: "role",
  LAST_ACTIVITY: "last_active",
  JOINED: "joined_at",
};

const TableUsers: FC<IProps> = ({
  setSortConfig,
  sortConfig,
  setCurrentLimit,
  setCurrentOffset,
  currentOffset,
  currentLimit,
  updateParams,
}) => {
  const [searchParams] = useSearchParams();
  const [tableWidth, setTableWidth] = useState<number>(500);
  const {
    usersTable,
    deleteUserById,
    editUserById,
    getUsersTable,
    getFiltersForUsers,
    recordsCountUsers,
    isLoadingUsersTable,
    filtersUsersTable,
    resendInvitation,
  } = useSettingsStore((state) => state);
  const { asideCollapsed } = useUserSettingsStore((state) => state);
  const recordsCount = recordsCountUsers;
  const { openNotification, contextHolder } = NotificationCustom();

  const [isEditRolePopupShow, setEditRolePopupShow] = useState(false);
  const [isDeleteUserPopupShow, setDeleteUserPopupShow] = useState(false);
  const [isRevokeUserPopupShow, setRevokeUserPopupShow] = useState(false);
  const [activeUser, setActiveUser] = useState<IUser>();
  const { user } = useUserStore((state) => state);
  const { isAdmin, isManager, isOwner } = useCheckRole();

  const resendInvitationLocal = async () => {
    try {
      await resendInvitation(activeUser?.email || "");
      openNotification({
        placement: "bottom",
        message: `Invite resend to ${activeUser?.email}`,
        duration: 5,
        type: "success",
        className: "notification__wrapper",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const itemsMenuPending = (isDisabled: boolean = false) => {
    return [
      getMenuPropsItems(
        "Resend invitation",
        "rename",
        null,
        undefined,
        isDisabled,
        false,
        resendInvitationLocal
      ),
      getMenuPropsItems(
        "Revoke invitation",
        "cancel",
        null,
        undefined,
        isDisabled,
        true,
        (e) => setRevokeUserPopupShow(true)
      ),
    ];
  };

  const itemsActionMenu = (isDisabled: boolean = false) => {
    return [
      getMenuPropsItems(
        "Edit role",
        "rename",
        null,
        undefined,
        isDisabled,
        false,
        () => setEditRolePopupShow(true)
      ),
      getMenuPropsItems(
        "Delete team member",
        "delete",
        null,
        undefined,
        isDisabled,
        true,
        () => setDeleteUserPopupShow(true)
      ),
    ];
  };

  const chooseMenuItems = (user: IUser) => {
    if (
      isAdmin &&
      (user.role === UserRolesEnum.ADMIN || user.role === UserRolesEnum.OWNER)
    ) {
      return itemsActionMenu(true);
    }
    if (
      isManager &&
      (user.role === UserRolesEnum.MANAGER ||
        user.role === UserRolesEnum.ADMIN ||
        user.role === UserRolesEnum.OWNER)
    ) {
      return itemsActionMenu(true);
    }
    if (isOwner && user.role === UserRolesEnum.OWNER) {
      return itemsActionMenu(true);
    }

    return itemsActionMenu(false);
  };

  const chooseMenuItemsPending = (user: IUser) => {
    if (isAdmin && user.role === UserRolesEnum.ADMIN) {
      return itemsMenuPending(true);
    }
    if (
      isManager &&
      (user.role === UserRolesEnum.MANAGER || user.role === UserRolesEnum.ADMIN)
    ) {
      return itemsMenuPending(true);
    }
    if (user.role === UserRolesEnum.OWNER) {
      return itemsMenuPending(true);
    }
    return itemsMenuPending(false);
  };

  useEffect(() => {
    const drawerCloseWidth = 72;
    const drawerOpenWidth = 248;

    if (asideCollapsed) {
      setTableWidth(
        window.innerWidth - drawerOpenWidth - window.innerWidth / 100 - 8 - 28
      );
    } else {
      setTableWidth(window.innerWidth - drawerCloseWidth - 55);
    }
  }, [asideCollapsed, usersTable]);

  const handleSort = (key: string) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === SortParams.ASC
        ? SortParams.DESC
        : SortParams.ASC;
    setSortConfig({ key, direction });
    updateParams({
      [ORDER_BY_PARAM]: key,
      [DIRECTION_PARAM]:
        direction === SortParams.ASC ? SortParams.ASC : SortParams.DESC,
    });
  };

  const definePageCount = () => recordsCount;

  const definePrefPage = useCallback(() => {
    const newOffset = Math.max(0, currentOffset - currentLimit);
    setCurrentOffset(newOffset);
    updateParams({ [OFFSET_PARAM]: newOffset, [LIMIT_PARAM]: currentLimit });
  }, [currentOffset, currentLimit, setCurrentOffset]);

  const defineNextPage = useCallback(() => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
      updateParams({ [OFFSET_PARAM]: newOffset, [LIMIT_PARAM]: currentLimit });
    }
  }, [currentOffset, currentLimit, recordsCount, setCurrentOffset]);

  const onChangeItemsCount = (value: string) => {
    const limit = Number(value);
    setCurrentLimit(limit);
    setCurrentOffset(0);
    updateParams({ [OFFSET_PARAM]: 0, [LIMIT_PARAM]: limit });
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const defineCurrentOffset = () => {
    const cOffset =
      usersTable.length && currentOffset >= 0
        ? (currentOffset + 1).toString()
        : "0";

    return cOffset;
  };

  const dataTableBodyCompare = [
    {
      content: ({ firstName, lastName, id }: any) => {
        if (!firstName && !lastName) {
          return <div>-</div>;
        }

        const isYou = user?.id === id;

        return (
          <div>
            {firstName} {lastName} {isYou ? "(you)" : ""}
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: ({ email }: any) => {
        return <div>{email}</div>;
      },
      className: () => ``,
    },
    {
      content: ({ role }: any) => {
        const chooseStyleBadge = () => {
          switch (role.toLowerCase()) {
            case UserRolesEnum.OWNER.toLowerCase():
              return "badge-role_owner";
            case UserRolesEnum.ADMIN.toLowerCase():
              return "badge-role_admin";
            case UserRolesEnum.MANAGER.toLowerCase():
              return "badge-role_manager ";
            case UserRolesEnum.VIEWER.toLowerCase():
              return "badge-role_viewer";
            default:
              return "badge-role_viewer";
          }
        };
        return (
          <div>
            <BadgeCustom
              text={capitalizeFirstLetter(role.toLowerCase())}
              className={chooseStyleBadge()}
            />
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: ({ updated_at }: any) => {
        return <div>{moment(updated_at).format("DD.MM.YYYY")}</div>;
      },
      className: () => ``,
    },
    {
      content: ({ joined, inviteToken, updated_at }: any) => {
        return (
          <div>
            {inviteToken ? (
              <BadgeCustom text="Pending" className="badge-role_pending" />
            ) : (
              moment(updated_at).format("DD.MM.YYYY")
            )}
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: (user: any) => {
        const isPending = user.inviteToken;
        return (
          <div className="ta-right" onClick={() => setActiveUser(user)}>
            <AsideDropdown
              collapsed={true}
              items={
                isPending ? chooseMenuItemsPending(user) : chooseMenuItems(user)
              }
              placement="bottomRight"
              className="relu__menu"
            >
              <div>
                <Dots color="#6C757D" />
              </div>
            </AsideDropdown>
          </div>
        );
      },
      className: () => ``,
    },
  ];

  const headDataTable = [
    {
      content: generateTextColumn({
        text: "Name",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-326",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({
        text: "Email",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.EMAIL === sortConfig.key,
      }),
      className: "table-w-230",
      sortKey: THEAD_SORT_KEYS.EMAIL,
    },
    {
      content: generateTextColumn({
        text: "Role",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.ROLE === sortConfig.key,
      }),
      className: "table-w-151 ",
      sortKey: THEAD_SORT_KEYS.ROLE,
    },
    {
      content: generateTextColumn({
        text: "Last activity",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.LAST_ACTIVITY === sortConfig.key,
      }),
      className: "table-w-161 ",
      sortKey: THEAD_SORT_KEYS.LAST_ACTIVITY,
    },
    {
      content: generateTextColumn({
        text: "Joined",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.JOINED === sortConfig.key,
      }),
      className: "table-w-161 ",
      sortKey: THEAD_SORT_KEYS.JOINED,
    },
    {
      content: generateTextColumn({
        text: "Actions",
      }),
      className: "table-w-90 ",
    },
  ];

  const dataTableBody = usersTable.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      //   isSelected: item.id === activeProductId,
      className: "",
      item: item,
    }));
  });

  const handleOkDeleteUser = async () => {
    if (!activeUser?.id) {
      return;
    }
    await deleteUserById(activeUser.id);
    getFiltersForUsers();
    setDeleteUserPopupShow(false);
    setRevokeUserPopupShow(false);
    getUsersTable(getParamsAsObject(searchParams));
  };

  const handleOkEditUser = async (value: UserRolesEnum) => {
    if (!activeUser?.id) {
      return;
    }
    await editUserById(activeUser.id, value);
    setEditRolePopupShow(false);
    getUsersTable(getParamsAsObject(searchParams));
    getFiltersForUsers();
  };

  return (
    <>
      {contextHolder}
      <RemoveUserPopup
        mode={MODE_POPUP.REMOVE}
        flag={isDeleteUserPopupShow}
        onClose={() => setDeleteUserPopupShow(false)}
        onOk={handleOkDeleteUser}
        activeUser={activeUser}
      />
      <RemoveUserPopup
        mode={MODE_POPUP.REVOKE}
        flag={isRevokeUserPopupShow}
        onClose={() => setRevokeUserPopupShow(false)}
        onOk={handleOkDeleteUser}
        activeUser={activeUser}
      />
      <EditUserPopup
        flag={isEditRolePopupShow}
        onClose={() => setEditRolePopupShow(false)}
        onOk={handleOkEditUser}
        activeUser={activeUser}
      />
      {isLoadingUsersTable ? <LoaderItemsTable /> : null}
      <div className="users-table-block" style={{ width: tableWidth }}>
        <TableCustom
          headData={headDataTable}
          bodyData={dataTableBody}
          handleSort={handleSort}
          noData={
            !filtersUsersTable.users && !isLoadingUsersTable ? (
              <div>No results found. Adjust your search to try again</div>
            ) : undefined
          }
          noDataColspan={
            !filtersUsersTable.users && !isLoadingUsersTable ? 6 : undefined
          }
        />
      </div>
      {!isLoadingUsersTable ? (
        <div className="pagination__container group-items__pagination-container">
          <div></div>
          <Pagination
            prevPage={definePrefPage}
            nextPage={defineNextPage}
            onChange={onChangeItemsCount}
            recordsCount={definePageCount()}
            currentLimit={defineCurrentLimit()}
            currentOffset={defineCurrentOffset()}
            currentSelectLimit={currentLimit.toString()}
          />
        </div>
      ) : null}
    </>
  );
};

export default TableUsers;
