export enum SIGNUP_STEPS {
  BASE = "base",
  CHECK_CODE = "checkCode",
  SET_PASSWORD = "setPassword",
  SET_PERSONAL_INFO = "setPersonalInfo",
  FINISH = "finish",
}

export enum FORGOT_PASSWORD_STEPS {
  CHECK_EMAIL = "checkEmail",
  CHECK_CODE = "checkCode",
  SET_PASSWORD = "setPassword",
  COMPLETE = "complete",
}
