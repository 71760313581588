import "./setPassForm.scss";
import { FC, useState } from "react";
import { IFormPasswordState } from "@shared/interfaces";
import { ButtonStyled } from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";

import { Input } from "antd";
import { Close, Done } from "@shared/ui/assets";
import { useFormik } from "formik";
import { validationSchema } from "./validations/setPassFormValidation";
import { useSearchParams } from "react-router-dom";
import { useUserStore } from "@entities/user";

const formState: IFormPasswordState = {
  password: "",
};

const optionsValidation = [
  {
    value: "Min 8 characters",
    isValid: false,
    key: "length",
  },

  {
    value: "At least one number",
    isValid: false,
    key: "number",
  },
  {
    value: "At least one letter",
    isValid: false,
    key: "letter",
  },
  {
    value: "One special character (!@#$%^&*)",
    isValid: false,
    key: "special",
  },
  {
    value: "One upper case letter",
    isValid: false,
    key: "upperLetter",
  },
];

interface IProps {
  onContinueClick: (obj: any) => void;
}

const SetPassForm: FC<IProps> = ({ onContinueClick }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [options, setOptions] = useState(optionsValidation);
  const { onCreatePasswordSignUp } = useUserStore((state) => state);

  const formik = useFormik<IFormPasswordState>({
    initialValues: formState,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await onCreatePasswordSignUp({
        password: values.password,
        email: searchParams.get("email") || "",
        token: searchParams.get("token") || "",
      });

      onContinueClick({
        step: SIGNUP_STEPS.SET_PERSONAL_INFO,
      });

      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("step", SIGNUP_STEPS.SET_PERSONAL_INFO);
      setSearchParams(updatedParams);
    },
  });

  const { setFieldValue, values, errors } = formik;

  const onChangeInput = ({ target }: any) => {
    const value = target.value;
    setFieldValue("password", value);

    if (value.length >= 8) {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/\d/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/[a-zA-Z]/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[^A-Za-z0-9]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[A-Z]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
  };

  const checkErrors = (key: string) => {
    return errors.password?.includes(key) || errors.password === "all";
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
        className="setpass-form_wrapper"
      >
        <div className="verify-code__container"></div>
        <div className="form__item custom-height">
          <label className="" htmlFor={"password"}>
            Password
          </label>

          <Input.Password
            id="password"
            className="gray-primary-900"
            name="password"
            type="password"
            onChange={onChangeInput}
            value={values.password}
            status={errors.password && "error"}
          />
        </div>
        {errors.password && (
          <div className="error-validation-text">
            Password doesn’t match the requirements
          </div>
        )}
        <div>
          {options.map((option) => (
            <div key={option.value} className="validation-item">
              {option.isValid ? (
                <Done />
              ) : (
                <Close
                  width="16"
                  height="16"
                  color={checkErrors(option.key) ? "red" : "#6C757D"}
                />
              )}
              <span
                className={
                  !option.isValid && checkErrors(option.key)
                    ? "error-validation"
                    : undefined
                }
              >
                {option.value}
              </span>
            </div>
          ))}
        </div>

        <ButtonStyled
          text="Continue"
          className="setpass__btn"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />
      </form>
    </div>
  );
};

export default SetPassForm;
