import "./SignupSetPassWidget.scss";
import { FC } from "react";
import { SetPassForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { SIGNUP_STEPS } from "@widgets/interfaces";

const { Title } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: SIGNUP_STEPS }) => void;
}

const SignupSetPassWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup-setpass__container">
      <h1 className="signup-setpass__title">The Relu</h1>
      <h1 className="signup-setpass__subtitle">Email confirmed!</h1>
      <h4 className="signup-setpass__text">Create your password to proceed</h4>
      <SetPassForm onContinueClick={onContinueClick} />
    </div>
  );
};

export default SignupSetPassWidget;
