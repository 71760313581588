import { FC, useEffect, useRef, useState } from "react";
import "./customGraphElasticity.scss";
import {
  ComposedChart,
  Line,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  XAxis,
  Cell,
  Rectangle,
  BarChart,
} from "recharts";
import { capitalizeFirstLetter } from "@shared/utils";

const calculatePriceSteps = (prices: number[]): number[] => {
  const minValue: number = Math.min(...prices);
  const maxValue: number = Math.max(...prices);

  let steps: number[] = [];

  const roundingRules: number[] = [2, 5, 10, 25, 50, 100, 250, 500, 1000];
  let roundingFactorIndex: number = 0;

  while (true) {
    const roundingFactor: number = roundingRules[roundingFactorIndex];

    const adjustedMin: number =
      Math.floor(minValue / roundingFactor) * roundingFactor;
    const adjustedMax: number =
      Math.ceil(maxValue / roundingFactor) * roundingFactor;

    steps = [];
    steps = steps.map((step) => parseFloat(step.toFixed(2)));

    let currentStep: number = adjustedMin;
    while (currentStep <= adjustedMax) {
      steps.push(currentStep);
      currentStep += roundingFactor;
    }

    if (steps.length <= 6) {
      break;
    } else {
      roundingFactorIndex += 1;

      if (roundingFactorIndex >= roundingRules.length) {
        throw new Error("Too large range");
      }
    }
  }

  return steps;
};

interface IProps {
  data: any[];
  newPriceValue?: string;
  currentPrice?: number;
  setActiveTrend: (trend: any) => void;
}

const idCurrentPrice = "currentPrice";
const idNewPrice = "newPrice";

const CustomLabel = ({
  property,
  price,
  isNewPrice,
  onLabelOverlap,
  id,
  modifyPositionX,
  yOffset = 0,
}: any) => {
  const { x, y } = property.viewBox;
  const [textWidth, setTextWidth] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);
  const dataPositionsLabelsRef = useRef<any>(yOffset);

  useEffect(() => {
    if (yOffset) {
      dataPositionsLabelsRef.current = yOffset;
    }
  });

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getBoundingClientRect().width;
      setTextWidth(width);

      if (onLabelOverlap) {
        onLabelOverlap({
          x: x - width / 2,
          width,
          id,
          xOriginal: x,
        });
      }
    }
  }, [price, x]);

  return (
    <foreignObject
      x={modifyPositionX || x - textWidth / 2}
      y={y - 30 + yOffset}
      width={textWidth}
      height="28"
      id={id}
    >
      <div
        ref={textRef}
        style={{
          backgroundColor: isNewPrice ? "#6C757D" : "#DEE2E6",
          borderRadius: "12px",
          padding: "2px 8px",
          color: isNewPrice ? "#FBFCFC" : "#495057",
          fontSize: "12px",
          textAlign: "center",
          whiteSpace: "nowrap",
          display: "inline-block",
          zIndex: 1,
        }}
      >
        <span>{isNewPrice ? "New" : "Current"} Price:&nbsp;</span>
        <span>{price}</span>
      </div>
    </foreignObject>
  );
};

const heightGraph = 300;

const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  maxRightYAxisValue,
}: any) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const revenueValue =
    payload.find((entry: any) => entry.name === "revenue")?.value || 0;

  const graphHeight = heightGraph;

  const revenuePercentage = revenueValue / maxRightYAxisValue;

  const finalYPosition = -100;
  // : graphHeight + yPosition - 700 - tooltipOffset <= -100;

  return (
    <div
      style={{
        left: `${coordinate.x}px`,
        top: `${finalYPosition}px`,
      }}
      className="custom-tooltip-block"
    >
      <div className="tooltip-arrow"></div>
      <p className="tooltip-label">{`Price: $${label}`}</p>
      {payload.map((entry: any, index: number) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: entry.color,
            }}
            className="badge-item-color-box"
          ></div>
          <span className="tooltip-item">{`${capitalizeFirstLetter(
            entry.name
          )}: $${entry.value}`}</span>
        </div>
      ))}
    </div>
  );
};

const CustomGraphElasticity: FC<IProps> = ({
  newPriceValue,
  currentPrice,
  data,
  setActiveTrend,
}) => {
  const newData = [...data];
  const dataPositionsLabelsRef = useRef<any>({});

  const newPriceValueChooser = newPriceValue ?? 0;

  const activeColumnIndexChooser = data.findIndex(
    (el: any) => el.price === +newPriceValueChooser
  );

  const [activeColumnIndex, setActiveColumnIndex] = useState<number>(
    activeColumnIndexChooser
  );

  useEffect(() => {
    setActiveColumnIndex(activeColumnIndexChooser);
  }, [activeColumnIndexChooser]);

  const checkIntersectionRange = (range1: number[], range2: number[]) => {
    const intersectionStart = Math.max(range1[0], range2[0]);
    const intersectionEnd = Math.min(range1[1], range2[1]);

    if (intersectionStart <= intersectionEnd) {
      return intersectionEnd - intersectionStart;
    }

    return 0;
  };

  const handleLabelOverlap = (labelData: any) => {
    const prevData = dataPositionsLabelsRef.current[labelData.id] || {};
    if (
      prevData.x !== labelData.x ||
      prevData.width !== labelData.width ||
      prevData.xOriginal !== labelData.xOriginal
    ) {
      dataPositionsLabelsRef.current = {
        ...dataPositionsLabelsRef.current,
        [labelData.id]: labelData,
      };

      modifyPositionY();
    }
  };

  useEffect(() => {
    // modifyPositionX();
    modifyPositionY();
  }, [currentPrice]);

  const modifyPositionY = () => {
    const currentPrice = dataPositionsLabelsRef.current[idCurrentPrice];
    const newPrice = dataPositionsLabelsRef.current[idNewPrice];

    if (!currentPrice || !newPrice) {
      return;
    }

    const currentPriceDiapason = [
      currentPrice.xOriginal - currentPrice.width / 2,
      currentPrice.xOriginal + currentPrice.width / 2,
    ];

    const newPriceDiapason = [
      newPrice.xOriginal - newPrice.width / 2,
      newPrice.xOriginal + newPrice.width / 2,
    ];

    const overlap = checkIntersectionRange(
      currentPriceDiapason,
      newPriceDiapason
    );

    const newPriceElement: any = document.getElementById(idNewPrice);

    if (overlap) {
      if (newPriceElement) {
        newPriceElement.style.y = "25px";
      }

      dataPositionsLabelsRef.current = {
        ...dataPositionsLabelsRef.current,
        [idNewPrice]: {
          ...dataPositionsLabelsRef.current[idNewPrice],
          yOffset: +25,
        },
        [idCurrentPrice]: {
          ...dataPositionsLabelsRef.current[idCurrentPrice],
          yOffset: 0,
        },
      };
    } else {
      dataPositionsLabelsRef.current = {
        ...dataPositionsLabelsRef.current,
        [idNewPrice]: {
          ...dataPositionsLabelsRef.current[idNewPrice],
          yOffset: 0,
        },
        [idCurrentPrice]: {
          ...dataPositionsLabelsRef.current[idCurrentPrice],
          yOffset: 0,
        },
      };
    }
  };
  // const modifyPositionX = () => {
  //   const currentPrice = dataPositionsLabelsRef.current[idCurrentPrice];
  //   const newPrice = dataPositionsLabelsRef.current[idNewPrice];

  //   if (!currentPrice || !newPrice) {
  //     return;
  //   }

  //   const currentPriceDiapason = [
  //     currentPrice.xOriginal - currentPrice.width / 2,
  //     currentPrice.xOriginal + currentPrice.width / 2,
  //   ];
  //   const newPriceDiapason = [
  //     newPrice.xOriginal - newPrice.width / 2,
  //     newPrice.xOriginal + newPrice.width / 2,
  //   ];

  //   const overlap = checkIntersectionRange(
  //     currentPriceDiapason,
  //     newPriceDiapason
  //   );

  //   if (!overlap) {
  //     return 0;
  //   }

  //   if (currentPrice.xOriginal < newPrice.xOriginal) {
  //     dataPositionsLabelsRef.current = {
  //       ...dataPositionsLabelsRef.current,
  //       [idNewPrice]: {
  //         ...dataPositionsLabelsRef.current[idNewPrice],
  //         x: newPrice.x + overlap / 2,
  //       },
  //       [idCurrentPrice]: {
  //         ...dataPositionsLabelsRef.current[idCurrentPrice],
  //         x: currentPrice.x - overlap / 2 - 10,
  //       },
  //     };
  //   }
  //   if (currentPrice.xOriginal > newPrice.xOriginal) {
  //     dataPositionsLabelsRef.current = {
  //       ...dataPositionsLabelsRef.current,
  //       [idNewPrice]: {
  //         ...dataPositionsLabelsRef.current[idNewPrice],
  //         x: newPrice.x - overlap / 2,
  //       },
  //       [idCurrentPrice]: {
  //         ...dataPositionsLabelsRef.current[idCurrentPrice],
  //         x: currentPrice.x + overlap / 2 + 10,
  //       },
  //     };
  //   }
  //   if (currentPrice.xOriginal === newPrice.xOriginal) {
  //     dataPositionsLabelsRef.current = {
  //       ...dataPositionsLabelsRef.current,
  //       [idNewPrice]: {
  //         ...dataPositionsLabelsRef.current[idNewPrice],
  //         x: newPrice.x - overlap / 2,
  //       },
  //       [idCurrentPrice]: {
  //         ...dataPositionsLabelsRef.current[idCurrentPrice],
  //         x: currentPrice.x + overlap / 2 + 10,
  //       },
  //     };
  //   }
  // };

  const modifyPriceSteps = (priceSteps: number[]) => {
    const res = [...priceSteps.slice(1, -1)];
    return res;
  };

  const priceSteps = modifyPriceSteps(
    calculatePriceSteps(newData.map((el) => el.price))
  );

  const indexedData = newData.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  const maxRightYAxisValue = Math.max(
    ...newData.map((el) => Math.max(el.revenue || 0, el.profit || 0))
  );

  const handleBarClick = (index: number) => {
    setActiveColumnIndex(index);
  };

  return (
    <div className="pl" onClick={(e) => e.stopPropagation()}>
      <ComposedChart
        width={590}
        height={heightGraph}
        data={indexedData}
        margin={{ top: 30, right: 20, left: 20, bottom: 20 }}
        barGap={5}
        barCategoryGap="10%"
        onClick={(event: any) => {
          modifyPositionY();
          const { activeLabel, activeTooltipIndex } = event;
          handleBarClick(activeTooltipIndex);

          if (activeLabel) {
            const clickedData = indexedData.find(
              (item) => item.price === activeLabel
            );
            if (clickedData) {
              setActiveTrend(clickedData);
            }
          }
        }}
      >
        <CartesianGrid
          horizontal
          vertical={false}
          stroke="#e0e0e0"
          strokeDasharray="3 3"
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[0, "auto"]}
          tick={{ fontSize: 12, fill: "#000" }}
        />

        <YAxis
          tick={{ fontSize: 12, fill: "#000" }}
          yAxisId="right"
          orientation="right"
          domain={[0, "auto"]}
          tickFormatter={(value) =>
            `$${value / 1000 ? value / 1000 + "k" : value / 1000}`
          }
        />

        <XAxis
          dataKey="price"
          type="number"
          scale="linear"
          domain={[priceSteps[0], priceSteps[priceSteps.length - 1]]}
          ticks={priceSteps}
          tickFormatter={(value) => `$${value}`}
          tickLine={true}
          tick={{ fontSize: 12, fill: "#000" }}
          padding={{ left: 13, right: 13 }}
        />

        <CartesianGrid
          strokeDasharray="3 3"
          vertical={true}
          horizontal={false}
        />

        <Tooltip
          content={<CustomTooltip maxRightYAxisValue={maxRightYAxisValue} />}
        />
        <Bar
          yAxisId="left"
          dataKey="sales"
          fill={"#CED4DA"}
          activeIndex={activeColumnIndex}
          barSize={17}
          activeBar={{ fill: "#ADB5BD" }}
          isAnimationActive={false}
        />
        <Line
          dataKey="revenue"
          yAxisId="right"
          type="monotone"
          stroke="#198754"
          dot={{ r: 2 }}
          strokeWidth={1}
          isAnimationActive={false}
        />
        <Line
          dataKey="profit"
          yAxisId="right"
          type="monotone"
          stroke="#0D6EFD"
          dot={{ r: 2 }}
          strokeWidth={1}
          isAnimationActive={false}
        />

        <ReferenceLine
          yAxisId="right"
          x={currentPrice}
          stroke="gray"
          strokeDasharray="3 3"
          label={(property) => {
            return (
              <CustomLabel
                // modifyPositionX={
                //   dataPositionsLabelsRef.current?.currentPrice?.x
                // }
                property={property}
                price={currentPrice}
                isNewPrice={false}
                onLabelOverlap={handleLabelOverlap}
                id={idCurrentPrice}
                yOffset={
                  dataPositionsLabelsRef.current?.currentPrice?.yOffset || 0
                }
              />
            );
          }}
        />
        {newPriceValue && (
          <ReferenceLine
            yAxisId="right"
            x={+newPriceValue}
            stroke="gray"
            strokeDasharray="3 3"
            label={(property) => {
              return (
                <CustomLabel
                  // modifyPositionX={dataPositionsLabelsRef.current?.newPrice?.x}
                  property={property}
                  price={newPriceValue}
                  isNewPrice={true}
                  onLabelOverlap={handleLabelOverlap}
                  id={idNewPrice}
                  yOffset={
                    dataPositionsLabelsRef.current?.newPrice?.yOffset || 0
                  }
                />
              );
            }}
          />
        )}
      </ComposedChart>
      <div className="legend-block-elasticity">
        <div className="sales-label-y">Sales (units)</div>
        <div className="performance-label-y">Performance</div>

        <div className="legend-block-elasticity-item-sales">
          <div className="block"></div> <span>Sales</span>
        </div>
        <div className="legend-block-elasticity-item-revenue">
          <div className="block-custom-revenue"></div>
          <span>Revenue</span>
        </div>
        <div className="legend-block-elasticity-item-profit">
          <div className="block-custom-profit"></div>
          <span>Profit</span>
        </div>
      </div>
    </div>
  );
};

export default CustomGraphElasticity;
