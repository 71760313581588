import "./scoreRange.scss";

interface IProps {
  count: number;
}

const ScoreRange = ({ count }: IProps) => {
  const render = () => {
    let itemsCount = 0;
    let className = "";

    if (count <= 20) {
      itemsCount = 2;
      className = "two";
    } else if (count <= 40) {
      itemsCount = 4;
      className = "four";
    } else if (count <= 60) {
      itemsCount = 6;
      className = "six";
    } else if (count <= 80) {
      itemsCount = 8;
      className = "eight";
    } else if (count <= 100) {
      itemsCount = 10;
    }

    const items = Array.from({ length: itemsCount }, (_, i) => (
      <div key={i} className={`score-item ${className}`}></div>
    ));

    return <div className="df">{items}</div>;
  };
  return render();
};

export default ScoreRange;
