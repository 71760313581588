import { Divider, Modal } from "antd";
import { FC, useState } from "react";
import "./deleteAccountPopupByDefault.scss";
import { Circle, Close } from "@shared/ui/assets";
import { ButtonStyled } from "@shared/ui/components";
import CheckCircle from "@shared/ui/assets/CheckCircle";
import { useUserStore } from "@entities/user";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => Promise<void>;
}

const DeleteAccountPopupByDefault: FC<IProps> = (props) => {
  const { flag, onClose, onOk } = props;
  const { logOut } = useUserStore((state) => state);
  const navigate = useNavigate();
  const [isDeleteAccEvent, setIsDeleteAccEvent] = useState(true);

  const handleLogout = async () => {
    await logOut();
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <Modal
        width={isDeleteAccEvent ? 500 : 320}
        open={flag}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => {
          onClose();
        }}
      >
        <div className="modal-remove-account">
          {isDeleteAccEvent ? (
            <>
              <div onClick={onClose} className="modal-close__icon">
                <Close />
              </div>
              <div className="modal-remove-account-title">Delete Account?</div>
              <Divider />
              <div className="modal-remove-account-content">
                This will delete your account. You will lose your current access
                to the app. You cannot undo this action.
              </div>
              <Divider />
              <div className="modal-remove-account-footer">
                <ButtonStyled
                  text="Cancel"
                  type="default"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={onClose}
                />
                <ButtonStyled
                  text="Delete my account"
                  type="primary"
                  htmlType="button"
                  fill="red-600"
                  className="w-181"
                  onClick={async () => {
                    await onOk();
                    setIsDeleteAccEvent(false);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="del-user-success-block">
                <div className="svg-block">
                  <CheckCircle />
                </div>
                <div>Your account is deleted</div>
              </div>
              <Divider className="divider-del-acc" />
              <div className="del-user-button-block">
                <ButtonStyled
                  text="OK"
                  type="primary"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={handleLogout}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccountPopupByDefault;
