import * as Yup from "yup";

export const getValidationSchema = (currentEmail: string) =>
  Yup.object({
    confirmEmail: Yup.string()
      .required("Current email is required.")
      .email("Invalid email format")
      .test(
        "is-different",
        "The email address must be the same as your current one.",
        function (value) {
          return value === currentEmail;
        }
      ),
  });
export const getValidationFullNameSchema = () =>
  Yup.object({
    fullName: Yup.string()
      .matches(/.*\s.*/, "Full name must include a first name and a last name.")

      .matches(
        /^[A-Za-zА-Яа-яЁё0-9]+(\s[A-Za-zА-Яа-яЁё0-9]+)+$/,
        "Full name must not contain special characters."
      )
      .matches(
        /^(?=.*\s)[A-Za-zА-Яа-яЁё0-9]{2,}(\s[A-Za-zА-Яа-яЁё0-9]{2,})+$/,
        "Full name must contain at least two words, each at least 2 letters long."
      )
      .max(33, "Fullname must not exceed 33 characters.")
      .required("Please specify your full name"),
  });
