import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { ISortParams } from "@shared/interfaces";
import { Info } from "@shared/ui/assets";
import { CustomTooltip } from "@shared/ui/components";
import { FC } from "react";

interface IGenerateTextColumn {
  text: string | React.ReactElement;
  isSorted?: boolean;
  sortDirection?: ISortParams | "";
  isActiveSort?: boolean;
  tooltipText?: string;
}
export const generateTextColumn: FC<IGenerateTextColumn> = (props) => {
  const { text, tooltipText, isSorted, sortDirection, isActiveSort } = props;
  return (
    <div className="column__content">
      <h5>{text}</h5>
      {tooltipText && (
        <CustomTooltip
          title={tooltipText}
          placement="top"
          arrow={true}
          className="mr-4"
        >
          <Info />
        </CustomTooltip>
      )}
      {isSorted && (
        <div className="sorted__box">
          <CaretUpOutlined
            style={{
              fontSize: "12px",
              color:
                isActiveSort && sortDirection === "ASC" ? "#000" : "#6C757D",
              marginBottom: "-4px",
            }}
          />
          <CaretDownOutlined
            style={{
              fontSize: "12px",
              color:
                isActiveSort && sortDirection === "DESC" ? "#000" : "#6C757D",
            }}
          />
        </div>
      )}
    </div>
  );
};
