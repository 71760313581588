import * as Yup from "yup";

export const validationSchema = Yup.object({
  currentPassword: Yup.string()
    .required("Current password is required.")
    .min(8, "Current password does not meet requirements.")
    .matches(/[A-Z]/, "Current password does not meet requirements.")
    .matches(/[a-z]/, "Current password does not meet requirements.")
    .matches(/\d/, "Current password does not meet requirements.")
    .matches(/[\W_]/, "Current password does not meet requirements."),

  newPassword: Yup.string()
    .required("all")
    .test("password-strength", "", function (value) {
      if (!value) return this.createError({ message: "all" });

      const errors = [];
      if (value.length < 8) errors.push("length");
      if (!/[A-Za-z]/.test(value)) errors.push("letter");
      if (!/\d/.test(value)) errors.push("number");
      if (!/[^A-Za-z0-9]/.test(value)) errors.push("special");
      if (!/[A-Z]/.test(value)) errors.push("upperLetter");

      if (errors.length > 0) {
        return this.createError({ message: errors.join(", ") });
      }

      return true;
    }),
});
