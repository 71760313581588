import "./strategyRule.scss";
import Competition from "./Competition/Competition";
import Elasticity from "./Elasticity/Elasticity";
import {
  AdditionalCustomStep,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import { useEffect } from "react";

interface IProps {
  setHighlighted: (index: number) => void;
}

const StrategyRule = ({ setHighlighted }: IProps) => {
  const typeOfRule = useGroupsStore((state) => state.strategy.strategyType);
  const { updateStrategy } = useGroupsStore((state) => state);

  useEffect(() => {
    console.log(
      AdditionalCustomStep.STRATEGY_CHECK_NO_COMPETITORS_ERROR,
      "AdditionalCustomStep.STRATEGY_CHECK_NO_COMPETITORS_ERROR"
    );

    updateStrategy(AdditionalCustomStep.STRATEGY_CHECK_NO_COMPETITORS_ERROR);
  }, []);

  const renderObj = {
    [StrategyTypeEnum.ELASTICITY]: <Elasticity />,
    [StrategyTypeEnum.COMPETITION]: (
      <Competition setHighlighted={setHighlighted} />
    ),
  };

  if (!typeOfRule) {
    return <></>;
  }

  return <>{renderObj[typeOfRule]}</>;
};

export default StrategyRule;
