import "./nameSummary.scss";
import {
  Rate,
  Metric,
  CurrentStep,
  useGroupsStore,
  PriceFormulaType,
  MetricToMaintain,
  StrategyTypeEnum,
  AutoRepricingEnum,
  SelectLimitPricing,
  PriceRoundingEnum,
  MetricToGrow,
  AlertType,
} from "@entities/groups";
import { IProduct } from "@entities/products";
import { LS_IS_WARNING_LIMITS, LS_NEW_GROUP_NAME } from "@shared/constants";
import { ArrowBorder } from "@shared/ui/assets";
import { InputWithIcons } from "@shared/ui/components";
import { getItemWord } from "@shared/utils";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const EditNameSummary = () => {
  const [isExceptionOpened, setExceptionsOpen] = useState<boolean>(false);
  const {
    currentGroup,
    recordsCountInGroup,
    strategy,
    validationErrors,
    exceptionProducts,
    getExceptions,
    updateGroupName,
    setValidationError,
    getProductsInGroup,
  } = useGroupsStore((state) => state);

  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const valueLocal =
      localStorage.getItem(LS_NEW_GROUP_NAME) || currentGroup.name;
    setValue(valueLocal);
  }, [value]);

  const isExceptionLength = exceptionProducts.length;
  const nameValidError = validationErrors[CurrentStep.SUMMARY]?.name;

  useEffect(() => {
    setValue(currentGroup.name);
  }, [currentGroup.name]);

  useEffect(() => {
    currentGroup.id && getProductsInGroup({ groupId: currentGroup.id });
    currentGroup.id && getExceptions();
  }, [currentGroup]);

  const renderPriceType = () => {
    if (strategy.strategyType === StrategyTypeEnum.ELASTICITY) {
      return "Price Elasticity";
    }

    if (strategy.strategyType === StrategyTypeEnum.COMPETITION) {
      return "Competition";
    }
  };

  const renderStrategyRule = () => {
    const { strategyRule } = strategy;
    const { pricesFormula, competitors } = strategyRule;

    if (strategy.strategyType === StrategyTypeEnum.ELASTICITY) {
      let text = "";

      if (pricesFormula?.metricToGrow) {
        text += `Grow: ${formatElasticityMetric(pricesFormula?.metricToGrow)}`;
      }

      if (
        pricesFormula?.metricToMaintain &&
        pricesFormula?.metricToMaintain !== MetricToMaintain.NONE
      ) {
        text += `${
          pricesFormula?.metricToGrow ? ", maintain" : "Maintain"
        }: ${formatElasticityMetric(pricesFormula?.metricToMaintain)}`;

        if (pricesFormula?.metricToMaintain === MetricToMaintain.SALES) {
          text += `(units))`;
        }
      }

      return text;
    }

    if (!pricesFormula.type) {
      return "N/A";
    }

    let rate = "";
    let priceFormulaType = "";

    switch (pricesFormula.type) {
      case PriceFormulaType.LOWEST: {
        priceFormulaType = "Lower than";
        break;
      }

      case PriceFormulaType.AVERAGE: {
        priceFormulaType = "Average to";
        break;
      }

      case PriceFormulaType.HIGHEST: {
        priceFormulaType = "Highest than";
        break;
      }
    }

    switch (pricesFormula.rate) {
      case Rate.DOLLAR: {
        rate = "$";
        break;
      }

      case Rate.PERCENTAGE: {
        rate = "%";
        break;
      }
    }

    let text = `${priceFormulaType} ${competitors.length} ${
      competitors.length > 1 ? "Competitors" : "Competitor"
    } `;

    if (pricesFormula.type !== PriceFormulaType.AVERAGE) {
      text += `${pricesFormula.metric === Metric.PLUS ? "+" : "-"} ${
        pricesFormula?.value || "0.00"
      }${rate} `;
    }

    return text;
  };

  const renderPriceLimits = () => {
    const { priceLimits } = strategy;

    const isMinActive = priceLimits.pricing && priceLimits.min?.value;

    const isMaxActive = priceLimits?.pricing && priceLimits.max?.value;

    let text = "";

    if (isMinActive) {
      text += `Min
            ${formatPricing(priceLimits.pricing)}
          
            ${
              priceLimits.pricing === SelectLimitPricing.CURRENT_PRICE
                ? "-"
                : ""
            }
            ${priceLimits.min.value}%`;
    }

    if (isMaxActive) {
      text += `${isMinActive ? ", max" : "Max"}
            ${formatPricing(priceLimits.pricing)}
            ${
              priceLimits.pricing === SelectLimitPricing.MSRP
                ? formatMetric(priceLimits.metric || Metric.MINUS)
                : ""
            }
                 ${
                   priceLimits.pricing === SelectLimitPricing.CURRENT_PRICE
                     ? "+"
                     : ""
                 }
            ${priceLimits.max.value}%`;
    }

    if (text === "") {
      return "N/A";
    }

    return text;
  };

  const renderExceptions = () => {
    if (!isExceptionLength) {
      return "";
    }

    return `${isExceptionLength} ${
      isExceptionLength > 1 ? "custom limits" : "custom limit"
    }`;
  };

  const renderExceptionItem = (exception: IProduct, index: number) => {
    const pricing = exception.exceptionLimits?.pricing;
    const isMinActive = exception?.exceptionLimits?.min;
    const isMaxActive = exception?.exceptionLimits?.max;

    return (
      <li
        key={`exception_${index}`}
        className="summary__overview-exception-item"
      >
        <span className="exception-item__desc">{exception.article}</span>
        <span className="exception-item__desc point">{exception.name}</span>
        {isMinActive && pricing && (
          <span className="exception-item__desc point">
            {isMinActive && "Min "}
            {pricing && (
              <span className="exception-item__desc-cor">
                {formatPricing(pricing)}
              </span>
            )}
            {isMinActive.value} %
          </span>
        )}

        {isMaxActive && pricing && (
          <span className="exception-item__desc point">
            {isMaxActive && "Max "}
            {pricing && (
              <span className="exception-item__desc-cor">
                {" "}
                {formatPricing(pricing)}
              </span>
            )}
            {isMaxActive.value} %
          </span>
        )}
      </li>
    );
  };

  const debounced = useDebouncedCallback((value: string) => {
    checkNameOfGroup(value);
  }, 500);

  const handleChangeGroupName = (value: string) => {
    localStorage.setItem(LS_NEW_GROUP_NAME, value);
    const trimValue = value.trim();
    setValue(value);
    debounced(trimValue);
  };

  const isErrorClassInput = () => {
    if (nameValidError) {
      return "error-validation-input";
    }

    return "";
  };

  const isErrorTextInput = () => {
    const textError = validationErrors[CurrentStep.SUMMARY]?.textError;
    if (nameValidError) {
      if (textError) {
        return `${textError}`;
      }
      return "Group name is required";
    }

    return "";
  };

  const renderPriceRounding = () => {
    if (!Object.keys(strategy.priceRounding).length) {
      return "N/A";
    }

    const decimal = strategy.priceRounding?.decimal;
    const integer = strategy.priceRounding?.integer;
    const metric = strategy.priceRounding.metric;

    if (!decimal?.value && !integer?.value) {
      return "N/A";
    }

    let text = "";

    if (decimal?.active && decimal?.value) {
      text += `Per decimals`;

      if (decimal?.value && decimal.value !== "") {
        text += ` (${decimal.value}), `;
      } else {
        text += " N/A, ";
      }
    }

    if (integer?.active && integer?.value) {
      text += `Per integer`;

      if (integer?.value && integer.value !== "") {
        text += ` (${integer.value}),  `;
      } else {
        text += " N/A, ";
      }
    }

    if (metric) {
      if (metric === PriceRoundingEnum.ROUND_UP) {
        text += " round up";
      }

      if (metric === PriceRoundingEnum.ROUND_DOWN) {
        text += " round down";
      }

      text += "";
    }

    return text;
  };

  const renderUpdatePolicy = () => {
    let text = "Auto repricing";

    if (
      !strategy.updatePolicy?.value ||
      strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_OFF
    ) {
      text += ` off`;
    }

    if (strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_ON) {
      text += ` on`;
    }

    return text;
  };

  const formatPricing = (pricing: any) => {
    if (!pricing) {
      return "";
    }

    switch (pricing) {
      case SelectLimitPricing.CURRENT_PRICE: {
        return "Current price";
      }

      case SelectLimitPricing.MARGIN: {
        return "Margin";
      }

      case SelectLimitPricing.MSRP: {
        return "MSRP";
      }
    }
  };

  const formatMetric = (metric: string) => {
    if (!metric) {
      return "";
    }

    if (metric === Metric.MINUS) {
      return "-";
    }

    return "+";
  };

  const formatElasticityMetric = (metric: string) => {
    if (!metric) {
      return "";
    }

    switch (metric) {
      case MetricToGrow.PROFIT: {
        return "Profit";
      }
      case MetricToGrow.SALES: {
        return "Sales";
      }
      case MetricToGrow.REVENUE: {
        return "Revenue";
      }
    }
  };

  const checkNameOfGroup = async (value: string) => {
    let isValidNameOfGroup = true;
    try {
      await updateGroupName(value);
      setValidationError({
        [CurrentStep.SUMMARY]: null,
      });
    } catch (error: any) {
      setValidationError({
        [CurrentStep.SUMMARY]: {
          name: {
            priority: 0,
            type: AlertType.ERROR,
          },
          textError: error?.text,
        },
      });
      isValidNameOfGroup = false;
    }
    return isValidNameOfGroup;
  };

  return (
    <div className="groups-summary__wrapper content__container poz-r">
      <h3 className="groups-summary__title">Group Name & Summary</h3>
      <h5 className="groups-summary__subtitle">
        Name the group and review the configuration
      </h5>
      <div className="summary__overview">
        <h4 className="summary__overview-title">Group name</h4>
        <InputWithIcons
          wrapperClassName={`group-summary__input-block ${isErrorClassInput()}`}
          classNameInput={`group-summary__input`}
          value={value}
          onChange={handleChangeGroupName}
        />
        <div className="error-validation-message">{isErrorTextInput()}</div>
      </div>
      <div className="summary__overview">
        <h4 className="summary__overview-title">Group summary</h4>
        <div className="summary__overview-item">
          <h5 className="summary__overview-key">Product Items</h5>
          <h5 className="summary__overview-value">
            {recordsCountInGroup} {getItemWord(recordsCountInGroup)}
            {/* from{" "} */}
            {/* {currentGroup.selectedCategory || "N/A"} category */}
          </h5>
        </div>

        <div className="summary__overview-item">
          <h5 className="summary__overview-key">Strategy Type</h5>
          <div className={`summary__overview-value ${strategy.strategyType}`}>
            <span className="capitalize">{renderPriceType()}</span>
          </div>
        </div>

        <div className="summary__overview-item">
          <h5 className="summary__overview-key">Strategy Rule</h5>
          <div className="summary__overview-value">{renderStrategyRule()}</div>
        </div>

        <div className="summary__overview-item">
          <h5 className="summary__overview-key">Price Limits</h5>
          <div className="summary__overview-value">{renderPriceLimits()}</div>
        </div>

        {isExceptionLength ? (
          <div className="summary__overview-item">
            <div className="summary__overview-key"></div>
            <div className="summary__overview-value" style={{ flex: 1 }}>
              <div className="summary-exceptions">
                {renderExceptions()}
                <div
                  className={`summary-exception__img ${
                    isExceptionOpened ? "exception__img-rotate" : ""
                  }`}
                  onClick={() =>
                    isExceptionLength && setExceptionsOpen(!isExceptionOpened)
                  }
                >
                  {isExceptionLength ? <ArrowBorder /> : <></>}
                </div>
              </div>

              {isExceptionOpened && (
                <ul className="summary__overview-exception">
                  {exceptionProducts.map((item: any, index: number) =>
                    renderExceptionItem(item, index)
                  )}
                </ul>
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="summary__overview-item">
          <h5 className="summary__overview-key">Price Roundings </h5>
          <div className="summary__overview-value">{renderPriceRounding()}</div>
        </div>

        {/* <div className="summary__overview-item">
          <h5 className="summary__overview-key">Update Policy</h5>
          <div className="summary__overview-value">{renderUpdatePolicy()}</div>
        </div> */}
      </div>
    </div>
  );
};

export default EditNameSummary;
