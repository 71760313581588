import { Input } from "antd";
import { FC, useState } from "react";

import "./inputCustom.scss";

interface IProps {
  value?: string;
  fill?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  iconRight?: React.ReactElement;
  iconBgClassName?: string;
  onChangeInput?: (e: React.SyntheticEvent<EventTarget>) => void;
  className?: string;
  onBlur?: (e: React.SyntheticEvent<EventTarget>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InputCustom: FC<IProps> = ({
  value,
  fill,
  name,
  type,
  iconRight,
  onChangeInput,
  className,
  onBlur,
  onKeyDown,
  iconBgClassName,
  ...props
}: IProps) => {
  const [isFocused, setIsFocused] = useState(false);

  let classes = ["input-custom__wrapper"];
  if (fill === "gray-primary-900") {
    classes.push("gray-primary-900");
  }

  if (iconRight) {
    classes.push("input-icon__padding-right");
  }

  if (isFocused) {
    classes.push("input-custom__wrapper--focused");
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: React.SyntheticEvent<EventTarget>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const getIconClassName = () => {
    if (iconBgClassName && iconRight) {
      return `input__right-icon-bg ${iconBgClassName}`;
    }
    if (iconRight) {
      return "input__right-icon";
    }
    return null;
  };

  return (
    <div className={`${classes.join(" ")}`}>
      <Input
        className={className}
        name={name}
        value={value}
        onChange={onChangeInput}
        type={type}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        {...props}
      />
      {iconRight && <div className={getIconClassName() || ""}>{iconRight}</div>}
    </div>
  );
};

export default InputCustom;
