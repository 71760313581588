import { UserRolesEnum } from "@entities/settings";
import { useUserStore } from "@entities/user";
import React from "react";

const useCheckRole = () => {
  const role = useUserStore((state) => state.user.role);

  const isAdmin = React.useMemo(() => role === UserRolesEnum.ADMIN, [role]);
  const isManager = React.useMemo(() => role === UserRolesEnum.MANAGER, [role]);
  const isViewer = React.useMemo(() => role === UserRolesEnum.VIEWER, [role]);
  const isOwner = React.useMemo(() => role === UserRolesEnum.OWNER, [role]);
  const isSuAdmin = React.useMemo(
    () => role === UserRolesEnum.SU_ADMIN,
    [role]
  );

  return { isAdmin, isManager, isViewer, isOwner, isSuAdmin };
};

export default useCheckRole;
