import {
  AlertType,
  CurrentStep,
  IAlert,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import "./layoutWithTabs.scss";
import { useEffect, useState } from "react";
import { EditGroupItems, EditStrategyType } from "@widgets/admin/groups";
import { useNavigate, useParams } from "react-router-dom";
import { EditStrategyRule } from "../../EditStrategyRule";
import { EditPriceLimits } from "../../EditPriceLimits";
import { EditPriceRounding } from "../../EditPriceRounding";
import EditNameSummary from "../../EditNameSummary/ui/NameSummary";
import Footer from "../Footer/Footer";
import { CheckOval, Error, Warning } from "@shared/ui/assets";
import { LS_IS_WARNING_LIMITS, LS_NEW_GROUP_NAME } from "@shared/constants";
import { AlertCustom } from "@shared/ui/components";
import React from "react";
import useValidationStepper, {
  IValidationExceptionItem,
} from "@shared/model/hooks/useValidationSteper";
import { ROUTES } from "@shared/interfaces";
import DiscardPopup from "../../DiscardPopup/DiscardPopup";

interface ITab {
  key: CurrentStep;
  label: string;
  error?: boolean;
  warning?: boolean;
}

const tabsInit: ITab[] = [
  { key: CurrentStep.PRODUCT_ITEM, label: "Product Items" },
  { key: CurrentStep.STRATEGY_TYPE, label: "Strategy Type" },
  { key: CurrentStep.STRATEGY_RULE, label: "Strategy Rule" },
  {
    key: CurrentStep.PRICE_LIMITS,
    label: "Price Limits",
  },
  { key: CurrentStep.PRICE_ROUNDING, label: "Price Roundings" },
  { key: CurrentStep.SUMMARY, label: "Name and Summary" },
];

export const LayoutWithTabs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getGroup,
    currentGroup,
    productsInGroup,
    setCurrentGroup,
    setExceptionProducts,
    rollbackEditGroup,
    clearStrategyInStore,
  } = useGroupsStore();
  const [tabs, setTabs] = useState(tabsInit);
  const [activeTab, setActiveTab] = useState<CurrentStep | null>(
    CurrentStep.SUMMARY
  );

  const [isShowSummaryAlert, setShowSummaryAlert] = useState(false);
  const { validationErrors, strategy, setValidationError, commitEditGroup } =
    useGroupsStore((state) => state);
  const {
    isErrorValidPriceLimitsInputs,
    arrValidationExceptions,
    isErrorValidDecimalInput,
    isErrorValidIntegerInput,
    isErrorValidNameSummaryInput,
  } = useValidationStepper();
  const [isShowDiscardPopup, setShowDiscardPopup] = useState(false);

  useEffect(() => {
    id && getGroup(id);
    return () => {
      setCurrentGroup({});
      setExceptionProducts([]);
      localStorage.removeItem(LS_NEW_GROUP_NAME);
    };
  }, []);

  const changeTab = () => {
    const updatedTabs = tabs.map((item, index) => {
      const stepKey = item.key;
      const validationErrorByKey = validationErrors[stepKey];

      const isValidationError =
        stepKey &&
        validationErrorByKey &&
        Object.values(validationErrorByKey).some((value) => !!value);

      const alertsLocal: IAlert[] =
        stepKey !== CurrentStep.STRATEGY_TYPE && strategy[stepKey]?.alerts
          ? [...(strategy[stepKey]?.alerts || [])]
          : [];

      if (isValidationError) {
        alertsLocal.unshift({
          type: AlertType.ERROR,
          priority: 0,
          message: "Validation error",
          target: "",
        });
      }

      const LSWarningLimits =
        localStorage.getItem(LS_IS_WARNING_LIMITS) === "true";

      const customAlertError =
        (strategy && alertsLocal[0]?.type === AlertType.ERROR) || false;

      const customAlertWarning =
        (strategy && alertsLocal[0]?.type === AlertType.WARNING) || false;

      if (customAlertError) {
        return {
          ...item,
          error: true,
        };
      }

      if (customAlertWarning) {
        if (LSWarningLimits && stepKey === CurrentStep.PRICE_LIMITS) {
          return {
            ...item,
            warning: true,
          };
        }

        if (stepKey === CurrentStep.STRATEGY_RULE) {
          return {
            ...item,
            warning: true,
          };
        }
      }

      return {
        ...item,
        error: false,
        warning: false,
      };
    });

    setTabs(updatedTabs);
  };

  useEffect(() => {
    changeTab();
  }, [
    currentGroup,
    strategy.priceLimits,
    strategy.strategyRule,
    validationErrors,
  ]);

  const renderTabContent = (activeTab: CurrentStep | null) => {
    switch (activeTab) {
      case CurrentStep.PRODUCT_ITEM:
        return <EditGroupItems />;
      case CurrentStep.STRATEGY_TYPE:
        return <EditStrategyType />;
      case CurrentStep.STRATEGY_RULE:
        return <EditStrategyRule setActiveTab={setActiveTab} />;
      case CurrentStep.PRICE_LIMITS:
        return <EditPriceLimits handleBlurValidation={handleBlurValidation} />;
      case CurrentStep.PRICE_ROUNDING:
        return (
          <EditPriceRounding handleBlurValidation={handleBlurValidation} />
        );
      //   // case CurrentStep.UPDATE_POLICY:
      //   // return <UpdatePolicy />;
      case CurrentStep.SUMMARY:
        return <EditNameSummary />;
      default:
        return null;
    }
  };

  const checkAlertsErrors = () => {
    return tabsInit.some((el, index) => {
      // const isValidationError = Object.values(
      //   validationErrors[el.key] || {}
      // ).some((value) => !!value);

      return (
        el.key !== CurrentStep.STRATEGY_TYPE &&
        strategy[el.key]?.alerts?.some((alert: IAlert) => {
          return alert.type === AlertType.ERROR;
        })
      );
    });
  };

  const checkExceptionsValidation = (price?: SelectLimitPricing) => {
    const currentValidationErrors =
      useGroupsStore.getState().validationErrors[CurrentStep.PRICE_LIMITS];

    if (!arrValidationExceptions(price).length) {
      setValidationError({
        [CurrentStep.PRICE_LIMITS]: {
          ...currentValidationErrors,
          exceptionProducts: null,
        },
      });

      return true;
    }

    const updatedExceptionProducts: { [key: string]: any } = {
      ...currentValidationErrors?.exceptionProducts,
    };

    const validationStoreArr: { [key: string]: any } = {};

    arrValidationExceptions(price).forEach((el: IValidationExceptionItem) => {
      const { id, ...rest } = el;

      const validationException = {
        ...updatedExceptionProducts[el.id],
        ...rest,
      };
      validationStoreArr[id] = validationException;
    });

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: {
        ...currentValidationErrors,
        exceptionProducts: {
          ...validationStoreArr,
        },
      },
    });

    return false;
  };

  const checkPriseLimitsValidation = () => {
    let isValid = true;

    const errorConfigs = [
      {
        error: isErrorValidPriceLimitsInputs,
        field: "maxInput",
        key: "range",
      },
      {
        error: isErrorValidPriceLimitsInputs,
        field: "minInput",
        key: "range",
      },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field, key }) => {
      const errorData = error ? { priority: 0, type: AlertType.ERROR } : null;

      if (errorData) {
        if (key) {
          newErrors[field] = {
            [key]: errorData,
          };
        } else {
          newErrors[field] = errorData;
        }
        isValid = false;
      }
    });

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: newErrors,
    });

    return isValid;
  };

  const checkNameAndSummaryValidation = () => {
    let isValid = true;
    const errorConfigs = [
      { error: isErrorValidNameSummaryInput, field: "name" },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field }) => {
      if (error) {
        newErrors[field] = { priority: 0, type: AlertType.ERROR };
        isValid = false;
      }
    });

    setValidationError({
      [CurrentStep.SUMMARY]: Object.keys(newErrors).length ? newErrors : null,
    });

    return isValid;
  };
  const checkPriceRoundingValidation = () => {
    let isValid = true;

    const errorConfigs = [
      { error: isErrorValidDecimalInput, field: "decimal" },
      { error: isErrorValidIntegerInput, field: "integer" },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field }) => {
      if (error) {
        newErrors[field] = { priority: 0, type: AlertType.ERROR };
        isValid = false;
      } else {
        newErrors[field] = null;
      }
    });

    setValidationError({
      [CurrentStep.PRICE_ROUNDING]: isValid
        ? null
        : {
            ...useGroupsStore.getState().validationErrors[
              CurrentStep.PRICE_ROUNDING
            ],
            ...newErrors,
          },
    });

    return isValid;
  };

  const handleBlurValidation = (price?: SelectLimitPricing) => {
    const isValid = checkNameAndSummaryValidation();
    const isValidPriseLimits = checkPriseLimitsValidation();
    const exceptionsValidation = checkExceptionsValidation(price);

    const priceRoundingValidation = checkPriceRoundingValidation();

    const isAlertsErrors = checkAlertsErrors();

    if (
      isValid &&
      isValidPriseLimits &&
      exceptionsValidation &&
      priceRoundingValidation &&
      isAlertsErrors
    ) {
      setValidationError({
        [CurrentStep.SUMMARY]: null,
      });
      return true;
    } else {
      setShowSummaryAlert(true);
    }
    return false;
  };

  const combineErrorsLinks = () => {
    // const errorLinks = STEP_KEYS.reduce((acc: any, keyOfStrategy, index) => {
    //   const stepKey = STEP_KEYS[index];
    //   const validationErrorByKey = validationErrors[stepKey];

    //   const isValidationError =
    //     stepKey &&
    //     validationErrorByKey &&
    //     Object.values(validationErrorByKey).some((value) => !!value);

    //   const isError =
    //     strategy[keyOfStrategy]?.alerts?.[0]?.type === AlertType.ERROR &&
    //     stepKey !== CurrentStep.SUMMARY;

    //   if ((isError || isValidationError) && stepKey !== CurrentStep.SUMMARY) {
    //     acc.push({
    //       key: keyOfStrategy,
    //       element: (
    //         <span
    //           key={index}
    //           className="error-link"
    //           onClick={() => {
    //             setHighlighted(index);
    //           }}
    //         >
    //           {STEPS[index].title}
    //         </span>
    //       ),
    //     });
    //   }

    //   return acc;
    // }, []);

    return [];
  };

  const chooseRenderSummaryAlert = () => {
    const validationErrors = combineErrorsLinks();

    return validationErrors.length && isShowSummaryAlert ? (
      <div className="summary-alert">
        <AlertCustom
          type="error"
          message={
            <>
              Please fix errors in{" "}
              {validationErrors.map((el: any, index: number) => {
                if (!el) {
                  return null;
                }
                const { element } = el;
                return <React.Fragment key={index}>{element}, </React.Fragment>;
              })}
              sections
            </>
          }
          icon={<Error />}
          className="alert-custom-all-errors"
          closable={false}
        />
      </div>
    ) : null;
  };

  const handleCancel = () => {
    setShowDiscardPopup(true);
  };

  return (
    <>
      <div className="tabs-container">
        <DiscardPopup
          flag={isShowDiscardPopup}
          onClose={() => setShowDiscardPopup(false)}
          onOk={async () => {
            id && (await rollbackEditGroup(id));
            clearStrategyInStore();
            navigate(`${ROUTES.ADMIN_GROUPS_ID.replace(/\/:id$/, "")}/${id}`);
          }}
          onClickSaveChanges={async () => {
            id &&
              (await commitEditGroup(id).then(() => {
                navigate(
                  `${ROUTES.ADMIN_GROUPS_ID.replace(/\/:id$/, "")}/${id}`
                );
              }));

            clearStrategyInStore();
            setShowDiscardPopup(false);
          }}
        />
        <div className="tabs-list">
          {tabs.map((el, index) => (
            <div
              key={index}
              className={`tab-item ${
                activeTab === el.key ? "active" : ""
              } poz-r`}
              onClick={() => setActiveTab(el.key)}
            >
              <span className="tab-text">{el.label}</span>
              {el.error ? (
                <span className="error-icon">
                  <Error />
                </span>
              ) : el.warning ? (
                <span className="error-icon">
                  <Warning />
                </span>
              ) : null}
            </div>
          ))}
        </div>

        <div className="tab-content">{renderTabContent(activeTab)}</div>
      </div>
      {chooseRenderSummaryAlert()}
      <Footer
        handleCancel={handleCancel}
        isCorrectData={checkAlertsErrors()}
        handleBlurValidation={handleBlurValidation}
      />
    </>
  );
};

export default LayoutWithTabs;
