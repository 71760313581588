import { useGroupsStore } from "@entities/groups";

const Header = () => {
  const { currentGroup } = useGroupsStore();
  return (
    <>
      <div className="content__title df jc-sb ai-center">
        <div>
          <h1 className="">Edit {currentGroup?.name || ""}</h1>
        </div>

        <div className="edit-title__actions"></div>
      </div>
    </>
  );
};

export default Header;
