import {
  BadgeCustom,
  ButtonStyled,
  InputCustom,
  MultipleSelect,
} from "@shared/ui/components";
import { Close, Search } from "@shared/ui/assets";
import { FC, useEffect, useState } from "react";
import { Modal } from "antd";
import {
  AlertTarget,
  AlertType,
  CurrentStep,
  EGroupType,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import { useProductsStore } from "@entities/products";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  ungrouped,
} from "@shared/constants";
import { getItemWord, htmlToString } from "@shared/utils";
import useStrategy from "@shared/model/hooks/useStrategy";

interface IProps {
  setSearchValue: (str: string) => void;
  searchValue: string;
  currentBrands: string[];
  setCurrentBrands: (str: string[]) => void;
  setCurrentGroupsList: (str: string[]) => void;
  currentGroupsList: string[];
  setCurrentCategories: (str: string[]) => void;
  selectedCategories: string[];
  pagination: {
    currentLimit: number;
    currentOffset: number;
  };
  setCurrentLimit: (limit: number) => void;
  setCurrentOffset: (offset: number) => void;
}

const EditItemsPopupBulkAction: FC<IProps> = (props) => {
  const {
    searchValue,
    setSearchValue,
    currentBrands,
    setCurrentBrands,
    setCurrentCategories,
    selectedCategories,
    setCurrentGroupsList,
    setCurrentLimit,
    setCurrentOffset,
  } = props;

  const categories = useGroupsStore((state) => state.categories);
  const brands = useGroupsStore((state) => state.brands);
  const type = useGroupsStore((state) => state.strategy.strategyType);
  const groupsList = useGroupsStore((state) => state.groupsList);
  const {
    getCategories,
    removeProductsInGroup,
    addAlertToStore,
    updateStrategy,
  } = useGroupsStore((state) => state);
  const { productsInGroup } = useGroupsStore((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);

  const { setProductsSelectedIds } = useProductsStore((state) => state);

  const adaptedCategories = categories?.map((category) => ({
    label: <>{category.name}</>,
    value: category.id.toString(),
  }));

  const adaptedBrands = brands?.map((brand) => ({
    label: <>{brand.name}</>,
    value: brand.id.toString(),
  }));

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
  };

  const onChange = (value: string[]) => {
    setCurrentCategories(value);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setProductsSelectedIds([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleConfirmCancel = () => {
    setIsModalConfirmOpen(false);
  };

  const handleChange = (ids: string[]) => {
    onChange(ids);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setProductsSelectedIds([]);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
  };

  const handleChangeBrands = (ids: string[]) => {
    setCurrentBrands(ids);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setProductsSelectedIds([]);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
  };
  const handleChangeGroupsList = (ids: string[]) => {
    setCurrentGroupsList(ids);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setProductsSelectedIds([]);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
  };

  const textErrorItems =
    "Product items have been removed due to category change. Please add items to the group.";
  const textErrorStrategyRule =
    "Settings reset due to product items changes. Please review and reconfigure the Strategy Rule.";

  const handleConfirmOk = () => {
    removeProductsInGroup();
    setIsModalConfirmOpen(false);
  };

  const handleClear = () => {
    onChange([]);
    setProductsSelectedIds([]);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
  };
  const handleClearBrands = () => {
    setCurrentBrands([]);
    setProductsSelectedIds([]);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
  };

  const handleClearAll = () => {
    onChange([]);
    setCurrentBrands([]);
    setProductsSelectedIds([]);
    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
    setSearchValue("");
  };

  const isActiveClearAll =
    selectedCategories.length || currentBrands.length || searchValue;

  return (
    <>
      <Modal
        title={<div className="modal__title">Change Category</div>}
        open={isModalConfirmOpen}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        centered={true}
        closable={true}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={handleConfirmCancel}
              />

              <ButtonStyled
                text="Remove and change"
                type="primary"
                fill="red-600"
                htmlType="button"
                className="w-188"
                onClick={handleConfirmOk}
              />
            </div>
          </div>,
        ]}
      >
        <div className="confirm-modal-body">
          {`Changing the category will remove all ${
            productsInGroup.length
          } ${getItemWord(productsInGroup.length)} you have already added
            from the current category. All configuration on other steps will be
            reset. You cannot undo this action.`}
        </div>
      </Modal>

      <Modal
        title={<div className="modal__title">Change Category</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        closable={true}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={handleCancel}
              />

              <ButtonStyled
                text="Delete"
                type="primary"
                fill="red-600"
                htmlType="button"
                className="w-84"
                onClick={handleOk}
              />
            </div>
          </div>,
        ]}
      >
        <div className="confirm-modal-body">
          Changing categories will clear your current selection.
        </div>
      </Modal>

      <div className="ml-24 mr-24 items-bulk-block">
        <div className="items-bulk__container">
          <div className="mr-32">
            <InputCustom
              type="text"
              value={searchValue}
              name="items-search"
              placeholder="Search for items"
              onChangeInput={onSearchChange}
              fill="gray-primary-900"
              iconRight={<Search />}
            />
          </div>
          <div className={`items-select__container`}>
            <MultipleSelect
              options={adaptedCategories}
              value={selectedCategories}
              onChange={handleChange}
              handleClear={handleClear}
              placeholder="Category"
              className={`gray-primary-900 items-select__wrapper`}
              selectedItems={selectedCategories}
              selectedText={<div>Category ({selectedCategories.length})</div>}
            />
          </div>

          <div className={`items-select__container `}>
            <MultipleSelect
              options={adaptedBrands}
              value={currentBrands}
              onChange={handleChangeBrands}
              placeholder="Band"
              className={`gray-primary-900 items-select__wrapper`}
              selectedItems={currentBrands}
              selectedText={<div>Brand ({currentBrands.length})</div>}
              handleClear={handleClearBrands}
            />
          </div>
          {/* <div className={`items-select__container `}>
              <MultipleSelect
                mainOption={ungroupedOption}
                options={adaptedGroupsList}
                value={currentGroupsList}
                onChange={handleChangeGroupsList}
                placeholder="Groups"
                className={`gray-primary-900 items-select__wrapper`}
                selectedItems={currentGroupsList}
                selectedText={<div>Group ({currentGroupsList.length})</div>}
                handleClear={handleClearGroups}
              />
            </div> */}
          {isActiveClearAll ? (
            <div className="items-bulk__clear" onClick={handleClearAll}>
              <Close />
              <span>Clear all</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EditItemsPopupBulkAction;
