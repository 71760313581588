interface IProps {
  color?: string;
}

const CheckComplete = ({ color = "#F8F9FA" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.0619 7.93775C46.2016 8.07709 46.3125 8.24262 46.3881 8.42485C46.4637 8.60709 46.5026 8.80245 46.5026 8.99975C46.5026 9.19705 46.4637 9.39242 46.3881 9.57465C46.3125 9.75689 46.2016 9.92241 46.0619 10.0618L25.0619 31.0618C24.9226 31.2014 24.7571 31.3123 24.5748 31.3879C24.3926 31.4635 24.1972 31.5024 23.9999 31.5024C23.8026 31.5024 23.6073 31.4635 23.425 31.3879C23.2428 31.3123 23.0773 31.2014 22.9379 31.0618L13.9379 22.0618C13.7985 21.9223 13.6878 21.7567 13.6124 21.5745C13.5369 21.3923 13.498 21.197 13.498 20.9998C13.498 20.8025 13.5369 20.6072 13.6124 20.425C13.6878 20.2428 13.7985 20.0772 13.9379 19.9377C14.0774 19.7983 14.243 19.6877 14.4252 19.6122C14.6074 19.5367 14.8027 19.4979 14.9999 19.4979C15.1972 19.4979 15.3925 19.5367 15.5747 19.6122C15.7569 19.6877 15.9225 19.7983 16.0619 19.9377L23.9999 27.8787L43.9379 7.93775C44.0773 7.79806 44.2428 7.68723 44.425 7.61161C44.6073 7.53599 44.8026 7.49707 44.9999 7.49707C45.1972 7.49707 45.3926 7.53599 45.5748 7.61161C45.7571 7.68723 45.9226 7.79806 46.0619 7.93775Z"
      fill="#198754"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 7.50033C20.7366 7.50033 17.5465 8.46804 14.8331 10.2811C12.1197 12.0941 10.0049 14.6711 8.75602 17.6861C7.50717 20.701 7.18041 24.0186 7.81707 27.2193C8.45373 30.42 10.0252 33.36 12.3328 35.6676C14.6403 37.9752 17.5804 39.5466 20.781 40.1833C23.9817 40.8199 27.2993 40.4932 30.3143 39.2443C33.3293 37.9955 35.9062 35.8807 37.7193 33.1672C39.5323 30.4538 40.5 27.2637 40.5 24.0003C40.5 23.6025 40.6581 23.221 40.9394 22.9397C41.2207 22.6584 41.6022 22.5003 42 22.5003C42.3979 22.5003 42.7794 22.6584 43.0607 22.9397C43.342 23.221 43.5 23.6025 43.5 24.0003C43.4995 28.2928 42.0826 32.4652 39.4691 35.8704C36.8556 39.2756 33.1915 41.7233 29.0452 42.8339C24.8988 43.9445 20.5019 43.6559 16.5363 42.0129C12.5706 40.3699 9.25792 37.4643 7.11191 33.7467C4.96589 30.0292 4.10649 25.7074 4.66698 21.4516C5.22747 17.1959 7.17653 13.244 10.2119 10.2088C13.2472 7.17364 17.1993 5.22483 21.4551 4.66461C25.7109 4.1044 30.0326 4.96408 33.75 7.11033C33.9293 7.20411 34.0877 7.33317 34.2158 7.48976C34.3439 7.64635 34.439 7.82724 34.4953 8.02153C34.5517 8.21582 34.5682 8.41951 34.5438 8.62034C34.5194 8.82116 34.4546 9.01498 34.3534 9.19013C34.2522 9.36527 34.1165 9.51813 33.9547 9.63951C33.7928 9.76088 33.6081 9.84826 33.4116 9.89639C33.2151 9.94452 33.0109 9.95241 32.8113 9.91957C32.6117 9.88674 32.4208 9.81387 32.25 9.70533C29.7427 8.25541 26.8964 7.49467 24 7.50033Z"
      fill="#198754"
    />
  </svg>
);

export default CheckComplete;
