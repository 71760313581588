import { FC, useState, useEffect } from "react";
import { IFormCode } from "@shared/interfaces";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { GetProps, Input } from "antd";
import { useFormik } from "formik";
import { validationSchema } from "./validations/verifyFormValidation";
import { useUserStore } from "@entities/user";
import "./verifyForm.scss";
import { useSearchParams } from "react-router-dom";

interface IProps {
  onContinueClick: (obj: any) => void;
}

const baseTimerNum = 59;

type OTPProps = GetProps<typeof Input.OTP>;

const VerifyForm: FC<IProps> = ({ onContinueClick }) => {
  const [searchParams] = useSearchParams();
  const { checkVerifyCodeSignUp, resendCodeSignUp } = useUserStore(
    (state) => state
  );
  const { openNotification, contextHolder } = NotificationCustom();
  const [responseErrorSignUpText, setResponseErrorSignUpText] = useState("");
  const [isShowTimer, setShowTimer] = useState(
    !!localStorage.getItem("resendTimer")
  );
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (isShowTimer) {
      startTimer();
    }
  }, [isShowTimer]);

  const formik = useFormik<IFormCode>({
    initialValues: {
      code: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        const data = await checkVerifyCodeSignUp(
          values.code,
          searchParams.get("email") || ""
        );

        if (data.id) {
          onContinueClick({
            step: SIGNUP_STEPS.SET_PASSWORD,
            token: data.verificationCode,
          });
        }
      } catch (error: any) {
        setResponseErrorSignUpText(error.response.data.message);
        openNotification({
          message: error.response.data.message,
          type: "default",
          duration: 5,
        });
      }
    },
  });

  const { setFieldValue, errors, values } = formik;

  const onInput = (value: any) => {
    setFieldValue("code", value.join(""));
  };

  const sharedProps: OTPProps = {
    onInput,
  };

  const resendCode = () => {
    try {
      resendCodeSignUp(searchParams.get("email") || "");
      localStorage.setItem("resendTimer", Date.now().toString());
      setShowTimer(true);
      setSeconds(0);
      openNotification({
        message: "Another code was sent to your email",
        type: "default",
        duration: 5,
      });
    } catch (error) {}
  };

  const getElapsedSeconds = () => {
    const time = localStorage.getItem("resendTimer");
    if (!time) return 0;
    const timestamp = Number(time);
    const now = Date.now();
    return Math.floor((now - timestamp) / 1000);
  };

  const startTimer = () => {
    setSeconds(getElapsedSeconds());

    const timer = setInterval(() => {
      setSeconds((prev) => {
        const newSeconds = prev + 1;
        if (newSeconds > baseTimerNum - 1) {
          clearInterval(timer);
          setShowTimer(false);
          localStorage.removeItem("resendTimer");
        }
        return newSeconds;
      });
    }, 1000);
  };

  const viewSeconds =
    baseTimerNum - seconds >= 10
      ? baseTimerNum - seconds
      : "0" + (baseTimerNum - seconds);

  return (
    <div>
      {contextHolder}
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
        className="verify-form_wrapper"
      >
        <h4 className="verify__subtext">6-digit verification code</h4>
        <div
          className={
            errors.code || responseErrorSignUpText
              ? "verify-code__container-error"
              : ""
          }
        >
          <Input.OTP formatter={(str) => str.toUpperCase()} {...sharedProps} />
        </div>

        <div className="error-verify">
          {errors.code || responseErrorSignUpText ? (
            <>{responseErrorSignUpText || errors.code}</>
          ) : null}
        </div>

        <ButtonStyled
          text="Verify email"
          className="verify__btn"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />

        <h4 className="verify-form__text verify__subtext-styling">
          Didn’t receive a code?
        </h4>
        <div className="verify-form__resend mt-4">
          <div
            onClick={resendCode}
            className={
              isShowTimer ? "disabled-no-effect clicked" : "custom-style"
            }
          >
            Click to resend
          </div>

          {isShowTimer && (
            <div className="verify-form__timer clicked-timer">
              &nbsp;in 0:{viewSeconds}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default VerifyForm;
