import { useGroupStore } from "@entities/group";
import { LayoutWithAside } from "@widgets/admin";
import Header from "@widgets/admin/groups/ui/EditGroup/Layouts/Header/Header";
import LayoutWithTabs from "@widgets/admin/groups/ui/EditGroup/Layouts/LayoutWithTabs/LayoutWithTabs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EditGroupPage = () => {
  const { id } = useParams();

  const { getGroup } = useGroupStore();

  useEffect(() => {
    id && getGroup(id);
  }, [id]);

  return (
    <>
      <LayoutWithAside>
        <>
          <Header />
          <LayoutWithTabs />
        </>
      </LayoutWithAside>
    </>
  );
};

export default EditGroupPage;
