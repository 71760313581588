import { useEffect, useState } from "react";
import "./groupItems.scss";

import { FilterItem } from "@features/filterItem";
import { useParams, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  SortParams,
  IFilterItem,
  ISortParams,
  IDataTransfer,
} from "@shared/interfaces";
import {
  AlertCustom,
  InputCustom,
  LoaderItemsTable,
  MultipleSelect,
} from "@shared/ui/components";
import { AlertTarget, EGroupStatus, NEW_REPRICE_READY } from "@entities/groups";
import { useUserSettingsStore } from "@entities/userSettings";
import {
  BRAND_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  SEARCH_PARAM,
  GROUP_ID_PARAM,
  ORDER_BY_PARAM,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  DEFAULT_OFFSET_PAGINATION,
  DEFAULT_LIMIT_PAGINATION,
} from "@shared/constants";
import { Search, Warning } from "@shared/ui/assets";
import { filteredCompareQueryObj, getItemWord } from "@shared/utils";
import { ItemInfo } from "../../ItemInfo";
import TableItems from "../../ItemInfo/ui/TableItems/TableItems";
import { useGroupStore } from "@entities/group";

const allDefault = "All";

const GroupItems = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [currentBrand, setCurrentBrand] = useState<string[]>([]);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [isInfoDrawerShow, setShowInfoDrawer] = useState<boolean>(false);

  const {
    productsInGroup,
    getProductsInGroup,
    brandsOfGroup,
    isLoading,
    getBrandsInGroup,
    setProductsInGroup,
    setBrands,
    seTisInitialLoadingGroupItems,
    isInitialLoadingGroupItems,
    getCountNewPriceReady,
    closeAlertByGroupId,
  } = useGroupStore((state) => state);
  const [tableWidth, setTableWidth] = useState<number>(500);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);

  const { currentGroup } = useGroupStore((state) => state);
  const { productsNewRepriceReady } = useGroupStore((state) => state);

  const { asideCollapsed } = useUserSettingsStore((state) => state);
  const status = useGroupStore((state) => state.currentGroup.status);
  const adaptedBrands = brandsOfGroup?.map((brand: any) => ({
    label: <>{brand.name}</>,
    value: brand.name,
  }));

  useEffect(() => {
    const brandFromUrl = searchParams.get("brand");
    const searchUrl = searchParams.get("search");
    const filterUrl = searchParams.get("filter");
    if (brandFromUrl) {
      setCurrentBrand(brandFromUrl.split(","));
    }
    if (searchUrl) {
      setInputValue(searchUrl);
    }
    if (filterUrl) {
      setActiveFilter(filterUrl);
    } else {
      setActiveFilter(allDefault);
    }
  }, [searchParams]);

  const brandsCompare = [...adaptedBrands];

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams;
  }>({
    key: "",
    direction: SortParams.ASC,
  });

  useEffect(() => {
    getCountNewPriceReady(id || "");
    return () => {
      setProductsInGroup([]);
      seTisInitialLoadingGroupItems(true);
    };
  }, []);

  useEffect(() => {
    const chooseActiveFilter = activeFilter === allDefault ? "" : activeFilter;

    const data = {
      [GROUP_ID_PARAM]: id,
      [BRAND_PARAM]: currentBrand,
      [LIMIT_PARAM]: currentLimit,
      [SEARCH_PARAM]: searchValue,
      [OFFSET_PARAM]: currentOffset,
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [FILTER_BY_PARAM]: chooseActiveFilter,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;

    getProductsInGroup(filteredData);
    setShowInfoDrawer(false);

    return () => {
      setProductsInGroup([]);
    };
  }, [
    sortConfig,
    currentLimit,
    currentOffset,
    currentBrand,
    searchValue,
    activeFilter,
    id,
  ]);

  useEffect(() => {
    getBrandsInGroup();
    return () => {
      setBrands([]);
    };
  }, [currentGroup.id]);

  useEffect(() => {
    const drawerCloseWidth = 72;
    const drawerOpenWidth = 248;

    if (asideCollapsed) {
      setTableWidth(
        window.innerWidth - drawerOpenWidth - window.innerWidth / 100 - 8 - 30
      );
    } else {
      setTableWidth(window.innerWidth - drawerCloseWidth - 60);
    }
  }, [asideCollapsed, productsInGroup]);

  const filterItems: IFilterItem[] = [
    {
      name: allDefault,
      value: allDefault,
      className: "",
    },
    {
      name: `New price ready (${productsNewRepriceReady})`,
      value: NEW_REPRICE_READY,
      className: "filter__new-price-ready",
    },
  ];

  const onSetQueryParams = (values: { [key: string]: string }) => {
    const updatedParams = new URLSearchParams(searchParams);

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, value);
      } else {
        updatedParams.delete(key);
      }
    });
    setSearchParams(updatedParams);
  };

  const handleBrandChange = (values: string[], setSearchParams: any) => {
    onSetQueryParams({ brand: values.join(",") });
  };

  const onChangeBrand = (values: string[]) => {
    setCurrentBrand(values);
    handleBrandChange(values, setSearchParams);
  };

  const debounced = useDebouncedCallback(async (value) => {
    setSearchValue(value);
    onSetQueryParams({ search: value });
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    debounced(value);
  };

  const onChangeFilter = (value: string) => {
    setActiveFilter(value);
    setActiveProductId("");
    onSetQueryParams({ filter: value });
  };

  const isProcessStatus = status === EGroupStatus.IN_PROGRESS;
  const isShowAlert =
    currentGroup?.alerts &&
    currentGroup?.alerts?.length > 0 &&
    currentGroup.alerts.some(
      (el) => el.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES && el.isShow
    );
  return (
    <div className="group-items">
      {isShowAlert ? (
        <div className="group-page-alert">
          <AlertCustom
            type="warning"
            message={
              currentGroup.alerts?.find(
                (el) => el.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES
              )?.message
            }
            onClose={() => {
              id &&
                closeAlertByGroupId(
                  id,
                  AlertTarget.GROUP_NEGATIVE__NEW_PRICES,
                  false
                );
            }}
            closable={true}
            icon={<Warning />}
          />
        </div>
      ) : null}
      {!isInitialLoadingGroupItems ? (
        <>
          <div className={isProcessStatus ? "disabled-no-effect" : ""}>
            {isInfoDrawerShow && (
              <ItemInfo
                onClose={(flag: boolean) => {
                  setShowInfoDrawer(flag);
                  setActiveProductId("");
                }}
                productId={activeProductId}
              />
            )}
            {isLoading ? <LoaderItemsTable /> : null}
            <div className="df mb-16">
              <div className="mr-32">
                <InputCustom
                  type="text"
                  value={inputValue}
                  name="group-search"
                  placeholder="Search for items"
                  onChangeInput={onSearchChange}
                  fill="gray-primary-900"
                  iconRight={<Search />}
                />
              </div>
              <div className="group-items__filters">
                {filterItems.map((item) => (
                  <FilterItem
                    item={item}
                    key={item.value}
                    active={activeFilter}
                    onClick={(value) => onChangeFilter(value)}
                    className={item.className}
                  />
                ))}
              </div>
              <div>
                <div className={`group-items-select__container`}>
                  <MultipleSelect
                    selectedItems={currentBrand}
                    options={brandsCompare}
                    onChange={onChangeBrand}
                    className={"multiselect_brands"}
                    selectedText={<div>Brands ({currentBrand.length})</div>}
                    placeholder="Brands"
                    handleClear={() => {
                      setCurrentBrand([]);
                      handleBrandChange([], setSearchParams);
                    }}
                  />
                </div>
              </div>
            </div>
            <TableItems
              setSortConfig={setSortConfig}
              tableWidth={tableWidth}
              setShowInfoDrawer={setShowInfoDrawer}
              setActiveProductId={setActiveProductId}
              sortConfig={sortConfig}
              activeProductId={activeProductId}
              currentBrand={currentBrand}
              setCurrentLimit={setCurrentLimit}
              setCurrentOffset={setCurrentOffset}
              currentOffset={currentOffset}
              currentLimit={currentLimit}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default GroupItems;
