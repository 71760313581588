import { ROUTES } from "@shared/interfaces";
import CheckComplete from "@shared/ui/assets/CheckComplete";
import { ButtonStyled } from "@shared/ui/components";
import { useNavigate } from "react-router-dom";

const Complete = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="df ai-center jc-c">
        <CheckComplete />
      </div>
      <div className="mt-24 mb-24">
        <div className="title">Password reset</div>
        <div className="text ">
          Your password has been successfully reset. Click below to log in
          magically.
        </div>
      </div>
      <ButtonStyled
        text="Continue"
        className="setpass__btn"
        type="primary"
        fill="gray-primary-900"
        htmlType="button"
        onClick={() => (window.location.href = ROUTES.ADMIN_GROUPS)}
      />
    </div>
  );
};

export default Complete;
