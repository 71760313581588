import {
  ButtonStyled,
  InputWithIcons,
  NotificationCustom,
} from "@shared/ui/components";
import { LayoutWithAside } from "@widgets/admin";
import "./profile.scss";
import { Divider } from "antd";
import { useUserStore } from "@entities/user";
import { useFormik } from "formik";
import DeleteAccountPopupByDefault from "../DeleteAccountPopupByDefault/DeleteAccountPopupByDefault";
import { useState } from "react";
import DeleteAccountPopupByOwner from "../DeleteAccountPopupByOwner/DeleteAccountPopupByOwner";
import { UserRolesEnum } from "@entities/settings";
import { getValidationFullNameSchema, getValidationSchema } from "./validation";

const initialValues = {
  confirmEmail: "",
};

export const ProfilePage = () => {
  const user = useUserStore((state) => state.user);
  const { changePersonalInfo, deleteAccountByDefault } = useUserStore(
    (state) => state
  );
  const [isOpenDefaultDeleteModal, setOpenDefaultDeleteModal] = useState(false);
  const [isOpenDefaultDeleteModalOwner, setOpenDefaultDeleteModalOwner] =
    useState(false);
  const [isChangedFullName, setIsChangedFullName] = useState(false);
  const { contextHolder, openNotification } = NotificationCustom();

  const inputsOptions = [
    {
      label: "Full name",
      value: user.firstName + " " + user.lastName,
      isInput: true,
      addingClasses: "mb-36",
    },
    {
      label: "Email",
      value: user.email,
      isInput: false,
      addingClasses: "mb-36",
    },
    {
      label: "Language",
      value: "English",
      isInput: false,
      addingClasses: "mb-36",
    },
  ];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(user.email || ""),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        if (
          user.role === UserRolesEnum.OWNER ||
          user.role === UserRolesEnum.SU_ADMIN
        ) {
          return setOpenDefaultDeleteModalOwner(true);
        }

        setOpenDefaultDeleteModal(true);
      } catch (error: any) {
        console.log(error, "error");
      }
    },
  });

  const formikFullName = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      fullName: user.firstName + " " + user.lastName,
    },
    validationSchema: getValidationFullNameSchema,
    onSubmit: async (values) => {
      const fullName = values.fullName;

      const [firstName, lastName] = fullName.split(" ");

      await changePersonalInfo({ firstName, lastName });
      openNotification({
        message: "Full name changed successfully",
        type: "success",
      });
    },
  });

  const { setFieldValue, values, errors } = formik;

  const handleOk = async () => {
    return await deleteAccountByDefault();
  };

  return (
    <>
      {contextHolder}
      <DeleteAccountPopupByDefault
        onOk={handleOk}
        flag={isOpenDefaultDeleteModal}
        onClose={() => setOpenDefaultDeleteModal(false)}
      />
      <DeleteAccountPopupByOwner
        onOk={() => setOpenDefaultDeleteModalOwner(false)}
        flag={isOpenDefaultDeleteModalOwner}
        onClose={() => setOpenDefaultDeleteModalOwner(false)}
      />
      <LayoutWithAside>
        <>
          <h1 className="content__title pl-24 pt-30 pb-30">Personal Info</h1>

          <div className="form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formikFullName.handleSubmit();
              }}
              className="pl"
            >
              {inputsOptions.map((el) => {
                return (
                  <div
                    className={`form-block__item ${el.addingClasses}`}
                    key={el.label}
                  >
                    <div className="form-block-label">{el.label}</div>
                    {el.isInput ? (
                      <>
                        <InputWithIcons
                          wrapperClassName={`${
                            formikFullName.errors.fullName
                              ? "error-validation-input"
                              : ""
                          } form-block__input-block`}
                          classNameInput={`form-block__input`}
                          value={formikFullName.values.fullName || ""}
                          onChange={(value) => {
                            setIsChangedFullName(true);
                            formikFullName.setFieldValue("fullName", value);
                            formikFullName.setErrors({});
                          }}
                        />
                        {formikFullName.errors.fullName && (
                          <span className="error-validation-message-fullname">
                            {formikFullName.errors.fullName}
                          </span>
                        )}
                      </>
                    ) : (
                      //formikFullName.errors
                      <div className="form-block__disabled">{el.value}</div>
                    )}
                  </div>
                );
              })}
              <ButtonStyled
                text="Save"
                type="primary"
                htmlType="submit"
                className="w-84 del-account-btn mb-44"
                disabled={!isChangedFullName}
              />
            </form>
            <Divider />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div>
                <div className="form-delAccount-block">
                  <div className="form-delAccount-block__title">
                    Delete Account
                  </div>
                  <div className="form-delAccount-block__subtitle">
                    Once you delete your account and account data, there is no
                    going back
                  </div>

                  <div className={`form-block__item ${"mb-24 mt-24"} pl`}>
                    <div className="form-block-label">Confirm email </div>

                    <InputWithIcons
                      wrapperClassName={`${
                        errors.confirmEmail ? "error-validation-input" : ""
                      } `}
                      classNameInput={`form-block__input `}
                      value={values.confirmEmail || ""}
                      onChange={(value) => {
                        setFieldValue("confirmEmail", value);
                        formik.setErrors({});
                      }}
                    />
                    {errors.confirmEmail && (
                      <span className="error-validation-message-del-acc">
                        {errors.confirmEmail}
                      </span>
                    )}
                  </div>
                  <ButtonStyled
                    text="Delete account"
                    type="default"
                    htmlType="submit"
                    className="w-150 del-account-btn"
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      </LayoutWithAside>
    </>
  );
};

export default ProfilePage;
