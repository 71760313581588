import { useUserStore } from "@entities/user";
import { SING_IN_STEP } from "@shared/constants";
import { LoaderDefault } from "@shared/ui/components";
import {
  AuthWrapper,
  SignupPersonalInfoWidget,
  SignupSetPassWidget,
  SignupVerifyWidget,
  SignupWelcomeWidget,
} from "@widgets/auth/index";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const SignInPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { checkActualInviteUser } = useUserStore((state) => state);

  useEffect(() => {
    if (!searchParams.get(SING_IN_STEP)) {
      setSearchParams((prev) => {
        prev.set(SING_IN_STEP, SIGNUP_STEPS.BASE);
        return new URLSearchParams(prev);
      });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    // const email = searchParams.get("email");
    // const token = searchParams.get("token");
    // email &&
    //   token &&
    //   checkActualInviteUser({
    //     email,
    //     token,
    //   });
  }, []);

  const onContinueClick = (values: { [key: string]: string }) => {
    const updatedParams = new URLSearchParams(searchParams);

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, value);
      } else {
        updatedParams.delete(key);
      }
    });
    setSearchParams(updatedParams);
  };

  const renderStage = () => {
    switch (searchParams.get(SING_IN_STEP)) {
      case SIGNUP_STEPS.BASE:
        return <SignupWelcomeWidget onContinueClick={onContinueClick} />;

      case SIGNUP_STEPS.CHECK_CODE:
        return <SignupVerifyWidget onContinueClick={onContinueClick} />;

      case SIGNUP_STEPS.SET_PASSWORD:
        return <SignupSetPassWidget onContinueClick={onContinueClick} />;

      case SIGNUP_STEPS.SET_PERSONAL_INFO:
        return <SignupPersonalInfoWidget onContinueClick={onContinueClick} />;

      default:
        return <LoaderDefault />;
    }
  };

  return <AuthWrapper>{renderStage()}</AuthWrapper>;
};

export default SignInPage;
