import { ArrowBorder, Close, Delete } from "@shared/ui/assets";
import { Divider, Modal, Select } from "antd";
import { FC, useEffect, useState } from "react";
import "./itemException.scss";
import {
  CurrentStep,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import { ButtonStyled, InputWithIcons } from "@shared/ui/components";
import { isValidLimitPriceValue, isValidLimitValue } from "@shared/utils";
import useValidationStepper from "@shared/model/hooks/useValidationSteper";

interface IProps {
  product: any;
  handleBlurValidation: (price?: SelectLimitPricing) => void;
  deleteIsOpenSelectParam: () => void;
}

const optionsExceptionSelect = [
  {
    label: "Current price ($)",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
];

const ItemException: FC<IProps> = ({
  product,
  handleBlurValidation,
  deleteIsOpenSelectParam,
}) => {
  const [activeOption, setActiveOption] = useState(
    product?.exceptionLimits?.pricing || ""
  );

  const [activeProduct, setActiveProduct] = useState(product);

  useEffect(() => {
    setActiveProduct(product);
  }, [product]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    removeExceptionById,
    getExceptions,
    editException,
    saveExceptionChange,
    validationErrors,
    setValidationError,
  } = useGroupsStore((state) => state);
  const { getStrategy } = useGroupsStore((state) => state);
  const [validationErrorsLocal, setValidationErrorsLocal] = useState<any>({});

  useEffect(() => {
    setValidationErrorsLocal(validationErrors);
  }, [validationErrors]);

  const handleChangeExceptionsSelect = (el: SelectLimitPricing) => {
    editException(activeProduct.id, { pricing: el }, "select");
    setActiveOption(el);
    saveExceptionChange(activeProduct.id);
    const validationLimits = {
      ...validationErrorsLocal[CurrentStep.PRICE_LIMITS],
    };

    if (validationLimits.exceptionProducts) {
      delete validationLimits.exceptionProducts[activeProduct.id];

      if (Object.keys(validationLimits.exceptionProducts).length) {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            ...validationErrorsLocal[CurrentStep.PRICE_LIMITS],
            exceptionProducts: validationLimits.exceptionProducts,
          },
        });
      } else {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            ...validationErrorsLocal[CurrentStep.PRICE_LIMITS],
            exceptionProducts: null,
          },
        });
      }
    }
  };

  const setActiveClassName = () => {
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return "groups-price-limits__margin-select";
      case SelectLimitPricing.CURRENT_PRICE:
        return "groups-price-limits__current-price-select";
    }
  };

  const getTextError = (key: string) => {
    const isMaxInputErrorRange =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts?.[
        activeProduct.id
      ]?.rangeValidation;

    const isEmptyFieldsErrorValue =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts?.[
        activeProduct.id
      ][key]?.value;

    const isZeroFieldErrorValueMin =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts?.[
        activeProduct.id
      ].isZeroMin;

    const isZeroFieldErrorValueMax =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts?.[
        activeProduct.id
      ].isZeroMax;

    if (isMaxInputErrorRange) {
      return `Logical mismatch.`;
    }
    if (isEmptyFieldsErrorValue) {
      return `Please enter a value.`;
    }

    if (isZeroFieldErrorValueMin && isZeroFieldErrorValueMax) {
      return `Value cannot be zero.`;
    }
    if (isZeroFieldErrorValueMin && key === "min") {
      return `Value cannot be zero.`;
    }
    if (isZeroFieldErrorValueMax && key === "max") {
      return `Value cannot be zero.`;
    }
    return "";
  };

  const setActiveModeToChangeRender = () => {
    const min = activeProduct?.exceptionLimits?.min;
    const max = activeProduct?.exceptionLimits?.max;
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="chooser">
                <div className="df jc-c poz-r">
                  <InputWithIcons
                    wrapperClassName={`${isErrorClassInput("min")}`}
                    onChange={(value: string) => {
                      handleChangeExceptionsInput("min", value);
                    }}
                    rightIcon={<>%</>}
                    classNameInput={`margin-input-exception ml-8 `}
                    value={min?.value || ""}
                    placeholder={"Min"}
                    onBlur={() => handleBlur("min")}
                  />

                  {isErrorInput("min") && (
                    <div className=" error-validation-message-exception">
                      {getTextError("min")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="groups-price-limits__choose-block-option">
              <div className="chooser">
                <div className="df jc-c poz-r">
                  <InputWithIcons
                    wrapperClassName={`${isErrorClassInput("max")}`}
                    onChange={(value: string) => {
                      handleChangeExceptionsInput("max", value);
                    }}
                    rightIcon={<>%</>}
                    classNameInput="margin-input-exception ml-8"
                    value={max?.value || ""}
                    placeholder={"Max"}
                    onBlur={() => handleBlur("max")}
                  />
                  {isErrorInput("max") && (
                    <div className=" error-validation-message-exception">
                      {getTextError("max")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case SelectLimitPricing.CURRENT_PRICE:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="chooser">
                <div className="df poz-r">
                  <InputWithIcons
                    wrapperClassName={`${isErrorClassInput("min")} `}
                    onChange={(value: string) =>
                      handleChangeExceptionsInput("min", value)
                    }
                    rightIcon={<>$</>}
                    classNameInput="currentPrice-input-exception"
                    value={min?.value || ""}
                    onBlur={() => {
                      handleBlur("min");
                    }}
                    placeholder={"Min"}
                  />
                  {isErrorInput("min") && (
                    <div className=" error-validation-message-exception">
                      {getTextError("min")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="groups-price-limits__choose-block-option">
              <div className="chooser">
                <div className="df poz-r">
                  <InputWithIcons
                    wrapperClassName={`${isErrorClassInput("max")} `}
                    onChange={(value: string) =>
                      handleChangeExceptionsInput("max", value)
                    }
                    rightIcon={<>$</>}
                    classNameInput="currentPrice-input-exception"
                    value={max?.value || ""}
                    onBlur={() => handleBlur("max")}
                    placeholder={"Max"}
                  />
                  {isErrorInput("max") && (
                    <div className=" error-validation-message-exception">
                      {getTextError("max")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const handleRemoveEvent = async () => {
    await removeExceptionById(activeProduct.id);
    await getExceptions();
    setIsOpenModal(false);
    const currentValidationErrors =
      useGroupsStore.getState().validationErrors[CurrentStep.PRICE_LIMITS];

    const copyExceptionProducts: { [key: string]: any } = {
      ...currentValidationErrors?.exceptionProducts,
    };
    if (copyExceptionProducts?.[activeProduct.id]) {
      if (copyExceptionProducts?.hasOwnProperty(activeProduct.id)) {
        const descriptor = Object.getOwnPropertyDescriptor(
          copyExceptionProducts,
          activeProduct.id
        );

        if (descriptor?.configurable) {
          delete copyExceptionProducts[activeProduct.id];
        } else {
          console.warn(`Свойство ${product.id} не может быть удалено`);
        }
      }

      if (!Object.keys(copyExceptionProducts).length) {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: null,
          },
        });
      } else {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: copyExceptionProducts,
          },
        });
      }
    }
    getStrategy();
  };

  const handleChangeExceptionsInput = (key: string, value: string) => {
    if (value.includes(",")) {
      value = value.replace(",", ".");
    }
    const isValid = isValidLimitValue(value);
    const isValidPrice = isValidLimitPriceValue(value);
    if (isValid && activeOption === SelectLimitPricing.MARGIN) {
      editException(activeProduct.id, {
        [key]: { ...activeProduct?.exceptionLimits[key], value },
      });
    }
    if (isValidPrice && activeOption === SelectLimitPricing.CURRENT_PRICE) {
      editException(activeProduct.id, {
        [key]: { ...activeProduct?.exceptionLimits[key], value },
      });
    }

    const validationLimits = { ...validationErrors[CurrentStep.PRICE_LIMITS] };

    if (validationLimits.exceptionProducts) {
      delete validationLimits.exceptionProducts[activeProduct.id];
      if (Object.keys(validationLimits.exceptionProducts).length === 0) {
        delete validationErrors?.[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
        setValidationError({
          [CurrentStep.PRICE_LIMITS]:
            validationErrors[CurrentStep.PRICE_LIMITS],
        });
      } else {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            ...validationErrors[CurrentStep.PRICE_LIMITS],
            exceptionProducts: validationLimits.exceptionProducts,
          },
        });
      }
    }
  };

  const isErrorClassSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[activeProduct.id]?.pricing) {
      return "error-validation-select";
    }

    return "";
  };

  const isErrorClassInput = (key: string) => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.rangeValidation) {
      return "error-validation-input";
    }
    if (exceptionProducts && exceptionProducts[product.id]?.[key]?.value) {
      return "error-validation-input";
    }
    if (
      exceptionProducts &&
      exceptionProducts[product.id]?.isZeroMin &&
      exceptionProducts[product.id]?.isZeroMax
    ) {
      return "error-validation-input";
    }
    if (
      exceptionProducts &&
      exceptionProducts[product.id]?.isZeroMin &&
      key === "min"
    ) {
      return "error-validation-input";
    }
    if (
      exceptionProducts &&
      exceptionProducts[product.id]?.isZeroMax &&
      key === "max"
    ) {
      return "error-validation-input";
    }

    return "";
  };

  const isErrorInput = (key: string) => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (
      exceptionProducts &&
      exceptionProducts[activeProduct.id]?.rangeValidation
    ) {
      return true;
    }
    if (
      exceptionProducts &&
      exceptionProducts[activeProduct.id]?.[key]?.value
    ) {
      return true;
    }
    if (
      exceptionProducts &&
      exceptionProducts[activeProduct.id]?.isZeroMin &&
      exceptionProducts[activeProduct.id]?.isZeroMax
    ) {
      return true;
    }
    if (exceptionProducts && exceptionProducts[activeProduct.id]?.isZeroMin) {
      return true;
    }
    if (exceptionProducts && exceptionProducts[activeProduct.id]?.isZeroMax) {
      return true;
    }

    return false;
  };

  const isErrorSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (!exceptionProducts) {
      return false;
    }

    if (exceptionProducts[activeProduct.id]?.pricing) {
      return true;
    }
    return false;
  };

  const onClose = () => setIsOpenModal(false);

  const onOk = () => {
    handleRemoveEvent();
  };

  const renderConfirmModalRemoveException = () => {
    return (
      <Modal
        width={500}
        open={isOpenModal}
        onOk={() => {}}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => onClose()}
      >
        <div>
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal-remove-competitor-title">Remove Custom Limit</h2>
          <Divider />
        </div>
        <div className="modal__remove-competitor-content ">
          <div>Are you sure you want to remove the custom price limit?</div>
          <div>
            This item will follow the main price limit rule after removal.
          </div>
        </div>
        <Divider />
        <div className="modal__remove-competitor-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={() => onClose()}
            />
            <ButtonStyled
              text="Remove"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={() => onOk()}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const handleBlur = async (key: string) => {
    let value = activeProduct.exceptionLimits[key]?.value;
    if (value && !value.includes(".")) {
      value = `${value}.00`;
    } else if (value && value.split(".")[1].length === 0) {
      value = `${value}00`;
    } else if (value && value.split(".")[1].length === 1) {
      value = `${value}0`;
    }
    editException(activeProduct.id, {
      [key]: { ...activeProduct?.exceptionLimits[key], value },
    });
    await saveExceptionChange(activeProduct.id);
    handleBlurValidation();
  };

  const [isOpenSelect, setIsOpenSelect] = useState(true);

  useEffect(() => {
    !isOpenSelect && handleBlurValidation(activeOption);
  }, [activeOption, isOpenSelect]);

  return (
    <div>
      {renderConfirmModalRemoveException()}
      <div className="groups-price-limits__product-for-exception">
        <div className="trash-icon" onClick={() => setIsOpenModal(true)}>
          <Delete color="#6C757D" />
        </div>
        <div className="groups-price-limits__product">
          <div className="groups-price-limits__product-info">
            <div>
              {activeProduct.imageUrl && (
                <img
                  alt="img-url"
                  className="product__img"
                  src={activeProduct.imageUrl}
                />
              )}
            </div>
            <div className="groups-price-limits__product-name">
              <span className="groups-price-limits__article">
                {activeProduct.article}
              </span>
              <span className="groups-price-limits__name">
                {activeProduct.name}
              </span>
            </div>
          </div>

          <div className="groups-price-limits__product-actions ">
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">Cost</div>
              <div className="groups-price-limits__product-item-value">
                {`$${activeProduct.cost}`}
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Price
              </div>
              <div className="groups-price-limits__product-item-value">
                {`$${product.currentPrice}`}
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Margin
              </div>
              <div className="groups-price-limits__product-item-value">
                {`${product.currentMargin}%`}
              </div>
            </div>
          </div>
        </div>
        <div className="groups-price-limits__choose-block">
          <div className={`groups-price-limits__choose-block-limit `}>
            <div className="title">Limit by</div>
            <div
              className={`select-limit-block-single ${setActiveClassName()}`}
            >
              <Select
                open={product.exceptionLimits?.isOpenSelect}
                onChange={(el: SelectLimitPricing) => {
                  handleChangeExceptionsSelect(el);
                  deleteIsOpenSelectParam();
                }}
                value={(activeOption as SelectLimitPricing) || undefined}
                options={optionsExceptionSelect}
                suffixIcon={<ArrowBorder color="#212527" />}
                placeholder={"Select limit"}
                defaultValue={null}
                className={`custom-arrow  ${isErrorClassSelect()}`}
                onDropdownVisibleChange={(open) => {
                  !activeOption && deleteIsOpenSelectParam();
                  !open && setIsOpenSelect(open);
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />

              {isErrorSelect() && (
                <div className="error-validation-message mr-16 mt-4">
                  Please select a limit
                </div>
              )}
            </div>
          </div>
          <>{setActiveModeToChangeRender()}</>
        </div>
      </div>
    </div>
  );
};

export default ItemException;
