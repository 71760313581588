import { ROUTES } from "@shared/interfaces";
import { AuthWrapper } from "@widgets/auth/index";
import { Typography } from "antd";
import "./notFound.scss";
import { ButtonStyled } from "@shared/ui/components";
import { useUserStore } from "@entities/user";
import { useNavigate } from "react-router-dom";

const { Title, Link } = Typography;

const NotFoundPage = () => {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const chooseTextBtn = user.id ? "Go to Groups" : "Login";

  const handleClick = () => {
    if (user.id) {
      navigate(ROUTES.ADMIN_GROUPS);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  return (
    <div className="not-found-page">
      <div className="not-found-logo">ReLU</div>
      <div className="not-found-content">
        <div className="not-found-title">404</div>
        <div className="not-found-text">Page not found</div>
        <div className="not-found-subtext">
          The page you are trying to access does not exist
        </div>
        <div>
          <ButtonStyled
            text={chooseTextBtn}
            className="not-found-button"
            type="primary"
            fill="gray-primary-900"
            htmlType="button"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
