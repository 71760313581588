import { ApiService, arrayToQueryParams } from "@shared/utils";
import { url } from "@shared/utils/url.utils";
import { IDataTransfer } from "@shared/interfaces";
import { AlertTarget } from "@entities/groups";

class GroupService extends ApiService {
  baseURL: string;
  brandsURL: string;
  groupsURL: string;
  productsURL: string;

  constructor() {
    super();

    this.baseURL = "/api/v1";
    this.brandsURL = "/api/v1/brands";
    this.groupsURL = "/api/v1/groups";
    this.productsURL = "/api/v1/products";
  }
  async getProductsInGroup(data: IDataTransfer) {
    const { groupId, ...allParams } = data;
    let paramsBrands = "";

    if (data.brand) {
      paramsBrands = data.brand
        .map((brand: any) => `brand=${encodeURIComponent(brand)}`)
        .join("&");
    }

    delete allParams.brand;

    let params = url.generateQueryString(allParams || {});

    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/products?${params}&${paramsBrands}`
    );
  }

  async getBrandsInGroup(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.brandsURL}/groups/${groupId}`
    );
  }
  async getNewPriceReadyCount(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/products/filters`
    );
  }
  async getGroup(groupId: string) {
    return await this.baseServiceInstance.get(`${this.groupsURL}/${groupId}`);
  }
  async applyNewPrices(groupId: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/${groupId}/products/apply-new-prices`
    );
  }
  async fetchChangeLockProductMode(productId: string, isLocked: boolean) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/products/${productId}/state`,
      {
        state: isLocked,
      }
    );
  }
  async getProductsForReprice(data: IDataTransfer) {
    const { groupId, ...allParams } = data;
    let paramsBrands = "";

    if (data.brand) {
      paramsBrands = data.brand
        .map((brand: any) => `brand=${encodeURIComponent(brand)}`)
        .join("&");
    }

    delete allParams.brand;

    let params = url.generateQueryString(allParams || {});

    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/products?${params}&${paramsBrands}`
    );
  }
  async updateGroup(groupData: any) {
    return await this.baseServiceInstance.patch(
      `${this.groupsURL}/${groupData.id}`,
      {
        name: groupData.name,
      }
    );
  }
  async getProductById(id: string) {
    const url = this.productsURL + "/" + id;

    return await this.baseServiceInstance.get(url);
  }
  async modifyException(productId: string, exceptionLimits: any) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/exceptions/${productId}/optimize`,
      { exceptionLimits }
    );
  }
  async removeExceptionItemGroupById(id: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/exceptions/${id}/reprice`
    );
  }
  async saveNewPrice(productId: string, newPrice: number) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/products/${productId}/new-price`,
      { newPrice }
    );
  }
  async closeAlertByGroupId(
    groupId: string,
    target: AlertTarget,
    isShow: boolean
  ) {
    return await this.baseServiceInstance.patch(
      `${this.groupsURL}/${groupId}/alerts`,
      { target, isShow }
    );
  }
  async getProductsOfGroupCSVFile(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.groupsURL}/${groupId}/products/scv`
    );
  }
}

const groupService = new GroupService();

export default groupService;
