interface IProps {
  color?: string;
}
const LinkToNew = ({ color = "#6C757D" }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <g clipPath="url(#clip0_14889_69190)">
        <path
          d="M15 16.3333H5C4.54167 16.3333 4.16667 15.9583 4.16667 15.5V5.5C4.16667 5.04167 4.54167 4.66667 5 4.66667H9.16667C9.625 4.66667 10 4.29167 10 3.83333C10 3.375 9.625 3 9.16667 3H4.16667C3.24167 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V11.3333C17.5 10.875 17.125 10.5 16.6667 10.5C16.2083 10.5 15.8333 10.875 15.8333 11.3333V15.5C15.8333 15.9583 15.4583 16.3333 15 16.3333ZM11.6667 3.83333C11.6667 4.29167 12.0417 4.66667 12.5 4.66667H14.6583L7.05 12.275C6.725 12.6 6.725 13.125 7.05 13.45C7.375 13.775 7.9 13.775 8.225 13.45L15.8333 5.84167V8C15.8333 8.45833 16.2083 8.83333 16.6667 8.83333C17.125 8.83333 17.5 8.45833 17.5 8V3.83333C17.5 3.375 17.125 3 16.6667 3H12.5C12.0417 3 11.6667 3.375 11.6667 3.83333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_14889_69190">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkToNew;
