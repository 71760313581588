import { UserRolesEnum } from "@entities/settings";

export enum ROUTES {
  BASE = "/",
  SIGN_UP = "/sign_up",
  LOGIN = "/log_in",
  FORGOT_PASSWORD = "/forgot_password",
  CREATE_PASSWORD = "/create_password",
  ADMIN = "/admin",
  ADMIN_GROUPS = "/admin/groups",
  ADMIN_GROUPS_ID = "/admin/groups/:id",
  ADMIN_SETTINGS = "/admin/settings",
  ADMIN_SETTINGS_USERS = "/admin/settings/users",
  ADMIN_KNOWLEDGE_BASE = "/admin/knowledge_base",
  ADMIN_PROFILE = "/admin/profile",
  ADMIN_PROFILE_EDIT_PASSWORD = "/admin/profile/edit_password",

  NOT_FOUND = "*",
  NOT_FOUND_404 = "/404",

  ADMIN_GROUPS_CREATE = "/admin/groups/create",
  ADMIN_GROUPS_CREATE_ID = "/admin/groups/create/:id",
  ADMIN_GROUPS_EDIT = "/admin/groups/edit",
  ADMIN_GROUPS_EDIT_ID = "/admin/groups/edit/:id",
}

export interface IRoute {
  path: ROUTES;
  element: React.ReactElement;
  allowedRoles?: string[];
}
