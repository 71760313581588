import React from "react";

const Done = ({
  color = "#198754",
  size = "16",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_13238_70214)">
        <path
          d="M5.46167 10.7999L3.12833 8.46655C2.86833 8.20655 2.455 8.20655 2.195 8.46655C1.935 8.72655 1.935 9.13988 2.195 9.39988L4.98833 12.1932C5.24833 12.4532 5.66833 12.4532 5.92833 12.1932L12.995 5.13322C13.255 4.87322 13.255 4.45988 12.995 4.19988C12.735 3.93988 12.3217 3.93988 12.0617 4.19988L5.46167 10.7999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13238_70214">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Done;
