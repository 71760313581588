import "./LoginWidget.scss";
import { LoginForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { ROUTES } from "@shared/interfaces";
import { FC } from "react";
import { NavLink } from "react-router-dom";

const { Title, Link } = Typography;

const LoginWelcome: FC = () => {
  return (
    <div className="login__container">
      <h1 className="login__title">The Relu</h1>
      <h1 className="login__subtitle">Welcome!</h1>
      <h4 className="login__text">Log in into your Relu account</h4>
      <LoginForm />

      <h4 className="login-terms__text">
        <div>Don’t have an account?&nbsp;</div>
        <NavLink to={ROUTES.SIGN_UP}> Sign up</NavLink>
      </h4>
    </div>
  );
};

export default LoginWelcome;
