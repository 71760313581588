import "./groupsTitle.scss";
import { Typography } from "antd";
import { Plus, Refresh } from "@shared/ui/assets";
import { ButtonStyled, CustomTooltip } from "@shared/ui/components";
import { FC, useEffect, useId } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { Spiner } from "@features/index";
import moment from "moment";
import { FILTER_BY_PARAM, SEARCH_PARAM } from "@shared/constants";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { VIEWER_DISABLE_ITEMS_TEXT } from "@shared/constants/utilsConstants";

const { Title } = Typography;

interface IProps {}

const GroupsTitle: FC<IProps> = () => {
  const { isViewer } = useCheckRole();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { groups, isLoading, isInitialLoaderGroups } = useGroupsStore(
    (state) => state
  );
  const { updatePrices, getConfig } = useGroupsStore(
    useShallow((state) => state)
  );

  const { createGroup } = useGroupsStore(useShallow((state) => state));

  const lastPricesUpdate = useGroupsStore(
    useShallow((state) => state.lastPricesUpdate)
  );
  const isUpdatePriceInProcess = useGroupsStore(
    useShallow((state) => state.isUpdatePriceInProcess)
  );

  useEffect(() => {
    getConfig();
  }, []);
  const parseLastDateUpdateDate = () => {
    if (!lastPricesUpdate) {
      return "";
    }

    return moment(lastPricesUpdate).format("lll");
  };

  const renderLastDatePriceUpdate = () => {
    if (isUpdatePriceInProcess) {
      return (
        <>
          <h5 className="content-update__text mr-4">
            Data is updating... This may take a few minutes
          </h5>
          <Spiner />
        </>
      );
    }

    return (
      <>
        <Title className="content-update__text" level={5}>
          Last data update {parseLastDateUpdateDate()}
        </Title>
        <div className="refresh__ico" onClick={updatePrices}>
          <CustomTooltip
            title={"Sync integration data and update prices"}
            placement="top"
            arrow={true}
            className="mr-4"
          >
            <Refresh />
          </CustomTooltip>
        </div>
      </>
    );
  };

  const handleClick = async () => {
    const res = await createGroup();

    if (res) {
      const groupId = res.id;
      navigate(`${ROUTES.ADMIN_GROUPS_CREATE}/${groupId}`);
    }
  };

  const isFilter = searchParams.get(FILTER_BY_PARAM) === "ALL";
  const isSearch = searchParams.get(SEARCH_PARAM);

  const isShowNoEmpty = !groups.length && isFilter && !isSearch?.length;

  const btnBlock = (
    <div className={` df ai-center`}>
      <CustomTooltip title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}>
        <div
          className={`${isViewer ? "disabled" : ""} content-ipdate__wrapper`}
        >
          {isInitialLoaderGroups ? null : renderLastDatePriceUpdate()}
        </div>
      </CustomTooltip>
      <CustomTooltip
        title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
        styles={{ width: "250px" }}
      >
        <div className={`${isViewer ? "disabled" : ""}`}>
          <ButtonStyled
            type="primary"
            text="Create group"
            htmlType="button"
            fill="gray-primary-900"
            className="groups-create__btn"
            icon={<Plus />}
            onClick={() => !isViewer && handleClick()}
          />
        </div>
      </CustomTooltip>
    </div>
  );
  const chooseShowNoEmpty = !isShowNoEmpty ? btnBlock : null;

  const isLoadingState = isLoading ? btnBlock : chooseShowNoEmpty;

  return (
    <div className="content__title">
      <div className="content__title-left">
        <Title className="" level={1}>
          Groups
        </Title>
      </div>
      {isLoadingState}
    </div>
  );
};

export default GroupsTitle;
