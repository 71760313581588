import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create, StateCreator, StoreMutators } from "zustand";
import {
  IChangePersonalInfo,
  ICompleteDataTransfer,
  IEditPassword,
  IInviteDataTransfer,
  ISetNewPassword,
  IUser,
  IUserNotification,
  IUserState,
} from "@entities/user/model/user.types";
import { IFormLoginState } from "@shared/interfaces";
import { SUCCESS_STATUS } from "@shared/interfaces/response";
import userService from "../api";
import { errorHandler } from "@shared/utils";
import { UserRolesEnum } from "@entities/settings";

// const asyncMiddleware =
//   <
//     T extends IUserState,
//     M extends [keyof StoreMutators<unknown, unknown>, unknown][]
//   >(
//     config: StateCreator<T, [], M>
//   ): StateCreator<T, [], M> =>
//   (set, get, api) => {
//     const state = config(set, get, api);

//     Object.keys(state).forEach((key) => {
//       const property = state[key as keyof T];

//       if (
//         typeof property === "function" &&
//         property.constructor.name === "AsyncFunction"
//       ) {
//         const originalFn = property as (...args: any[]) => Promise<any>;

//         state[key as keyof T] = (async (...args: any[]) => {
//           try {
//             const result = await originalFn(...args);

//             set((state) => ({
//               ...state,
//               qwe: "",
//             }));

//             return result;
//           } catch (error) {
//             set((state) => ({
//               ...state,
//               qwe: "Произошла ошибка",
//             }));
//             throw error;
//           }
//         }) as any;
//       }
//     });

//     return state;
//   };

const initUser = {
  id: null,
  email: null,
  firstName: "",
  lastName: "",
  role: UserRolesEnum.VIEWER,
  userName: "",
  productCategories: [],
};
// immer -  for mutate state directly

export const useUserStore = create<IUserState>()(
  // asyncMiddleware(
  devtools(
    immer((set, get) => ({
      user: initUser,
      notifications: [],
      validationErrorMessage: "",
      qwe: "",

      checkAuth: async () => {
        try {
          const { data } = await userService.checkAuth();

          const { accessToken, user } = data;
          localStorage.setItem("_accessToken", accessToken);
          set({
            user,
          });
          return data;
        } catch (e) {
          set({ user: initUser });
        }
      },
      refreshToken: async () => {
        // const { data } = await userService.refreshToken();
        // if (data?.status === ERROR_STATUS.ACCESS_DENIED_403) {
        //   localStorage.removeItem("_accessToken");
        //   return set({ user: {} });
        // }
        // const { accessToken, user } = data;
        // localStorage.setItem("_accessToken", accessToken);
        // get().setUser(user);
      },
      setUser: (userData: IUser) => {
        set({ user: userData });
      },
      logIn: async (formData: IFormLoginState) => {
        try {
          const res = await userService.login(formData);

          const data = res.data;
          if (data?.status) {
            throw data;
          }
          const { accessToken, user } = data;
          localStorage.setItem("_accessToken", accessToken);
          get().setUser(user);

          return user;
        } catch (e) {
          const customError: any = errorHandler.isResponseHandled(e);

          if (customError) {
            throw customError;
          }
        }
      },

      logOut: async () => {
        const userId = get().user.id || "";
        const { status } = await userService.logout(userId);

        if (status === SUCCESS_STATUS.SUCCESS_204) {
          set({ user: initUser });
          localStorage.removeItem("_accessToken");
        }
      },
      getNotifications: async () => {
        // const userId = get().user.id || "";
        // const { data } = await userService.getNotifications(userId);
        // set({ notifications: data });
      },
      checkActualInviteUser: async (dataObj: IInviteDataTransfer) => {
        const { data } = await userService.checkActualInviteUser(dataObj);
      },
      checkInvite: async (email: string) => {
        try {
          const { data } = await userService.checkInvite(email);

          return data;
        } catch (error: any) {
          throw error;
        }
      },
      checkEmail: async (email: string) => {
        const { data } = await userService.checkEmail(email);
        return data;
      },

      checkVerifyCodeSignUp: async (code: string, email: string) => {
        const { data } = await userService.checkVerifyCodeSignUp(code, email);
        return data;
      },
      resendCodeSignUp: async (email: string) => {
        const { data } = await userService.resendCodeSignUp(email);
      },
      onCreatePasswordSignUp: async (params: ISetNewPassword) => {
        const { data } = await userService.onCreatePasswordSignUp(params);
        return data;
      },
      onCompleteSignup: async (params: ICompleteDataTransfer) => {
        const { data } = await userService.onCompleteSignup(params);
        return data;
      },
      changePassword: async (dataObj: IEditPassword) => {
        try {
          const { data } = await userService.changePassword(dataObj);
          return data;
        } catch (error: any) {
          set({ validationErrorMessage: error.response.data.message });
        }
      },
      setValidationErrorMessage: (message: string) =>
        set({ validationErrorMessage: message }),
      changePersonalInfo: async (dataObj: IChangePersonalInfo) => {
        const { data } = await userService.changePersonalInfo(dataObj);
        set({ user: data });
      },
      deleteAccountByDefault: async () => {
        const { data } = await userService.deleteAccountByDefault();
        return data;
      },
      setShowNotificationType: async (obj: IUserNotification) => {
        const { data } = await userService.setShowNotificationType(obj);
        return data;
      },
      onEditPassword: async (data: ISetNewPassword) => {
        const { data: res } = await userService.onEditPassword(data);
        return res;
      },
    }))
  )
  // )
);
