import { UserRolesEnum } from "@entities/settings/model/settings.types";
import { IUser } from "@entities/user";
import {
  ForgotPasswordPage,
  LogInPage,
  NotFoundPage,
  SignInPage,
  GroupsPage,
  SettingsPage,
  KnowledgePage,
  ProfilePage,
  CreateGroupPage,
  GroupPage,
  EditPassword,
  EditGroupPage,
} from "@pages/index";
import { IRoute, ROUTES } from "@shared/interfaces";
import WithRoleGuard from "@shared/model/hocs/WithRoleGuard";
import { TeamManagement } from "@widgets/admin";
import { Navigate } from "react-router-dom";

const permissionsWithoutViewerRoute = [
  UserRolesEnum.ADMIN,
  UserRolesEnum.OWNER,
  UserRolesEnum.SU_ADMIN,
  UserRolesEnum.MANAGER,
];
const permissionsWithoutViewerAndManagerRoute = [
  UserRolesEnum.ADMIN,
  UserRolesEnum.OWNER,
  UserRolesEnum.SU_ADMIN,
];

export class RouterBuilder {
  baseRouters: IRoute[] = [];
  authRouter: IRoute[] = [];

  constructor() {
    this.baseRouters = [
      {
        path: ROUTES.BASE,
        element: <GroupsPage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_GROUPS,
        element: <GroupsPage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_GROUPS_ID,
        element: <GroupPage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_GROUPS_CREATE,
        element: <CreateGroupPage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_GROUPS_CREATE_ID,
        element: <CreateGroupPage />,
        allowedRoles: permissionsWithoutViewerRoute,
      },
      {
        path: ROUTES.ADMIN_GROUPS_EDIT_ID,
        element: <EditGroupPage />,
        allowedRoles: permissionsWithoutViewerRoute,
      },
      {
        path: ROUTES.ADMIN_SETTINGS,
        element: <SettingsPage />,
        allowedRoles: permissionsWithoutViewerRoute,
      },
      {
        path: ROUTES.ADMIN_KNOWLEDGE_BASE,
        element: <KnowledgePage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_PROFILE,
        element: <ProfilePage />,
        allowedRoles: [],
      },
      {
        path: ROUTES.ADMIN_PROFILE_EDIT_PASSWORD,
        element: <EditPassword />,
        allowedRoles: [],
      },
      {
        path: ROUTES.LOGIN,
        element: <Navigate to={ROUTES.BASE} />,
      },
      {
        path: ROUTES.ADMIN_SETTINGS_USERS,
        element: <TeamManagement />,
        allowedRoles: permissionsWithoutViewerAndManagerRoute,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <NotFoundPage />,
      },
    ];

    this.authRouter = [
      {
        path: ROUTES.BASE,
        element: <LogInPage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LogInPage />,
      },
      {
        path: ROUTES.SIGN_UP,
        element: <SignInPage />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Navigate to={ROUTES.BASE} />,
      },
    ];
  }

  addAuthRouters(): RouterBuilder {
    this.baseRouters = [...this.authRouter];
    return this;
  }

  removeAuthRouters(): RouterBuilder {
    this.baseRouters = this.baseRouters.filter(({ path }: IRoute) => {
      return path !== ROUTES.LOGIN && path !== ROUTES.SIGN_UP;
    });
    return this;
  }

  build(): IRoute[] {
    return this.baseRouters.map((route: IRoute) => {
      if (route.allowedRoles) {
        return {
          ...route,
          element: (
            <WithRoleGuard allowedRoles={route.allowedRoles}>
              {route.element}
            </WithRoleGuard>
          ),
        };
      }
      return route;
    });
  }
}
