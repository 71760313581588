import { Close } from "@shared/ui/assets";
import { ButtonStyled } from "@shared/ui/components";
import { Divider, Modal } from "antd";
import { FC } from "react";
import "./removeUserPopup.scss";
import { MODE_POPUP } from "../TableUsers";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
  activeUser: any;
  mode: MODE_POPUP;
}

const RemoveUserPopup: FC<IProps> = (props) => {
  const { flag, onClose, onOk, activeUser, mode } = props;
  const isRevokeMode = mode === MODE_POPUP.REVOKE;

  return (
    <Modal
      open={flag}
      onOk={onOk}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose()}
    >
      <>
        <div className="users-modal-del__wrapper">
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <h3>{isRevokeMode ? "Revoke Invitation" : "Delete User"}</h3>
        </div>
        <Divider />
      </>
      {isRevokeMode ? (
        <div className="modal__remove-user-content">
          This will revoke invitation for {activeUser?.email || "[ - ]"}. You
          cannot undo this action.
        </div>
      ) : (
        <div className="modal__remove-user-content">
          This will delete {activeUser?.email || "[ - ]"} from team. You cannot
          undo this action.
        </div>
      )}
      <Divider />

      <div className="modal__monitoring-footer">
        <div className="df jc-fe">
          <ButtonStyled
            text="Cancel"
            htmlType="button"
            className="w-84 mr-16"
            onClick={onClose}
          />

          <ButtonStyled
            text={isRevokeMode ? "Revoke" : "Delete"}
            type="primary"
            fill="red-600"
            htmlType="button"
            className="w-84"
            onClick={onOk}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUserPopup;
