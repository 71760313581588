import { FC, useEffect, useState } from "react";
import { Checkbox, Divider, Modal } from "antd";
import { AlertCustom, ButtonStyled } from "@shared/ui/components";
import { useParams } from "react-router-dom";
import { Close, Delete, Info, Plus } from "@shared/ui/assets";
import { generateTextColumn, Pagination } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { IProduct, useProductsStore } from "@entities/products";
import { useShallow } from "zustand/react/shallow";
import { useGroupsStore } from "@entities/groups";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import "./popupUnmatchedProducts.scss";
import {
  BRAND_IDS_PARAM,
  CATEGORY_IDS_PARAM,
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  GROUP_IDS_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
  ungrouped,
} from "@shared/constants";
import {
  filteredCompareQueryObj,
  getItemWord,
  toFixedCustom,
} from "@shared/utils";
import { useDebounce } from "use-debounce";

interface IProps {
  flag: boolean;
  setItemsPopupShow: (bool: boolean) => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  BRAND = "brand",
  GROUPS = "groupId",
  STORE = "store",
  CATEGORY = "category",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
}

const PopupUnmatchedProducts: FC<IProps> = ({
  flag,
  setItemsPopupShow,
}: IProps) => {
  let { id } = useParams();

  const productsInGroup = useGroupsStore((state) => state.productsInGroup);

  const productsInGroupSelectedIds = useGroupsStore(
    useShallow((state) => state.productsInGroupSelectedIds)
  );
  const isAllSelected = productsInGroup.every((product) =>
    productsInGroupSelectedIds.includes(product.id)
  );
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);
  const {
    selectProductInGroup,
    selectAllProductsInGroup,
    deleteProductsInGroup,
    setProductsInGroup,
  } = useGroupsStore(useShallow((state) => state));
  const {
    getProductsWithoutCompetitors,
    removeSelectProductsIdsInGroup,
    isLoading,
  } = useGroupsStore((state) => state);
  const { getStrategy } = useGroupsStore((state) => state);

  const recordsCount = useGroupsStore(
    useShallow((state) => state.recordsCountInGroup)
  );

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | null;
  }>({
    key: "",
    direction: null,
  });

  useEffect(() => {
    const data = {
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [LIMIT_PARAM]: currentLimit,
      [OFFSET_PARAM]: currentOffset,
      [GROUP_ID_PARAM]: id,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;
    getProductsWithoutCompetitors(filteredData);
  }, [sortConfig, currentLimit, currentOffset]);

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!productsInGroup.length}
          onChange={(event) => {
            selectAllProductsInGroup(event.target.checked);
          }}
        ></Checkbox>
      ),
      className: "table-w-48",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71",
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Category",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CATEGORY === sortConfig.key,
      }),
      className: "table-w-140",
      sortKey: THEAD_SORT_KEYS.CATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: IProduct) => (
        <Checkbox
          checked={productsInGroupSelectedIds.some((el) => el === id)}
          onChange={(event) => {
            selectProductInGroup(id, event?.target.checked);
          }}
        ></Checkbox>
      ),
      className: "ta-center",
    },
    {
      content: ({ article, name }: IProduct) => {
        return (
          <>
            <h5 className="product__subtitle">{article}</h5>
            <h5 className="product__title">{name}</h5>
          </>
        );
      },
      className: "w-430",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div>
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${toFixedCustom(currentPrice)}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => (
        <h5>{toFixedCustom(currentMargin)}%</h5>
      ),
      className: "ta-right",
    },
    {
      content: ({ cost }: IProduct) => <h5>${toFixedCustom(cost)}</h5>,
      className: "ta-right",
    },
    {
      content: ({ brand }: IProduct) => <h5>{brand}</h5>,
    },
    {
      content: ({ category }: IProduct) => <h5>{category}</h5>,
    },
    {
      content: ({ store }: IProduct) => <h5>{store}</h5>,
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productsInGroupSelectedIds.some((id) => id === item.id),
      className: el.className,
      item: item,
    }));
  });

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const deleteSelectedProducts = async () => {
    if (!id) {
      return;
    }
    await deleteProductsInGroup(+id);
    removeSelectProductsIdsInGroup();
    const params = {
      [GROUP_ID_PARAM]: id,
    };

    await getProductsWithoutCompetitors({ ...params });
    getStrategy();
  };

  const handleChange = async () => {
    await deleteSelectedProducts();

    setCurrentLimit(DEFAULT_LIMIT_PAGINATION);
    setCurrentOffset(DEFAULT_OFFSET_PAGINATION);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };
  const onClose = () => {
    setItemsPopupShow(false);
  };
  return (
    <div className="main-block">
      <Modal
        open={flag}
        className="modal-products-without-competitors"
        footer={null}
        centered={true}
        onOk={() => {}}
        closeIcon={false}
        maskClosable={false}
      >
        <>
          <div onClick={() => onClose()} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal__title">Unmatched Items</h2>
          <Divider />
          <div className="modal__info-alert">
            <AlertCustom
              type="default"
              icon={<Info color="#6C757D" />}
              closable={true}
              message={
                "Select and remove unmatched items that have no direct competitors. This action will ungroup them."
              }
            />
          </div>
          {productsInGroup.length ? (
            <>
              <div className="modal__content">
                <div className="groups-edite-table-no-competitors">
                  <TableCustom
                    headData={headDataTable}
                    bodyData={dataTableBody}
                    handleSort={handleSort}
                  />
                </div>
              </div>
              <>
                <div className="pagination__container">
                  {productsInGroupSelectedIds.length ? (
                    <div className="selected__block mr-24">
                      <span className="selected__count">{`${productsInGroupSelectedIds.length}`}</span>
                      <div className="selected__text">
                        {`${getItemWord(
                          productsInGroupSelectedIds.length
                        )} selected`}
                      </div>

                      <div className="groups-block-delete__btn">
                        <ButtonStyled
                          type="default"
                          icon={<Delete />}
                          text="Remove"
                          htmlType="button"
                          fill="danger_300"
                          className="groups-delete__btn"
                          onClick={handleChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <Pagination
                    prevPage={definePrefPage}
                    nextPage={defineNextPage}
                    onChange={onChangeItemsCount}
                    recordsCount={definePageCount()}
                    currentLimit={defineCurrentLimit()}
                    currentSelectLimit={currentLimit.toString()}
                    currentOffset={defineCurrentOffset()}
                  />
                </div>
              </>
            </>
          ) : (
            <div className="no-data-block">
              <div className="no-data__text">
                <div>Unmatched items have been removed and ungrouped.</div>
                <div>Click 'Close' to continue.</div>
              </div>
            </div>
          )}
        </>
        <div className="modal__footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Close"
              htmlType="button"
              className="w-84 mr-16"
              onClick={() => onClose()}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PopupUnmatchedProducts;
