import React from "react";
import { Navigate } from "react-router-dom";
import { useUserStore } from "@entities/user";
import { ROUTES } from "@shared/interfaces";

interface WithRoleGuardProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

const WithRoleGuard: React.FC<WithRoleGuardProps> = ({
  allowedRoles,
  children,
}) => {
  const { user } = useUserStore();

  if (!user) {
    return <Navigate to="/404" replace />;
  }

  const hasAccess =
    allowedRoles.includes(user.role as string) || !allowedRoles.length;

  return hasAccess ? (
    <>{children}</>
  ) : (
    <Navigate to={ROUTES.NOT_FOUND_404} replace />
  );
};

export default WithRoleGuard;
