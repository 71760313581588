import React, { useEffect, useRef } from "react";
import "./inputWithIcons.scss";

interface IProps {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onChange: (str: string) => void;
  value: string;
  classNameInput?: string;
  wrapperClassName?: string;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

const InputWithIcons = ({
  wrapperClassName,
  leftIcon,
  rightIcon,
  onChange,
  value,
  classNameInput,
  onBlur,
  onKeyDown,
  autoFocus = false,
  ...props
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={`input-wrapper ${wrapperClassName}`}>
      {leftIcon && (
        <div className="icon left-icon">
          <span className="left">{leftIcon}</span>
        </div>
      )}
      <input
        ref={inputRef}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value}
        className={`"custom-input" ${classNameInput || ""}`}
        {...props}
        onChange={(e) => onChange(e.target.value)}
        placeholder={props.placeholder || ""}
      />
      {rightIcon && (
        <div className="icon right-icon">
          <span className="right">{rightIcon}</span>
        </div>
      )}
    </div>
  );
};

export default InputWithIcons;
