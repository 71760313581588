import { LayoutWithAside } from "@widgets/admin";
import "./editPassword.scss";
import { useFormik } from "formik";
import { useState } from "react";
import { Input } from "antd";
import { Close, Done } from "@shared/ui/assets";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { validationSchema } from "./validation";
import { IEditPassword, useUserStore } from "@entities/user";

const optionsValidation = [
  {
    value: "Min 8 characters",
    isValid: false,
    key: "length",
  },

  {
    value: "At least one number",
    isValid: false,
    key: "number",
  },
  {
    value: "At least one letter",
    isValid: false,
    key: "letter",
  },
  {
    value: "One special character (!@#$%^&*)",
    isValid: false,
    key: "special",
  },
  {
    value: "One upper case letter",
    isValid: false,
    key: "upperLetter",
  },
];

const initialState: IEditPassword = {
  currentPassword: "",
  newPassword: "",
};

const EditPassword = () => {
  const { changePassword, setValidationErrorMessage } = useUserStore(
    (state) => state
  );
  const validationErrorMessage = useUserStore(
    (state) => state.validationErrorMessage
  );

  const { contextHolder, openNotification } = NotificationCustom();
  const [options, setOptions] = useState(optionsValidation);
  const [isChangedSomePassword, setIsChangedSomePassword] = useState(false);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const res = await changePassword(values);
        if (res.id) {
          openNotification({
            message: "Password changed successfully.",
            type: "success",
          });
          resetForm();
          setOptions(optionsValidation);
        }
      } catch (error) {}
    },
  });

  const { setFieldValue, values, errors, setFieldError, resetForm } = formik;

  const onChangeInput = ({ target }: any) => {
    setFieldError("newPassword", "");
    const value = target.value;
    setFieldValue("newPassword", value);

    if (value.length >= 8) {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/\d/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/[a-zA-Z]/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[^A-Za-z0-9]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[A-Z]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
  };

  const checkErrors = (key: string) => {
    return errors.newPassword?.includes(key) || errors.newPassword === "all";
  };

  return (
    <LayoutWithAside>
      <>
        {contextHolder}

        <h1 className="content__title pl-24 pt-30 pb-30">
          Password & Security
        </h1>
        <div className="edit-password">
          <div>
            <form
              onSubmit={(e) => {
                formik.handleSubmit();
                e.preventDefault();
              }}
              className="edit-password-form_wrapper"
            >
              {/* Input for accessibility only */}
              <input
                type="text"
                name="username"
                autoComplete="username"
                value=""
                hidden
                onChange={() => {}}
              />
              <div className="form__item custom-height mb-12">
                <label className="" htmlFor={"currentPassword"}>
                  Password
                </label>

                <Input.Password
                  autoComplete="current-password"
                  className="gray-primary-900"
                  name="currentPassword"
                  type="password"
                  onChange={({ target: { value } }) => {
                    setValidationErrorMessage("");
                    setFieldError("currentPassword", "");
                    setFieldValue("currentPassword", value);
                    setIsChangedSomePassword(true);
                  }}
                  value={values.currentPassword}
                  status={
                    (errors.currentPassword || validationErrorMessage) &&
                    "error"
                  }
                />

                {(errors.currentPassword || validationErrorMessage) && (
                  <div className="error-validation-text">
                    {validationErrorMessage || errors.currentPassword}
                  </div>
                )}
              </div>
              <div className="form__item custom-height mb-12">
                <label className="" htmlFor={"new-password"}>
                  New password
                </label>

                <Input.Password
                  autoComplete="new-password"
                  className="gray-primary-900"
                  name="new-password"
                  type="password"
                  onChange={(value) => {
                    setIsChangedSomePassword(true);
                    onChangeInput(value);
                  }}
                  value={values.newPassword}
                  status={errors.newPassword && "error"}
                />
                {errors.newPassword && (
                  <div className="error-validation-text">
                    New password does not meet requirements below.
                  </div>
                )}
              </div>
              <div className=" mb-24">
                {options.map((option) => (
                  <div key={option.value} className="validation-item">
                    {option.isValid ? (
                      <Done />
                    ) : (
                      <Close
                        width="16"
                        height="16"
                        color={checkErrors(option.key) ? "#DC3545" : "#6C757D"}
                      />
                    )}
                    <span
                      className={
                        !option.isValid && checkErrors(option.key)
                          ? "error-validation"
                          : undefined
                      }
                    >
                      {option.value}
                    </span>
                  </div>
                ))}
              </div>
              <ButtonStyled
                text="Change password"
                className="w-171"
                type="primary"
                htmlType="submit"
                disabled={!isChangedSomePassword}
              />
            </form>
          </div>
        </div>
      </>
    </LayoutWithAside>
  );
};

export default EditPassword;
