import "./SignupWelcomeWidget.scss";
import { ROUTES } from "@shared/interfaces";
import { SignupForm } from "@widgets/auth/index";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface IProps {
  onContinueClick: ({ type }: { type: SIGNUP_STEPS }) => void;
}

const SignupWelcomeWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup__container">
      <h1 className="signup__title">The Relu</h1>
      <h1 className="signup__subtitle">Welcome!</h1>
      <h4 className="signup__text">Enter your email to proceed</h4>
      <SignupForm onContinueClick={onContinueClick} />
      <h4 className="signup-terms__text">
        By clicking continue, you agree to our{" "}
        <NavLink to={ROUTES.LOGIN}>Terms of Service</NavLink> and{" "}
        <NavLink to={ROUTES.LOGIN}>Privacy Policy</NavLink>
      </h4>

      <h4 className="signup-terms__text signup-account__text">
        Have an account? <NavLink to={ROUTES.LOGIN}>Log in</NavLink>
      </h4>
    </div>
  );
};

export default SignupWelcomeWidget;
