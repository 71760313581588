import { useEffect, useState } from "react";
import "./groupWidget.scss";
import { Divider, type MenuProps } from "antd";
import { url } from "@shared/utils/url.utils";
import { Link, useSearchParams } from "react-router-dom";
import { GroupsDeletePopup } from "../../GroupsDeletePopup";
import { AsideDropdown } from "@widgets/admin/asideDropdown";
import { Dots, Warning } from "@shared/ui/assets";
import {
  AlertTarget,
  EGroupStatus,
  EGroupType,
  IGroup,
  useGroupsStore,
} from "@entities/groups";
import {
  Status,
  Badge as CustomBadge,
  LoaderGroupsTable,
  CustomTooltip,
} from "@shared/ui/components";
import { GroupsEditPopup } from "../../GroupsEditPopup";
import { getMenuPropsItems } from "@shared/common/GetMenuPropsItems";
import { generateTextColumn } from "@shared/common";
import { ISortParams, ROUTES, SortParams } from "@shared/interfaces";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import {
  LIMIT_PARAM,
  SEARCH_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  FILTER_BY_PARAM,
  DIRECTION_PARAM,
} from "@shared/constants";
import { getTimeAgo } from "@shared/utils";
import useCheckRole from "@shared/model/hooks/useCheckRole";

enum THEAD_SORT_KEYS {
  NAME = "name",
  STATUS = "status",
  LAST_REPRICE = "last_reprice",
  TOTAL_ITEMS = "totalItems",
  REPRICE_ITEMS = "repriceItems",
}

const GroupsWidget = () => {
  const [isDeleteGroupPopupShow, setDeleteGroupPopupShow] =
    useState<boolean>(false);
  const [isEditGroupPopupShow, setEditGroupPopupShow] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isViewer } = useCheckRole();

  const defaultDirection = searchParams.get(DIRECTION_PARAM) ?? SortParams.ASC;
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: searchParams.get(ORDER_BY_PARAM) || "",
    direction: (defaultDirection as ISortParams) || "",
  });

  const {
    selectAllGroup,
    setCurrentGroup,
    newPricesAvailable,
    isLoading,
    clearGroup,
    setGroups,
  } = useGroupsStore((state) => state);

  const groups = useGroupsStore((state) => state.groups);
  const isAllSelected = groups.some((group: IGroup) => !group.isSelected);

  useEffect(() => {
    const MIN_OFFSET = 10;

    if (!groups.length) {
      const searchObjectParams = url.getSearchParams(searchParams);
      let offset = +searchObjectParams[OFFSET_PARAM];
      const limit = +searchObjectParams[LIMIT_PARAM];

      if (offset >= MIN_OFFSET) {
        offset -= limit;
      }

      //addition check
      if (offset < 0) {
        offset = 0;
      }

      searchParams.set(OFFSET_PARAM, offset.toString());
      setSearchParams(searchParams);
    }
  }, [groups.length]);

  const chooseDisabledMenu = (isDisabled: boolean) => {
    return [
      getMenuPropsItems(
        "Rename group",
        "rename",
        null,
        undefined,
        isDisabled,
        false,
        (e) => setEditGroupPopupShow(true)
      ),
      getMenuPropsItems("Export as CSV", "export", null, undefined, true),
      getMenuPropsItems(
        "Delete group",
        "delete",
        null,
        undefined,
        isDisabled,
        true,
        () => setDeleteGroupPopupShow(true)
      ),
    ];
  };

  useEffect(() => {
    if (sortConfig.direction && sortConfig.key) {
      searchParams.set(DIRECTION_PARAM, sortConfig.direction);
      searchParams.set(ORDER_BY_PARAM, sortConfig.key);
    }

    setSearchParams(searchParams);
  }, [sortConfig]);

  useEffect(() => {
    const searchObjectParams = url.getSearchParams(searchParams);
    if (!Object.keys(searchObjectParams).length) {
      searchParams.set(FILTER_BY_PARAM, "ALL");
      searchParams.set(LIMIT_PARAM, "10");
      searchParams.set(OFFSET_PARAM, "0");
      searchParams.set(SEARCH_PARAM, "");

      setSearchParams(searchParams);
    }
  }, [isAllSelected]);

  useEffect(() => {
    return () => {
      selectAllGroup(false);
      setGroups([]);
    };
  }, []);

  const onCloseDeletePopupHandler = (flag: boolean, groupId: string) => {
    setDeleteGroupPopupShow(flag);
    if (groupId) {
      return handleDeleteGroup(groupId);
    }
    return {};
  };

  const handleDeleteGroup = (groupId: string) => {
    const remainingGroups = groups.filter((group) => group.id !== groupId);

    if (remainingGroups.length === 0) {
      const searchObjectParams = url.getSearchParams(searchParams);
      let offset = +searchObjectParams[OFFSET_PARAM];
      const limit = +searchObjectParams[LIMIT_PARAM];

      if (offset >= limit) {
        offset -= limit;
      }

      if (offset < 0) {
        offset = 0;
      }

      searchParams.set(OFFSET_PARAM, offset.toString());

      setSearchParams(searchParams);
    }
    return url.getSearchParams(searchParams);
  };

  const dataTableTitles = [
    {
      content: generateTextColumn({
        text: "Group name",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-451",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({
        text: "Reprice status",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.STATUS === sortConfig.key,
      }),
      className: "table-w-151",
      sortKey: THEAD_SORT_KEYS.STATUS,
    },
    {
      content: generateTextColumn({
        text: "Reprice items",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.REPRICE_ITEMS === sortConfig.key,
        tooltipText: "Items ready for repricing",
      }),
      className: "table-w-166",
      sortKey: THEAD_SORT_KEYS.REPRICE_ITEMS,
    },
    {
      content: generateTextColumn({
        text: "Total items",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.TOTAL_ITEMS === sortConfig.key,
      }),
      className: "table-w-130",
      sortKey: THEAD_SORT_KEYS.TOTAL_ITEMS,
    },
    {
      content: generateTextColumn({
        text: "Last repriced",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.LAST_REPRICE === sortConfig.key,
      }),
      className: "table-w-166",
      sortKey: THEAD_SORT_KEYS.LAST_REPRICE,
    },
    {
      content: null,
      className: "table-w-52 ",
    },
  ];

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const isDraftType = (type: EGroupType) => type === EGroupType.DRAFT;

  const dataTableBodyCompare = [
    {
      content: (item: IGroup) => {
        const { name, type, id, alerts } = item;
        const chooseUrl = () => {
          switch (type) {
            case EGroupType.DRAFT:
              return `${ROUTES.ADMIN_GROUPS}/create/${id}`;
            case EGroupType.COMPLETE:
              return `${ROUTES.ADMIN_GROUPS}/${id}`;
            default:
              return "";
          }
        };
        const alertNoPositiveValue = alerts?.find(
          (alert) => alert.target === AlertTarget.GROUP_NEGATIVE__NEW_PRICES
        );

        const isShowTooltip = item.type === EGroupType.DRAFT && isViewer;
        const chooseText = isShowTooltip
          ? "Viewing draft groups is restricted based on your role"
          : "";

        return (
          <div className="df ai-center group-name__link">
            <CustomTooltip title={chooseText}>
              <div
                className={
                  isShowTooltip
                    ? "disabled df ai-center group-name__link"
                    : " df ai-center group-name__link"
                }
              >
                <Link to={chooseUrl()}>{name}</Link>&nbsp;
                {alertNoPositiveValue ? (
                  <>
                    <CustomTooltip
                      styles={{ width: 440 }}
                      title={alertNoPositiveValue.message}
                    >
                      <Warning />
                    </CustomTooltip>
                  </>
                ) : null}
                {type === EGroupType.DRAFT && (
                  <CustomBadge
                    count={type.toLowerCase()}
                    className="ml-8 group-name__badge"
                  />
                )}
              </div>
            </CustomTooltip>
          </div>
        );
      },
      className: (item: IGroup) => "table-w-451",
    },
    {
      content: ({ id, status, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <Status key={`status-${id}`} type={status} />
        ) : (
          <></>
        );
        return render;
      },
      className: (item: IGroup) =>
        item.type === EGroupType.DRAFT && isViewer ? "disabled " : "",
    },
    {
      content: ({ repriceItems, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <div className="reprice-badge__table">
            <CustomBadge
              count={repriceItems}
              fill={repriceItems > 0 ? "gray-600" : ""}
              className="mr-16"
            />
          </div>
        ) : (
          <></>
        );
        return render;
      },
      className: (item: IGroup) =>
        item.type === EGroupType.DRAFT && isViewer ? "disabled " : "",
    },
    {
      content: ({ totalItems, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <div className="total-items__table">
            <h5 className="pr-16">{totalItems}</h5>
          </div>
        ) : (
          <></>
        );
        return render;
      },
      className: (item: IGroup) =>
        item.type === EGroupType.DRAFT && isViewer
          ? "disabled ta-right"
          : "ta-right",
    },
    {
      content: ({ last_reprice, type }: IGroup) => {
        const chooserDate = last_reprice ? (
          getTimeAgo(last_reprice)
        ) : (
          <div className="no-date">N/A</div>
        );
        const render = !isDraftType(type) ? chooserDate : <></>;

        return render;
      },
      className: (item: IGroup) =>
        item.type === EGroupType.DRAFT && isViewer ? "disabled" : "",
    },
    {
      content: (item: IGroup) => {
        const isShowTooltip = item.type === EGroupType.DRAFT && isViewer;
        const chooseText = isShowTooltip
          ? "Viewing draft groups is restricted based on your role"
          : "";
        return (
          <CustomTooltip title={chooseText}>
            <div className={isShowTooltip ? "disabled" : ""}>
              <AsideDropdown
                onOpenChange={(isOpen) => {
                  if (!isOpen) {
                    clearGroup();
                  }
                }}
                collapsed={true}
                items={chooseDisabledMenu(
                  isViewer || item.status === EGroupStatus.IN_PROGRESS
                )}
                placement="bottomRight"
                className="relu__menu"
              >
                <div>
                  <Dots color="#6C757D" />
                </div>
              </AsideDropdown>
            </div>
          </CustomTooltip>
        );
      },
      className: (item: IGroup) => "",
    },
  ];

  const dataTableBody = groups.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: item.isSelected,
      className: el?.className(item) || "",
      item: item,
      disabled: item.type === EGroupType.DRAFT && isViewer ? "disabled" : "",
    }));
  });

  const isLoadingState = isLoading ? <LoaderGroupsTable /> : null;

  return (
    <div className="table-block-widget">
      <>{isLoadingState}</>
      <div
        className={`table__padding table__scroll ${
          newPricesAvailable?.length ? "table__scroll-notification" : ""
        }`}
      >
        <GroupsDeletePopup
          flag={isDeleteGroupPopupShow}
          onClose={onCloseDeletePopupHandler}
        />
        <GroupsEditPopup
          flag={isEditGroupPopupShow}
          onClose={setEditGroupPopupShow}
        />

        <div className="wrapper-table">
          <TableCustom
            headData={dataTableTitles}
            bodyData={dataTableBody}
            handleSort={handleSort}
            onRowEvent={setCurrentGroup}
          />
          {!groups.length && !isLoading ? (
            <>
              <div className="group-items__no-results">No results found.</div>
              <Divider />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GroupsWidget;
