import { useGroupsStore } from "@entities/groups";
import "./groupsPage.scss";
import { LayoutWithAside } from "@widgets/admin";
import {
  GroupsBottomBulkAction,
  GroupsBulkAction,
  GroupsWidget,
} from "@widgets/admin/groups";
import { useEffect } from "react";
import { GroupsTitle } from "@widgets/admin/groups";
import { useNavigate, useSearchParams } from "react-router-dom";
import { url } from "@shared/utils/url.utils";
import {
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";
import { ButtonStyled, LoaderFullScreen } from "@shared/ui/components";
import { Plus } from "@shared/ui/assets";
import { ROUTES } from "@shared/interfaces";

export const GroupsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getGroups, getGroupsFilters, isLoading, isInitialLoaderGroups } =
    useGroupsStore((state) => state);
  const { groups, createGroup } = useGroupsStore((state) => state);

  const searchObjectParams = url.getSearchParams(searchParams);

  useEffect(() => {
    getGroupsFilters(searchObjectParams);

    if (!Object.keys(searchObjectParams).length) {
      const updatedSearchParams = new URLSearchParams(searchParams);

      updatedSearchParams.set(FILTER_BY_PARAM, "ALL");
      updatedSearchParams.set(LIMIT_PARAM, "10");
      updatedSearchParams.set(OFFSET_PARAM, "0");
      setSearchParams(updatedSearchParams);
    }
  }, []);

  useEffect(() => {
    const isSearchParamsInit =
      searchObjectParams.limit || searchObjectParams.offset;

    if (isSearchParamsInit) {
      getGroups(searchObjectParams);
    }
  }, [searchParams]);

  const handleClick = async () => {
    const res = await createGroup();

    if (res) {
      const groupId = res.id;
      navigate(`${ROUTES.ADMIN_GROUPS_CREATE}/${groupId}`);
    }
  };

  const isFilter = searchParams.get(FILTER_BY_PARAM) === "ALL";
  const isSearch = searchParams.get(SEARCH_PARAM);

  const isShowEmpty = !groups.length && isFilter && !isSearch?.length;

  const empty = (
    <div className="groups-empty">
      <div className="groups-empty__content">
        <h4 className="groups-empty__title">
          You have not added any groups yet
        </h4>
        <h4 className="groups-empty__subtitle">
          <div>A group is a set of items with its own rules and limits.</div>
          <div>Create a group to start getting pricing recommendations.</div>
        </h4>
        <ButtonStyled
          type="primary"
          text="Create group"
          htmlType="button"
          fill="gray-primary-900"
          className="groups-create__btn"
          icon={<Plus />}
          onClick={handleClick}
        />
      </div>
    </div>
  );

  const chooseRender = isShowEmpty ? (
    empty
  ) : (
    <>
      <GroupsBulkAction />
      <GroupsWidget />
      <GroupsBottomBulkAction />
    </>
  );

  const isLoadingState = isLoading ? (
    <>
      <GroupsBulkAction />
      <GroupsWidget />
      <GroupsBottomBulkAction />
    </>
  ) : (
    chooseRender
  );

  return (
    <LayoutWithAside>
      <>
        <GroupsTitle />
        {isInitialLoaderGroups ? <LoaderFullScreen /> : isLoadingState}
      </>
    </LayoutWithAside>
  );
};

export default GroupsPage;
