import "./strategyRule.scss";
import Competition from "./Competition/Competition";
import Elasticity from "./Elasticity/Elasticity";
import {
  AdditionalCustomStep,
  AlertTarget,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import { useEffect } from "react";

interface IProps {
  setActiveTab: (index: CurrentStep | null) => void;
}

const EditStrategyRule = ({ setActiveTab }: IProps) => {
  const typeOfRule = useGroupsStore((state) => state.strategy.strategyType);

  const { updateStrategy } = useGroupsStore((state) => state);

  useEffect(() => {
    updateStrategy(AdditionalCustomStep.STRATEGY_CHECK_NO_COMPETITORS_ERROR);
  }, []);

  const renderObj = {
    [StrategyTypeEnum.ELASTICITY]: <Elasticity />,
    [StrategyTypeEnum.COMPETITION]: <Competition setActiveTab={setActiveTab} />,
  };

  if (!typeOfRule) {
    return <></>;
  }

  return <>{renderObj[typeOfRule]}</>;
};

export default EditStrategyRule;
