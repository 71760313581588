import { useUserStore } from "@entities/user";
import { IFormPasswordState } from "@shared/interfaces";
import { Close, Done } from "@shared/ui/assets";
import { ButtonStyled, NotificationCustom } from "@shared/ui/components";
import { FORGOT_PASSWORD_STEPS } from "@widgets/interfaces";
import { Input } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./enterNewPassword.scss";
import { validationSchema } from "./validation";

interface IProps {
  onClickContinue: ({ step }: { step: FORGOT_PASSWORD_STEPS }) => void;
}

const formState: IFormPasswordState = {
  password: "",
};

const optionsValidation = [
  {
    value: "Min 8 characters",
    isValid: false,
    key: "length",
  },

  {
    value: "At least one number",
    isValid: false,
    key: "number",
  },
  {
    value: "At least one letter",
    isValid: false,
    key: "letter",
  },
  {
    value: "One special character (!@#$%^&*)",
    isValid: false,
    key: "special",
  },
  {
    value: "One upper case letter",
    isValid: false,
    key: "upperLetter",
  },
];

const EnterNewPassword = (props: IProps) => {
  const { onClickContinue } = props;

  const [searchParams] = useSearchParams();
  const [options, setOptions] = useState(optionsValidation);
  const { onEditPassword } = useUserStore((state) => state);
  const [responseErrorSignUpText, setResponseErrorSignUpText] = useState("");
  const { openNotification, contextHolder } = NotificationCustom();

  const formik = useFormik({
    initialValues: formState,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const data: any = await onEditPassword({
          password: values.password,
          email: searchParams.get("email") || "",
          token: searchParams.get("token") || "",
        });

        if (data.accessToken) {
          localStorage.setItem("_accessToken", data.accessToken);
        }

        onClickContinue({
          step: FORGOT_PASSWORD_STEPS.COMPLETE,
        });
      } catch (error: any) {
        setResponseErrorSignUpText(error.response.data.message);
        openNotification({
          message: "Internal Server Error. Try one more time.",
          type: "default",
          duration: 5,
        });
      }
    },
  });

  const { setFieldValue, values, errors } = formik;

  const checkErrors = (key: string) => {
    return errors.password?.includes(key) || errors.password === "all";
  };

  const onChangeInput = ({ target }: any) => {
    setResponseErrorSignUpText("");
    const value = target.value;
    setFieldValue("password", value);

    if (value.length >= 8) {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "length") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/\d/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "number") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
    if (value.match(/[a-zA-Z]/)) {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "letter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[^A-Za-z0-9]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "special") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }

    if (/[A-Z]/.test(value)) {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = true;
        }
        return option;
      });
      setOptions(updateOptions);
    } else {
      const updateOptions = options.map((option) => {
        if (option.key === "upperLetter") {
          option.isValid = false;
        }
        return option;
      });
      setOptions(updateOptions);
    }
  };
  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit();
        e.preventDefault();
      }}
    >
      {contextHolder}
      <div className="enter-new-password ">
        <div className="enter-new-password -block">
          <div className="title">Create new password</div>
          <div className="text ">
            <div>Your new password must be different</div>
            <div>from previously used passwords</div>
          </div>
        </div>

        <div className="mb-24">
          <div className="label">Password</div>

          <Input.Password
            id="password"
            className="gray-primary-900"
            name="password"
            type="password"
            onChange={onChangeInput}
            value={values.password}
            status={(errors.password || responseErrorSignUpText) && "error"}
          />
          {(errors.password || responseErrorSignUpText) && (
            <div className="error-validation-message">
              {responseErrorSignUpText ||
                " Password doesn’t match the requirements"}
            </div>
          )}
        </div>
        <div>
          {options.map((option) => (
            <div key={option.value} className="new-password-validation-item">
              {option.isValid ? (
                <Done />
              ) : (
                <Close
                  width="16"
                  height="16"
                  color={checkErrors(option.key) ? "red" : "#6C757D"}
                />
              )}
              <span
                className={
                  !option.isValid && checkErrors(option.key)
                    ? "error-validation"
                    : undefined
                }
              >
                {option.value}
              </span>
            </div>
          ))}
        </div>
        <div className="mb-24 mt-24">
          <ButtonStyled
            text="Reset password"
            className="setpass__btn"
            type="primary"
            fill="gray-primary-900"
            htmlType="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default EnterNewPassword;
