import "./groupsFilters.scss";
import { IFilterItem } from "@shared/interfaces";
import { FilterItem } from "@features/filterItem";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { url } from "@shared/utils/url.utils";
import { Typography } from "antd";
import { EGroupStatus, useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { FILTER_BY_PARAM } from "@shared/constants";

const { Title } = Typography;

const GroupsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useGroupsStore((state) => state.filters);
  const { setGroups } = useGroupsStore((state) => state);

  const filterItems: IFilterItem[] = [
    {
      name: "All",
      value: "ALL",
      element: (
        <h4 className="filter__text filter__text-badge">
          {filters.groupAll || ""}
        </h4>
      ),
      className: "filter__all",
    },
    {
      name: "Reprice ready",
      value: EGroupStatus.REPRICE_READY,
      element: (
        <h4 className="filter__text filter__text-badge">
          {filters?.groupInRepriceReady || 0}
        </h4>
      ),
      className: "filter__reprice-ready",
    },
    {
      name: "Failed",
      value: EGroupStatus.FAILED,
      element: (
        <h4 className="filter__text filter__text-badge">
          {filters?.groupFailed || 0}
        </h4>
      ),
      className: "filter__failed",
    },
    {
      name: "In progress",
      value: EGroupStatus.IN_PROGRESS,
      element: (
        <h4 className="filter__text filter__text-badge">
          {filters?.groupInProgress || 0}
        </h4>
      ),
      className: "filter__in-progress",
    },
    {
      name: "Completed",
      value: EGroupStatus.COMPLETED,
      element: (
        <h4 className="filter__text filter__text-badge">
          {filters?.groupCompleted || 0}
        </h4>
      ),
      className: "filter__completed",
    },
  ];

  const defineActiveFilter = (item: IFilterItem) => {
    const searchObjectParams = url.getSearchParams(searchParams);
    const filterBy = searchObjectParams?.filterBy;

    if (filterBy && filterBy.toUpperCase() === item.value) {
      return item.value;
    }

    return "";
  };

  const onFilterItemClick = (value: string) => {
    if (searchParams.get(FILTER_BY_PARAM) !== value) {
      searchParams.set(FILTER_BY_PARAM, value);
      setSearchParams(searchParams);
      setGroups([]);
    }
  };

  return (
    <div className="groups-filter__container">
      {filterItems.map((item) => (
        <FilterItem
          item={item}
          key={item.value}
          active={defineActiveFilter(item)}
          onClick={onFilterItemClick}
          className={item.className}
        />
      ))}
    </div>
  );
};

export default GroupsFilters;
