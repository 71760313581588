import React from "react";

const CreditCardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
    >
      <g clipPath="url(#clip0_14666_65390)">
        <rect y="0.5" width="44" height="44" rx="8" fill="#D1E7DD" />
        <path
          d="M31 15H15C13.89 15 13.01 15.89 13.01 17L13 29C13 30.11 13.89 31 15 31H31C32.11 31 33 30.11 33 29V17C33 15.89 32.11 15 31 15ZM30 29H16C15.45 29 15 28.55 15 28V23H31V28C31 28.55 30.55 29 30 29ZM31 19H15V17H31V19Z"
          fill="#212529"
        />
      </g>
      <defs>
        <clipPath id="clip0_14666_65390">
          <rect y="0.5" width="44" height="44" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreditCardIcon;
