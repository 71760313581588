import React from "react";

const IntegrationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
    >
      <g clipPath="url(#clip0_14666_65403)">
        <path
          d="M36 0.5H8C3.58172 0.5 0 4.08172 0 8.5V36.5C0 40.9183 3.58172 44.5 8 44.5H36C40.4183 44.5 44 40.9183 44 36.5V8.5C44 4.08172 40.4183 0.5 36 0.5Z"
          fill="#F7D6E6"
        />
        <path
          d="M29 13H24.82C24.4 11.84 23.3 11 22 11C20.7 11 19.6 11.84 19.18 13H15C14.86 13 14.73 13.01 14.6 13.04C14.21 13.12 13.86 13.32 13.59 13.59C13.41 13.77 13.26 13.99 13.16 14.23C13.06 14.46 13 14.72 13 15V29C13 29.27 13.06 29.54 13.16 29.78C13.26 30.02 13.41 30.23 13.59 30.42C13.86 30.69 14.21 30.89 14.6 30.97C14.73 30.99 14.86 31 15 31H29C30.1 31 31 30.1 31 29V15C31 13.9 30.1 13 29 13ZM20.3 24.88C19.91 25.27 19.27 25.27 18.88 24.88L16.71 22.71C16.32 22.32 16.32 21.69 16.71 21.3L18.88 19.13C19.27 18.74 19.91 18.74 20.3 19.13C20.69 19.52 20.69 20.15 20.3 20.54L18.83 22L20.29 23.46C20.68 23.85 20.69 24.49 20.3 24.88ZM22 14.25C21.59 14.25 21.25 13.91 21.25 13.5C21.25 13.09 21.59 12.75 22 12.75C22.41 12.75 22.75 13.09 22.75 13.5C22.75 13.91 22.41 14.25 22 14.25ZM23.7 24.88C23.31 24.49 23.31 23.86 23.7 23.47L25.17 22L23.7 20.53C23.31 20.14 23.31 19.51 23.7 19.12C24.09 18.73 24.73 18.73 25.12 19.12L27.29 21.29C27.68 21.68 27.68 22.31 27.29 22.7L25.12 24.87C24.73 25.27 24.09 25.27 23.7 24.88Z"
          fill="#212529"
        />
      </g>
      <defs>
        <clipPath id="clip0_14666_65403">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IntegrationIcon;
