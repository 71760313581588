import { IProduct } from "@entities/products";
import { IDataTransfer, IQuery } from "@shared/interfaces";

export enum Period {
  LAST_28_DAYS = "last_28_days",
  LAST_7_DAYS = "last_7_days",
}

export interface DateOfCompetitors {
  competitorProductId: string | null;
  productId: string | null;
  price: string;
  date: string;
}

export interface IItemCompetitor {
  name: string;
  competitorProductPrice: number;
  competitorProductTraffic: number;
  competitorProductDelivery: string;
  monitoringStatus: boolean;
  id: number;
  competitorProductId: string;
  score?: number;
  promo?: boolean;
  last_change?: string;
  state: boolean;
  isAvailable: boolean;
  competitorLink: string;
}

export interface IItemInfoState {
  itemCompetitors: IItemCompetitor[];
  datesOfGraphCompetitors: DateOfCompetitors[];
  datesOfGraphElasticity: any[];
  isLoading: boolean;

  getItemCompetitors: (productId: string) => Promise<void>;
  changeMonitoringStatus: (
    id: number,
    monitoringStatus: boolean
  ) => Promise<void>;
  getDatesOfGraphCompetitors: (
    date: string,
    strategyId: string,
    competitorsIds: any[]
  ) => void;
  clearDatesOfGraphCompetitors: () => void;
  setDatesOfGraphElasticity: (arr: []) => void;
  getElasticityDataGraph: (productId: string) => void;
  changeLocalMonitoringStatus: (id: number, monitoringStatus: boolean) => void;
  filterByStateCompetitorsProduct: (
    id: string,
    state: boolean
  ) => Promise<void>;
  removeCompetitorMonitoringFromGroup: (id: string) => Promise<void>;
}
