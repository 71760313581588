interface IProps {
  color?: string;
}

const Star = ({ color = "#495057" }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_14889_69172)">
        <path
          d="M12.0003 17.7698L16.1503 20.2798C16.9103 20.7398 17.8403 20.0598 17.6403 19.1998L16.5403 14.4798L20.2103 11.2998C20.8803 10.7198 20.5203 9.61977 19.6403 9.54977L14.8103 9.13977L12.9203 4.67977C12.5803 3.86977 11.4203 3.86977 11.0803 4.67977L9.19032 9.12977L4.36032 9.53977C3.48032 9.60977 3.12032 10.7098 3.79032 11.2898L7.46032 14.4698L6.36032 19.1898C6.16032 20.0498 7.09032 20.7298 7.85032 20.2698L12.0003 17.7698Z"
          fill="#495057"
        />
      </g>
      <defs>
        <clipPath id="clip0_14889_69172">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;
