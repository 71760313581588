import { IDataTransfer } from "@shared/interfaces";

export enum UserRolesEnum {
  SU_ADMIN = "SU_ADMIN",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  VIEWER = "VIEWER",
}

export interface ISettingsUser {}

export interface IInviteUser {
  email: string;
  role: string | null;
}

export interface ISettingsStore {
  usersTable: ISettingsUser[];
  inviteUserError: string;
  recordsCountUsers: number;
  filtersUsersTable: { [key: string]: number };
  isLoadingUsersTableInitial: boolean;
  isLoadingUsersTable: boolean;
  isLoadingInviteUser: boolean;
  getUsersTable: (params: IDataTransfer) => void;
  saveInviteUser: (inviteUsers: IInviteUser) => Promise<any>;
  deleteUserById: (id: string) => Promise<void>;
  editUserById: (id: string, role: UserRolesEnum) => Promise<void>;
  getFiltersForUsers: () => Promise<any>;
  setUsersTable: (users: ISettingsUser[]) => void;
  resendInvitation: (email: string) => Promise<any>;
  setInviteUserErrorText: (text: string) => void;
}
