import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import { SortParams } from "@shared/interfaces";
import { ArrowBorder, Close, Search } from "@shared/ui/assets";
import { Checkbox, Divider, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import "./strategyCompetitionPopup.scss";
import { generateTextColumn } from "@shared/common";
import { ISortParams } from "@shared/interfaces";
import {
  ButtonStyled,
  InputCustom,
  LoaderDefault,
  ScoreRange,
} from "@shared/ui/components";
import { CurrentStep, ICompetitor, useGroupsStore } from "@entities/groups";
import { NavLink, useParams } from "react-router-dom";
import {
  filteredCompareQueryObj,
  getCompetitorWord,
  getItemWord,
} from "@shared/utils";

import { useDebounce } from "use-debounce";
import {
  CATEGORY_PARAM,
  DIRECTION_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";
import ScoreItem from "@shared/ui/components/scoreRange/ScoreRange";
import LinkToNew from "@shared/ui/assets/LinkToNew";

interface IProps {
  flag: boolean;
  onClose: () => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  TRAFFIC = "categoryTraffic",
}

const StrategyCompetitionPopup: FC<IProps> = (props) => {
  const { flag, onClose } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedValue] = useDebounce(searchValue, 500);
  const [monthlyCategoryTraffic, setMonthlyCategoryTraffic] =
    useState<number>(0);

  const { id } = useParams();
  const {
    getAllCompetitors,
    selectCompetitorsAll,
    selectCompetitor,
    addCompetitorsToGroup,
    setCompetitorsAllSelectedIds,
    clearCompetitorsAll,
    isLoading,
    selectProductOfCompetitor,
  } = useGroupsStore((state) => state);
  const competitorsAll = useGroupsStore((state) => state.competitorsAll);
  const competitorsAllSelectedIds = useGroupsStore(
    (state) => state.competitorsAllSelectedIds
  );
  const isAllSelected = (competitorsAll || [])?.every((competitor) =>
    competitorsAllSelectedIds.includes(competitor.id)
  );

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: "",
    direction: "",
  });

  useEffect(() => {
    const compareQueryObj = {
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
      [SEARCH_PARAM]: debouncedValue,
    };
    const queryObject = filteredCompareQueryObj(compareQueryObj);
    if (id && flag) {
      getAllCompetitors(id, queryObject);
    }

    return () => {
      setCompetitorsAllSelectedIds([]);
      clearCompetitorsAll();
    };
  }, [flag, sortConfig, debouncedValue]);

  useEffect(() => {
    return () => {
      setSearchValue("");
      setExpandedRowKeys([]);
    };
  }, [flag]);

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const checkMainCheckboxValue = () => {
    const isShowMinus = competitorsAll.reduce((acc, item) => {
      if (
        !acc &&
        item.products.some(
          (product) =>
            product.checked &&
            !competitorsAll.every((competitor) =>
              competitorsAllSelectedIds.includes(competitor.id)
            )
        )
      ) {
        return true;
      }
      return acc;
    }, false);
    return isShowMinus;
  };

  const checkCompetitorsCheckboxValue = (id: string) => {
    const isAllInCompetitorsChecked = competitorsAll.reduce(
      (acc, competitor) => {
        if (
          !acc &&
          competitor.id === id &&
          competitor.products.every((product) => product.checked)
        ) {
          acc = true;
        }

        return acc;
      },
      false
    );

    const isShowMinus = competitorsAll
      .find((item) => item.id === id)
      ?.products.some(
        (product) =>
          product.checked &&
          !competitorsAll.every((competitor) =>
            competitorsAllSelectedIds.includes(competitor.id)
          )
      );
    return isShowMinus && !isAllInCompetitorsChecked;
  };

  const headDataTable = [
    {
      content: (
        <>
          <Checkbox
            indeterminate={checkMainCheckboxValue()}
            checked={isAllSelected && !!competitorsAll.length}
            onChange={(event) => selectCompetitorsAll(event.target.checked)}
          />
        </>
      ),
      className: "table-w-52",
    },
    {
      content: <div></div>,
      className: "table-w-16 table__arrow",
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Competitor name",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
          })}
        </>
      ),
      className: "table-w-544 ",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Score",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.TRAFFIC === sortConfig.key,
            tooltipText: "Traffic per month",
          })}
        </>
      ),
      className: "table-w-117",
      sortKey: THEAD_SORT_KEYS.TRAFFIC,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: ICompetitor) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              indeterminate={checkCompetitorsCheckboxValue(id)}
              checked={competitorsAllSelectedIds.some((el) => el === id)}
              onChange={(event) => {
                selectCompetitor(id);
              }}
            />
          </div>
        );
      },
      className: "table-w-52 table__checkbox",
    },
    {
      content: ({ id }: any) => {
        return (
          <>
            {expandedRowKeys.includes(id) ? (
              <div className="arrow__border-top">
                <ArrowBorder />
              </div>
            ) : (
              <div className="arrow__border-bottom">
                <ArrowBorder />
              </div>
            )}
          </>
        );
      },
      className: "table__arrow table-w-16",
    },
    {
      content: ({ name, externalLink, products }: ICompetitor) => {
        return (
          <span>
            {/* <a href={externalLink} target="_blank"> */}
            {name}
            {/* </a> */}
            <span className="expanded__list-count">
              ({products.length} {getItemWord(products.length)})
            </span>
          </span>
        );
      },
      className: "table-item-title table-w-544 ",
    },
    {
      content: ({ score }: ICompetitor) => {
        return (
          <div className="table-item-traffic ">
            <div> {score}</div>

            <div>{score ? <ScoreRange count={score} /> : "-"}</div>
          </div>
        );
      },
      className: "table-item-title ta-left table-w-117",
    },
  ];

  const handleExpanded = (item: string) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(item)) {
        return prevKeys.filter((key) => key !== item);
      } else {
        return [...prevKeys, item];
      }
    });
  };

  const dataTableBody = competitorsAll.map((item: ICompetitor | any) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      className: el.className,
      isSelected: competitorsAllSelectedIds.some((id) => id === item.id),
      isExpanded: expandedRowKeys.includes(item.id),
      item: item.id,
    }));
  });

  const handleChangeInput = (target: any) => {
    const value = target.value;
    setSearchValue(value);
  };

  const handleClickAdd = async () => {
    await addCompetitorsToGroup();
    onClose();
  };

  const chooseShowNoEmptyData = !competitorsAll.length ? (
    <>
      <div>You have already added all suggested direct competitors.</div>
      <div>No more available to add at this time.</div>
      <div className="empty-data">
        <span> Didn’t find your competitors? Please&nbsp;</span>
        <span className={"no-data-link"}>Contact customer support</span>
      </div>
    </>
  ) : (
    ""
  );

  const handleClick = (productId: string) => {
    selectProductOfCompetitor(productId);
  };

  return (
    <Modal
      open={flag}
      width={856}
      onOk={() => {}}
      className="modal__competition"
      maskClosable={false}
      closeIcon={false}
      centered={true}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onClose}
            />

            <ButtonStyled
              type="primary"
              text="Add"
              htmlType="button"
              fill="gray-primary-900"
              className={`w-84`}
              // icon={<ArrowRight />}
              onClick={handleClickAdd}
              // iconPosition="end"
            />
          </div>
        </div>,
      ]}
    >
      <div className="modal__container">
        <div onClick={() => onClose()} className="modal-close__icon">
          <Close />
        </div>
        <h2 className="modal__title">
          Add competitors
          <p className="groups-strategy__subtitle">
            View direct competitors offering identical product items
          </p>
        </h2>
        <Divider />
        <div className="modal__content">
          <div className="modal__body modal__body-competition">
            <div className="modal__search-competitor-block">
              <InputCustom
                type="text"
                value={searchValue}
                name="items-search"
                placeholder="Search for competitors"
                onChangeInput={({ target }) => handleChangeInput(target)}
                fill="gray-primary-900"
                iconRight={<Search />}
              />
            </div>
            <div className="modal__table">
              {isLoading ? (
                <>
                  <LoaderDefault />
                </>
              ) : !competitorsAll.length && debouncedValue ? (
                <div className="no-data">
                  <TableCustom
                    headData={headDataTable}
                    noData={<div className="no-data">No results found.</div>}
                    noDataColspan={4}
                  />
                  <div className="no-data-text">
                    <span> Didn’t find your competitors? Please&nbsp;</span>
                    <span className={"no-data-link"}>
                      Contact customer support
                    </span>
                  </div>
                </div>
              ) : competitorsAll.length ? (
                <>
                  <TableCustom
                    isModalMode={true}
                    // noDataText={chooseShowNoEmptyData}
                    headData={headDataTable}
                    bodyData={dataTableBody}
                    onRowEvent={(item) => {
                      handleExpanded(item);
                    }}
                    handleSort={handleSort}
                    expandedRowRender={(rowItem: string) => {
                      const expandedRow = competitorsAll.find(
                        (el) => el.id === rowItem
                      );
                      if (expandedRow) {
                        return expandedRowKeys.includes(rowItem) ? (
                          <>
                            {expandedRow.products.map((product) => {
                              return (
                                <tr
                                  className={`disable-default-styles ${
                                    product.checked ? "tr-selected" : ""
                                  }`}
                                >
                                  <td></td>
                                  <td className="disable-padding">
                                    <div className=" check-item-product">
                                      <Checkbox
                                        checked={product.checked}
                                        onClick={() => handleClick(product.id)}
                                      />
                                    </div>
                                  </td>
                                  <td
                                    colSpan={2}
                                    className="expanded__list-container"
                                  >
                                    <ul>
                                      <li
                                        key={product.id}
                                        className="expanded__list"
                                      >
                                        <div className="df">
                                          <div
                                            className="expanded__list-item"
                                            onClick={() =>
                                              handleClick(product.id)
                                            }
                                          >
                                            {product.name}
                                          </div>
                                          <a
                                            className="icon"
                                            target="_blank"
                                            href={product.externalLink}
                                          >
                                            <LinkToNew />
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : null;
                      }
                      return null;
                    }}
                  />
                  <div className="support-text">
                    <span> Didn’t find your competitors? Please&nbsp;</span>
                    <span className={"no-data-link"}>
                      Contact customer support
                    </span>
                  </div>
                </>
              ) : (
                <div className="df jc-c ai-center no__data">
                  <div className="ta-center">{chooseShowNoEmptyData}</div>
                </div>
              )}
            </div>
            <div className="selected__container">
              <div className="selected__block mr-24">
                <span className="selected__count">
                  {competitorsAllSelectedIds.length || 0}
                </span>
                <span className="selected__text">
                  {getCompetitorWord(competitorsAllSelectedIds.length)} selected
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StrategyCompetitionPopup;
