import { FC, useEffect, useState } from "react";
import "./itemMarket.scss";
import {
  AlertCustom,
  BadgeCustom,
  CustomTooltip,
  MultipleSelect,
  NotificationCustom,
  ScoreRange,
} from "@shared/ui/components";
import {
  ArrowBorder,
  Done,
  Info,
  Notification,
  StarBorder,
  Warning,
} from "@shared/ui/assets";
import { Select, Switch } from "antd";
import CustomGraphLine from "../CustomGraphLine/CustomGraphLine";
import { generateTextColumn, TableCustom } from "@shared/common";
import {
  IItemCompetitor,
  Period,
  useItemInfoStore,
} from "@entities/itemDetails";
import ConfirmMonitoringPopup from "../ConfirmMonitoringPopup/ConfirmMonitoringPopup";
import moment from "moment";
import { Spiner } from "@features/spiner";
import { RepricingStatus } from "@entities/products";
import { useParams } from "react-router-dom";
import { VIEWER_DISABLE_ITEMS_TEXT } from "@shared/constants/utilsConstants";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { useGroupStore } from "@entities/group";
import Star from "@shared/ui/assets/Star";
import LinkToNew from "@shared/ui/assets/LinkToNew";
import CheckComplete from "@shared/ui/assets/CheckComplete";
import { getCompetitorWord, getTimeAgo } from "@shared/utils";

const optionsPeriod = [
  { value: Period.LAST_7_DAYS, label: "Last 7 days" },
  { value: Period.LAST_28_DAYS, label: "Last 28 days" },
];

const arrColors = ["#6610F2", "#D63384", "#FD7E14", "#0DCAF0", "#198754"];

const defaultViewCount = 5;

interface IProps {
  productId: string;
}

const ItemMarket: FC<IProps> = (props) => {
  const { productId } = props;
  const { id } = useParams();
  const { itemCompetitors } = useItemInfoStore((state) => state);
  const { isViewer } = useCheckRole();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isShowAllCompetitors, setIsShowAllCompetitors] = useState(false);
  const { contextHolder, openNotification } = NotificationCustom();

  const {
    getItemCompetitors,
    changeMonitoringStatus,
    getDatesOfGraphCompetitors,
    datesOfGraphCompetitors,
    clearDatesOfGraphCompetitors,
    isLoading,
    removeCompetitorMonitoringFromGroup,
    changeLocalMonitoringStatus,
    filterByStateCompetitorsProduct,
  } = useItemInfoStore((state) => state);
  const { getProductById, setStatusProductById, getGroup } = useGroupStore(
    (state) => state
  );

  const getCountNewPriceReady = useGroupStore(
    (state) => state.getCountNewPriceReady
  );
  const [isOpenConfirmMonitoringDialog, setOpenConfirmMonitoringDialog] =
    useState(false);
  const [activeCompetitorInfo, setActiveCompetitorInfo] = useState<any>(null);

  const [selectedPeriod, setSelectedPeriod] = useState<Period>(
    Period.LAST_7_DAYS
  );

  const customStringProductId = "customStringProductId";

  const compareNewDataArrFromNames = itemCompetitors
    ? itemCompetitors
        .map((el, ind) => ({
          id: el.competitorProductId,
          name: el.name,
          color: el.state && arrColors[ind],
        }))
        .concat([
          {
            id: customStringProductId,
            name: "yourCurrentPrice",
            color: "#000000",
          },
        ])
    : [];

  const transformCompetitorData = () => {
    if (!datesOfGraphCompetitors.length) return [];

    const result: any = [];

    const idToNameMap = Object.fromEntries(
      compareNewDataArrFromNames.map(({ id, name }) => [id, name])
    );

    const sortedDates = datesOfGraphCompetitors.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const groupedByDate: Record<
      string,
      Record<string, string>
    > = sortedDates.reduce((acc: any, { price, date, competitorProductId }) => {
      const formattedDate = date.split("T")[0];
      if (!acc[formattedDate]) acc[formattedDate] = {};
      const actuallyId = competitorProductId || customStringProductId;

      if (actuallyId) {
        acc[formattedDate][idToNameMap[actuallyId]] = price;
      }

      return acc;
    }, {});

    for (const [date, prices] of Object.entries(groupedByDate)) {
      result.push({ date, ...prices });
    }

    return result;
  };

  const getDateToRequest = () => {
    const date28DaysFromNow = moment()
      .subtract(28, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const date7DaysFromNow = moment()
      .subtract(6, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const chooseDateToRequest =
      selectedPeriod === Period.LAST_28_DAYS
        ? date28DaysFromNow
        : date7DaysFromNow;
    return chooseDateToRequest;
  };

  const getItemCompetitorsLocal = async () => {
    const data = await getItemCompetitors(productId);
    return data;
  };

  useEffect(() => {
    clearDatesOfGraphCompetitors();
    const fetchData = async () => {
      const data: any = await getItemCompetitorsLocal();

      if (data) {
        const competitorsIds = data
          .filter((item: IItemCompetitor) => item.state)
          .map((item: IItemCompetitor) => item.competitorProductId);
        await getDatesOfGraphCompetitors(
          getDateToRequest(),
          productId,
          competitorsIds
        );
      }
    };

    fetchData();

    return () => {
      setIsShowAllCompetitors(false);
    };
  }, [productId]);

  const historyTooltip = (
    <CustomTooltip
      title={"View competitors’ pricing history data."}
      placement="right"
    >
      <Info />
    </CustomTooltip>
  );

  const competitorsTooltip = (
    <CustomTooltip
      title={"View key competitors’ data for today."}
      placement="right"
    >
      <Info />
    </CustomTooltip>
  );

  const handleChangePeriod = async (value: Period) => {
    clearDatesOfGraphCompetitors();
    const date28DaysFromNow = moment()
      .subtract(28, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const date7DaysFromNow = moment()
      .subtract(6, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const chooseDateToRequest =
      value === Period.LAST_28_DAYS ? date28DaysFromNow : date7DaysFromNow;
    setSelectedPeriod(value);

    const data: any = await getItemCompetitorsLocal();
    const competitorsIds = data
      .filter((item: IItemCompetitor) => item.state)
      .map((item: IItemCompetitor) => item.competitorProductId);

    getDatesOfGraphCompetitors(chooseDateToRequest, productId, competitorsIds);
  };

  const getCountActiveCompetitors = () => {
    return itemCompetitors.filter((item: IItemCompetitor) => item.state).length;
  };

  const handleClickCompetitorState = async (id: number, state: boolean) => {
    const count = getCountActiveCompetitors();

    const modifyCount = !state ? count + 1 : count - 1;

    if (modifyCount <= defaultViewCount) {
      await filterByStateCompetitorsProduct(id.toString(), !state);

      const data: any = await getItemCompetitorsLocal();
      const competitorsIds = data
        .filter((item: IItemCompetitor) => item.state)
        .map((item: IItemCompetitor) => item.competitorProductId);

      await getDatesOfGraphCompetitors(
        getDateToRequest(),
        productId,
        competitorsIds
      );
    }
  };

  const onRemoveItem = async (id: string) => {
    await removeCompetitorMonitoringFromGroup(id);
    openNotification({
      type: "default",
      message: "Competitor’s product has been removed",
    });
    await getItemCompetitorsLocal();
  };

  const headDataTable = [
    {
      content: generateTextColumn({
        text: "Competitor name",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
      }),
      className: "table-w-180",
    },
    {
      content: generateTextColumn({
        text: "Score",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
        tooltipText:
          "Competitor Score calculated based on several factors, such as competitor traffic, impact on sales, and others.",
      }),
      className: "table-w-90",
    },
    {
      content: generateTextColumn({
        text: "Delivery",
        tooltipText: "Expected delivery time for competitor product item",
      }),
      className: "table-w-104",
    },

    {
      content: generateTextColumn({
        text: "Promo",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
        tooltipText: "Discounted/promotional product",
      }),
      className: "table-w-87",
    },

    {
      content: generateTextColumn({
        text: "Price",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
        tooltipText:
          "Current price and last updated time from the competitor's website",
      }),
      className: "table-w-104 ",
    },
    {
      content: generateTextColumn({
        text: "Calc",
        tooltipText: "Enable/disable competitor price in strategy calculation",
      }),
      className: "table-w-75",
    },
  ];

  const dataTableBodyCompare = [
    {
      content: (
        { name, state, id, competitorLink, isAvailable }: IItemCompetitor,
        ind: number
      ) => {
        const isFullFields =
          itemCompetitors.filter((item: IItemCompetitor) => item.state)
            .length === defaultViewCount;
        const chooseText = isFullFields
          ? "To show a competitor on Price History chart, please unstar an existing competitor first (limit: 5 selected competitors)"
          : "Show on Price History chart";

        return (
          <div className="name-block-with-color">
            <CustomTooltip
              title={!state ? chooseText : ""}
              styles={{ width: isFullFields ? 340 : "auto" }}
            >
              <div
                className="df"
                onClick={() => handleClickCompetitorState(id, state)}
              >
                {state ? (
                  <Star />
                ) : (
                  <StarBorder color={isFullFields ? "#CED4DA" : "#6C757D"} />
                )}
              </div>
            </CustomTooltip>
            <div
              className="colorCircle"
              style={{ backgroundColor: state ? arrColors[ind] : "" }}
            ></div>
            <div className="name-competitor"> {name}</div>
            <div
              className={`ml-8 link-to-new ${isAvailable ? "" : "disabled"}`}
            >
              <a href={competitorLink} target="_blank" className="df">
                <LinkToNew />
              </a>
            </div>
          </div>
        );
      },
      className: "table-w-180",
    },
    {
      content: ({ score, isAvailable, id }: IItemCompetitor) =>
        isAvailable ? (
          <div className="table-item-traffic">
            <div> {score}</div>

            <div>{score ? <ScoreRange count={score} /> : "-"}</div>
          </div>
        ) : (
          <AlertCustom
            type={"warning"}
            message={
              <>
                Product no longer available.{" "}
                <span
                  className="remove-competitor-item"
                  onClick={() => onRemoveItem(id.toString())}
                >
                  Remove competitor’s item
                </span>
              </>
            }
            className="alert-custom-td"
            icon={<Warning />}
          />
        ),
      className: "table-w-90",
      checkColspan: ({ isAvailable }: IItemCompetitor) => (isAvailable ? 1 : 5),
    },
    {
      content: ({
        competitorProductDelivery,
        isAvailable,
      }: IItemCompetitor) => {
        const isOutOfStock =
          competitorProductDelivery.toLowerCase() === "out of stock";

        const value = isOutOfStock ? (
          <CustomTooltip title={"Out of stock"}>
            <BadgeCustom text={"OOS"} className="badge-custom" />
          </CustomTooltip>
        ) : (
          <div>{competitorProductDelivery}</div>
        );
        return isAvailable ? value : null;
      },
      className: "table-w-104 deliveryColumn",
      checkColspan: ({ isAvailable }: IItemCompetitor) => (isAvailable ? 1 : 0),
    },
    {
      content: ({ promo, isAvailable }: IItemCompetitor) => {
        return isAvailable ? (
          <div className="df ai-center jc-c">
            {promo ? <Done size={"20"} color="#6C757D" /> : ""}
          </div>
        ) : null;
      },
      className: "table-w-87",
      checkColspan: ({ isAvailable }: IItemCompetitor) => (isAvailable ? 1 : 0),
    },
    {
      content: ({
        competitorProductPrice,
        last_change,
        isAvailable,
      }: IItemCompetitor) => {
        return isAvailable ? (
          <div>
            <div>${competitorProductPrice}</div>
            <div className="time-ago">{getTimeAgo(last_change || "")}</div>
          </div>
        ) : null;
      },
      className: "table-w-104 ",
      checkColspan: ({ isAvailable }: IItemCompetitor) => (isAvailable ? 1 : 0),
    },
    {
      content: ({
        monitoringStatus,
        id,
        name,
        isAvailable,
      }: IItemCompetitor) => {
        return isAvailable ? (
          <div className="df ai-center jc-c">
            <CustomTooltip
              styles={{ width: "250px" }}
              title={
                isViewer
                  ? VIEWER_DISABLE_ITEMS_TEXT
                  : monitoringStatus
                  ? "Enabled competitor price in strategy calculation. Click to disable"
                  : "Disabled competitor price in strategy calculation. Click to enable"
              }
              placement="top"
            >
              <div className={isViewer ? "disabled" : ""}>
                <Switch
                  onChange={() => {
                    if (!isViewer) {
                      setActiveCompetitorInfo({ monitoringStatus, id, name });
                      setOpenConfirmMonitoringDialog(true);
                    }
                  }}
                  checked={monitoringStatus}
                />
              </div>
            </CustomTooltip>
          </div>
        ) : null;
      },
      className: "table-w-75",
      checkColspan: ({ isAvailable }: IItemCompetitor) => (isAvailable ? 1 : 0),
    },
  ];

  const chooseLengthCompetitors = () => {
    if (!isShowAllCompetitors) {
      return itemCompetitors.filter((item: IItemCompetitor) => item.state);
    }
    if (isShowAllCompetitors) {
      return itemCompetitors;
    }

    return [];
  };

  const dataTableBody = chooseLengthCompetitors().map((item, ind) => {
    return dataTableBodyCompare.map((el) => {
      const isColspan =
        el.checkColspan && Number.isInteger(el.checkColspan(item));

      return {
        element: el.content(item, ind),
        className: el.className,
        item: item,
        colspan: isColspan ? el.checkColspan(item) : 1,
      };
    });
  });

  const data = transformCompetitorData();

  const handleOk = async () => {
    setStatusProductById(productId, RepricingStatus.PENDING);
    changeLocalMonitoringStatus(
      activeCompetitorInfo.id,
      !activeCompetitorInfo.monitoringStatus
    );
    setOpenConfirmMonitoringDialog(false);

    await changeMonitoringStatus(
      activeCompetitorInfo.id,
      !activeCompetitorInfo.monitoringStatus
    );
    id && (await getGroup(id));
    await getProductById(productId);
    await getCountNewPriceReady(id);
  };

  const lowestPrice = itemCompetitors.reduce((acc, item) => {
    if (acc > item.competitorProductPrice && item.isAvailable) {
      return item.competitorProductPrice;
    }
    return acc;
  }, Infinity);

  const highestPrice = itemCompetitors.reduce((acc, item) => {
    if (acc < item.competitorProductPrice && item.isAvailable) {
      return item.competitorProductPrice;
    }
    return acc;
  }, 0);

  const averagePrice =
    itemCompetitors.reduce((acc, item) => {
      if (item.competitorProductPrice && item.isAvailable) {
        return acc + +item.competitorProductPrice;
      }

      return acc;
    }, 0) / itemCompetitors.filter((el) => el.isAvailable).length;

  const heightCompetitorTr = 54;
  const paddingCompetitors = 0;
  const togglerList = 60;
  const chooseAddHeight =
    itemCompetitors.filter((el) => el.state).length !== itemCompetitors.length
      ? togglerList
      : 0;

  const calcHeightTable =
    heightCompetitorTr +
    paddingCompetitors +
    +chooseAddHeight +
    chooseLengthCompetitors().length * heightCompetitorTr +
    "px";

  return (
    <>
      {contextHolder}
      <ConfirmMonitoringPopup
        isOpenModal={isOpenConfirmMonitoringDialog}
        onClose={() => setOpenConfirmMonitoringDialog(false)}
        competitorName={activeCompetitorInfo?.name || ""}
        onOk={handleOk}
      />
      <div className="item-market">
        <section>
          <div className="item-market-info-badge">
            <div>
              <div className="title">↓ Lowest price</div>
              {
                <div className="text">
                  {lowestPrice && lowestPrice !== Infinity ? (
                    <> ${lowestPrice}</>
                  ) : (
                    <>-</>
                  )}
                </div>
              }
            </div>
            <div>
              <div className="title">Average price </div>
              <div className="text">
                {averagePrice ? <>${averagePrice.toFixed(2)}</> : <>-</>}
              </div>
            </div>
            <div>
              <div className="title">↑ Highest price </div>
              <div className="text">
                {highestPrice ? <>${highestPrice}</> : <>-</>}
              </div>
            </div>
          </div>
        </section>
        <section className="item-market-history df  ai-center jc-sb">
          <h3 className="market-title">
            Price History <span>{historyTooltip}</span>
          </h3>
          <div className="market-select-period">
            <Select
              value={selectedPeriod}
              options={optionsPeriod}
              placeholder="Select period"
              className="custom-arrow"
              suffixIcon={<ArrowBorder color="#212527" />}
              onChange={(value) => {
                handleChangePeriod(value);
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </section>

        {!isLoading ? (
          <section className="item-market-graph">
            <CustomGraphLine
              data={data}
              mode={selectedPeriod}
              dataSettings={compareNewDataArrFromNames}
              dataOriginal={datesOfGraphCompetitors}
            />
          </section>
        ) : (
          <div className="item-market-graph-loader">
            <Spiner />
          </div>
        )}
        <section className="market-competitors">
          <h3 className="market-title-competitors">
            Competitors ({itemCompetitors.length}){" "}
            <span>{competitorsTooltip}</span>
          </h3>
          <div
            className="market-competitors-table"
            style={{
              height: calcHeightTable,
            }}
          >
            <TableCustom
              tableClassName="table-competitors"
              headData={headDataTable}
              bodyData={dataTableBody}
            />
            {itemCompetitors.filter((el) => el.state).length !==
            itemCompetitors.length ? (
              <div
                className="toggle-competitors-list"
                onClick={() => setIsShowAllCompetitors(!isShowAllCompetitors)}
              >
                {isShowAllCompetitors ? (
                  "Show less"
                ) : (
                  <>
                    Show more (+
                    {itemCompetitors.filter((item) => !item.state).length}{" "}
                    {getCompetitorWord(
                      itemCompetitors.length - defaultViewCount
                    )}
                    )
                  </>
                )}
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </>
  );
};

export default ItemMarket;
