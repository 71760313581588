import { FC } from "react";
import { Divider, Modal } from "antd";
import { Typography } from "antd";
import { ButtonStyled } from "@shared/ui/components";
import { Close } from "@shared/ui/assets";

const { Title } = Typography;

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
  onClickSaveChanges: () => void;
}

const DiscardPopup: FC<IProps> = ({
  flag,
  onClose,
  onOk,
  onClickSaveChanges,
}: IProps) => {
  return (
    <Modal
      open={flag}
      onOk={() => {}}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose()}
    >
      <div className="groups__form-create">
        <Title level={2} className="groups__form-title">
          Discard Group Changes
        </Title>
        <Divider />
        <Title level={5} className="groups__form-suptitle">
          This action will permanently delete all changes made to the current
          group configurations.
        </Title>
        <div onClick={() => onClose()} className="modal-close__icon">
          <Close />
        </div>
        <Divider />
        <div className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Save changes"
              htmlType="button"
              className="w-84 mr-16 reject__btn"
              onClick={onClickSaveChanges}
            />

            <ButtonStyled
              text="Discard changes"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-210"
              onClick={onOk}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardPopup;
