import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IGroupState } from "./group.types";
import { errorHandler, loadStateWrapper } from "@shared/utils";
import { IDataTransfer } from "@shared/interfaces";
import groupService from "../api";
import { IProduct, RepricingStatus } from "@entities/products";
import {
  AlertTarget,
  CurrentStep,
  EGroupStatus,
  EGroupType,
  IBrand,
  IGroup,
  IProductExceptionItem,
} from "@entities/groups";

export const initStateGroup: IGroup = {
  id: "",
  name: "",
  totalItems: 0,
  repriceItems: 0,
  isSelected: false,
  type: EGroupType.DRAFT,
  status: EGroupStatus.COMING_SOON,
  currentStep: CurrentStep.PRODUCT_ITEM,
  alerts: [],
};

export const useGroupStore = create<IGroupState>()(
  devtools(
    immer((set, get) => ({
      productsInGroup: [],
      currentGroup: initStateGroup,
      brandsOfGroup: [],
      recordsCountInGroup: 0,
      isInitialLoadingGroupItems: true,
      isLoading: false,
      productsNewRepriceReady: 0,
      competitorsInGroup: [],
      repricedIds: [],
      recordsCountForReprice: 0,
      productsForReprice: [],
      strategy: [],
      getGroup: async (groupId: string) => {
        try {
          const { data } = await groupService.getGroup(groupId);
          const { strategies, ...groupInfo } = data;
          set({
            currentGroup: groupInfo,
            strategy: strategies[0],
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      clearGroup: () => {
        set({
          currentGroup: initStateGroup,
        });
      },
      setCompetitorsInGroup: (arr: IProduct[]) => {
        set({
          competitorsInGroup: arr,
        });
      },
      applyNewPrices: async (groupId: string) => {
        const { data } = await groupService.applyNewPrices(groupId);
      },
      setStatusProductById: (id: string, status: RepricingStatus) => {
        const products = get().productsInGroup;
        set({
          productsInGroup: products.map((p) =>
            p.id === id ? { ...p, status } : p
          ),
        });
      },

      getProductById: async (id: string) => {
        try {
          const { data } = await groupService.getProductById(id);
          const productsInGroup = get().productsInGroup;

          const newProducts = productsInGroup.map((el) =>
            el.id === data.id ? data : el
          );

          set({
            productsInGroup: newProducts,
          });
        } catch (e) {
          console.log(e);
        }
      },

      modifyException: async (productId: string, exceptionLimits: any) => {
        const { data } = await loadStateWrapper(
          set,
          groupService.modifyException(productId, exceptionLimits),
          "isLoadingModifyException"
        );

        const productsInGroup = get().productsInGroup.map((el) => {
          if (el.id === productId) {
            return data;
          }
          return el;
        });

        set({ productsInGroup, currentGroup: data.group });
        return data;
      },

      removeExceptionItemGroupById: async (id: string) => {
        const { data } = await groupService.removeExceptionItemGroupById(id);
        const newProducts = get().productsInGroup.map((el) => {
          if (el.id === data.id) {
            return data;
          }
          return el;
        });
        set({ productsInGroup: newProducts, currentGroup: data.group });
      },

      removeLocalExceptionById: async (id: string) => {
        const filteredProductsInGroup = get().productsInGroup.map((el) => {
          if (el.id === id) {
            return { ...el, exceptionLimits: undefined };
          }
          return el;
        });

        set({ productsInGroup: filteredProductsInGroup });
      },

      modifyLocalException: (
        productId: string,
        exceptionLimits: IProductExceptionItem
      ) => {
        const productsInGroup = get().productsInGroup.map((el) => {
          if (el.id === productId) {
            return {
              ...el,
              exceptionLimits,
            };
          }
          return el;
        });
        set({ productsInGroup });
      },
      handleSaveNewPrice: async (productId: string, newPrice: number) => {
        const productsInGroup = get().productsInGroup;
        try {
          const { data } = await groupService.saveNewPrice(productId, newPrice);
          const index = productsInGroup.findIndex(
            (item) => item.id === productId
          );

          let updatedProducts = productsInGroup;

          if (index !== -1) {
            updatedProducts = productsInGroup.splice(index, 1, data);
          }
          set({
            productsInGroup: productsInGroup,
          });
        } catch (e) {
          console.log(e);
        }
      },

      getProductsForReprice: async (params: IDataTransfer) => {
        try {
          const { data } = await groupService.getProductsForReprice(params);
          set({
            productsForReprice: data.items,
            recordsCountForReprice: data.count,
          });
        } catch (e) {
          console.log(e);
        }
      },
      setProductsForReprice: (products: IProduct[]) => {
        set({
          productsForReprice: products,
        });
      },

      updateGroupName: async (value: string) => {
        const currentGroupId = get().currentGroup.id;
        try {
          const res = await groupService.updateGroup({
            id: get().currentGroup.id,
            name: value,
          });

          if (res?.status !== 200) {
            throw res.data;
          }

          if (currentGroupId) {
            set({ currentGroup: { ...get().currentGroup, name: value } });
          }
          return res.data;
        } catch (e) {
          const customError: any = errorHandler.isResponseHandled(e);

          if (customError) {
            throw customError;
          }
        }
      },
      setRepricedIds: (arr: string[]) => {
        set({
          repricedIds: arr,
        });
      },
      setNewStatusGroup: (status: EGroupStatus) => {
        set({
          currentGroup: {
            ...get().currentGroup,
            status,
          },
        });
      },
      setCountNewPriceReady: (count: number) => {
        set({ productsNewRepriceReady: count });
      },

      clearIsRepricedStatuses: async () => {
        set({
          productsInGroup: get().productsInGroup.map((el: any) => ({
            ...el,
            isRepriced: false,
          })),
        });
      },

      fetchChangeLockProductMode: async (
        productId: string,
        isLocked: boolean
      ) => {
        const { data } = await groupService.fetchChangeLockProductMode(
          productId,
          isLocked
        );

        const updatedProductsInGroup = get().productsInGroup.map((el) => {
          if (el.id !== data.id) {
            return el;
          }
          return data;
        });

        set({
          productsInGroup: updatedProductsInGroup,
          currentGroup: data.group,
        });
      },

      changeCountNewPriceReady: (isLocked: boolean, productId: string) => {
        const productsNewRepriceReady = get().productsNewRepriceReady;
        const productsInGroup = get().productsInGroup;
        let newValueCount = productsNewRepriceReady;
        const findProduct = productsInGroup.find((el) => el.id === productId);
        if (findProduct?.newPrice && +findProduct?.newPrice > 0) {
          !isLocked ? newValueCount++ : newValueCount--;
        }

        set({
          productsNewRepriceReady: newValueCount,
        });
      },

      getProductsInGroup: async (params: IDataTransfer) => {
        if (params.groupId) {
          try {
            const { data } = await loadStateWrapper(
              set,
              groupService.getProductsInGroup({ ...params })
            );
            set({
              productsInGroup: data.items,
              recordsCountInGroup: data.count,
              isInitialLoadingGroupItems: false,
            });
          } catch (e) {
            console.log(e);
          }
        }
      },
      getBrandsInGroup: async () => {
        const groupId = get().currentGroup.id;
        if (groupId) {
          try {
            const { data } = await groupService.getBrandsInGroup(groupId);
            set({ brandsOfGroup: data });
          } catch (e) {
            console.log(e);
          }
        }
      },
      setProductsInGroup: (value: IProduct[]) => {
        set({ productsInGroup: value });
      },
      setBrands: (brands: IBrand[]) => {
        set({ brandsOfGroup: brands });
      },
      seTisInitialLoadingGroupItems: (value: boolean) => {
        set({ isInitialLoadingGroupItems: value });
      },
      getCountNewPriceReady: async (groupId?: string) => {
        const groupIdLocal = groupId ?? get().currentGroup.id;
        if (!groupIdLocal) {
          return;
        }
        const { data } = await groupService.getNewPriceReadyCount(groupIdLocal);

        set({
          productsNewRepriceReady: data.productsNewRepriceReady,
        });
      },

      closeAlertByGroupId: async (
        groupId: string,
        target: AlertTarget,
        isShow: boolean
      ) => {
        const { data } = await groupService.closeAlertByGroupId(
          groupId,
          target,
          isShow
        );
        set({ currentGroup: data });
      },
      getProductsOfGroupCSVFile: (groupId: string) => {
        const response = groupService.getProductsOfGroupCSVFile(groupId);
        return response;
      },
    }))
  )
);
