interface IProps {
  color?: string;
}

const CheckCircle = ({ color = "#198754" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
  >
    <mask
      id="mask0_6755_24648"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="64"
      height="65"
    >
      <rect y="0.5" width="64" height="64" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6755_24648)">
      <path
        d="M28.2666 37.3002L22.5333 31.5668C22.0444 31.0779 21.4221 30.8335 20.6666 30.8335C19.911 30.8335 19.2888 31.0779 18.7999 31.5668C18.311 32.0557 18.0666 32.6779 18.0666 33.4335C18.0666 34.1891 18.311 34.8113 18.7999 35.3002L26.3999 42.9002C26.9333 43.4335 27.5555 43.7002 28.2666 43.7002C28.9777 43.7002 29.5999 43.4335 30.1333 42.9002L45.1999 27.8335C45.6888 27.3446 45.9333 26.7224 45.9333 25.9668C45.9333 25.2113 45.6888 24.5891 45.1999 24.1002C44.711 23.6113 44.0888 23.3668 43.3333 23.3668C42.5777 23.3668 41.9555 23.6113 41.4666 24.1002L28.2666 37.3002ZM31.9999 59.1668C28.311 59.1668 24.8444 58.4668 21.5999 57.0668C18.3555 55.6668 15.5333 53.7668 13.1333 51.3668C10.7333 48.9668 8.83325 46.1446 7.43325 42.9002C6.03325 39.6557 5.33325 36.1891 5.33325 32.5002C5.33325 28.8113 6.03325 25.3446 7.43325 22.1002C8.83325 18.8557 10.7333 16.0335 13.1333 13.6335C15.5333 11.2335 18.3555 9.3335 21.5999 7.9335C24.8444 6.5335 28.311 5.8335 31.9999 5.8335C35.6888 5.8335 39.1555 6.5335 42.3999 7.9335C45.6444 9.3335 48.4666 11.2335 50.8666 13.6335C53.2666 16.0335 55.1666 18.8557 56.5666 22.1002C57.9666 25.3446 58.6666 28.8113 58.6666 32.5002C58.6666 36.1891 57.9666 39.6557 56.5666 42.9002C55.1666 46.1446 53.2666 48.9668 50.8666 51.3668C48.4666 53.7668 45.6444 55.6668 42.3999 57.0668C39.1555 58.4668 35.6888 59.1668 31.9999 59.1668Z"
        fill="#198754"
      />
    </g>
  </svg>
);

export default CheckCircle;
