import { FC, useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { timeParse, timeFormat } from "d3-time-format";
import "./customGraphLine.scss";

import moment from "moment";
import { Period } from "@entities/itemDetails";

interface IProps {
  data: any;
  mode: Period;
  dataSettings: any;
  dataOriginal: any;
}
const heightGraph = 310;

const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  priceSteps,
}: any) => {
  const defaultMaxMarginOfTooltip = 600;
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const refArrow = useRef<HTMLDivElement | null>(null);

  const filteredPayload = payload.filter(
    (entry: any) => entry.dataKey !== "yourCurrentPrice"
  );

  const maxPayload = Math.max(...payload.map((entry: any) => entry.value));

  const maxPriseStep = priceSteps.at(-1);
  const minPriseStep = priceSteps.at(0);
  const priceRange = maxPriseStep - minPriseStep;

  const normalizedPosition = (maxPayload - minPriseStep) / priceRange;

  const tooltipHeight = tooltipRef.current?.getBoundingClientRect().height || 0;

  const adjustedPositionY =
    (1 - normalizedPosition) * heightGraph - tooltipHeight - 70;

  const tooltipWidth = tooltipRef.current?.getBoundingClientRect().width || 0;
  const defaultHalfWidthOfTooltip = tooltipWidth / 2;

  const adjustedPositionX =
    coordinate.x > defaultMaxMarginOfTooltip
      ? defaultMaxMarginOfTooltip
      : coordinate.x;

  const adjustedPositionArrow =
    coordinate.x > defaultMaxMarginOfTooltip
      ? coordinate.x - defaultMaxMarginOfTooltip + defaultHalfWidthOfTooltip
      : defaultHalfWidthOfTooltip;
  const top =
    adjustedPositionY > 0 || adjustedPositionY > -40
      ? -adjustedPositionY + -70
      : adjustedPositionY;

  return (
    <div
      ref={tooltipRef}
      style={{
        left: `${adjustedPositionX}px`,
        top: `${top}px`,
      }}
      className="custom-tooltip-block"
    >
      <div
        ref={refArrow}
        className="tooltip-arrow"
        style={
          adjustedPositionArrow ? { left: `${adjustedPositionArrow}px` } : {}
        }
      ></div>
      <p className="tooltip-label">{moment(label).format("MMMM D")}</p>
      {filteredPayload.map((entry: any, index: number) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: entry.color,
            }}
            className="badge-item-color-box"
          ></div>
          <span className="tooltip-item">{`${entry.name}: $${entry.value}`}</span>
        </div>
      ))}
    </div>
  );
};

const CustomGraphLine: FC<IProps> = ({
  data,
  mode,
  dataSettings,
  dataOriginal,
}) => {
  const filterDataByMode = (
    data: any[],
    mode: Period,
    baseDate: Date = new Date()
  ) => {
    let startDate;

    if (mode === Period.LAST_7_DAYS) {
      startDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() - 7
      );
    } else if (mode === Period.LAST_28_DAYS) {
      startDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() - 27
      );
    } else {
      return data;
    }
    return data;
  };

  const parseDate = timeParse("%Y-%m-%d");
  const formatDate = timeFormat("%b %d");

  const calculatePriceSteps = (competitorsPrices: number[]): number[] => {
    const minValue: number = Math.min(...competitorsPrices);
    const maxValue: number = Math.max(...competitorsPrices);

    let steps: number[] = [];

    const roundingRules: number[] = [2, 5, 10, 25, 50, 100, 250, 500, 1000];
    let roundingFactorIndex: number = 0;

    while (true) {
      const roundingFactor: number = roundingRules[roundingFactorIndex];

      const adjustedMin: number =
        Math.floor(minValue / roundingFactor) * roundingFactor;
      const adjustedMax: number =
        Math.ceil(maxValue / roundingFactor) * roundingFactor;

      steps = [];
      let currentStep: number = adjustedMin;
      while (currentStep <= adjustedMax) {
        steps.push(currentStep);
        currentStep += roundingFactor;
      }

      if (steps.length <= 6) {
        break;
      } else {
        roundingFactorIndex += 1;

        if (roundingFactorIndex >= roundingRules.length) {
          console.error("Too large range");
          return [];
        }
      }
    }

    return steps;
  };

  const formattedData = data.map((d: any) => ({
    ...d,
    date: parseDate(d.date)?.getTime() ?? 0,
  }));

  const generateFixedTicks = (
    minDate: number,
    maxDate: number,
    count: number
  ) => {
    const step = (maxDate - minDate) / (count - 1);
    return Array.from({ length: count }, (_, i) => minDate + i * step);
  };

  const filteredData = filterDataByMode(formattedData, mode);

  const minDate = Math.min(...filteredData.map((d: any) => d.date));
  const maxDate = Math.max(...filteredData.map((d: any) => d.date));
  const tickCount = mode === Period.LAST_7_DAYS ? 7 : 5;
  const fixedTicks = generateFixedTicks(minDate, maxDate, tickCount);

  const priceSteps = calculatePriceSteps(
    dataOriginal.map((el: any) => +el.price)
  );

  return (
    <>
      <LineChart
        width={670}
        height={heightGraph}
        data={formattedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="2 2" stroke="#DEE2E6" />

        <XAxis
          dataKey="date"
          scale="time"
          type="number"
          domain={[minDate, maxDate]}
          ticks={fixedTicks}
          tickFormatter={(tick) => formatDate(new Date(tick))}
          tick={{ fontSize: 12, color: "#212529" }}
        />
        <YAxis
          tick={{ fontSize: 12, color: "#212529" }}
          tickFormatter={(value) => `$${value}`}
          domain={[priceSteps[0], priceSteps[priceSteps.length - 1]]}
          ticks={priceSteps}
        />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip priceSteps={priceSteps} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />

        {dataSettings.map((el: any) => {
          return (
            <Line
              isAnimationActive={false}
              animationDuration={0}
              key={el.name}
              type="linear"
              dataKey={el.name}
              stroke={el.color}
              dot={false}
              strokeDasharray={el.name === "yourCurrentPrice" ? "3 3" : ""}
            />
          );
        })}
      </LineChart>
      <div className="today-text">( Today )</div>
      <div className="legend-block">------- Your current item price</div>
    </>
  );
};

export default CustomGraphLine;
