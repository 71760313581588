import { ButtonStyled } from "@shared/ui/components";
import "./footer.scss";
import { useGroupsStore } from "@entities/groups";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";

const Footer = ({
  isCorrectData,
  handleBlurValidation,
  handleCancel,
}: {
  isCorrectData: boolean;
  handleBlurValidation: () => boolean;
  handleCancel: () => void;
}) => {
  const { id } = useParams();
  const { rollbackEditGroup, clearStrategyInStore, commitEditGroup } =
    useGroupsStore();
  const navigate = useNavigate();

  return (
    <div className="edit-group-footer">
      <ButtonStyled
        text="Cancel"
        className="w-84"
        htmlType="button"
        onClick={async () => {
          handleCancel();
        }}
      />
      <ButtonStyled
        type="primary"
        text="Save"
        className="w-84"
        htmlType="button"
        onClick={async () => {
          // handleBlurValidation();

          if (!isCorrectData) {
            id &&
              (await commitEditGroup(id).then(() => {
                navigate(
                  `${ROUTES.ADMIN_GROUPS_ID.replace(/\/:id$/, "")}/${id}`
                );
              }));

            clearStrategyInStore();
          }
        }}
      />
    </div>
  );
};

export default Footer;
