import { notification, NotificationArgsProps } from "antd";
import "./notification.scss";
import { NotificationInstance } from "antd/es/notification/interface";
import { Done, Info, Warning } from "@shared/ui/assets";

type NotificationPlacement = NotificationArgsProps["placement"];

interface CustomNotificationProps {
  placement?: NotificationPlacement;
  message: string;
  description?: string;
  duration?: number;
  type: "info" | "success" | "warning" | "error" | "default";
  className?: string;
  onClose?: () => void;
  icon?: React.ReactNode | null;
}

interface NotificationNewInstance
  extends Omit<NotificationInstance, "info" | "success" | "warning" | "error"> {
  info: (args: NotificationArgsProps) => void;
  success: (args: NotificationArgsProps) => void;
  warning: (args: NotificationArgsProps) => void;
  error: (args: NotificationArgsProps) => void;
  default: (args: NotificationArgsProps) => void;
}

const NotificationCustom = () => {
  const [api, contextHolder] = notification.useNotification();

  const getActualClassNameByType = (type: string) => {
    switch (type) {
      case "info":
        return "notification-info";
      case "warning":
        return "notification-warning";
      case "success":
        return "notification-success";
      case "error":
        return "notification-error";
      default:
        return "notification-default";
    }
  };

  const openNotification = ({
    placement = "bottom",
    message = "Message notification",
    description,
    duration = 5,
    type = "info",
    className = "notification__wrapper",
    onClose,
    icon = null,
  }: CustomNotificationProps) => {
    const compareClassNames = `${className} ` + getActualClassNameByType(type);

    const chooseIconByType = (type: string) => {
      switch (type) {
        case "info":
          return <Info />;
        case "warning":
          return <Warning />;
        case "success":
          return <Done />;
        case "error":
          return <Warning />;

        default:
          return null;
      }
    };

    const apiExtended: NotificationNewInstance = {
      ...api,
      default: (args: NotificationArgsProps) => {
        api.open({ ...args, type: undefined });
      },
    };

    apiExtended[type]({
      message,
      description,
      placement,
      duration,
      className: compareClassNames,
      closeIcon: null,
      icon: !icon ? <></> : chooseIconByType(type),
      onClose: () => {
        onClose && onClose();
      },
    });
  };

  return {
    contextHolder,
    openNotification,
  };
};

export default NotificationCustom;
