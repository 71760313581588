import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { FC } from "react";

interface IProps {
  title: string;
  arrow?: boolean;
  className?: string;
  placement?: TooltipPlacement;
  children: React.ReactElement;
  isUseClasses?: boolean;
  styles?: any;
}

const CustomTooltip: FC<IProps> = ({
  title,
  placement = "top",
  arrow = true,
  children,
  className: customClasses,
  isUseClasses = true,
  styles = {},
}: IProps) => {
  const classes = isUseClasses ? ["df", "ai-center"] : [];

  if (customClasses) {
    classes.push(customClasses);
  }
  return (
    <Tooltip
      title={title}
      arrow={arrow}
      placement={placement}
      color={"#495057"}
      styles={{ root: { ...styles, fontSize: "12px", maxWidth: "100%" } }}
    >
      <div className={classes.join(" ")}>{children}</div>
    </Tooltip>
  );
};

export default CustomTooltip;
