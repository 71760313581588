import { SelectLimitPricing } from "@entities/groups";
import * as Yup from "yup";

export const validationSchema = Yup.object({
  pricing: Yup.string().required("Please select a limit"),
  min: Yup.object({
    value: Yup.string(),
  })
    .test("min-less-than-max", "Logical mismatch.", function () {
      const { min = {}, max = {} } = this.parent || {};
      const minValue = parseFloat(min.value);
      const maxValue = parseFloat(max.value);

      if (!isNaN(minValue) && !isNaN(maxValue)) {
        return minValue <= maxValue;
      }

      return true;
    })
    .test("min-not-zero", "Value cannot be zero", function () {
      const { min = {}, pricing } = this.parent || {};

      if (
        min.value === "0.00" &&
        pricing === SelectLimitPricing.CURRENT_PRICE
      ) {
        return false;
      }
      return true;
    }),
  max: Yup.object({
    value: Yup.string(),
  })
    .test("min-less-than-max", "Logical mismatch.", function () {
      const { min = {}, max = {} } = this.parent || {};
      const minValue = parseFloat(min.value);
      const maxValue = parseFloat(max.value);

      if (!isNaN(minValue) && !isNaN(maxValue)) {
        return minValue <= maxValue;
      }

      return true;
    })

    .test("max-not-zero", "Value cannot be zero", function () {
      const { pricing, max = {} } = this.parent || {};

      if (
        max.value === "0.00" &&
        pricing === SelectLimitPricing.CURRENT_PRICE
      ) {
        return false;
      }
      return true;
    }),
});
