import {
  ButtonStyled,
  InputCustom,
  LoaderFullScreen,
} from "@shared/ui/components";
import "./teamManagement.scss";
import { Arrow, Plus, Search } from "@shared/ui/assets";
import { useEffect, useState } from "react";
import { FilterItem } from "@features/filterItem";
import {
  IFilterItem,
  ISortParams,
  ROUTES,
  SortParams,
} from "@shared/interfaces";
import { UserRolesEnum, useSettingsStore } from "@entities/settings";
import TableUsers from "./TableUsers/TableUsers";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";
import InviteUsersPopup from "./InviteUsersPopup/InviteUsersPopup";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import { filteredCompareQueryObj, getParamsAsObject } from "@shared/utils";
import { LayoutWithAside } from "@widgets/admin/layouts/aside";

const ALL_USERS = "all_users";

const TeamManagement = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState<string>(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const [isOpenInviteUsersPopup, setOpenInviteUsersPopup] =
    useState<boolean>(false);

  const limitQuery = searchParams.get(LIMIT_PARAM);
  const offsetQuery = searchParams.get(OFFSET_PARAM);
  const filterQuery = searchParams.get(FILTER_BY_PARAM);
  const orderByQuery = searchParams.get(ORDER_BY_PARAM);
  const directionQuery = searchParams.get(DIRECTION_PARAM);

  const limitInitial = limitQuery ? +limitQuery : DEFAULT_LIMIT_PAGINATION;
  const offsetInitial = offsetQuery ? +offsetQuery : DEFAULT_OFFSET_PAGINATION;
  const filterInitial = filterQuery ? filterQuery : ALL_USERS;
  const orderByInitial = orderByQuery ? orderByQuery : "";
  const directionInitial = directionQuery
    ? (directionQuery as SortParams)
    : null;

  const [currentLimit, setCurrentLimit] = useState<number>(limitInitial);
  const [currentOffset, setCurrentOffset] = useState<number>(offsetInitial);
  const [activeFilter, setActiveFilter] = useState<string>(filterInitial);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | null;
  }>({
    key: orderByInitial,
    direction: directionInitial,
  });

  const {
    getUsersTable,
    getFiltersForUsers,
    filtersUsersTable,
    isLoadingUsersTableInitial,
    setUsersTable,
  } = useSettingsStore((state) => state);

  const filterItems: IFilterItem[] = [
    {
      name: `All users ${filtersUsersTable.users}`,
      value: ALL_USERS,
    },
    {
      name: `Owner ${filtersUsersTable.usersOwners}`,
      value: UserRolesEnum.OWNER,
    },
    {
      name: `Admin ${filtersUsersTable.usersAdmins}`,
      value: UserRolesEnum.ADMIN,
    },
    {
      name: `Manager ${filtersUsersTable.usersManagers}`,
      value: UserRolesEnum.MANAGER,
    },
    {
      name: `Viewer ${filtersUsersTable.usersViewers}`,
      value: UserRolesEnum.VIEWER,
    },
  ];

  const updateParams = (
    newParams: Record<string, string | number | null | undefined>
  ) => {
    const updatedParams = new URLSearchParams(searchParams);
    Object.entries(newParams).forEach(([key, value]: any) => {
      if (value === null || value === undefined || value === "") {
        updatedParams.delete(key);
      } else {
        updatedParams.set(key, value);
      }
    });

    setSearchParams(updatedParams);
  };

  useEffect(() => {
    setUsersTable([]);
    const params = filteredCompareQueryObj({
      ...getParamsAsObject(searchParams),
      [LIMIT_PARAM]: currentLimit.toString(),
      [OFFSET_PARAM]: currentOffset,
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction || undefined,
      [FILTER_BY_PARAM]: activeFilter === ALL_USERS ? "" : activeFilter,
      [SEARCH_PARAM]: inputValue,
    } as any);

    getUsersTable(params);
  }, [currentLimit, currentOffset, activeFilter, sortConfig, inputValue]);

  useEffect(() => {
    getFiltersForUsers();
  }, []);

  const debounced = useDebouncedCallback(async (value) => {
    updateParams({ [SEARCH_PARAM]: value });
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    debounced(value.trim());
  };

  const onChangeFilter = (value: string) => {
    setActiveFilter(value);
    updateParams({ [FILTER_BY_PARAM]: value === ALL_USERS ? "" : value });
  };

  return (
    <LayoutWithAside>
      <>
        <div className="main-header-tm">
          <div className="back_to-title">
            <div
              className="back_to_container-title"
              onClick={() => navigate(ROUTES.ADMIN_SETTINGS)}
            >
              <Arrow />
              <h5 className="">Back to Settings</h5>
            </div>
            <div className="header__title ">Team Management</div>
          </div>
        </div>
        <InviteUsersPopup
          flag={isOpenInviteUsersPopup}
          onClose={() => setOpenInviteUsersPopup(false)}
        />
        {isLoadingUsersTableInitial ? (
          <LoaderFullScreen />
        ) : (
          <div className="block-tm">
            <div className="tm-block-filters">
              <div className="tm-filters-container">
                <div className="mr-32">
                  <InputCustom
                    type="text"
                    value={inputValue}
                    name="group-search"
                    placeholder="Search for team member"
                    onChangeInput={onSearchChange}
                    fill="gray-primary-900"
                    iconRight={<Search />}
                  />
                </div>
                <div className="tm-filters">
                  {filterItems.map((item) => (
                    <FilterItem
                      item={item}
                      key={item.value}
                      active={activeFilter}
                      onClick={(value) => onChangeFilter(value)}
                    />
                  ))}
                </div>
              </div>
              <ButtonStyled
                type="primary"
                text="Invite team member"
                htmlType="button"
                fill="gray-primary-900"
                className="users-invite__btn"
                icon={<Plus />}
                onClick={() => setOpenInviteUsersPopup(true)}
              />
            </div>
            <TableUsers
              setSortConfig={setSortConfig}
              // tableWidth={tableWidth}
              sortConfig={sortConfig}
              setCurrentLimit={setCurrentLimit}
              setCurrentOffset={setCurrentOffset}
              currentOffset={currentOffset}
              currentLimit={currentLimit}
              updateParams={updateParams}
            />
          </div>
        )}
      </>
    </LayoutWithAside>
  );
};

export default TeamManagement;
