import { useGroupStore } from "@entities/group";
import { EGroupStatus, IGroup, useGroupsStore } from "@entities/groups";
import { IProduct } from "@entities/products";
import { BRAND_PARAM } from "@shared/constants";
import {
  GROUP_EVENTS,
  ITEM_EVENTS,
  SOCKET_DEFAULT_EVENTS,
} from "@shared/constants/socketConstants";
import { IDataTransfer } from "@shared/interfaces";
import { filteredCompareQueryObj, getLSKeyRepricedIds } from "@shared/utils";
import socket from "@shared/utils/configs/socket";
import { useEffect, useState } from "react";

const useSocket = () => {
  const {
    CONNECT,
    DISCONNECT,
    CONNECT_ERROR,
    CONNECT_TIMEOUT,
    PING,
    PONG,
    ERROR,
  } = SOCKET_DEFAULT_EVENTS;
  const [isConnected, setIsConnected] = useState(false);
  const { setNewStatusGroup, getProductsInGroup } = useGroupStore(
    (state) => state
  );

  const { setNewStatusToGroups } = useGroupsStore((state) => state);

  const getCountNewPriceReady = useGroupStore(
    (state) => state.getCountNewPriceReady
  );

  useEffect(() => {
    socket.on(CONNECT, () => {
      setIsConnected(true);
      console.log("Socket ID:", socket.id);
    });

    socket.on(DISCONNECT, () => {
      setIsConnected(false);
      console.log("Disconnected from socket server useSocket");
    });

    socket.on(
      GROUP_EVENTS.APPLYING_PRICES_SUCCESSFUL,
      async (data: {
        payload: { groupWithUpdates: IGroup; repricedProductsIds: string[] };
      }) => {
        const { payload } = data;
        localStorage.setItem(
          getLSKeyRepricedIds(payload.groupWithUpdates.id),
          data.payload.repricedProductsIds.toString()
        );
        const params = new URLSearchParams(window.location.search);
        const brands = params.get("brand")?.split(",") || null;
        // const filter = params.get("filter") || null;
        const dataParams = {
          [BRAND_PARAM]: brands,
          // [FILTER_BY_PARAM]: filter,
        };

        const filteredData = filteredCompareQueryObj(
          dataParams
        ) as IDataTransfer;
        await getProductsInGroup({
          groupId: payload.groupWithUpdates.id,
          ...filteredData,
        });
        setNewStatusGroup(EGroupStatus.COMPLETED);
        setNewStatusToGroups(data.payload.groupWithUpdates);
        await getCountNewPriceReady();
      }
    );

    socket.on(
      ITEM_EVENTS.APPLYING_PRICE_SUCCESSFUL,
      async (data: { payload: IProduct }) => {
        // await getCountNewPriceReady();
        // changeProduct(data.payload);
      }
    );

    socket.on(CONNECT_ERROR, (err: any) => {
      console.error("Connection error:", err);
    });

    socket.on(CONNECT_TIMEOUT, () => {
      console.error("Connection timed out");
    });

    socket.on(ERROR, (err: any) => {
      console.error("Socket error:", err);
    });

    socket.on(PONG, (data) => {
      const latency = Date.now() - data.timestamp;
      console.log(`Received pong from server, latency: ${latency} ms`);
    });
    const pingInterval = setInterval(() => {
      if (socket.connected) {
        socket.emit(PING, (response: any) => {
          console.log("Ping response from server:", response);
        });
      } else {
        console.log("Socket is not connected, skipping ping");
      }
    }, 90000);

    return () => {
      socket.off(CONNECT);
      socket.off(DISCONNECT);
      socket.off(CONNECT_ERROR);
      socket.off(CONNECT_TIMEOUT);
      socket.off(ERROR);
      socket.off(PONG);
      clearInterval(pingInterval);
    };
  }, []);

  const emitEvent = (event: string, data: any) => {
    socket.emit(event, data);
  };
  return {
    isConnected,
    emitEvent,
  };
};

export default useSocket;
