import {
  EGroupStatus,
  NEW_REPRICE_READY,
  useGroupsStore,
} from "@entities/groups";
import { Arrow, Delete, Dots, Edit, Export } from "@shared/ui/assets";
import { AsideDropdown, LayoutWithAside } from "@widgets/admin";
import { Divider, MenuProps, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./groupPage.scss";
import {
  ButtonStyled,
  CustomTooltip,
  LoaderDefault,
  LoaderFullScreen,
  NotificationCustom,
} from "@shared/ui/components";
import { ROUTES } from "@shared/interfaces";
import { getMenuPropsItems } from "@shared/common";
import { GroupItems } from "@widgets/admin/group";
import { GroupDeletePopup } from "@widgets/admin/group/ui/GroupDeletePopup";
import { GroupEditPopup } from "@widgets/admin/group/ui/GroupEditPopup";
import { GroupApplyNewPricesPopup } from "@widgets/admin/group/ui/GroupApplyNewPricesPopup";
import { getItemWord, getLSKeyRepricedIds } from "@shared/utils";
import { VIEWER_DISABLE_ITEMS_TEXT } from "@shared/constants/utilsConstants";
import useCheckRole from "@shared/model/hooks/useCheckRole";
import { useGroupStore } from "@entities/group";
import { FILTER_BY_PARAM, GROUP_ID_PARAM } from "@shared/constants";
import moment from "moment";

const tabOptions = [
  {
    label: <h4 className="tab-title-page">Overview</h4>,
    key: "overview",
    disabled: true,
    children: <></>,
  },
  {
    label: <h4 className="tab-title-page">Items</h4>,
    key: "items",
    disabled: false,
    children: <GroupItems />,
  },
  // {
  //   label: <h4 className="tab-title-page">Configuration</h4>,
  //   key: "configuration",
  //   disabled: true,
  //   children: <></>,
  // },
];

type MenuItem = Required<MenuProps>["items"][number];

const GroupPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isViewer } = useCheckRole();
  const { contextHolder, openNotification } = NotificationCustom();
  const groupName = useGroupStore((state) => state.currentGroup.name);
  const status = useGroupStore((state) => state.currentGroup.status);
  const { startEditGroup } = useGroupsStore((state) => state);

  const productsInGroup = useGroupStore((state) => state.productsInGroup);

  const isInitialLoadingGroupItems = useGroupStore(
    (state) => state.isInitialLoadingGroupItems
  );
  const productsNewRepriceReady = useGroupStore(
    (state) => state.productsNewRepriceReady
  );

  const {
    getGroup,
    clearGroup,
    setCompetitorsInGroup,
    applyNewPrices,
    setRepricedIds,
    setNewStatusGroup,
    setCountNewPriceReady,
    clearIsRepricedStatuses,
    getProductsForReprice,
    getProductsOfGroupCSVFile,
  } = useGroupStore((state) => state);

  const [isDeleteGroupPopupShow, setDeleteGroupPopupShow] =
    useState<boolean>(false);
  const [isEditGroupPopupShow, setEditGroupPopupShow] =
    useState<boolean>(false);
  const [isApplyNewPricesPopupShow, setApplyNewPricesPopupShow] =
    useState<boolean>(false);

  useEffect(() => {
    setRepricedIds([]);

    if (id) {
      getGroup(id);
    }
    return () => {
      id && localStorage.removeItem(getLSKeyRepricedIds(id));
      setRepricedIds([]);
    };
  }, []);

  useEffect(() => {
    if (id) {
      const isLSShowNotificationValue = localStorage.getItem(
        getLSKeyRepricedIds(id)
      );
      if (!isLSShowNotificationValue) return;
      if (
        id &&
        productsInGroup.some((el: any) => el.isRepriced) &&
        isLSShowNotificationValue &&
        status === EGroupStatus.COMPLETED
      ) {
        const countRepriced = isLSShowNotificationValue.split(",").length;

        openNotification({
          message: `${countRepriced} ${getItemWord(
            countRepriced
          )} have been successfully repriced in ${groupName} group`,
          type: "success",
          placement: "bottom",
          onClose: () => {
            setRepricedIds([]);
            clearIsRepricedStatuses();
            localStorage.removeItem(getLSKeyRepricedIds(id));
          },
        });
      }
    }
  }, [productsInGroup, status]);

  const isProcessStatus = status === EGroupStatus.IN_PROGRESS;

  const handleDownload = async () => {
    const response = await getProductsOfGroupCSVFile(id || "");

    if (response.status !== 200) {
      console.error("Download failed");
      return;
    }

    const blob = new Blob([response.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${groupName}_${moment().format("YYYY-MM-DD")}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const iconExport = (
    <div onClick={handleDownload}>
      <Export />
    </div>
  );

  const itemsTopMenu: MenuItem[] = [
    getMenuPropsItems(
      <>Edit</>,
      "edit",
      <Edit width="40" height="40" />,
      undefined,
      isViewer,
      false,
      async () => {
        await startEditGroup(id as string);
        navigate(`${ROUTES.ADMIN_GROUPS_EDIT}/${id}`);
      }
    ),
    getMenuPropsItems(
      <div onClick={handleDownload}>Download CSV</div>,
      "export",
      iconExport,
      undefined,
      isViewer,
      false
    ),
    getMenuPropsItems(
      "Delete group",
      "delete",
      <Delete />,
      undefined,
      isViewer,
      true,
      () => setDeleteGroupPopupShow(true)
    ),
  ];

  const handleClick = () => {
    navigate(ROUTES.ADMIN_GROUPS);
    clearGroup();
    // clearStrategyInStore();
    setCompetitorsInGroup([]);
  };

  const onCloseDeletePopupHandler = (flag: boolean) => {
    setDeleteGroupPopupShow(flag);
  };

  const handleOpenPopupApplyNewPrices = async () => {
    await getProductsForReprice({
      [GROUP_ID_PARAM]: id,
      [FILTER_BY_PARAM]: NEW_REPRICE_READY,
    }).then(() => {
      setApplyNewPricesPopupShow(true);
    });
  };

  const onOkApplyNewPrices = async () => {
    setApplyNewPricesPopupShow(false);
    setNewStatusGroup(EGroupStatus.IN_PROGRESS);
    await applyNewPrices(id as string);
    setCountNewPriceReady(0);
  };

  const chooseTextBtnApplyNewPrices = () => {
    if (isProcessStatus) {
      return (
        <>
          {/* <LoaderBlock />  */}
          {`Applying prices (${productsNewRepriceReady})`}
        </>
      );
    }
    if (status === EGroupStatus.REPRICE_READY && productsNewRepriceReady > 0) {
      return `Apply new prices (${productsNewRepriceReady})`;
    }
    if (status === EGroupStatus.COMPLETED && productsNewRepriceReady > 0) {
      return `Apply new prices (${productsNewRepriceReady})`;
    }
    return `Apply new prices ${
      productsNewRepriceReady ? `(${productsNewRepriceReady})` : ""
    }`;
  };

  const chooserTooltipText = () => {
    if (isViewer) {
      return VIEWER_DISABLE_ITEMS_TEXT;
    }
    if (isProcessStatus) {
      return `Repricing in progress`;
    }
    if (status === EGroupStatus.REPRICE_READY && productsNewRepriceReady > 0) {
      return `${productsNewRepriceReady} ${getItemWord(
        productsNewRepriceReady
      )} ready to reprice`;
    }
    if (status === EGroupStatus.COMPLETED && productsNewRepriceReady > 0) {
      return `${productsNewRepriceReady} ${getItemWord(
        productsNewRepriceReady
      )} ready to reprice`;
    }
    if (productsNewRepriceReady) {
      return `${productsNewRepriceReady} ${getItemWord(
        productsNewRepriceReady
      )} ready to reprice`;
    }
    return `No items to reprice`;
  };

  return (
    <>
      {contextHolder}
      <GroupDeletePopup
        flag={isDeleteGroupPopupShow}
        onClose={onCloseDeletePopupHandler}
      />
      <GroupEditPopup
        flag={isEditGroupPopupShow}
        onClose={setEditGroupPopupShow}
      />
      <GroupApplyNewPricesPopup
        flag={isApplyNewPricesPopupShow}
        onClose={() => setApplyNewPricesPopupShow(false)}
        onOk={() => onOkApplyNewPrices()}
      />
      {isInitialLoadingGroupItems ? <LoaderFullScreen /> : null}

      <LayoutWithAside>
        <div className="group-page">
          <div className="df ai-center jc-sb group-page-title">
            <div className="">
              <div className="back_to_container" onClick={handleClick}>
                <Arrow />
                <h5 className="">Back to Groups</h5>
              </div>
              <h1 className="">{groupName || <>&nbsp;</>}</h1>
            </div>

            {!isInitialLoadingGroupItems ? (
              <div className="edit-title__actions">
                <CustomTooltip title={chooserTooltipText()}>
                  <div className={isViewer ? "disabled" : ""}>
                    <ButtonStyled
                      type="primary"
                      text={chooseTextBtnApplyNewPrices()}
                      htmlType="button"
                      fill="#fff3cd"
                      disabled={productsNewRepriceReady === 0}
                      className={`groups-empty__btn w-188 mr-16 ${
                        isProcessStatus && "disabled"
                      }`}
                      onClick={() =>
                        !isViewer && handleOpenPopupApplyNewPrices()
                      }
                      iconPosition="start"
                      icon={isProcessStatus ? <LoaderDefault /> : undefined}
                    />
                  </div>
                </CustomTooltip>
                <CustomTooltip
                  title={isViewer ? VIEWER_DISABLE_ITEMS_TEXT : ""}
                  styles={{ width: "250px" }}
                >
                  <div
                    className={isProcessStatus || isViewer ? "disabled" : ""}
                  >
                    <AsideDropdown
                      collapsed={true}
                      items={itemsTopMenu}
                      placement="bottomRight"
                      className={"relu__menu"}
                    >
                      <div className="dots-title__btn">
                        <Dots color="#212529" />
                      </div>
                    </AsideDropdown>
                  </div>
                </CustomTooltip>
              </div>
            ) : null}
          </div>
          <Divider />

          {isProcessStatus ? (
            <div className="applying-prices-block">
              <div className="applying-prices-block__content">
                <div>
                  <LoaderDefault className="loader" />
                </div>
                <div className="block__text">
                  <h2 className="block__text__title">Applying prices...</h2>
                  <div className="block__text__subtitle">
                    It can take up to 1 minute.
                  </div>
                  <div className="block__text__subtitle">
                    Group actions temporarily disabled.
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="group-page-tabs">
            <Tabs
              defaultActiveKey="1"
              items={tabOptions.map(({ label, key, children }, ind) => {
                return {
                  disabled: true,
                  label: label,
                  key: ind.toString(),
                  children: children,
                };
              })}
            />
          </div>
        </div>
      </LayoutWithAside>
    </>
  );
};

export default GroupPage;
