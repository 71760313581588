import React from "react";

interface IProps {
  color?: string;
}

const GroupsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
    >
      <g clipPath="url(#clip0_14666_65377)">
        <path
          d="M36 0.5H8C3.58172 0.5 0 4.08172 0 8.5V36.5C0 40.9183 3.58172 44.5 8 44.5H36C40.4183 44.5 44 40.9183 44 36.5V8.5C44 4.08172 40.4183 0.5 36 0.5Z"
          fill="#E2D9F3"
        />
        <path
          d="M22 22.75C23.63 22.75 25.07 23.14 26.24 23.65C27.32 24.13 28 25.21 28 26.38V27C28 27.55 27.55 28 27 28H17C16.45 28 16 27.55 16 27V26.39C16 25.21 16.68 24.13 17.76 23.66C18.93 23.14 20.37 22.75 22 22.75ZM14 23C15.1 23 16 22.1 16 21C16 19.9 15.1 19 14 19C12.9 19 12 19.9 12 21C12 22.1 12.9 23 14 23ZM15.13 24.1C14.76 24.04 14.39 24 14 24C13.01 24 12.07 24.21 11.22 24.58C10.48 24.9 10 25.62 10 26.43V27C10 27.55 10.45 28 11 28H14.5V26.39C14.5 25.56 14.73 24.78 15.13 24.1ZM30 23C31.1 23 32 22.1 32 21C32 19.9 31.1 19 30 19C28.9 19 28 19.9 28 21C28 22.1 28.9 23 30 23ZM34 26.43C34 25.62 33.52 24.9 32.78 24.58C31.93 24.21 30.99 24 30 24C29.61 24 29.24 24.04 28.87 24.1C29.27 24.78 29.5 25.56 29.5 26.39V28H33C33.55 28 34 27.55 34 27V26.43ZM22 16C23.66 16 25 17.34 25 19C25 20.66 23.66 22 22 22C20.34 22 19 20.66 19 19C19 17.34 20.34 16 22 16Z"
          fill="#212529"
        />
      </g>
      <defs>
        <clipPath id="clip0_14666_65377">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GroupsIcon;
