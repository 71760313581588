import { Divider, Modal, Select } from "antd";
import { FC, useState } from "react";
import "./deleteAccountPopupByOwner.scss";
import { ArrowBorder, Close } from "@shared/ui/assets";
import { ButtonStyled, RadioCustom } from "@shared/ui/components";
import React from "react";
import { useFormik } from "formik";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
}

const initialValues = {
  activeRadio: "",
};

const DeleteAccountPopupByOwner: FC<IProps> = (props) => {
  const { flag, onClose, onOk } = props;

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: {},
    onSubmit: async (values) => {
      try {
        alert("DeleteAccountPopupByOwner");
      } catch (error: any) {
        console.log(error, "error");
      }
    },
  });

  const { setFieldValue, setValues, isValid, values } = formik;

  const optionRadio = [
    {
      text: "Delegate owner rights",
      key: "1",
      classes: "mb-12",
    },
    {
      text: "Delete my account and organization",
      key: "2",
      classes: "",
    },
  ];
  return (
    <>
      <Modal
        width={500}
        open={flag}
        onOk={() => {}}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => onClose()}
      >
        <div className="modal-remove-account">
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <div className="modal-remove-account-title">Delete Account?</div>
          <Divider />
          <div className="modal-remove-account-content">
            <span>
              This will delete your account. You will lose your current access
              to the app. You cannot undo this action.
            </span>
            {false && (
              <>
                <div className="radio-block">Select scenario to proceed:</div>

                {optionRadio.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`radio-block-item ${item.classes}`}
                      onClick={() => setFieldValue("activeRadio", item.key)}
                    >
                      <RadioCustom checked={item.key === values.activeRadio} />
                      <span>{item.text}</span>
                    </div>
                    {index === 0 && values.activeRadio === "1" ? (
                      <div className="radio-block-input">
                        <div>
                          <span>Transfer owner rights to</span>
                        </div>
                        <Select
                          onChange={() => {}}
                          options={[]}
                          suffixIcon={<ArrowBorder color="#212527" />}
                          className="custom-arrow"
                          placeholder="Select a user"
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}

                <div className="radio-block-description">
                  Deleting the account will also delete the entire organization,
                  including all associated user accounts
                </div>
              </>
            )}
          </div>

          <Divider />
          <div className="modal-remove-account-footer">
            <ButtonStyled
              text="Cancel"
              type="default"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onClose}
            />
            <ButtonStyled
              text="Delete my account"
              type="primary"
              htmlType="button"
              fill="red-600"
              className="w-181"
              onClick={onOk}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccountPopupByOwner;
